import moment from 'moment-timezone';
import { TripStopTimeWindowStatuses } from '../store/config/enums/tripStatusFilter.enum';
import { Stop, Trip, TripArrangeStop, TripWithStopsDetails } from '../store/config/types/trips.types';
import { Customer } from '../store/config/types';

export function isAlliveTrip(trip: Trip) {
  return ['IN_TRANSIT', 'NEW', 'PENDING'].includes(trip.status);
}

export function getTimeWindowStatus(
  stop: Stop | TripArrangeStop,
  tripStatus: string = 'NEW',
  tripStart?: Date,
  timezone?: string,
  computedMode?: boolean,
): TripStopTimeWindowStatuses {
  const tz = timezone ? moment().tz(timezone).format('ZZ') : '';
  const delayedMinutes = Math.max(
    ['NEW', 'PENDING'].includes(tripStatus) && tripStart ? moment().diff(tripStart, 'minute') : 0,
    0,
  );
  const start = moment(
    computedMode
      ? stop.computedArrivalAt
      : ('schedulerArrivalAt' in stop ? stop.schedulerArrivalAt : undefined) ?? stop.routerArrivalAt,
  ).add(delayedMinutes, 'minutes');
  const end = moment(
    computedMode
      ? stop.computedDepartureAt
      : ('schedulerDepartureAt' in stop ? stop.schedulerDepartureAt : undefined) ?? stop.routerDepartureAt,
  ).add(delayedMinutes, 'minutes');
  const timeWindowFrom = moment(
    `${start.format('YYYY-MM-DD')}T${stop.timeWindowFrom ?? '00:00:00'}${tz}`,
    'YYYY-MM-DDTHH:mm:ssZ',
  );
  const timeWindowTo = moment(
    `${end.format('YYYY-MM-DD')}T${stop.timeWindowTo ?? '23:59:59'}${tz}`,
    'YYYY-MM-DDTHH:mm:ssZ',
  );

  if (!timeWindowTo && !timeWindowFrom) return TripStopTimeWindowStatuses.TIME_WINDOW_UNDEFINED;

  if (
    (start.diff(timeWindowFrom, 'minutes') >= 0 && start.diff(timeWindowTo, 'minutes') <= 0) ||
    (end.diff(timeWindowFrom, 'minutes') >= 0 && end.diff(timeWindowTo, 'minutes') <= 0)
  )
    return TripStopTimeWindowStatuses.TIME_WINDOW_IN_RANGE;
  else return TripStopTimeWindowStatuses.TIME_WINDOW_WARNING;
}

export const stopSortBySchedulerTimes = (a: Stop | TripArrangeStop, b: Stop | TripArrangeStop) =>
  moment(a.schedulerArrivalAt ?? a.routerArrivalAt).unix() - moment(b.schedulerArrivalAt ?? b.routerArrivalAt).unix();

export function convertTripToTripWithStopsDetails(trip?: Trip): TripWithStopsDetails | null {
  if (!trip) return null;
  return {
    ...(trip as unknown as TripWithStopsDetails),
    DSP: trip.dspJob?.deliveryServiceProvider,
    dspRefrenceId: trip.dspJob?.dspReferenceId,
  };
}

export const stopSortByComputedTimes = (a: Stop | TripArrangeStop, b: Stop | TripArrangeStop) => {
  return moment(a.computedArrivalAt).unix() - moment(b.computedArrivalAt).unix();
};

/* eslint-disable */
export const filterByComputedDate = (
  a: Stop | TripArrangeStop,
  index: number,
  allStops: Stop[] | TripArrangeStop[],
) => {
  return Boolean(a.computedArrivalAt);
};
/* eslint-enable */

export const customerLabel = (customer?: Customer, customerIndex?: number) => {
  return customer && (customer.firstName || customer.lastName)
    ? `${(customer.firstName ?? ' ')[0]} ${(customer.lastName ?? ' ')[0]}`
    : customer?.companyName
    ? `${customer.companyName[0]}`
    : `C${customerIndex ?? ''}`;
};
