import { useEffect, useState } from 'react';
import { CostInput, CostInputUoM } from '../../store/config/types/cost.types';
import { CostService } from '../../services/cost.service';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Add, Delete, Edit } from '@material-ui/icons';

interface CostsFormProps {
  subject: string;
  subjectId: string;
  // eslint-disable-next-line
  setToast?: (message: string, messageType: string) => void;
}
export default function CostsForm({ subject, subjectId, setToast }: CostsFormProps) {
  const [costs, setCosts] = useState<CostInput[]>();
  const [costToDelete, setCostToDelete] = useState(0);
  const [costToEdit, setCostToEdit] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [newValue, setNewValue] = useState('0');
  const [newCategory, setNewCategory] = useState('');
  const [newUoM, setNewUoM] = useState('ONETIME');
  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    CostService.getSubjectCosts(subject, subjectId).then((result) => {
      setCosts(result);
    });
  }, [subject, subjectId]);

  const handleDialogDeleteClose = () => {
    if (!processing) setCostToDelete(0);
  };

  const handleDialogNewClose = () => {
    if (!processing) {
      setOpenNew(false);
      setNewUoM('ONETIME');
      setNewValue('0');
      setNewCategory('');
    }
  };

  const handleDialogEditClose = () => {
    if (!processing) {
      setCostToEdit(0);
      setNewValue('0');
    }
  };
  const handleDeleteCost = () => {
    setProcessing(true);
    CostService.deleteCost(costToDelete)
      .then((deleted) => {
        if (deleted) {
          setToast && setToast('Cost deleted', 'success');
          setCosts(costs?.filter((cost) => cost.costInputId !== costToDelete));
          handleDialogDeleteClose();
        } else {
          setToast && setToast('Can not delete cost', 'danger');
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleNewCost = () => {
    setProcessing(true);
    CostService.newCost(subject, subjectId, newCategory, newUoM, +newValue)
      .then((cost) => {
        if (cost) {
          setToast && setToast('Cost created successfuly', 'success');
          setCosts([...(costs ?? []), cost]);
          handleDialogNewClose();
        } else {
          setToast && setToast('Can not create cost', 'danger');
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleEditCost = () => {
    const cost = costs?.find((c) => c.costInputId === costToEdit);
    if (cost && +newValue !== cost.value) {
      setProcessing(true);
      CostService.editCost(subject, subjectId, costToEdit, +newValue)
        .then((edited) => {
          if (edited) {
            const newCosts: CostInput[] =
              costs?.map((c) => {
                return c.costInputId === costToEdit ? { ...c, value: +newValue } : { ...c };
              }) ?? [];
            setCosts(newCosts);
            handleDialogEditClose();
          } else {
            setToast && setToast('Can not edit cost', 'danger');
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <>
      <h3 className="section-title">Costs</h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>UoM</TableCell>
            <TableCell>Value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costs &&
            costs.map((cost) => {
              return (
                <TableRow key={`cost-${cost.costInputId}`}>
                  <TableCell>{cost.costInputCategory}</TableCell>
                  <TableCell>{cost.UoM}</TableCell>
                  <TableCell>{cost.value}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNewValue(`${cost.value}`);
                        setCostToEdit(cost.costInputId);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setCostToDelete(cost.costInputId);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <IconButton
        size="small"
        onClick={() => {
          setOpenNew(true);
        }}
      >
        <Add />
      </IconButton>
      <Dialog open={costToDelete > 0} onClose={handleDialogDeleteClose}>
        <DialogTitle>Delete Cost</DialogTitle>
        <DialogContent>Are you sure to delete?</DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDeleteCost} disabled={processing}>
            {processing ? <CircularProgress size={24} /> : <>Yes</>}
          </Button>
          <Button variant="outlined" color="success" onClick={handleDialogDeleteClose} disabled={processing}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={costToEdit > 0} onClose={handleDialogEditClose}>
        <DialogTitle>Edit Cost</DialogTitle>
        <DialogContent>
          <Grid container>
            <h3>New Value for {costs?.find((cost) => cost.costInputId === costToEdit)?.costInputCategory}</h3>
          </Grid>
          <Grid container>
            <TextField
              type="number"
              value={newValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogEditClose} disabled={processing}>
            Cancel
          </Button>
          <Button color="success" onClick={handleEditCost} disabled={processing}>
            {processing ? <CircularProgress size={24} /> : <>Ok</>}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openNew} onClose={handleDialogNewClose}>
        <DialogTitle>New Cost</DialogTitle>
        <DialogContent>
          <Grid container mt={2}>
            <TextField
              label="Category"
              variant="outlined"
              size="small"
              value={newCategory}
              onChange={(e) => {
                setNewCategory(e.target.value);
              }}
            />
          </Grid>
          <Grid container mt={2}>
            <FormControl fullWidth>
              <InputLabel id="dialog-new-UoM-label">UoM</InputLabel>
              <Select
                labelId="dialog-new-UoM-label"
                variant="outlined"
                size="small"
                value={newUoM}
                label="UoM"
                onChange={(e) => {
                  setNewUoM(e.target.value);
                }}
              >
                <MenuItem value={CostInputUoM.ONETIME}>One Time</MenuItem>
                <MenuItem value={CostInputUoM.COUNT}>Per Count</MenuItem>
                <MenuItem value={CostInputUoM.MINUTE}>Per Minute</MenuItem>
                <MenuItem value={CostInputUoM.HOUR}>Per Hour</MenuItem>
                <MenuItem value={CostInputUoM.DAY}>Per Day</MenuItem>
                <MenuItem value={CostInputUoM.WEEK}>Per Week</MenuItem>
                <MenuItem value={CostInputUoM.MONTH}>Per Moth</MenuItem>
                <MenuItem value={CostInputUoM.YEAR}>Per Year</MenuItem>
                <MenuItem value={CostInputUoM.FOOT}>Per Foot</MenuItem>
                <MenuItem value={CostInputUoM.MILE}>Per Mile</MenuItem>
                <MenuItem value={CostInputUoM.LITER}>Per Liter</MenuItem>
                <MenuItem value={CostInputUoM.GALLON}>Per Gallon</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container mt={2}>
            <TextField
              type="number"
              label="Value"
              variant="outlined"
              size="small"
              value={newValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogNewClose} disabled={processing}>
            Cancel
          </Button>
          <Button color="success" onClick={handleNewCost} disabled={processing}>
            {processing ? <CircularProgress size={24} /> : <>Ok</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
