import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { VehicleEnum } from '../../store/config/enums/vehicle.enum';

import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import Spinner from '../../components/Spinner/Spinner';
import SelectField from '../../components/SpecialInput/SelectField';
import { RootState, Vehicle } from '../../store/config/types';
import { FormControl, MenuItem } from '@mui/material';
import { ThunkDispatch } from 'redux-thunk';
import { ConnectedProps, connect } from 'react-redux';
import { setToast } from '../../store/actions/toast.actions';
import CostsForm from '../../components/Cost/CostsForm';
import { VehiclesService } from '../../services/vehicles.service';
import { hasPermission } from '../../utils/permissions';

interface VehicleParams {
  vehicleId?: string;
}

interface VehicleValues {
  vehicleId?: number;
  vehicleType?: VehicleEnum;
  licensePlate: string;
  model: string;
  color: string;
  capacity?: number;
}
const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.account,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
const VehicleForm = ({ loggedIn, user }: PropsFromRedux) => {
  const { vehicleId } = useParams<VehicleParams>();
  const editMode: boolean = Boolean(vehicleId);
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();

  useEffect(() => {
    if (vehicleId) {
      VehiclesService.fetchVehicle(+vehicleId)
        .then((vehicle) => {
          setSelectedVehicle(vehicle);
        })
        .catch((err) => {
          history.goBack();
          setToast(err.response?.data?.message ?? err.message, 'danger');
        });
    }
  }, [editMode, loggedIn, vehicleId]);

  // useEffect(() => {
  //   if (submitting) {
  //     // todo update vehicle
  //   }
  // }, [submitting, history, setSubmitting]);

  const valueChangedHandler = (setFieldValue: any, e: any) => setFieldValue('vehicleType', e.target.value);
  const handleGoBack = () => history.push('/vehicles');

  const submitForm = async (values: VehicleValues) => {
    const VehicleRequest: Vehicle = {
      vehicleType: values.vehicleType!,
      licensePlate: values.licensePlate,
      model: values.model,
      color: values.color,
      capacity: values.capacity!,
    };
    setSubmitting(true);
    try {
      if (editMode && vehicleId) {
        await VehiclesService.updateVehicle(+vehicleId, VehicleRequest);
      } else {
        await VehiclesService.createVehicle(VehicleRequest);
      }
      setToast(`${editMode ? 'Edit' : 'Create'} Successful`, 'success');
      history.goBack();
    } catch (err: any) {
      setToast(err?.message ?? 'Error', 'danger');
    }
    setSubmitting(false);
  };

  if (editMode && !selectedVehicle) {
    return <Spinner />;
  }

  return (
    <div className="vehicle-form">
      <Card profile>
        <CardHeader color="primary" className="card-header">
          {editMode && selectedVehicle ? (
            <h4>{`${selectedVehicle.vehicleType} ${selectedVehicle.model} ${selectedVehicle.licensePlate}`}</h4>
          ) : (
            <h4>New Vehicle</h4>
          )}
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              vehicleType: selectedVehicle?.vehicleType! ?? VehicleEnum.CAR,
              licensePlate: selectedVehicle?.licensePlate! ?? '',
              model: selectedVehicle?.model! ?? '',
              color: selectedVehicle?.color! ?? '',
              capacity: selectedVehicle?.capacity! ?? '',
            }}
            onSubmit={submitForm}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <h3 className="section-title">Vehicle info</h3>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormControl variant="standard" fullWidth className="vehicle-type-select">
                      <SelectField
                        placeholder="Vehicle type"
                        name="vehicleType"
                        values={values.vehicleType}
                        defaultValue=""
                        onChange={(e: React.ChangeEvent): void => valueChangedHandler(setFieldValue, e)}
                      >
                        <MenuItem value={VehicleEnum.CAR}>{VehicleEnum[VehicleEnum.CAR]}</MenuItem>
                        <MenuItem value={VehicleEnum.TRUCK}>{VehicleEnum[VehicleEnum.TRUCK]}</MenuItem>
                        <MenuItem value={VehicleEnum.MOTORCYCLE}>{VehicleEnum[VehicleEnum.MOTORCYCLE]}</MenuItem>
                        <MenuItem value={VehicleEnum.BIKE}>{VehicleEnum[VehicleEnum.BIKE]}</MenuItem>
                        <MenuItem value={VehicleEnum.WALKER}>{VehicleEnum[VehicleEnum.WALKER]}</MenuItem>
                      </SelectField>
                    </FormControl>
                  </GridItem>

                  {[VehicleEnum.MOTORCYCLE, VehicleEnum.CAR, VehicleEnum.TRUCK].includes(values.vehicleType!) && (
                    <>
                      <GridItem xs={12} sm={6}>
                        <InputField placeholder="License plate" name="licensePlate" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <InputField placeholder="Vehicle model" name="model" type="text" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <InputField placeholder="Vehicle color" name="color" type="text" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <InputField placeholder="Vehicle capacity" name="capacity" type="text" />
                      </GridItem>
                    </>
                  )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary" disabled={submitting}>
                        {`${editMode ? 'Update' : 'Create'} vehicle`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
          {editMode && hasPermission(user, ['analyze.cost']) && (
            <GridContainer>
              <CostsForm subject="vehicle" subjectId={`${selectedVehicle?.vehicleId ?? 0}`} />
            </GridContainer>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default connector(VehicleForm);
