import moment from 'moment-timezone';
import { SET_TIME, TimeActionTypes } from '../config/ActionTypes';
import { TimeState } from '../config/types/time.types';

export const initialState: TimeState = {
  time: moment().format('MM/DD/YYYY hh:mm A'),
};

export function timeReducer(state = initialState, action: TimeActionTypes): TimeState {
  switch (action.type) {
    case SET_TIME:
      return {
        ...state,
        time: moment().format('MM/DD/YYYY hh:mm A'),
      };

    default:
      return state;
  }
}
