import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AssemblyBuild } from '../../store/config/types';
import { strOrAlt } from '../../utils/string.helpers';

interface AssemblyBuildDetailsProps {
  assemblyBuild: AssemblyBuild;
}

export function AssemblyBuildDetails({ assemblyBuild }: AssemblyBuildDetailsProps) {
  return (
    <Grid container p={4} pt={2} mb={4}>
      <Typography variant="button">ITEMS</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Source</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Committed</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assemblyBuild?.items?.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.product?.externalSource}</TableCell>
                <TableCell>{item.product?.externalId}</TableCell>
                <TableCell>{strOrAlt(item.product?.description, item.product?.name)}</TableCell>
                <TableCell>{item.product?.type}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.committedQuantity}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
}
