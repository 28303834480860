import { AuthState } from '../config/types/auth.types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGGING_OUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  AuthDataTypes,
  GETTING_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  UPDATING_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  CLEAR_LOGIN_ERROR,
} from '../config/ActionTypes';

export const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  logInErrorMessage: null,
  token: null,
  user: null,
  loggingOut: false,
  logoutSuccess: false,
  logoutErrorMessage: null,
  account: null,
  updatingAccount: false,
  updateAccountSuccess: false,
  updateAccountErrorMessage: null,
};

export function authReducer(state = initialState, action: AuthDataTypes): AuthState {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        token: null,
        user: null,
        logInErrorMessage: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        token: action.token,
        account: action.account || null,
        logInErrorMessage: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        token: null,
        user: null,
        logInErrorMessage: action.error,
      };

    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        logInErrorMessage: null,
      };

    case LOGGING_OUT:
      return {
        ...state,
        loggingOut: true,
        logoutSuccess: false,
        logoutErrorMessage: null,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        account: null,
        loggingOut: false,
        logoutSuccess: true,
        logoutErrorMessage: null,
        loggedIn: false,
      };

    case LOGOUT_FAILED:
      return {
        ...state,
        loggingOut: false,
        logoutSuccess: false,
        logoutErrorMessage: action.error,
      };

    case GETTING_ACCOUNT:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        logInErrorMessage: null,
      };

    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        logInErrorMessage: null,
        account: action.account,
      };

    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        logInErrorMessage: action.error,
      };

    case UPDATING_ACCOUNT:
      return {
        ...state,
        updatingAccount: true,
        updateAccountSuccess: false,
        updateAccountErrorMessage: null,
      };

    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        updatingAccount: false,
        updateAccountSuccess: true,
        updateAccountErrorMessage: null,
      };

    case UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updatingAccount: false,
        updateAccountSuccess: false,
        updateAccountErrorMessage: action.error,
      };

    default:
      return state;
  }
}
