import { Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Grid, Link, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export interface UserDataTableProps {
  data: any;
  debugMode?: boolean;
  captions?: any;
  // eslint-disable-next-line
  onDisplayAllClick?: (data: any) => void;
}
export default function UserDataTable({ data, captions, debugMode, onDisplayAllClick }: UserDataTableProps) {
  const MAX_VISIBLE_ROWS = 3;
  let isDataOK = Array.isArray(data);
  if (isDataOK) {
    data = (data as any[]).map((row) => {
      const rowObj: any = {};
      Object.keys(row).forEach((field) => {
        try {
          rowObj[field] = JSON.stringify(row[field]);
        } catch {
          rowObj[field] = '#ERROR#';
        }
      });
      return rowObj;
    });
  }

  if (!isDataOK) {
    return (
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item>
            <Error color="error" /> <span>Data is null or not a table.</span>
          </Grid>
          {debugMode && (
            <Grid item>
              <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers wrapLongLines>
                {JSON.stringify(data, null, '   ')}
              </SyntaxHighlighter>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
  const columns: string[] = isDataOK && data.length ? Object.keys(data[0]) : [];
  const visibleData = isDataOK ? (data as Object[]).filter((elm, idx) => idx < MAX_VISIBLE_ROWS) : [];
  const hiddenRows = isDataOK && data.length > MAX_VISIBLE_ROWS ? data.length - MAX_VISIBLE_ROWS : 0;
  return columns.length > 0 ? (
    <Grid item p={1} fontSize="normal" overflow="auto">
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((col, idx) => (
              <TableCell key={`col-${idx}`}>{captions && captions[col] ? captions[col] : col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleData.map((row: any, ridx: number) => (
            <TableRow key={`row-${ridx}`}>
              {columns.map((col, cidx) => (
                <TableCell key={`cell-${ridx}-${cidx}`}>{row[col] ?? ''}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {hiddenRows > 0 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Link
                  onClick={() => {
                    onDisplayAllClick && onDisplayAllClick(data);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography>See all {data.length} rows</Typography>
                </Link>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </Grid>
  ) : (
    <Grid item p={1}>
      No data to display
    </Grid>
  );
}
