import { AIChunkStruct, AIMessageStruct } from '../screens/Prospero/prospero.types';
import axios from './axios';

async function loadSessions() {
  const res = await axios.get(`/ai/sessions`);
  return res.data.data;
}

async function createChatSession(title?: string) {
  const res = await axios.post(`/ai/sessions`, { title });
  return res.data.data;
}

async function editChatSessionTitle(aiChatSessionId: number, title: string) {
  const res = await axios.patch(`/ai/sessions`, { aiChatSessionId, title });
  return res.data.data;
}

async function sendChatMessage(
  aiChatSessionId: number,
  message: AIMessageStruct,
  /* eslint-disable */
  onStatusChange?: (status: string) => void,
  onStreamMessage?: (message: string) => void,
  onResponseRecieve?: (response: string) => void,
  /* eslint-enable */
) {
  const divider = `-----${new Date().toISOString()}-----`;
  const streamLog: any[] = [];
  let lastPointer: number = 0;
  const res = await axios.post(
    `/ai`,
    { aiChatSessionId, messageBody: message.message, messageType: message.type, divider },
    {
      onDownloadProgress: (event) => {
        const streamChunk: string = event.currentTarget?.response;

        try {
          const newChunk = streamChunk.substring(lastPointer, streamChunk.lastIndexOf('\n'));
          lastPointer = streamChunk.lastIndexOf('\n') + 1;
          const lines = newChunk.split('\n');
          streamLog.push({ lines, date: new Date() });
          lines.forEach((line) => {
            if (onStatusChange && line.startsWith('status:')) {
              onStatusChange(line.substring(7));
            }
            if (onStreamMessage && line.startsWith('comment:')) {
              onStreamMessage(line.substring(8));
            }
            if (onResponseRecieve && line.startsWith('response:')) {
              onResponseRecieve(line.substring(9));
            }
          });
        } catch (err) {
          // error handeling
          console.log(err);
        }
      },
    },
  );
  console.log(streamLog);
  try {
    // const dataPart = res.data?.split(divider).pop();
    // const resMessage: AIMessageStruct = JSON.parse(dataPart);
    const resParts = (res.data as string).split(`divider-${divider}\n`);
    console.log(resParts);
    return JSON.parse(resParts.pop() ?? '[]');
  } catch (err) {
    // do nothing
    console.error(err);
  }
  return [];
}

async function ragAddChunkToKnowledge(chunk: AIChunkStruct) {
  const res = await axios.post(`/ai/rag/chunk`, chunk);
  return res.data.data;
}

async function ragUpdateKnowledge(aiKnowledgeId: number, content: string) {
  const res = await axios.patch(`/ai/rag/knowledge`, { aiKnowledgeId, content });
  return res.data.data;
}

async function ragRemoveChunkFromKnowledge(chunk: AIChunkStruct) {
  const res = await axios.delete(`/ai/rag/chunk/${chunk.aiChunkId}`);
  return res.data.data;
}

async function searchName(query: string) {
  const res = await axios.get(`/search/customer/${encodeURI(query)}`);
  return res.data.data;
}

const ProsperoService = {
  loadSessions,
  createChatSession,
  sendChatMessage,
  editChatSessionTitle,
  ragAddChunkToKnowledge,
  ragRemoveChunkFromKnowledge,
  ragUpdateKnowledge,
  searchName
};
export default ProsperoService;
