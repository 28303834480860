import { Switch, FormControlLabel } from '@material-ui/core';
import { ChangeEvent } from 'react';
interface SwitchProps {
  label: string | null;
  name: string;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  setFieldValue: (name: string, value: boolean) => void;
}

const ToggleSwitch = ({ label, checked, name, setFieldValue }: SwitchProps) => (
  <FormControlLabel
    control={
      <Switch
        name={name}
        color="primary"
        checked={checked}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFieldValue(name, event.target.checked);
        }}
      />
    }
    label={label}
  />
);

export default ToggleSwitch;
