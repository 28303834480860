import moment from 'moment-timezone';
import { ReactElement, useEffect, useState } from 'react';
import './dndt-style.scss';

interface DndTimelineTimesProps {
  additionAreaWidth: number;
  minutesToPixel?: number;
  minorMinutes?: number;
  majorMinutes?: number;
  timelineWidth?: number;
  start?: moment.Moment;
  end?: moment.Moment;
  showCurrentTime?: boolean;
  timezone?: string;
  // eslint-disable-next-line
  additionalAreaRender?: () => ReactElement;
}
interface DndTimelineTimeGuideProps {
  day: string;
  hour: string;
  minutes: string;
  class: 'minor' | 'major';
}
export default function DndTimelineTimes({
  minutesToPixel,
  additionAreaWidth,
  start,
  end,
  minorMinutes,
  majorMinutes,
  timelineWidth,
  showCurrentTime,
  timezone,
  additionalAreaRender,
}: DndTimelineTimesProps) {
  const [timeGuides, setTimeGuides] = useState<DndTimelineTimeGuideProps[]>([]);
  const [minorWidth, setMinorWidth] = useState(1);
  const currentTimePointerLeft = start ? moment().diff(start, 'minute') * (minutesToPixel ?? 1) : 0;
  const currentTimeIsInTimeline = moment().isBetween(start, end);
  timezone && moment.tz.setDefault(timezone);
  useEffect(() => {
    if (start && minutesToPixel && timelineWidth && minorMinutes && minorMinutes > 0 && end) {
      const durationWidth = Math.max(Math.abs(end.diff(start, 'minutes')) * minutesToPixel, timelineWidth);
      const newMinorWidth = minorMinutes * minutesToPixel;

      const totalMinors = Math.ceil(durationWidth / newMinorWidth) + 1;
      let guides = [];
      const currTime = start.clone();
      let currDate = start.format('MM/DD');
      let day = moment().format('MM/DD') !== currDate ? currDate : '';

      let guideClass: 'minor' | 'major' = 'major';
      //const majorStart = start.clone().startOf('hour');
      let lastMajorMinut = currTime.clone();
      for (let idx = 0; idx < totalMinors; idx++) {
        if (idx === 0 || currTime.diff(lastMajorMinut, 'minutes') >= (majorMinutes ?? 60)) {
          lastMajorMinut = currTime.clone();
          guideClass = 'major';
        } else {
          guideClass = 'minor';
        }
        if (guideClass == 'major' && currTime.format('MM/DD') !== currDate) {
          currDate = currTime.format('MM/DD');
          day = currDate;
        } else {
          day = '';
        }
        const guide: DndTimelineTimeGuideProps = {
          class: guideClass,
          day,
          hour: guideClass === 'major' ? currTime.format('HH') : '',
          minutes: currTime.format('mm'),
        };
        guides.push(guide);
        currTime.add(minorMinutes, 'minutes');
      }
      setTimeGuides(guides);
      setMinorWidth(newMinorWidth);
    }
  }, [minutesToPixel, start, end]);

  return (
    <div className="dndt-times">
      <div className="dndt-addition-area" style={{ width: additionAreaWidth }}>
        {additionalAreaRender && additionalAreaRender()}
      </div>
      <div className="dndt-time-band" style={{ width: timelineWidth ?? 100 }}>
        {timeGuides.map((guide, index) => {
          return (
            <div key={`time-guide-${index}`} className={`dndt-time-guide ${guide.class}`} style={{ width: minorWidth }}>
              {guide.class === 'major' ? (
                <>
                  <div className="dndt-time-guide-date">{guide.day}</div>
                  <div className="dndt-time-guide-hour">
                    <div className="label">
                      <span className="hour">{guide.hour}:</span>
                      <span className="minutes">{guide.minutes}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="dndt-time-guide-minutes">{guide.minutes}</div>
              )}
            </div>
          );
        })}
      </div>
      <div className="dndt-border-guides" style={{ paddingLeft: additionAreaWidth }}>
        {timeGuides.map((guide, index) => {
          return (
            <div
              key={`guide-${index}`}
              className={`dndt-time-guide ${guide.class}`}
              style={{ left: additionAreaWidth + minorWidth * index }}
            ></div>
          );
        })}
      </div>
      {showCurrentTime && currentTimeIsInTimeline && (
        <div className="dndt-time-pointer" style={{ left: additionAreaWidth + currentTimePointerLeft }}>
          <span className="current-time">{moment().format('h:mm')}</span>
        </div>
      )}
    </div>
  );
}
