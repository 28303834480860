import { useField } from 'formik';
import CustomInput from '../CustomInput/CustomInput';

function InputField({ ...props }) {
  const [field, meta] = useField(props);

  return (
    <CustomInput
      labelText={props.placeholder}
      id={field.name}
      formControlProps={{ fullWidth: true, className: props.formControlClassName }}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      inputProps={{ ...field, ...props }}
    />
  );
}

export default InputField;
