import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment-timezone';
import { Dialog, DialogContent, IconButton, Paper } from '@material-ui/core';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/white_loader.json';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import CustomToggle from '../../components/CustomToggle/CustomToggle';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import Spinner from '../../components/Spinner/Spinner';
import AutocompleteField from '../../components/SpecialInput/AutocompleteField';
import { setToast } from '../../store/actions/toast.actions';
import {
  createDeliveryOrder,
  clearDeliveryOrdersState,
  fetchCustomersDeliveryOrders,
} from '../../store/actions/deliveryOrders.actions';
import { RootState } from '../../store/config/types';
import {
  CustomerRequest,
  DeliveryOrder,
  DeliveryOrderRequest,
  // ExtendedDeliveryOrder,
  PackageGroup,
  PackageGroupEditStruct,
  PackageModel,
  PackageRequest,
} from '../../store/config/types/deliveryOrders.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { fetchCustomers } from '../../store/actions/customers.actions';
import { fetchWarehouses } from '../../store/actions/warehouses.actions';
import schemas from '../../utils/schemas';
import useIsMobile from '../../utils/useIsMobile';
import { transformCSVLines } from '../../utils/deliveryOrders.helpers';
import WarehouseForm from '../Warehouses/WarehouseForm';
import PackageForm from './PackageForm';
import PackagesTable from './PackagesTable';
// import CSVDialog from './CSVDialog';

import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { Grid } from '@mui/material';
import { SaveAlt } from '@material-ui/icons';
import UniversalCSVDialog from '../../components/CSVImporter/CSVDialog';

interface DeliveryOrderParams {
  packageGroupId?: string;
}

interface DeliveryOrderValues {
  date: Date | string;
  warehouseId: number;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  deliveryOrders: state.deliveryOrders,
  customers: state.customers,
  warehouses: state.warehouses,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateDeliveryOrder: (deliveryOrderRequest: DeliveryOrderRequest) =>
    dispatch(createDeliveryOrder(deliveryOrderRequest)),
  fetchWarehouses: (shipperId: number) => dispatch(fetchWarehouses(shipperId)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  clearDeliveryOrdersState: () => dispatch(clearDeliveryOrdersState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DeliveryOrderForm({
  auth,
  deliveryOrders,
  customers,
  warehouses,
  onCreateDeliveryOrder,
  fetchCustomers,
  fetchWarehouses,
  setToast,
  clearDeliveryOrdersState,
}: PropsFromRedux) {
  const history: History = useHistory();
  const isMobile: boolean = useIsMobile();
  const { packageGroupId } = useParams<DeliveryOrderParams>();
  const editMode: boolean = Boolean(packageGroupId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState<DeliveryOrder | null>(null);
  const [selectedPackageGroup, setSelectedPackageGroup] = useState<PackageGroup>();
  const [packages, setPackages] = useState<PackageModel[]>([]);
  const [sameDay, setSameDay] = useState<boolean>(true);

  const [warehouseOpen, setWarehouseOpen] = useState<boolean>(false);
  const [justCreatedWarehouse, setJustCreatedWarehouse] = useState<boolean>(false);
  const [deliveryItemErrorIndexes, setDeliveryItemErrorIndexes] = useState<number[]>([]);
  const [deliveryExpectedAt, setDeliveryExpectedAt] = useState<Date>();
  const [csvDialogOpen, setCsvDialogOpen] = useState(false);

  useEffect(() => {
    if (editMode && selectedDeliveryOrder === null && packageGroupId) {
      deliveryOrdersService.getDeliveryOrderOfPackageGroup(+packageGroupId).then((deliveryOrder) => {
        if (deliveryOrder) {
          setSelectedDeliveryOrder(deliveryOrder);
          setSameDay(moment().isSame(deliveryOrder.deliveryExpectedAt, 'day'));
          setDeliveryExpectedAt(deliveryOrder.deliveryExpectedAt);
          setSelectedPackageGroup(deliveryOrder.packageGroups[0]);
        }
      });
    }
  }, [editMode, packageGroupId]);

  // useEffect(() => {
  //   if (!deliveryOrders.deliveryOrders && auth.account) {
  //     deliveryOrdersService.fetchDeliveryOrders
  //   }
  // }, [deliveryOrders.deliveryOrders, auth.account, fetchDeliveryOrders]);

  useEffect(() => {
    if (
      !warehouses.loadingWarehouses &&
      !warehouses.warehouses &&
      !warehouses.warehousesFetched &&
      !warehouses.warehousesErrorMessage &&
      auth.account
    ) {
      fetchWarehouses(auth.account?.shipper?.shipperId || 0);
    }
  }, [
    fetchWarehouses,
    warehouses.loadingWarehouses,
    warehouses.warehouses,
    warehouses.warehousesFetched,
    warehouses.warehousesErrorMessage,
    auth.account,
  ]);

  useEffect(() => {
    if (
      (!customers.loadingCustomers &&
        !customers.customers &&
        !customers.customersFetched &&
        !customers.customersErrorMessage) ||
      submitting
    ) {
      fetchCustomers();
    }
  }, [
    fetchCustomers,
    customers.loadingCustomers,
    customers.customers,
    customers.customersFetched,
    customers.customersErrorMessage,
    submitting,
  ]);

  useEffect(() => {
    if (submitting) {
      if (deliveryOrders.createDeliveryOrderSuccess) {
        setSubmitting(false);
        setToast(
          `Delivery Order successfully ${deliveryOrders.createDeliveryOrderSuccess ? 'created' : 'updated'}`,
          'success',
        );
        clearDeliveryOrdersState();
        const today = moment().format('YYYY-MM-DD');
        fetchCustomersDeliveryOrders(auth.account?.shipper?.shipperId ?? 0, today, today);
        history.goBack();
      } else if (deliveryOrders.createDeliveryOrderErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${
            deliveryOrders.createDeliveryOrderErrorMessage ? 'creating' : 'updating'
          } Delivery Order`,
          'danger',
        );
        clearDeliveryOrdersState();
      }
    }
  }, [
    submitting,
    deliveryOrders.createDeliveryOrderSuccess,
    deliveryOrders.createDeliveryOrderErrorMessage,
    history,
    setSubmitting,
    setToast,
    clearDeliveryOrdersState,
  ]);
  const handleSetSameDay = (sameDay: boolean) => {
    if (sameDay) {
      setDeliveryExpectedAt(moment().startOf('day').toDate());
    }
    setSameDay(sameDay);
  };

  // const importCSV = () => setOpen(true);

  const createNewWarehouse = () => setWarehouseOpen(true);

  const buildCustomer = (packageObject: PackageModel): CustomerRequest => {
    return {
      firstName: packageObject.firstName,
      lastName: packageObject.lastName,
      companyName: packageObject.companyName,
      specialInstructions: packageObject.deliveryInstructions,
      email: packageObject.email,
      contactPhoneNumber: packageObject.contactPhoneNumber,
      location: {
        locationId: packageObject.locationId,
        city: packageObject.city,
        latitude: packageObject.latitude?.toString(),
        longitude: packageObject.longitude?.toString(),
        streetAddress: packageObject.streetAddress,
      },
    };
  };

  const submitForm = (values: DeliveryOrderValues) => {
    if (auth.account) {
      const allPackages = [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)];
      if (allPackages.length < 1) return;

      const packageRequests: PackageRequest[] = allPackages.map((packageObject: PackageModel) => {
        return {
          customerId: packageObject.customerId,
          customer: packageObject.customerId ? undefined : buildCustomer(packageObject),
          instructions: packageObject.deliveryInstructions!,
          count: packageObject.packagesAmount!,
          size: packageObject.packagesSize!,
          timeWindowFrom: packageObject.timeWindowFrom!,
          timeWindowTo: packageObject.timeWindowTo!,
          shippingAddress: packageObject.shippingAddress!,
        };
      });

      // const currentTime = moment().format('THH:mm:ssz');

      const deliveryOrderRequest: DeliveryOrderRequest = {
        shipperId: auth.account.shipper?.shipperId!,
        warehouseId: values.warehouseId,
        deliveryExpectedAt: moment(values.date).format(),
        packageGroups: packageRequests,
      };

      setSubmitting(true);

      if (editMode) {
        // deliveryOrderRequest.deliveryOrderId = selectedDeliveryOrder?.deliveryOrderId;
      } else {
        onCreateDeliveryOrder(deliveryOrderRequest);
      }
    }
  };

  const handleGoBack = () => {
    clearDeliveryOrdersState();
    history.goBack();
  };
  const handleCsvDialogClose = (open: boolean) => {
    if (open === false) {
      setCsvDialogOpen(false);
      handleGoBack();
    }
  };

  const submitPackage = (values: PackageGroupEditStruct, formikHelpers: FormikHelpers<PackageGroupEditStruct>) => {
    const newPackage: PackageModel = {
      ...values,
      packageId: packages.length + 1,
    };

    setPackages([...packages, newPackage]);
    formikHelpers.resetForm();
  };

  if (editMode && !selectedPackageGroup) {
    return <Spinner />;
  }

  const selectedWarehouse =
    selectedDeliveryOrder &&
    warehouses.warehouses &&
    warehouses.warehouses.find((warehouse: Warehouse) => warehouse.warehouseId === selectedDeliveryOrder.warehouseId);

  if (auth && auth.account && auth.account.shipper?.timezone) {
    // console.log('TimeZone: ' + auth.account?.shipper?.timezone);
    moment.tz.setDefault(auth.account?.shipper?.timezone);
  }
  // const selectedDate = moment(selectedDeliveryOrder?.createdAt).format('MM/DD/YYYY');

  const closeWarehouseModal = () => setWarehouseOpen(false);

  const warehouseModalSuccess = () => {
    setJustCreatedWarehouse(true);
    closeWarehouseModal();
  };

  return (
    <div className="delivery-order-form">
      <Card profile>
        <CardHeader color="primary" className="delivery-order-header">
          <Grid container>
            <Grid item flexGrow={1} textAlign="left">
              {editMode && selectedDeliveryOrder ? `PG# ${selectedPackageGroup?.packageGroupId}` : 'New Delivery'}
            </Grid>
            {!editMode && (
              <Grid item>
                <IconButton size="small" onClick={() => setCsvDialogOpen(true)}>
                  <SaveAlt style={{ color: 'white' }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              date: moment(selectedDeliveryOrder?.deliveryExpectedAt).format('YYYY-MM-DD'),
              warehouseId:
                justCreatedWarehouse && warehouses.createdWarehouse
                  ? warehouses.createdWarehouse.warehouseId!
                  : selectedDeliveryOrder
                  ? selectedDeliveryOrder.warehouseId!
                  : warehouses.warehouses
                  ? warehouses.warehouses[0]?.warehouseId!
                  : 0,
            }}
            validationSchema={schemas.DeliveryOrderSchema}
            onSubmit={submitForm}
            // enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GridContainer alignItems="center">
                  <GridItem xs={12} lg={6}>
                    {warehouses.warehouses && (
                      <div className="warehouse-row">
                        {editMode && selectedWarehouse ? (
                          <span className="info">
                            <b>Warehouse: </b>
                            {selectedWarehouse?.name}
                          </span>
                        ) : (
                          <AutocompleteField
                            placeholder="Warehouse"
                            name="warehouseId"
                            values={warehouses.warehouses.map((warehouse: Warehouse) => ({
                              id: warehouse.warehouseId,
                              title: warehouse.name,
                            }))}
                            value={values.warehouseId}
                            error={values.warehouseId < 1}
                          />
                        )}
                        {!editMode && (
                          <span className="link" onClick={createNewWarehouse}>
                            {isMobile ? 'New' : 'Create new warehouse'}
                          </span>
                        )}
                      </div>
                    )}
                  </GridItem>

                  <CustomToggle value={sameDay} setValue={handleSetSameDay} />

                  {!sameDay && (
                    <GridItem xs={12} lg={3}>
                      <InputField
                        name="date"
                        type="date"
                        formControlClassName="custom-input"
                        onChange={(e: any) => {
                          setDeliveryExpectedAt(moment(e.target.value).toDate());
                          setFieldValue('date', e.target.value);
                        }}
                      />
                    </GridItem>
                  )}
                  {!editMode && (
                    <GridItem xs={12} sm={12}>
                      <div className="btn-group">
                        <Button
                          type="submit"
                          className="submit-button"
                          color="primary"
                          disabled={
                            deliveryOrders.creatingDeliveryOrder ||
                            deliveryItemErrorIndexes.length > 0 ||
                            packages.length + (deliveryOrders.parsedCSVLines ?? []).length === 0 ||
                            values.warehouseId < 1
                          }
                        >
                          {deliveryOrders.creatingDeliveryOrder ? (
                            <Lottie animationData={loadingAnimation} className="loading-button" loop={true} />
                          ) : (
                            'Create Delivery Order'
                          )}
                        </Button>
                        <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                          Cancel
                        </Button>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </Form>
            )}
          </Formik>
          <GridContainer>
            {/* {!editMode && (
              <GridItem xs={12}>
                <div className="import-subtitle">
                  <h3 className="section-title">Import your packages or add manually</h3>
                  <Button color="primary" onClick={importCSV}>
                    Import delivery items CSV
                  </Button>
                </div>
              </GridItem>
            )} */}
            <GridItem xs={12}>
              <Paper className="package-form">
                <PackageForm
                  customers={customers}
                  selectedPackageGroup={selectedPackageGroup}
                  deliveryExpectedAt={deliveryExpectedAt}
                  submitPackage={submitPackage}
                />
              </Paper>
            </GridItem>
            {!editMode && (
              <GridItem xs={12}>
                <h3 className="section-title text">Delivery items</h3>
              </GridItem>
            )}
          </GridContainer>
          {!editMode && (
            <PackagesTable
              packages={packages}
              setPackages={setPackages}
              selectedDeliveryOrder={selectedDeliveryOrder}
              selectedPackageGroup={selectedPackageGroup}
              setErrorIndexes={setDeliveryItemErrorIndexes}
            />
          )}
        </CardBody>
      </Card>

      <UniversalCSVDialog
        open={csvDialogOpen}
        setOpen={handleCsvDialogClose}
        subject="delivery-order"
        title="Import Delivery Orders From CSV"
      />
      <Dialog open={warehouseOpen} onClose={closeWarehouseModal}>
        <DialogContent>
          <WarehouseForm fromModal closeModal={closeWarehouseModal} submitModal={warehouseModalSuccess} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connector(DeliveryOrderForm);
