import { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { NotificationSettingsState, NotificationTemplate, RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { setToast } from '../../store/actions/toast.actions';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { Button, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import GridItem from '../../components/Grid/GridItem';
import { NotificationService } from '../../services/notificationSystem.service';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddButton from '../../components/CustomButtons/AddButton';
import NotificationTemplateEditDialog from './NotificationTemplateEditDialog';

const TemplateTypeTitle = (type: string) => {
  return type === 'ON_SHIPPING' ? 'On Shipping' : type === 'ON_DELIVERY' ? 'On Delivery' : 'Unknown';
};
const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function EditNotificationTemplates({ auth, setToast }: PropsFromRedux) {
  const [templateFetched, setTemplateFetched] = useState(false);
  const [templates, setTemplates] = useState<NotificationTemplate[]>([]);
  const [settings, setSettiings] = useState<NotificationSettingsState>();
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState<NotificationTemplate>();
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  const history: History = useHistory();

  useEffect(() => {
    if (!templateFetched) {
      // TODO: fetch temlates here
      NotificationService.fetchNotificationTemplates(auth.account?.shipper?.shipperId ?? 0, true)
        .then((templates) => setTemplates([...templates.shipping, ...templates.delivery]))
        .catch(() => {
          setToast('Template fech error', 'error');
        });
      NotificationService.fetchNotificationSettings(auth.account?.shipper?.shipperId ?? 0).then((settings) =>
        setSettiings(settings),
      );
      setTemplateFetched(true);
    }
  }, [templateFetched]);

  const handleGoBack = () => {
    history.push('/notification-settings');
  };
  const handleTemplateEdit = (id: number) => {
    setTemplateToEdit(templates.find((tmplt) => tmplt.id === id));
    setIsNewTemplate(false);
    setTemplateDialogOpen(true);
  };
  const handleCreateTemplate = () => {
    setIsNewTemplate(true);
    setTemplateToEdit(undefined);
    setTemplateDialogOpen(true);
  };
  const handleDialogSave = (template: NotificationTemplate, isNew: boolean) => {
    if (isNew) {
      NotificationService.createNotificationTemplate({ ...template }).then(() => {
        setTemplateFetched(false);
      });
    } else {
      NotificationService.updateNotificationTemplate(template).then(() => {
        setTemplateFetched(false);
      });
    }
    setTemplateDialogOpen(false);
  };
  return (
    <div className="EditNotificationTemplates">
      <Card>
        <CardHeader color="primary" className="card-header">
          <Grid container>
            <Grid item sx={{ flexGrow: 1 }}>
              Edit Notification Templates{' '}
              {`(${
                auth.account?.shipper?.companyName ??
                `${auth.account?.shipper?.firstName} ${auth.account?.shipper?.lastName}`
              })`}
            </Grid>
            <Grid item>
              <Link component="button" variant="body2" underline="hover" onClick={handleGoBack}>
                Back
              </Link>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid container spacing={2} direction="row">
            {templates.map((template) => {
              return (
                <Grid container md={4} sm={6} xs={12} key={`template-${template.id}`}>
                  <Grid
                    item
                    xs
                    component={Card}
                    style={{ margin: 16, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                  >
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {TemplateTypeTitle(template.type)}
                      </Typography>
                      <Typography variant="h5" component="div">
                        {template.name}
                      </Typography>
                      <Typography variant="body2">{template.body}</Typography>
                    </CardContent>
                    <CardActions>
                      <Grid sx={{ flexGrow: 1 }}>
                        <Button
                          size="small"
                          onClick={() => {
                            handleTemplateEdit(template.id);
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                      {template.id != settings?.deliveryNotificationTemplate &&
                      template.id != settings?.shippingNotificationTemplate ? (
                        <Grid>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Typography variant="caption" color="success.main">
                          Current
                        </Typography>
                      )}
                    </CardActions>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </CardBody>
        <CardFooter>
          <GridItem></GridItem>
        </CardFooter>
      </Card>
      <AddButton onClick={handleCreateTemplate} />
      <NotificationTemplateEditDialog
        open={templateDialogOpen}
        isNew={isNewTemplate}
        template={templateToEdit}
        onCancelClick={() => {
          setTemplateDialogOpen(false);
        }}
        onSaveClick={handleDialogSave}
      />
    </div>
  );
}

export default connector(EditNotificationTemplates);
