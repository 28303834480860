import { LoggedAccount } from '../store/config/types/auth.types';

export const permissionMatch = (source: string, dest: string) => {
  const sourceParts = source.split('.');
  const destParts = dest.split('.');
  let isMatch = true;
  while (destParts.length > 0 && sourceParts.length > 0 && isMatch) {
    const sourcePart = sourceParts.shift()?.toLowerCase();
    const destPart = destParts.shift()?.toLowerCase();
    isMatch = sourcePart === destPart || sourcePart === '*' || destPart === '*';
  }
  return isMatch;
};

export const hasPermission = (user: LoggedAccount | null, permissions: string[]) => {
  if (user) {
    let hasPermission = true;
    permissions.forEach((permission) => {
      let findPermission = false;
      user.permissions?.forEach((permItem) => {
        findPermission ||= permissionMatch(permItem, permission);
      });
      hasPermission &&= findPermission;
    });
    return hasPermission;
  } else return false;
};
