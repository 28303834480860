import { Marker } from '@react-google-maps/api';
import { Courier, LocationHistory, Vehicle } from '../../store/config/types';
import { ColorSpectrum } from '../../store/config/types/ColorSpectrum';
import moment from 'moment-timezone';

interface CourierTrackPointsProps {
  color: ColorSpectrum;
  track: LocationHistory[];
  courier?: Courier;
  vehicle?: Vehicle;
  label?: string;
  // eslint-disable-next-line
  onHover?: (info: LocationHistory) => void;
}
export default function CourierTrackPoints({ color, track, courier, vehicle, label }: CourierTrackPointsProps) {
  return (
    <>
      {track.map((point, index) => {
        const titleInfo: string[] = [];
        if (courier) titleInfo.push(`${courier.firstName ?? ''} ${courier.lastName ?? ''}`);
        if (vehicle) titleInfo.push(`${vehicle.model ?? ''} ${vehicle.color ?? ''}`);
        if (label) titleInfo.push(label);
        titleInfo.push(`@${moment(point.locationAt).format('hh:mm a')}`);
        return (
          <Marker
            title={titleInfo.join('\n')}
            key={index}
            position={{ lat: point.latitude, lng: point.longitude }}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: color.dark40,
              fillOpacity: 1,
              strokeWeight: 0,
            }}
          />
        );
      })}
    </>
  );
}
