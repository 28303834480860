import './TripsDnd.styles.scss';

interface CourierWithAvatarProps {
  avatarUrl?: string;
  name: string;
}

export default function CourierWithAvatar({ avatarUrl, name }: CourierWithAvatarProps) {
  return (
    <div className="courier-with-avatar">
      <div className="courier-avatar" style={{ backgroundImage: `url(${avatarUrl})` }}></div>
      <span className="courier-name">{name}</span>
    </div>
  );
}
