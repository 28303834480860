import { Marker } from '@react-google-maps/api';
import { TripParking } from '../../store/config/types';
interface ParkingMarkerProps {
  parking: TripParking;
  // eslint-disable-next-line
  onHover?: (customerId: number, isOver: boolean) => void;
}
export default function ParkingMarker({ parking }: ParkingMarkerProps) {
  return (
    <>
      <Marker
        position={{ lat: parking.latitude, lng: parking.longitude }}
        icon={{
          path: 'M 0 0 L 50 0 C 50 0 50 14.274 50 17.751 C 50 17.751 65.794 35.219 64.95 34.685 C 50.464 25.516 50 25 50 25 L 0 25 L 0 0 Z',
          fillColor: 'blue',
          strokeColor: 'black',
          strokeWeight: 1,
          fillOpacity: 1,
          anchor: new window.google.maps.Point(65, 35),
          labelOrigin: new window.google.maps.Point(25, 12),
          scale: 0.5,
        }}
        label={{
          text: `${parking.duration}'`,
          fontSize: '10px',
          color: 'white',
        }}
      />
    </>
  );
}
