import { useField } from 'formik';
import CustomAutocomplete from './CustomSelect/CustomAutocomplete';

function AutocompleteField({ ...props }) {
  const [field, , { setValue }] = useField(props);

  return (
    <CustomAutocomplete
      labelText={props.placeholder}
      id={field.name}
      value={props.value}
      formControlProps={{ fullWidth: true }}
      inputProps={{ ...field, ...props, setValue }}
      error={props.error}
    />
  );
}

export default AutocompleteField;
