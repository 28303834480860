import { ChangeEvent, useEffect, useState } from 'react';
// import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { RootState } from '../../store/config/types';
import {
  UpdateNotificationSettingsRequest,
  NotificationTemplate,
} from '../../store/config/types/notificationSystem.types';

import { setToast } from '../../store/actions/toast.actions';
// import schemas from '../../utils/schemas';

import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Spinner from '../../components/Spinner/Spinner';
import Switch from '../../components/Switch/Switch';
import SelectField from '../../components/SpecialInput/SelectField';
import { MenuItem, TextField } from '@material-ui/core';

import { AppActions } from '../../store/config/ActionTypes';
import {
  getNotificationSettings,
  getNotificationTemplates,
  updateNotificationSettings,
} from '../../store/actions/notificationSystem.actions';
import { Paper, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

// import schemas from '../../utils/schemas';

interface NotificationSettingsValues {
  notifyOnShipping: boolean;
  shippingNotificationTemplate: number | null;
  notifyOnDelivery: boolean;
  deliveryNotificationTemplate: number | null;
  // twilioAccountSid: string | null;
  // twilioAccountAuthToken: string | null;
  // twilioAccountPhoneNumber: string | null;
}
const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  notificationSettings: state.notificationSettings,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getNotificationSettings: (shipperId: number) => dispatch(getNotificationSettings(shipperId)),
  getNotificationTemplates: (shipperId: number) => dispatch(getNotificationTemplates(shipperId)),
  updateNotificationSettings: (settings: UpdateNotificationSettingsRequest) =>
    dispatch(updateNotificationSettings(settings)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function NotificationSettings({
  auth,
  notificationSettings,
  getNotificationSettings,
  getNotificationTemplates,
  updateNotificationSettings,
  setToast,
}: PropsFromRedux) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [templateFetched, setTemplateFetched] = useState<boolean>(false);
  const [shippingNotificationTemplateExample, setShippingNotificationTemplateExample] = useState('');
  const [deliveryNotificationTemplateExample, setDeliveryNotificationTemplateExample] = useState('');
  const [templateExampleInit, setTemplateExampleInit] = useState(false);
  const history: History = useHistory();
  const getTemplatesExamples = (templates: NotificationTemplate[], id: number | null) => {
    try {
      let template: NotificationTemplate | undefined = templates?.find((templateItem) => templateItem.id == id);

      if (template) {
        //return replaceVariableNameByItsValue(template.body, { customer: { firstName: 'Taha' } });
        return template.body;
      }
      return '';
    } catch {
      console.log('can not set template example');
    }
    return '';
  };

  useEffect(() => {
    if (submitting && !notificationSettings.isUpdating) {
      if (notificationSettings.updateSuccess) {
        setSubmitting(false);
        setToast('Settings successfully updated', 'success');
      }
      if (notificationSettings.updateErrorMessage !== null) {
        setSubmitting(false);
        setToast('An error has ocurred while updating settings', 'danger');
      }
    }
  }, [
    submitting,
    setSubmitting,
    notificationSettings.isUpdating,
    notificationSettings.updateSuccess,
    notificationSettings.updateErrorMessage,
    setToast,
  ]);
  useEffect(() => {
    if (!templateFetched) {
      getNotificationSettings(auth.account!.shipper?.shipperId ?? 0);
      getNotificationTemplates(auth.account!.shipper?.shipperId ?? 0);
      setTemplateFetched(true);
    }
  }, [templateFetched]);
  useEffect(() => {
    if (!templateExampleInit)
      setShippingNotificationTemplateExample(
        getTemplatesExamples(
          notificationSettings.templates.shipping,
          notificationSettings.shippingNotificationTemplate,
        ),
      );
    setDeliveryNotificationTemplateExample(
      getTemplatesExamples(notificationSettings.templates.delivery, notificationSettings.deliveryNotificationTemplate),
    );
  });
  const submit = async (values: NotificationSettingsValues) => {
    setSubmitting(true);
    const updatedSettings: UpdateNotificationSettingsRequest = {
      shipperId: auth.account!.shipper?.shipperId ?? 0,
      notifyOnShipping: values.notifyOnShipping,
      shippingNotificationTemplate: values.shippingNotificationTemplate,
      notifyOnDelivery: values.notifyOnDelivery,
      deliveryNotificationTemplate: values.deliveryNotificationTemplate,
      // twilioAccountSid: values.twilioAccountSid,
      // twilioAccountAuthToken: values.twilioAccountAuthToken,
      // twilioAccountPhoneNumber: values.twilioAccountPhoneNumber,
    };

    await updateNotificationSettings(updatedSettings);
  };

  if (auth.account === null || notificationSettings.isFetching || notificationSettings.isUpdating) {
    return <Spinner />;
  }

  return (
    <div className="notificationSettings">
      <Formik
        initialValues={{
          isUpdating: false,
          notifyOnShipping: notificationSettings.notifyOnShipping,
          shippingNotificationTemplate: notificationSettings.shippingNotificationTemplate,
          shippingNotificationTemplateExample: getTemplatesExamples(
            notificationSettings.templates.shipping,
            notificationSettings.shippingNotificationTemplate,
          ),
          notifyOnDelivery: notificationSettings.notifyOnDelivery,
          deliveryNotificationTemplate: notificationSettings.deliveryNotificationTemplate,
          deliveryNotificationTemplateExample: getTemplatesExamples(
            notificationSettings.templates.delivery,
            notificationSettings.deliveryNotificationTemplate,
          ),
          // twilioAccountSid: notificationSettings.twilioAccountSid,
          // twilioAccountAuthToken: notificationSettings.twilioAccountAuthToken,
          // twilioAccountPhoneNumber: notificationSettings.twilioAccountPhoneNumber,
        }}
        // validationSchema={schemas.ProfileSchema}
        onSubmit={submit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Card>
              <CardHeader color="primary" className="card-header">
                Customer Notification
                {`(${
                  auth.account?.shipper?.companyName ??
                  `${auth.account?.shipper?.firstName} ${auth.account?.shipper?.lastName}`
                })`}
              </CardHeader>
              <CardBody>
                <h3>Notify customers when</h3>
                <Paper elevation={5} style={{ padding: 8, marginBottom: 16 }}>
                  <GridContainer>
                    <GridItem xs={12} md={6}>
                      <Switch
                        label="Shipping starts"
                        checked={values.notifyOnShipping}
                        name="notifyOnShipping"
                        setFieldValue={setFieldValue}
                      />
                      <SelectField
                        placeholder="Shipping notification template"
                        name="shippingNotificationTemplate"
                        leftAligned
                        value={values.shippingNotificationTemplate}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setTemplateExampleInit(true);
                          setFieldValue('shippingNotificationTemplate', event.target.value);
                          setShippingNotificationTemplateExample(
                            getTemplatesExamples(notificationSettings.templates.shipping, +event.target.value),
                          );
                        }}
                        values={notificationSettings.templates.shipping.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            {template.name}
                          </MenuItem>
                        ))}
                      />
                    </GridItem>
                    <GridItem sx={12} sm={6}>
                      <TextField
                        type="text"
                        disabled
                        fullWidth
                        multiline
                        value={shippingNotificationTemplateExample}
                        variant="outlined"
                        label="Body:"
                        inputProps={{ readOnly: true }}
                        rows={5}
                      />
                    </GridItem>
                  </GridContainer>
                </Paper>

                <Paper elevation={5} style={{ padding: 8, marginBottom: 16 }}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <Switch
                        label="Delivery completed"
                        checked={values.notifyOnDelivery}
                        name="notifyOnDelivery"
                        setFieldValue={setFieldValue}
                      />
                      <SelectField
                        placeholder="Delivery notification template"
                        name="deliveryNotificationTemplate"
                        leftAligned
                        value={values.deliveryNotificationTemplate}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setTemplateExampleInit(true);
                          setFieldValue('deliveryNotificationTemplate', event.target.value);
                          setDeliveryNotificationTemplateExample(
                            getTemplatesExamples(notificationSettings.templates.delivery, +event.target.value),
                          );
                        }}
                        values={notificationSettings.templates.delivery.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            {template.name}
                          </MenuItem>
                        ))}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <TextField
                        type="text"
                        disabled
                        fullWidth
                        multiline
                        value={deliveryNotificationTemplateExample}
                        variant="outlined"
                        label="Body:"
                        inputProps={{ readOnly: true }}
                        rows={5}
                      />
                    </GridItem>
                  </GridContainer>
                </Paper>
                {/* <h3>Twilio API settings</h3>
                <GridContainer justify="left">
                  <GridItem xs={12} sm={4}>
                    <TextField
                      type="text"
                      fullWidth
                      label="Account SID"
                      name="twilioAccountSid"
                      value={values.twilioAccountSid}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('twilioAccountSid', event.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <TextField
                      type="text"
                      fullWidth
                      // defaultValue={'1236453214831321'}
                      label="Auth Token"
                      name="twilioAccountAuthToken"
                      value={values.twilioAccountAuthToken}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('twilioAccountAuthToken', event.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <TextField
                      type="text"
                      fullWidth
                      // defaultValue={'1236453214831321'}
                      label="Twilio phone number"
                      name="twilioAccountPhoneNumber"
                      value={values.twilioAccountPhoneNumber}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('twilioAccountPhoneNumber', event.target.value);
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                <GridContainer>
                  <GridItem>
                    <Link
                      component="button"
                      variant="body2"
                      underline="hover"
                      onClick={(e) => {
                        history.push('/notification-settings/edit-templates');
                        e.preventDefault();
                      }}
                    >
                      Edit Templates
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button className="submit-button" type="submit" color="primary">
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default connector(NotificationSettings);
