import { useField } from 'formik';
import CustomSelect from '../SpecialInput/CustomSelect/CustomSelect';

function SelectField({ ...props }) {
  const [field, meta] = useField(props);

  return (
    <CustomSelect
      labelText={props.placeholder}
      id={field.name}
      formControlProps={{ fullWidth: true }}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      inputProps={{ ...field, ...props }}
    />
  );
}

export default SelectField;
