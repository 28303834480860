/* eslint-disable */
export enum InteractionTypeEnum {
  LOAD = 'LOAD',
  DROP_OFF = 'DROP_OFF',
  TRANSSHIPMENT_LOAD = 'TRANSSHIPMENT_LOAD',
  TRANSSHIPMENT_DROP_OFF = 'TRANSSHIPMENT_DROP_OFF',
}

export enum ChangeHandStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAIL = 'FAIL',
  FAILED_BEFORE = 'FAILED_BEFORE',
}
