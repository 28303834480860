import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
// import { Form } from 'formik';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { fetchCustomersDeliveryOrders } from '../../store/actions/deliveryOrders.actions';
import { RootState } from '../../store/config/types';
import { CustomerLocationOrder } from '../../store/config/types/deliveryOrders.types';
import { formatDate, maskPhone } from '../../utils/string.helpers';
import Table from '../../components/Table/Table';
import { getPackageGroupStatus } from '../../utils/deliveryOrders.helpers';
import moment from 'moment-timezone';

interface CustomerDeliveryOrderParams {
  warehouseId?: string;
  customerId?: string;
  deliveryExpectedAt?: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  loggedIn: state.auth.loggedIn,
  customersDeliveryOrders: state.customersDeliveryOrders,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCustomersDeliveryOrders: (shipperId: number, from: string, to: string) =>
    dispatch(fetchCustomersDeliveryOrders(shipperId, from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function CustomerDeliveryOrderDetails({
  auth,
  customersDeliveryOrders,
  fetchCustomersDeliveryOrders,
  loggedIn,
}: PropsFromRedux) {
  const history: History = useHistory();
  const { warehouseId, customerId, deliveryExpectedAt } = useParams<CustomerDeliveryOrderParams>();
  const [customerDeliveryOrder, setcustomerDeliveryOrder] = useState<CustomerLocationOrder | null>(null);

  useEffect(() => {
    if (
      !customersDeliveryOrders.loadingCustomersDeliveryOrders &&
      !customersDeliveryOrders.customersDeliveryOrders &&
      !customersDeliveryOrders.customerDeliveryOrdersErrorMessage
    ) {
      moment.tz.setDefault(auth.account?.shipper?.timezone);
      const today = moment().format('YYYY-MM-DD');
      fetchCustomersDeliveryOrders(auth.account?.shipper?.shipperId || 0, today, today);
    }
  }, [
    fetchCustomersDeliveryOrders,
    customersDeliveryOrders.customersDeliveryOrders,
    customersDeliveryOrders.customerDeliveryOrdersErrorMessage,
    customersDeliveryOrders.loadingCustomersDeliveryOrders,
    loggedIn,
    auth.account,
  ]);
  useEffect(() => {
    if (
      !customersDeliveryOrders.loadingCustomersDeliveryOrders &&
      customersDeliveryOrders.customersDeliveryOrders &&
      !customersDeliveryOrders.customerDeliveryOrdersErrorMessage &&
      warehouseId &&
      customerId &&
      deliveryExpectedAt
    ) {
      const customerOrder = customersDeliveryOrders.customersDeliveryOrders.find(
        (order) =>
          order.customerId == +customerId &&
          order.warehouseId == +warehouseId &&
          new Date(order.deliveryExpectedAt ? order.deliveryExpectedAt : 0).valueOf() ==
            new Date(deliveryExpectedAt).valueOf(),
      );
      if (customerOrder) setcustomerDeliveryOrder(customerOrder);
    }
  }, [
    fetchCustomersDeliveryOrders,
    customersDeliveryOrders.customersDeliveryOrders,
    customersDeliveryOrders.customerDeliveryOrdersErrorMessage,
    customersDeliveryOrders.loadingCustomersDeliveryOrders,
  ]);

  return (
    <div className="delivery-order-form">
      <Card profile>
        <CardHeader color="primary" className="delivery-order-header">
          <h4>
            <span className="go-back" onClick={() => history.goBack()}>
              <ChevronLeft /> Go back
            </span>

            <span>Delivery Order Details</span>
          </h4>
        </CardHeader>
        <CardBody profile>
          <GridContainer>
            <GridItem xs={12} className="left-align">
              <span className="info">
                <b>Date: </b>{' '}
                {customerDeliveryOrder?.deliveryExpectedAt &&
                  formatDate(customerDeliveryOrder?.deliveryExpectedAt?.toString())}
              </span>
            </GridItem>
          </GridContainer>
          {customerDeliveryOrder?.customer && (
            <GridContainer>
              <GridItem xs={12} className="left-align">
                <div className="info">
                  <b>Customer: </b>
                  {`${customerDeliveryOrder.customer.firstName} ${customerDeliveryOrder.customer.lastName}`}
                </div>
              </GridItem>
              <GridItem xs={12} className="left-align">
                <div className="info">{`${customerDeliveryOrder.shippingLocation?.streetAddress}`}</div>
              </GridItem>
              <GridItem xs={12} className="left-align">
                {/* <span className="email">{customerDeliveryOrder.customer.email}</span> */}
                <span className="phone">{maskPhone(customerDeliveryOrder.customer.contactPhoneNumber)}</span>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} lg={6}>
              <div className="warehouse-row">
                <span className="info">
                  <b>Warehouse: </b>
                  {customerDeliveryOrder?.warehouse.name}
                </span>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <h3 className="section-title text">Delivery items</h3>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  'Id',
                  'Size',
                  'Number of Packages',
                  'Status',
                  /*'Number of packages', 'Number of recipients',*/ '',
                ]}
                tableData={
                  customerDeliveryOrder
                    ? customerDeliveryOrder.packageGroups.map((packageGroup) => [
                        packageGroup.packageGroupId,
                        packageGroup.packages[0].size,
                        packageGroup.packages.length,
                        getPackageGroupStatus(packageGroup.packages),
                      ])
                    : []
                }
              />
            </GridItem>
          </GridContainer>
          {/* <PackagesTable packages={packages} setPackages={setPackages} selectedDeliveryOrder={selectedDeliveryOrder} /> */}
        </CardBody>
      </Card>
    </div>
  );
}

export default connector(CustomerDeliveryOrderDetails);
