import { Dispatch } from 'redux';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import {
  CSVLine,
  CustomerLocationOrder,
  DeliveryOrder,
  DeliveryOrderRequest,
} from '../config/types/deliveryOrders.types';
import {
  FETCHING_DELIVERY_ORDERS,
  FETCH_DELIVERY_ORDERS_SUCCESS,
  FETCH_DELIVERY_ORDERS_FAILED,
  FetchDeliveryOrdersTypes,
  CREATING_DELIVERY_ORDER,
  CREATE_DELIVERY_ORDER_SUCCESS,
  CREATE_DELIVERY_ORDER_FAILED,
  CreateDeliveryOrderTypes,
  PARSING_CSV,
  PARSE_CSV_SUCCESS,
  PARSE_CSV_FAILED,
  ParseCSVTypes,
  EDIT_CSV_LINE,
  DELETE_CSV_LINE,
  ClearDeliveryOrdersStateType,
  CLEAR_DELIVERY_ORDERS_STATE,
  FetchCustomersDeliveryOrdersTypes,
  FETCHING_CUSTOMERS_DELIVERY_ORDERS,
  FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS,
  FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED,
} from '../config/ActionTypes';

// #region Fetch DeliveryOrders

export const fetchDeliveryOrders = () => {
  return (dispatch: Dispatch<FetchDeliveryOrdersTypes>) => {
    dispatch(fetchingDeliveryOrders());

    return deliveryOrdersService.fetchDeliveryOrders().then(
      (response) => {
        dispatch(fetchDeliveryOrdersSuccess(response!));
      },
      (error) => {
        dispatch(fetchDeliveryOrdersFailed(error));
      },
    );
  };
};

export const fetchingDeliveryOrders = (): FetchDeliveryOrdersTypes => ({
  type: FETCHING_DELIVERY_ORDERS,
});

export const fetchDeliveryOrdersSuccess = (deliveryOrders: DeliveryOrder[]): FetchDeliveryOrdersTypes => ({
  type: FETCH_DELIVERY_ORDERS_SUCCESS,
  deliveryOrders,
});

export const fetchDeliveryOrdersFailed = (error: string): FetchDeliveryOrdersTypes => ({
  type: FETCH_DELIVERY_ORDERS_FAILED,
  error,
});

export const fetchCustomersDeliveryOrders = (shipperId: number, from: string, to: string) => {
  return (dispatch: Dispatch<FetchCustomersDeliveryOrdersTypes>) => {
    dispatch(fetchingCustomersDeliveryOrders());

    return deliveryOrdersService.fetchCustomersOrders(shipperId, { from, to }).then(
      (response) => {
        dispatch(fetchCustomersDeliveryOrdersSuccess(response!));
      },
      (error) => {
        dispatch(fetchCustomersDeliveryOrdersFailed(error));
      },
    );
  };
};

export const fetchCustomerDeliveryOrderDetails = (
  warehouseId: number,
  customerId: number,
  deliveryExpectedAt: Date,
) => {
  return (dispatch: Dispatch<FetchCustomersDeliveryOrdersTypes>) => {
    dispatch(fetchingCustomersDeliveryOrders());

    return deliveryOrdersService.fetchCustomerOrderDetais(warehouseId, customerId, deliveryExpectedAt).then(
      (response) => {
        dispatch(fetchCustomersDeliveryOrdersSuccess(response!));
      },
      (error) => {
        dispatch(fetchCustomersDeliveryOrdersFailed(error));
      },
    );
  };
};

export const fetchingCustomersDeliveryOrders = (): FetchCustomersDeliveryOrdersTypes => ({
  type: FETCHING_CUSTOMERS_DELIVERY_ORDERS,
});

export const fetchCustomersDeliveryOrdersSuccess = (
  customerDeliveryOrders: CustomerLocationOrder[],
): FetchCustomersDeliveryOrdersTypes => ({
  type: FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS,
  customerDeliveryOrders,
});

export const fetchCustomersDeliveryOrdersFailed = (error: string): FetchCustomersDeliveryOrdersTypes => ({
  type: FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED,
  error,
});
// #endregion Fetch DeliveryOrders

// #region Create Fetch DeliveryOrder

export const createDeliveryOrder = (createDeliveryOrderRequest: DeliveryOrderRequest) => {
  return (dispatch: Dispatch<CreateDeliveryOrderTypes>) => {
    dispatch(creatingDeliveryOrder());

    return deliveryOrdersService.createDeliveryOrder(createDeliveryOrderRequest).then(
      (response) => {
        dispatch(createDeliveryOrderSuccess(response!));
      },
      (error) => {
        dispatch(createDeliveryOrderFailed(error));
      },
    );
  };
};

export const creatingDeliveryOrder = (): CreateDeliveryOrderTypes => ({
  type: CREATING_DELIVERY_ORDER,
});

export const createDeliveryOrderSuccess = (deliveryOrder: DeliveryOrder): CreateDeliveryOrderTypes => ({
  type: CREATE_DELIVERY_ORDER_SUCCESS,
  deliveryOrder,
});

export const createDeliveryOrderFailed = (error: string): CreateDeliveryOrderTypes => ({
  type: CREATE_DELIVERY_ORDER_FAILED,
  error,
});

// #endregion Create DeliveryOrder

// #region Parse CSV to packageGroups

export const parseCSVToPackages = (formData: FormData) => {
  return (dispatch: Dispatch<ParseCSVTypes>) => {
    dispatch(parsingCSV());

    return deliveryOrdersService.parseCSVToPackages(formData).then(
      (response) => {
        dispatch(parseCSVSuccess(response!));
      },
      (error) => {
        dispatch(parseCSVFailed(error));
      },
    );
  };
};

const parsingCSV = (): ParseCSVTypes => ({
  type: PARSING_CSV,
});

const parseCSVSuccess = (parsedCSVLines: CSVLine[]): ParseCSVTypes => ({
  type: PARSE_CSV_SUCCESS,
  parsedCSVLines,
});

const parseCSVFailed = (error: string): ParseCSVTypes => ({
  type: PARSE_CSV_FAILED,
  error,
});

export const editCSVLine = (csvLine: CSVLine): ParseCSVTypes => ({
  type: EDIT_CSV_LINE,
  csvLine,
});

export const deleteCSVLine = (packageId: string): ParseCSVTypes => ({
  type: DELETE_CSV_LINE,
  packageId,
});

// #endregion Parse CSV to packageGroups

// #region Clear DeliveryOrders State

export const clearDeliveryOrdersState = (): ClearDeliveryOrdersStateType => ({
  type: CLEAR_DELIVERY_ORDERS_STATE,
});

// #endregion Clear DeliveryOrders State
