import { isAlliveTrip } from '../../utils/trips.helper';
import {
  TripsActionTypes,
  FETCHING_TRIPS,
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILED,
  FETCHING_ALIVE_TRIPS,
  FETCH_ALIVE_TRIPS_SUCCESS,
  FETCH_ALIVE_TRIPS_FAILED,
  FETCHING_TRIP_STOPS,
  FETCH_TRIP_STOPS_SUCCESS,
  FETCH_TRIP_STOPS_FAILED,
  CREATING_TRIP_OFFER,
  CREATE_TRIP_OFFER_SUCCESS,
  CREATE_TRIP_OFFER_FAILED,
  DELETING_TRIP_OFFER,
  DELETE_TRIP_OFFER_SUCCESS,
  DELETE_TRIP_OFFER_FAILED,
  CREATING_TRIP,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAILED,
} from '../config/ActionTypes';
import { Trip, TripOffer, TripsState } from '../config/types/trips.types';

export const initialState: TripsState = {
  loadingStops: true,
  stopsFetched: false,
  stops: null,
  stopsErrorMessage: null,
  loadingTrips: false,
  tripsFetched: false,
  loadingAliveTrips: false,
  aliveTripsFetched: false,
  trips: null,
  unAssignedOrders: null,
  tripsErrorMessage: null,
  creatingTrip: false,
  createTripSuccess: false,
  createTripErrorMessage: null,
  creatingTripOffer: false,
  createTripOfferSuccess: false,
  createTripOfferErrorMessage: null,
  deletingTripOffer: false,
  deleteTripOfferSuccess: false,
  deleteTripOfferErrorMessage: null,
};

export function tripsReducer(state = initialState, action: TripsActionTypes): TripsState {
  switch (action.type) {
    case FETCHING_TRIPS:
      return {
        ...state,
        loadingTrips: true,
        trips: null,
        tripsErrorMessage: null,
      };

    case FETCH_TRIPS_SUCCESS:
      return {
        ...state,
        loadingTrips: false,
        tripsFetched: true,
        trips: action.data.trips.concat(
          (state?.trips ?? []).filter(
            (sTrip) => action.data.trips.findIndex((aTrip) => aTrip.tripId === sTrip.tripId) < 0,
          ),
        ),
        tripsErrorMessage: null,
        unAssignedOrders: action.data.unAssignedOrders,
      };

    case FETCH_TRIPS_FAILED:
      return {
        ...state,
        loadingTrips: false,
        tripsFetched: true,
        trips: null,
        tripsErrorMessage: action.error,
      };

    case FETCHING_ALIVE_TRIPS:
      return {
        ...state,
        loadingAliveTrips: true,
        tripsErrorMessage: null,
      };

    case FETCH_ALIVE_TRIPS_SUCCESS:
      const alliveToCompleteTripsIds =
        state.trips
          ?.filter((trip) => isAlliveTrip(trip) && !action.data.trips.find((aTrip) => aTrip.tripId === trip.tripId))
          .map((trip) => trip.tripId ?? 0) || [];
      const recentlyCreatedTrips =
        action.data.trips?.filter((trip) => !(state.trips ?? []).find((aTrip) => aTrip.tripId === trip.tripId)) ?? [];

      return {
        ...state,
        loadingAliveTrips: false,
        aliveTripsFetched: true,
        trips: [
          ...recentlyCreatedTrips,
          ...(state.trips
            ?.filter((trip) => trip.status === 'IN_TRANSIT' || !alliveToCompleteTripsIds.includes(trip.tripId ?? -1))
            .map((trip) => {
              const updatedTrip = action.data.trips?.find((t) => t.tripId === trip.tripId);
              return (
                updatedTrip ??
                (alliveToCompleteTripsIds.includes(trip.tripId ?? -1)
                  ? { ...trip, status: trip.status === 'IN_TRANSIT' ? 'FINISHED' : 'CANCELLED' }
                  : trip)
              );
            }) ?? []),
        ],
        tripsErrorMessage: null,
      };

    case FETCH_ALIVE_TRIPS_FAILED:
      return {
        ...state,
        loadingAliveTrips: false,
        aliveTripsFetched: false,
      };

    case FETCHING_TRIP_STOPS:
      return {
        ...state,
        loadingStops: true,
        stops: null,
        stopsErrorMessage: null,
      };

    case FETCH_TRIP_STOPS_SUCCESS:
      return {
        ...state,
        loadingStops: false,
        stopsFetched: true,
        stops: action.stops,
        stopsErrorMessage: null,
      };

    case FETCH_TRIP_STOPS_FAILED:
      return {
        ...state,
        loadingStops: false,
        stopsFetched: true,
        stops: null,
        stopsErrorMessage: action.error,
      };

    case CREATING_TRIP:
      return {
        ...state,
        creatingTrip: true,
        createTripSuccess: false,
        createTripErrorMessage: null,
      };

    case CREATE_TRIP_SUCCESS:
      const newTrips = state.trips ? [...state.trips, ...action.trips] : [...action.trips];

      const trips = newTrips.reduce((acc: Trip[], item: Trip) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }

        return acc;
      }, []);

      return {
        ...state,
        trips,
        creatingTrip: false,
        createTripSuccess: true,
        createTripErrorMessage: null,
      };

    case CREATE_TRIP_FAILED:
      return {
        ...state,
        creatingTrip: false,
        createTripSuccess: false,
        createTripErrorMessage: action.error,
      };

    case CREATING_TRIP_OFFER:
      return {
        ...state,
        creatingTripOffer: true,
        createTripOfferSuccess: false,
        createTripOfferErrorMessage: null,
      };

    case CREATE_TRIP_OFFER_SUCCESS:
      let updatedTrips =
        state.trips &&
        state.trips.map((item: Trip) => {
          if (item.tripId !== action.updatedTrip.tripId) {
            return item;
          }

          return {
            ...action.updatedTrip,
          };
        });

      return {
        ...state,
        creatingTripOffer: false,
        createTripOfferSuccess: true,
        trips: updatedTrips,
        createTripOfferErrorMessage: null,
      };

    case CREATE_TRIP_OFFER_FAILED:
      return {
        ...state,
        creatingTripOffer: false,
        createTripOfferSuccess: false,
        createTripOfferErrorMessage: action.error,
      };

    case DELETING_TRIP_OFFER:
      return {
        ...state,
        deletingTripOffer: true,
        deleteTripOfferSuccess: false,
        deleteTripOfferErrorMessage: null,
      };

    case DELETE_TRIP_OFFER_SUCCESS:
      let nonDeletedTripOffers =
        state.trips &&
        state.trips.map((item: Trip) => {
          return {
            ...item,
            tripOffers: item.tripOffers.filter((item: TripOffer) => item.tripOfferId !== action.tripOfferId),
          };
        });

      return {
        ...state,
        deletingTripOffer: false,
        deleteTripOfferSuccess: true,
        trips: nonDeletedTripOffers,
        deleteTripOfferErrorMessage: null,
      };

    case DELETE_TRIP_OFFER_FAILED:
      return {
        ...state,
        deletingTripOffer: false,
        deleteTripOfferSuccess: false,
        deleteTripOfferErrorMessage: action.error,
      };

    default:
      return state;
  }
}
