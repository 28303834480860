import clsx from 'clsx';
import moment from 'moment';
import { googleMapsLink } from '../../utils/string.helpers';
import { ReactComponent as PackageIcon } from '../../assets/images/icon-packages.svg';
import { ReactComponent as Navigate } from '../../assets/images/icon-navigate.svg';
import { ReactComponent as ProgressBarCheckGreen } from '../../assets/images/curbhub_packages-details_bar_1_check.svg';
import { ReactComponent as ProgressBarGrey } from '../../assets/images/curbhub_packages-details_bar_2.svg';
import { ReactComponent as ProgressBarGreen } from '../../assets/images/curbhub_packages-details_bar_1.svg';
import Button from '../../components/CustomButtons/Button';
import { Package, Stop } from '../../store/config/types';

interface TripStopDetailsProps {
  stop: Stop;
  // eslint-disable-next-line no-unused-vars
  setStopPackages: (packages: Package[]) => void;
}

function TripStopDetails({ stop, setStopPackages }: TripStopDetailsProps) {
  const handleNavigate = () => {
    const location = {
      latitude: stop.location.latitude,
      longitude: stop.location.longitude,
      locationAt: new Date(),
    };
    const link = googleMapsLink(location);

    window.open(link, '_blank', 'noopener,noreferrer');
  };
  const interaction = stop.stopPackages?.[0]?.interactionType.toLowerCase().replaceAll('_', ' ');

  const handleStopTypeClass = () => {
    switch (stop.stopPackages?.[0]?.interactionType) {
      case 'LOAD':
        return 'load-badge';
      case 'DROP_OFF':
        return 'drop-off-badge';
      case 'TRANSSHIPMENT_LOAD':
        return 'transshipment-load-badge';
      case 'TRANSSHIPMENT_DROP_OFF':
        return 'transshipment-drop-off-badge';
      default:
        break;
    }
  };

  const handleTripProgress = () => {
    switch (stop.status) {
      case 'PENDING':
        return <ProgressBarGrey className="pending-progress-bar" />;
      case 'CURRENT':
        return <ProgressBarGreen className="current-progress-bar" />;
      case 'COMPLETED':
        return <ProgressBarCheckGreen className="completed-progress-bar" />;
      default:
        <ProgressBarGrey className="pending-progress-bar" />;
    }
  };

  const handleStopTypeName = () => {
    if (typeof stop?.location?.transshipment?.description === 'string') {
      return 'Transshipment';
    }
    if (typeof stop?.location?.warehouse?.name === 'string') {
      return `Warehouse: ${stop?.location?.warehouse?.name}`;
    }
    if (typeof stop?.location?.customer?.firstName === 'string') {
      return `Customer: ${stop?.location?.customer?.firstName} ${stop?.location?.customer?.lastName}`;
    }
  };

  const handleSetPackages = () => {
    const packages: Package[] = stop.stopPackages.map((stopPackage) => stopPackage.package);
    setStopPackages(packages);
  };

  return (
    <div className="information-div">
      {handleTripProgress()}
      <div className="stop-div">
        <div className="header">
          <h2 className="courier-name">{handleStopTypeName()}</h2>
          {interaction && (
            <span className={clsx(handleStopTypeClass(), 'badge')}>
              {interaction.charAt(0).toLocaleUpperCase() + interaction.slice(1)}
            </span>
          )}
        </div>
        <div className="general-info">
          <h3 className="direction">{stop?.location?.streetAddress}</h3>
          <p className="arrival-time">
            ARRIVE BY <br />{' '}
            <span className="time">
              {moment(stop.schedulerArrivalAt ?? stop.routerArrivalAt).format('MM-DD-YYYY, hh:mm a')}
            </span>
          </p>
        </div>
        <div className="extra-info">
          <Button className="navigate-button" type="button" onClick={handleNavigate}>
            <Navigate />
            NAVIGATE
          </Button>
          <Button className="packages" type="button" onClick={handleSetPackages}>
            <PackageIcon className={stop.status === 'COMPLETED' ? 'package-green' : 'package-black'} />
            {stop.stopPackages.length} package{stop.stopPackages.length > 1 && 's'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TripStopDetails;
