import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7FC15E',
      light: '#E8F0F8',
    },
    secondary: {
      main: '#cecf00',
    },
    error: {
      main: '#f11930',
      light: '#f9ecec',
      dark: '#c54343',
    },
    warning: {
      main: '#ffc400',
      light: '#fff9e5',
      dark: '#ffc400',
    },
    success: {
      main: '#529252',
      light: '#edf4ed',
      dark: '#529252',
    },
    background: {
      default: '#f6f6f6',
    },
  },
  typography: {
    fontFamily: `"Roboto Condensed", sans-serif`,
    h1: {
      fontSize: '3.75rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.125rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
    subtitle2: {
      color: '#5D5D5D',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 6,
        textTransform: 'none',
        padding: '8px 16px',
        fontSize: 16,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(112, 112, 112, 0.9)',
      },
    },
  },
});
