import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { WarningOutlined, Error, PersonAdd } from '@material-ui/icons';
import { RootState } from '../../store/config/types';
import {
  DeliveryOrder,
  PackageGroup,
  PackageGroupEditStruct,
  PackageModel,
} from '../../store/config/types/deliveryOrders.types';
import { deleteCSVLine } from '../../store/actions/deliveryOrders.actions';
import { transformCSVLines } from '../../utils/deliveryOrders.helpers';
import GridItem from '../../components/Grid/GridItem';
import TableList from '../../components/TableList/TableList';
import PackageForm from './PackageForm';
import { formatTimeWindow } from '../../utils/string.helpers';

interface PackagesTableProps {
  packages: PackageModel[];
  /* eslint-disable */
  setPackages(value: PackageModel[]): void;
  setErrorIndexes?(indexes: number[]): void;
  /* eslint-enable */
  selectedDeliveryOrder: DeliveryOrder | null;
  selectedPackageGroup?: PackageGroup;
}

const mapStateToProps = (state: RootState) => ({
  customers: state.customers,
  deliveryOrders: state.deliveryOrders,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  deleteCSVLine: (packageId: string) => dispatch(deleteCSVLine(packageId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = PackagesTableProps & PropsFromRedux;

function PackagesTable({
  packages,
  setPackages,
  setErrorIndexes,
  selectedDeliveryOrder,
  // selectedPackageGroup,
  customers,
  deliveryOrders,
  deleteCSVLine,
}: PropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<PackageModel | null>(null);
  const isDetail: boolean = selectedDeliveryOrder !== null;

  const editPackage = (packageId: number) => {
    const selectedPackage = [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)].find(
      (packageObject: PackageModel) => packageObject.packageId === packageId,
    );
    setSelectedPackage(selectedPackage!);
    setOpen(true);
  };

  const removePackage = (packageId: number) => {
    const newPackages = packages.filter((packageObject: PackageModel) => packageObject.packageId !== packageId);
    setPackages(newPackages);
    deleteCSVLine(packageId.toString());
  };

  const submitPackage = (values: PackageGroupEditStruct) => {
    const editedPackages = packages.map((item: PackageModel) => {
      if (item.packageId !== selectedPackage?.packageId) {
        return item;
      }

      return {
        ...values,
        packageId: selectedPackage?.packageId,
      };
    });

    setPackages(editedPackages);
    setOpen(false);
  };

  const packagesList: PackageModel[] = isDetail
    ? []
    : // ? createPackagesList(selectedDeliveryOrder!, selectedPackageGroup)
      [...packages, ...transformCSVLines(deliveryOrders.parsedCSVLines)];

  const errorIndexes: number[] = [];
  setErrorIndexes && setErrorIndexes([]);

  return (
    <div className="packages-table">
      <TableList
        head={[
          'Id',
          '',
          'Company',
          'Name',
          'Ship to',
          'Number of packages',
          'Size of packages',
          'Time window',
          'Status',
          '',
        ]}
        edit={!isDetail}
        onEdit={editPackage}
        delete={!isDetail}
        onDelete={removePackage}
        data={packagesList.map((packageObject: PackageModel, packageIndex) => {
          const customer = customers.customers?.find((c) => c.customerId === packageObject.customerId);
          const hasError: boolean =
            !packageObject.customerId &&
            (!packageObject.contactPhoneNumber ||
              packageObject.contactPhoneNumber.length < 10 ||
              !packageObject.firstName ||
              !packageObject.lastName);
          if (hasError) {
            errorIndexes.push(packageIndex);
            setErrorIndexes && setErrorIndexes([...errorIndexes]);
          }
          const validationIcon = packageObject.customerId ? (
            ''
          ) : hasError ? (
            <Error color="error" />
          ) : (
            <PersonAdd color="primary" />
          );
          return [
            packageObject.packageId,
            validationIcon,
            customer?.companyName || packageObject.companyName || '-',
            (customer?.firstName || packageObject.firstName || '') +
              ' ' +
              (customer?.lastName || packageObject.lastName || ''),
            packageObject.shippingAddress || customer?.location?.streetAddress || packageObject.streetAddress || (
              <WarningOutlined color="secondary" />
            ),
            packageObject.packagesAmount,
            packageObject.packagesSize !== 'Custom' ? packageObject.packagesSize : packageObject.customSize,
            formatTimeWindow(packageObject.timeWindowFrom ?? null, packageObject.timeWindowTo ?? null, false),
            packageObject.packagesStatus,
          ];
        })}
      />
      <Dialog open={open} onClose={() => setOpen(false)} className="package-dialog">
        <DialogTitle className="package-dialog-title">
          <GridItem xs={12}>Edit package</GridItem>
        </DialogTitle>
        <DialogContent className="package-dialog-content">
          <PackageForm
            customers={customers}
            submitPackage={submitPackage}
            selectedPackage={selectedPackage!}
            closeDialog={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connector(PackagesTable);
