/* eslint-disable */
export enum WarehouseWorkReferenceType {
  PACKAGE_GROUP = 'PACKAGE_GROUP',
  ASSEMBLY = 'ASSEMBLY',
}

export enum WarehouseWorkType {
  PICK = 'PICK',
  PACK = 'PACK',
  TRANSFER = 'TRANSFER',
}

export enum PackageGroupFulfillmentStatus {
  NEW = 'NEW',
  COMMITTED = 'COMMITTED', // Everything is Ok to Creating WOs
  SKIPPED = 'SKIPPED', // Skipped by user
  NEEDS_ATTENTION = 'NEEDS_ATTENTION', // some items are insufficient inventory or missing product
  PENDING = 'PENDING', // WOs created and visible for Pickers
  PROCESSING = 'PROCESSING',
  FULFILLED = 'FULFILLED',
  EXTERNALLY_FULFILLED = 'EXTERNALLY_FULFILLED',
  SYNCED = 'SYNCED',
  SYNC_FAILED = 'SYNC_FAILED',
}

export enum PackageGroupFulfillmentReadableStatus {
  INSUFFICIENT_INVENTORY = 'Insufficient Inventory',
  PENDING = 'Pending Fulfillment',
  PROCESSING = 'Processing',
  PARTIAL_FULFILLED = 'Partially Fulfilled',
  FULFILLED = 'Fulfilled',
  MIXED_STATUS = 'Mixed Status',
  NEEDS_ATTENTION = 'Needs Attention',
  NEW = 'New',
  COMMITTED = 'Committed',
  SKIPPED = 'Skipped',
  SYNCED = 'Fulfilled & Synced',
  EXTERNALLY_FULFILLED = 'Externally Fulfilled',
  SYNC_FAILED = 'Sync Failed',
}

export enum AssemblyBuildStatus {
  PENDING_API = 'PENDING_API',
  NEW = 'NEW',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PARTIAL_BUILT = 'PARTIAL_BUILT',
  BUILT = 'BUILT',
  INSUFFICIENT_INVENTORY = 'INSUFFICIENT_INVENTORY',
  SYNCED = 'SYNCED',
  AUTOLOT_FAILED = 'AUTOLOT_FAILED',
}

export enum AssemblyBuildStatusReadableStatus {
  PENDING_API = 'Pending Api Call',
  NEW = 'New',
  PENDING = 'Pending Build',
  PROCESSING = 'Processing',
  PARTIAL_BUILT = 'Partially Built',
  BUILT = 'Built',
  INSUFFICIENT_INVENTORY = 'Insufficient Inventory',
  SYNCED = 'Built & Synced',
  AUTOLOT_FAILED = 'Missing Lot',
}

export enum OrderItemFulfillmentStatus {
  UNKNOWN = 'UNKNOWN',
  MISSING_PRODUCT = 'MISSING_PRODUCT', // product not imported
  INSUFFICIENT_INVENTORY = 'INSUFFICIENT_INVENTORY', // backordered
  AUTOLOT_FAILED = 'AUTOLOT_FAILED', // auto lot failed
  READY_TO_FULFILL = 'READY_TO_FULFILL', // ready to create WorkOrder and FulfillmentItem
  PENDING = 'PENDING', // Visible by WarehouseUser
  FULFILLED = 'FULFILLED',
  INACTIVE = 'INACTIVE', // Shipper dosen't have WMS
}
