import { Dispatch } from 'redux';
import { NotificationService } from '../../services/notificationSystem.service';
import {
  NotificationSettingsState,
  NotificationTemplates,
  UpdateNotificationSettingsRequest,
} from '../config/types/notificationSystem.types';
import {
  FETCHING_NOTIFICATION_SETTINGS,
  FETCHING_NOTIFICATION_SETTINGS_SUCCESS,
  FETCHING_NOTIFICATION_SETTINGS_FAILED,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILED,
  NotificationSystemActionTypes,
  FETCHING_NOTIFICATION_TEMPLATES,
  FETCHING_NOTIFICATION_TEMPLATES_SUCCESS,
  FETCHING_NOTIFICATION_TEMPLATES_FAILED,
} from '../config/ActionTypes';

export const getNotificationSettings = (shipperId: number | string) => {
  return (dispatch: Dispatch<NotificationSystemActionTypes>) => {
    dispatch(fetchingNotificationSettings());

    return NotificationService.fetchNotificationSettings(shipperId).then(
      (response) => {
        dispatch(fetchingNotificationSettingsSuccess(response));
      },
      (error) => {
        dispatch(fetchingNotificationSettingsFailed(error));
      },
    );
  };
};

const fetchingNotificationSettings = (): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_SETTINGS,
});

const fetchingNotificationSettingsSuccess = (settings: NotificationSettingsState): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_SETTINGS_SUCCESS,
  settings,
});

const fetchingNotificationSettingsFailed = (error: string): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_SETTINGS_FAILED,
  error,
});

export const updateNotificationSettings = (settings: UpdateNotificationSettingsRequest) => {
  return (dispatch: Dispatch<NotificationSystemActionTypes>) => {
    dispatch(updatingNotificationSettings());

    return NotificationService.updateNotificationSettings(settings).then(
      (response) => {
        dispatch(updatingNotificationSettingsSuccess(response));
      },
      (error) => {
        dispatch(updatingNotificationSettingsFailed(error));
      },
    );
  };
};
const updatingNotificationSettings = (): NotificationSystemActionTypes => ({
  type: UPDATE_NOTIFICATION_SETTINGS,
});

const updatingNotificationSettingsSuccess = (settings: NotificationSettingsState): NotificationSystemActionTypes => ({
  type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  settings,
});

const updatingNotificationSettingsFailed = (error: string): NotificationSystemActionTypes => ({
  type: UPDATE_NOTIFICATION_SETTINGS_FAILED,
  error,
});

export const getNotificationTemplates = (shipperId: number | string, onlyCustomTemplates = false) => {
  return (dispatch: Dispatch<NotificationSystemActionTypes>) => {
    dispatch(fetchingNotificationTemplates());

    return NotificationService.fetchNotificationTemplates(shipperId, onlyCustomTemplates).then(
      (response) => {
        dispatch(fetchingNotificationTemplatesSuccess(response));
      },
      (error) => {
        dispatch(fetchingNotificationTemplatesFailed(error));
      },
    );
  };
};

const fetchingNotificationTemplates = (): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_TEMPLATES,
});

const fetchingNotificationTemplatesSuccess = (templates: NotificationTemplates): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_TEMPLATES_SUCCESS,
  templates,
});

const fetchingNotificationTemplatesFailed = (error: string): NotificationSystemActionTypes => ({
  type: FETCHING_NOTIFICATION_TEMPLATES_FAILED,
  error,
});
