import { Grid } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';

interface PaymentMethodSelectProps {
  paymentMethods: PaymentMethod[];
}

export default function PaymentMethodSelect({ paymentMethods }: PaymentMethodSelectProps) {
  return (
    <Grid container>
      {paymentMethods.map((method, methodIndex) => (
        <Grid xs={12} key={`method-${methodIndex}`}>
          {`${method.card?.brand}  **** ${method.card?.last4} ${('00' + method.card?.exp_month).slice(-2)}/${(
            '' + method.card?.exp_year
          ).slice(-2)}`}
        </Grid>
      ))}
    </Grid>
  );
}
