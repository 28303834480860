/* eslint-disable */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { LotItems, LotQuantity } from '../../store/config/types';
import { FulfillmentService } from '../../services/warehouseWork.service';
import { Divider } from '@material-ui/core';
import { fixNumber } from '../../utils/string.helpers';

export interface ChangeLotDialogProps {
  onClose: () => void;
  onChange?: (newLotQuantityId: number, newLotId: string, lotQuantityId?: number) => Promise<any>;
  onError?: (message: string) => void;
  open: boolean;
  defaultLotQuantityId?: number;
  minQuantity: number;
  productId: number;
  warehouseId: number;
}
export default function ChangeLotDialog({
  open,
  defaultLotQuantityId,
  minQuantity,
  productId,
  warehouseId,
  onClose,
  onError,
  onChange,
}: ChangeLotDialogProps) {
  const [processing, setProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [selectedLot, setSelectedLot] = useState<number>(0);
  const [availableLots, setAvailableLots] = useState<LotQuantity[]>([]);

  useEffect(() => {
    if (open) {
      setProcessing(true);
      FulfillmentService.getAvailableLotsFor(productId, [warehouseId], minQuantity)
        .then((lots) => {
          setAvailableLots(lots);
          setStatusMessage('');
          const selectedIndex = lots.findIndex(
            (lot: LotQuantity) => lot.inventoryLotQuantityId === defaultLotQuantityId,
          );
          if (selectedIndex) {
            setSelectedLot(lots[selectedIndex].inventoryLotQuantityId);
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [open]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleChangeLot = () => {
    if (onChange && selectedLot && selectedLot !== defaultLotQuantityId) {
      setProcessing(true);
      const lot = availableLots.find((l) => l.inventoryLotQuantityId == selectedLot);
      if (lot && lot.inventoryLotQuantityId) {
        onChange(lot.inventoryLotQuantityId, lot.lotId ?? '', defaultLotQuantityId)
          .then(() => {
            handleClose();
          })
          .catch((err) => {
            if (onError) {
              onError(err.message);
            }
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">Change</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="lot-select-label">Lot</InputLabel>
          <Select
            labelId="lot-select-label"
            id="lot-select"
            value={selectedLot}
            label="Lot"
            onChange={(e) => setSelectedLot(+e.target.value)}
          >
            {availableLots.map((lot) => (
              <MenuItem value={lot.inventoryLotQuantityId}>
                <Grid container>
                  <Grid item flexGrow={1}>
                    {lot.lotId}
                  </Grid>
                  <Grid item ml={2} mr={1}>
                    :
                  </Grid>
                  <Grid item>{fixNumber(lot.quantity)}</Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="success" disabled={processing || !selectedLot} onClick={handleChangeLot}>
              Change
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
