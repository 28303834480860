import { useState } from 'react';
import { Dialog, MenuItem } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { ThunkDispatch } from 'redux-thunk';
import { connect, ConnectedProps } from 'react-redux';
import { DialogContent, Grid } from '@mui/material';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import InputField from '../../components/SpecialInput/InputField';
import SelectField from '../../components/SpecialInput/SelectField';
import AutocompleteField from '../../components/SpecialInput/AutocompleteField';
// import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import schemas from '../../utils/schemas';
import useIsMobile from '../../utils/useIsMobile';
import {
  CSVLine,
  PackageGroup,
  PackageGroupEditStruct,
  PackageModel,
} from '../../store/config/types/deliveryOrders.types';
import { editCSVLine } from '../../store/actions/deliveryOrders.actions';
import { Customer, CustomersState, RootState } from '../../store/config/types';
import { Coordinates } from '../../store/config/types/location.types';
import CustomersForm from '../Customers/CustomersForm';
import { useHistory } from 'react-router-dom';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { setToast } from '../../store/actions/toast.actions';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';

interface PackageFormProps {
  customers: CustomersState;
  selectedPackageGroup?: PackageGroup;
  deliveryExpectedAt?: Date;
  // eslint-disable-next-line no-unused-vars
  submitPackage(values: PackageGroupEditStruct, formikHelpers: FormikHelpers<PackageGroupEditStruct>): void;
  selectedPackage?: PackageModel;
  closeDialog?(): void;
}

const sizes: string[] = ['Small', 'Medium', 'Large', 'Custom'];

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  editCSVLine: (csvLine: CSVLine) => dispatch(editCSVLine(csvLine)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PackageFormProps & PropsFromRedux;

function PackageForm({
  customers,
  submitPackage,
  selectedPackage,
  selectedPackageGroup,
  deliveryExpectedAt,
  closeDialog,
  editCSVLine,
  setToast,
}: PropsType) {
  const getCustomerAddress = (customerId?: number) => {
    if (!customerId)
      customerId =
        selectedPackageGroup?.customerId ??
        (createdCustomer ? createdCustomer.customerId : selectedPackage ? selectedPackage.customerId! : 0);
    return customers.customers?.find((customer) => customer.customerId == customerId)?.location.streetAddress ?? '';
  };

  const isMobile = useIsMobile();
  const isEditing = !!selectedPackage;
  const PGEditMode = !!selectedPackageGroup;

  const [address] = useState<string>((selectedPackage && selectedPackage.streetAddress) || '');
  const [createdCustomer, setCreatedCustomer] = useState<Customer | null>(null);
  const [shippingAddress, setShippingAddress] = useState<string>(
    selectedPackageGroup?.location?.streetAddress
      ? selectedPackageGroup?.location?.streetAddress
      : getCustomerAddress(),
  );
  const [coordinates] = useState<Coordinates>({ lat: null, lng: null });
  const [city] = useState<string>('');
  const [customerOpen, setCustomerOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const createNewCustomer = () => setCustomerOpen(true);
  const handleCancel = () => {
    history.goBack();
  };

  const isAvailableSize = (size: string) => sizes.indexOf(size) > -1;

  const editPackage = (values: PackageGroupEditStruct, formikHelpers: FormikHelpers<PackageGroupEditStruct>) => {
    values.shippingAddress = shippingAddress;
    if (!shippingAddress && selectedPackageGroup) values.shippingAddress = getCustomerAddress();
    if (!values.shippingAddress) return;

    if (PGEditMode) {
      // edit
      if (selectedPackageGroup) {
        setSubmitting(true);

        deliveryOrdersService
          .editPackageGroup(selectedPackageGroup, values)
          .then(() => {
            setToast('Saved', 'success');
            handleCancel();
          })
          .catch((err) => {
            setToast(err?.response?.data?.message ?? err.message ?? '', 'danger');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } else {
      if (values.customerId) {
        // Is manually added package
        submitPackage(values, formikHelpers);
      } else {
        // Is CSV package
        const csvLine: CSVLine = {
          city: city || selectedPackage?.city!,
          latitude: coordinates.lat || selectedPackage?.latitude || 0,
          longitude: coordinates.lng || selectedPackage?.longitude || 0,
          count: values.packagesAmount.toString(),
          size: values.packagesSize !== 'Custom' ? values.packagesSize : values.customSize,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.companyName,
          email: values.email,
          contactPhoneNumber: values.contactPhoneNumber,
          streetAddress: address,
          packageId: selectedPackage?.packageId?.toString() || '0',
          deliveryInstructions: values.deliveryInstructions,
          timeWindowFrom: values.timeWindowFrom,
          timeWindowTo: values.timeWindowTo,
        };
        editCSVLine(csvLine);
        closeDialog && closeDialog();
      }
    }
  };

  const closeCustomerModal = () => setCustomerOpen(false);

  const customerModalSuccess = (customer: Customer) => {
    setCreatedCustomer(customer);
    closeCustomerModal();
  };
  const getTimeWindowFrom = (customerId: number) =>
    customers.customers?.find((customer) => customer.customerId == customerId)?.timeWindowFrom;

  const getTimeWindowTo = (customerId: number) =>
    customers.customers?.find((customer) => customer.customerId == customerId)?.timeWindowTo;

  return (
    <>
      <Formik
        initialValues={{
          customerId:
            selectedPackageGroup?.customerId ??
            (createdCustomer ? createdCustomer.customerId : selectedPackage ? selectedPackage.customerId! : 0),
          packagesAmount: selectedPackageGroup
            ? selectedPackageGroup.packages.length
            : selectedPackage
            ? selectedPackage.packagesAmount!
            : 0,
          packagesSize: selectedPackageGroup
            ? selectedPackageGroup.packages[0].size
            : selectedPackage
            ? isAvailableSize(selectedPackage.packagesSize!)
              ? selectedPackage.packagesSize!
              : 'Custom'
            : 'Small',
          deliveryInstructions: selectedPackageGroup
            ? selectedPackageGroup.instructions ?? ''
            : selectedPackage
            ? selectedPackage.deliveryInstructions!
            : '',
          customSize: selectedPackage
            ? isAvailableSize(selectedPackage.packagesSize!)
              ? selectedPackage.customSize!
              : selectedPackage.packagesSize!
            : '',
          firstName: selectedPackage && selectedPackage.firstName ? selectedPackage.firstName : '',
          lastName: selectedPackage && selectedPackage.lastName ? selectedPackage.lastName : '',
          companyName: selectedPackage && selectedPackage.companyName ? selectedPackage.companyName : '',
          email: selectedPackage && selectedPackage.email ? selectedPackage.email : '',
          contactPhoneNumber:
            selectedPackage && selectedPackage.contactPhoneNumber ? selectedPackage.contactPhoneNumber : '',
          timeWindowFrom: selectedPackageGroup?.timeWindowFrom ?? selectedPackage?.timeWindowFrom ?? '',
          timeWindowTo: selectedPackageGroup?.timeWindowTo ?? selectedPackage?.timeWindowTo ?? '',
          deliveryExpectedAt: deliveryExpectedAt,
          shippingAddress: selectedPackageGroup?.location?.streetAddress ?? '',
          shipToAddressee: selectedPackageGroup?.shipToAddressee ?? '',
        }}
        validationSchema={PGEditMode ? undefined : schemas.PackageSchema}
        onSubmit={editPackage}
        enableReinitialize
        onReset={() => {
          setShippingAddress('');
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <GridContainer noMarginTop>
              <GridItem xs={12} md={8}>
                {customers.customers && (!selectedPackage || selectedPackage?.customerId) && (
                  <div className="customer-row">
                    <AutocompleteField
                      placeholder="Customer"
                      name="customerId"
                      values={customers.customers.map((customer: Customer) => ({
                        id: customer.customerId,
                        title: `${customer.companyName || ''} ${customer.firstName || ''} ${customer.lastName || ''}`,
                      }))}
                      value={values.customerId}
                      onValueChange={(customerId: number) => {
                        setFieldValue('timeWindowFrom', getTimeWindowFrom(customerId) ?? '');
                        setFieldValue('timeWindowTo', getTimeWindowTo(customerId) ?? '');
                        setShippingAddress(getCustomerAddress(customerId));
                      }}
                    />
                    {!isEditing && (
                      <span className="link" onClick={createNewCustomer}>
                        {isMobile ? 'New' : 'Create new customer'}
                      </span>
                    )}
                  </div>
                )}
              </GridItem>
              {!isMobile && <GridItem md={4} />}
              {/* {isEditing && !selectedPackageGroup?.customerId && (
                <>
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="Company name" name="companyName" />
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="First name" name="firstName" />
                  </GridItem>
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="Last name" name="lastName" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputSize={8}
                    inputName={'streetAdress'}
                    placeholderName={''}
                  />
                  <GridItem xs={6} md={4}>
                    <InputField placeholder="Phone" name="contactPhoneNumber" />
                  </GridItem>
                </>
              )} */}

              <GoogleAutocomplete
                address={shippingAddress}
                setAddress={setShippingAddress}
                // setCoordinates={setCoordinates}
                // setCity={setCity}
                // setState={setState}
                // setZipCode={setZipCode}
                setFieldValue={setFieldValue}
                usesAllGoogleProps={true}
                inputSize={8}
                inputName={'shippingAddress'}
                placeholderName={'Ship to'}
              />
              <GridItem xs={12} md={4}>
                <InputField placeholder="Ship to addressee" name="shipToAddressee" />
              </GridItem>
              <GridItem xs={6} md={3}>
                <InputField placeholder="Number of packages" name="packagesAmount" type="number" />
              </GridItem>
              <GridItem xs={6} md={3}>
                <SelectField
                  placeholder="Size of the largest package"
                  name="packagesSize"
                  leftAligned
                  values={sizes.map((size: string) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              <GridItem xs={12} md={selectedPackage ? 3 : 2}>
                {values.packagesSize === 'Custom' && <InputField placeholder="Custom size" name="customSize" />}
              </GridItem>

              <GridItem xs={12}>
                <InputField
                  placeholder="Delivery instructions"
                  name="deliveryInstructions"
                  type="textarea"
                  multiline={true}
                  rows={3}
                  formControlClassName="package-custom-input"
                />
              </GridItem>
              <Grid container>
                <GridItem xs={12} justifyContent="flex-start">
                  <p style={{ textAlign: 'left', marginBottom: -16 }}>Time Window</p>
                </GridItem>
                <GridItem xs={6} sm={3}>
                  <InputField placeholder={'From'} name="timeWindowFrom" type="time" />
                </GridItem>
                <GridItem xs={6} sm={3}>
                  <InputField mt={0} placeholder={'To'} name="timeWindowTo" type="time" />
                </GridItem>
              </Grid>
              <GridItem xs={12} className="left-align">
                <Button type="submit" color="primary" disabled={submitting}>
                  {PGEditMode ? 'Save changes' : 'Add delivery item'}
                </Button>
                {PGEditMode && (
                  <Button color="secondary" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
      <Dialog open={customerOpen} onClose={closeCustomerModal}>
        <DialogContent>
          <CustomersForm fromModal closeModal={closeCustomerModal} submitModal={customerModalSuccess} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connector(PackageForm);
