import * as Yup from 'yup';
// import { VehicleEnum } from '../store/config/enums/vehicle.enum';

const requiredString: string = 'Required field';
const wrongEmailString: string = 'Wrong email format';
const repeatedValueString: string = 'Value is repeated';

const onlyNumbersRegex: RegExp = /^[0-9]+$/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(wrongEmailString).required(requiredString),
  password: Yup.string().required(requiredString),
});

const WarehouseSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  descriptions: Yup.string(),
  streetAddress: Yup.string().required(requiredString),
  // shipper: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  pointOfContact: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
});

const DeliveryOrderSchema = Yup.object().shape({
  date: Yup.date().required(requiredString),
  warehouseId: Yup.number().integer().min(1).required(requiredString),
});

const PackageSchema = Yup.object().shape({
  packagesAmount: Yup.number().positive().required(requiredString),
  firstName: Yup.string().when('customerId', (val) => {
    if (!val) {
      return Yup.string().required(requiredString);
    } else {
      return Yup.string().notRequired();
    }
  }),
  lastName: Yup.string().when('customerId', (val) => {
    if (!val) {
      return Yup.string().required(requiredString);
    } else {
      return Yup.string().notRequired();
    }
  }),
  contactPhoneNumber: Yup.string().when('customerId', (val) => {
    if (!val) {
      return Yup.string().length(10).required(requiredString);
    } else {
      return Yup.string().notRequired();
    }
  }),
});

const CustomerSchema = Yup.object().shape(
  {
    firstName: Yup.string().required(requiredString),
    lastName: Yup.string().required(requiredString),
    email: Yup.string().when('email', (val) => {
      if (val?.length > 0) {
        return Yup.string().email(wrongEmailString);
      } else {
        return Yup.string().notRequired();
      }
    }),
    contactPhoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
    streetAddress: Yup.string().required(requiredString),
  },
  [['email', 'email']],
);

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  homeAddress: Yup.string().required(requiredString),
});

// TODO - Try to make a generic test function
const getOtherFieldsValues = (parent: any, fieldName: string) => {
  return Object.keys(parent)
    .filter((key) => key !== fieldName && !!parent[key])
    .map((key) => parent[key]);
};
const CSVSchema = Yup.object().shape({
  customerFirstName: Yup.string().required(requiredString),
  customerLastName: Yup.string().required(requiredString),
  customerAddressLineOne: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        return getOtherFieldsValues(this.parent, 'customerAddressLineOne').indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerAddressLineTwo: Yup.string().test({
    name: 'nonRepeatedValue',
    exclusive: false,
    params: {},
    message: repeatedValueString,
    test: function (value) {
      return getOtherFieldsValues(this.parent, 'customerAddressLineTwo').indexOf(value) === -1;
    },
  }),
  customerAddressCity: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        return getOtherFieldsValues(this.parent, 'customerAddressCity').indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerAddressState: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        return getOtherFieldsValues(this.parent, 'customerAddressState').indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerAddressZipCode: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        return getOtherFieldsValues(this.parent, 'customerAddressZipCode').indexOf(value) === -1;
      },
    })
    .required(requiredString),
  customerContactPhone: Yup.string()
    .test({
      name: 'nonRepeatedValue',
      exclusive: false,
      params: {},
      message: repeatedValueString,
      test: function (value) {
        return getOtherFieldsValues(this.parent, 'customerContactPhone').indexOf(value) === -1;
      },
    })
    .required(requiredString),
});
const CourierUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  email: Yup.string().required(requiredString),
  homeAddress: Yup.string().required(requiredString),
  // vehicleType: Yup.string().required(requiredString),
  // licensePlate: Yup.string().when(['vehicleType'], {
  //   is: (vehicleType: VehicleEnum) =>
  //     [VehicleEnum.MOTORCYCLE, VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
  //   then: Yup.string().required(requiredString),
  //   otherwise: Yup.string().nullable(),
  // }),
  // model: Yup.string().when(['vehicleType'], {
  //   is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
  //   then: Yup.string().required(requiredString),
  //   otherwise: Yup.string().nullable(),
  // }),
  // color: Yup.string().when(['vehicleType'], {
  //   is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
  //   then: Yup.string().required(requiredString),
  //   otherwise: Yup.string().nullable(),
  // }),
  // capacity: Yup.string().when(['vehicleType'], {
  //   is: (vehicleType: VehicleEnum) => [VehicleEnum.CAR, VehicleEnum.TRUCK].includes(vehicleType),
  //   then: Yup.string().required(requiredString),
  //   otherwise: Yup.string().nullable(),
  // }),
});

const CourierCreateSchema = CourierUpdateSchema.concat(
  Yup.object().shape({ password: Yup.string().required(requiredString) }),
);

const TripSchema = Yup.object().shape({
  date: Yup.string().required(requiredString),
});

const CourierOfferSchema = Yup.object().shape({
  courierId: Yup.number().min(1, requiredString).required(requiredString),
});

const ShipperUserSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  email: Yup.string().email(wrongEmailString).required(requiredString),
  phoneNumber: Yup.string().matches(onlyNumbersRegex).min(10).max(10).required(requiredString),
  homeAddress: Yup.string().required(requiredString),
});

const schemas = {
  LoginSchema,
  DeliveryOrderSchema,
  PackageSchema,
  WarehouseSchema,
  CustomerSchema,
  ProfileSchema,
  CSVSchema,
  CourierUpdateSchema,
  CourierCreateSchema,
  CourierOfferSchema,
  TripSchema,
  ShipperUserSchema,
};

export default schemas;
