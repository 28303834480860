import { CostInput } from '../store/config/types/cost.types';
import axios from './axios';

async function getSubjectCosts(subject: string, subjectId: string): Promise<CostInput[] | undefined> {
  const res = await axios.get(`/costs/${subject}/${subjectId}`);
  return res.data.data;
}

async function deleteCost(costInputId: number): Promise<boolean | undefined> {
  const res = await axios.delete(`/costs/${costInputId}`);
  return res.data.data;
}

async function editCost(
  subject: string,
  subjectId: string,
  costInputId: number,
  value: number,
): Promise<boolean | undefined> {
  const res = await axios.patch(`/costs/${subject}/${subjectId}`, { costInputId, value });
  return res.data.data;
}

async function newCost(
  subject: string,
  subjectId: string,
  costInputCategory: string,
  UoM: string,
  value: number,
): Promise<CostInput> {
  const res = await axios.post(`/costs/${subject}/${subjectId}`, { costInputCategory, UoM, value });
  return res.data.data;
}

export const CostService = {
  getSubjectCosts,
  deleteCost,
  editCost,
  newCost,
};
