/* eslint-disable */
import { Grid, TableBody, Table, TableCell, TableHead, TableRow, Typography, Chip } from '@mui/material';
import { FulfillmentSalesOrderStruct } from '../../store/config/types';

interface SalesOrderDetaileProps {
  salesOrder: FulfillmentSalesOrderStruct;
}
export default function SalesOrderDetails({ salesOrder }: SalesOrderDetaileProps) {
  return (
    <Grid container p={4} pt={2} mb={4}>
      <Typography variant="button">ITEMS</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Line</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Committed</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesOrder.items.map((item, index) => {
            let itemAllert: any = '';
            if (item.fulfillmentStatus === 'MISSING_PRODUCT') {
              itemAllert = <Chip label="Missing Product" size="small" color="warning" variant="outlined" />;
            } else if (item.fulfillmentStatus === 'INSUFFICIENT_INVENTORY') {
              itemAllert = <Chip label="Insufficient Inventory" size="small" color="error" variant="outlined" />;
            } else if (item.fulfillmentStatus === 'AUTOLOT_FAILED') {
              itemAllert = <Chip label="Missing Lot" size="small" color="error" variant="outlined" />;
            }
            return (
              <TableRow key={index}>
                <TableCell>{item.additionalData?.lineIndex}</TableCell>
                <TableCell>{item.externalSource}</TableCell>
                <TableCell>{item.externalId}</TableCell>
                <TableCell>{item.description ?? item.name}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.quantityCommitted}</TableCell>
                <TableCell>{itemAllert}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
}
