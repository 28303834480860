/* eslint-disable */
import {
  Grid,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button,
  Card,
  CardActionArea,
  CardActions,
  Tabs,
  Tab,
  Tooltip,
  Chip,
} from '@mui/material';
import {
  AssemblyBuildItem,
  LotItems,
  LotQuantity,
  LotUpdateStruct,
  OrderItem,
  ProductWithFulfillableItemsStruct,
  WorkorderUpdateStruct,
} from '../../store/config/types';
import { Add, Info } from '@material-ui/icons';
import { CardContent } from '@material-ui/core';
import { SyntheticEvent, useEffect, useState } from 'react';
import Lots from './Lots';
import ChangeLotDialog from './ChangeLotDialog';
import WarehouseWorkService from '../../services/warehouseWork.service';
import { fixNumber } from '../../utils/string.helpers';
import { OrderItemFulfillmentStatus, PackageGroupFulfillmentStatus } from '../../store/config/enums/warehouseWork.enum';

interface ItemPendingFulfillmentDetailsProps {
  productWithFulfillableItems: ProductWithFulfillableItemsStruct;
  // eslint-disable-next-line
  onAction?: (action: string, params: any) => void;
}
interface ChangeLotDialogState {
  open: boolean;
  minQuantity: number;
  productId: number;
  warehouseId: number;
  defaultLotQuantityId?: number;
}
interface AssemblyBuildItemGroup {
  productId: number;
  assemblyBuildItems: AssemblyBuildItem[];
}
export default function ItemPendingFulfillmentDetails({
  productWithFulfillableItems,
  onAction,
}: ItemPendingFulfillmentDetailsProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const [lotFFItems, setLotFFItems] = useState<LotItems[]>([]);
  const [lotABItems, setLotABItems] = useState<LotItems[]>([]);
  const [hasFFItem, setHasFFItem] = useState(false);
  const [hasABItem, setHasABItem] = useState(false);
  const [assemblyBuildItemGroups, setAssemblyBuildItemGroups] = useState<AssemblyBuildItemGroup[]>([]);
  const [changeDialogState, setChangeDialogState] = useState<ChangeLotDialogState>({
    open: false,
    minQuantity: 0,
    productId: 0,
    warehouseId: 0,
    defaultLotQuantityId: 0,
  });
  const isLotNumbered = productWithFulfillableItems.type.toLowerCase().includes('lot');
  const isAssembly = productWithFulfillableItems.type.toLowerCase().includes('assembly');

  useEffect(() => {
    const thereIsFFItem = Boolean(productWithFulfillableItems.orderItems?.length);
    const thereIsABItem = Boolean(productWithFulfillableItems.assemblyBuildItems?.length);
    setHasFFItem(thereIsFFItem);
    setHasABItem(thereIsABItem);
    if (thereIsABItem && !thereIsFFItem && [0, 2].includes(tabIndex)) setTabIndex(1);
    if (thereIsFFItem) {
      const lots: LotItems[] = [];
      productWithFulfillableItems.orderItems?.forEach((orderItem) => {
        orderItem.fulfillmentItems?.forEach((ffItem) => {
          const lotIdx = lots.findIndex((lot) => lot.inventoryLotQuantityId === ffItem.inventoryLotQuantityId);
          if (lotIdx < 0) {
            lots.push({
              inventoryLotQuantityId: ffItem.inventoryLotQuantityId,
              lotId: ffItem.lotId,
              totalQuantity: ffItem.quantity,
              fulfillmentItems: [ffItem],
              productId: ffItem.productId,
              warehouseId: orderItem.additionalData.warehouse.warehouseId,
            });
          } else {
            lots[lotIdx].totalQuantity += ffItem.quantity;
            lots[lotIdx].fulfillmentItems?.push(ffItem);
          }
        });
      });

      setLotFFItems(lots);
      setLotABItems([]);
    } else {
      setLotFFItems([]);
    }
  }, [productWithFulfillableItems.orderItems]);

  useEffect(() => {
    const thereIsFFItem = Boolean(productWithFulfillableItems.orderItems?.length);
    const thereIsABItem = Boolean(productWithFulfillableItems.assemblyBuildItems?.length);
    setHasFFItem(thereIsFFItem);
    setHasABItem(thereIsABItem);
    if (thereIsABItem && !thereIsFFItem && [0, 2].includes(tabIndex)) setTabIndex(1);

    if (thereIsABItem) {
      const lots: LotItems[] = [];
      const abItemGroups: AssemblyBuildItemGroup[] = [];
      productWithFulfillableItems.assemblyBuildItems?.forEach((abItem) => {
        const abgIdx = abItemGroups.findIndex((item) => item.productId === abItem.productId);
        if (abgIdx < 0) {
          abItemGroups.push({
            productId: abItem.productId,
            assemblyBuildItems: [abItem],
          });
        } else {
          abItemGroups[abgIdx].assemblyBuildItems.push(abItem);
        }
        const lotIdx = lots.findIndex((lot) => lot.inventoryLotQuantityId === abItem.inventoryLotQuantityId);
        if (lotIdx < 0) {
          lots.push({
            inventoryLotQuantityId: abItem.inventoryLotQuantityId,
            lotId: abItem.lotId,
            totalQuantity: abItem.quantity,
            assemblyBuildItems: [abItem],
            productId: abItem.productId,
            warehouseId: abItem.inventoryLotQuantity?.warehouseId,
          });
        } else {
          lots[lotIdx].totalQuantity += abItem.quantity;
          lots[lotIdx].assemblyBuildItems?.push(abItem);
        }
      });
      setLotABItems(lots);
      setLotFFItems([]);
      setAssemblyBuildItemGroups(abItemGroups);
    } else {
      setLotABItems([]);
      setAssemblyBuildItemGroups([]);
    }
  }, [productWithFulfillableItems.assemblyBuildItems]);

  function handleCreateAssemblyClick() {
    if (onAction) onAction('create-assembly-workorder', productWithFulfillableItems);
  }

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabIndex(value);
  };

  const handleLotClick = (items: OrderItem[]) => {
    if (onAction) onAction('edit-order-items-lot', { orderItems: items, product: productWithFulfillableItems });
  };
  const handleABLotClick = (items: AssemblyBuildItem[]) => {
    if (onAction)
      onAction('edit-assembly-build-items-lot', { assemblyBuildItems: items, product: productWithFulfillableItems });
  };
  const onClickChangeLot = (allLots: LotItems[], lot?: LotItems) => {
    const newState = { ...changeDialogState };
    if (lot) {
      newState.defaultLotQuantityId = lot.inventoryLotQuantityId;
      newState.minQuantity = lot.totalQuantity;
      newState.productId = productWithFulfillableItems.productId;
      newState.warehouseId = lot.warehouseId ?? 0;
      newState.open = true;
    } else if (allLots.length > 1) {
      newState.defaultLotQuantityId = undefined;
      newState.minQuantity = allLots.reduce((sum, lot) => sum + lot.totalQuantity, 0);
      newState.productId = productWithFulfillableItems.productId;
      newState.warehouseId = allLots[0].warehouseId ?? 0;
      newState.open = true;
    }
    setChangeDialogState(newState);
  };
  const handleChangeDialogClose = () => {
    setChangeDialogState({ ...changeDialogState, open: false });
  };
  const handleChangeLot = async (newLotQuantityId: number, newLotId: string, inventoryLotQuantityId?: number) => {
    const updateStruct: WorkorderUpdateStruct[] = [];
    const addFFitemTOUpdateStruct = (item: LotUpdateStruct, warehouseWorkOrderId?: number) => {
      const woIndex = updateStruct.findIndex((us) => us.warehouseWorkOrderId === warehouseWorkOrderId);
      if (woIndex < 0) {
        updateStruct.push({
          items: [item],
          warehouseWorkOrderId,
        });
      } else {
        updateStruct[woIndex].items.push(item);
      }
    };
    lotFFItems.forEach((lotItem) => {
      if (!inventoryLotQuantityId || lotItem.inventoryLotQuantityId === inventoryLotQuantityId) {
        lotItem.fulfillmentItems?.forEach((ffItem) => {
          addFFitemTOUpdateStruct(
            {
              itemId: ffItem.fulfillmentItemId,
              oldLotQuantity: {
                quantity: ffItem.quantity,
                inventoryLotQuantityId: ffItem.inventoryLotQuantityId,
              },
              newLotQuantity: {
                quantity: ffItem.quantity,
                inventoryLotQuantityId: newLotQuantityId,
                lotId: newLotId,
              },
            },
            ffItem.warehouseWorkOrderId,
          );
        });
      }
    });
    lotABItems.forEach((lotItem) => {
      if (!inventoryLotQuantityId || lotItem.inventoryLotQuantityId === inventoryLotQuantityId) {
        lotItem.assemblyBuildItems?.forEach((abItem) => {
          addFFitemTOUpdateStruct(
            {
              itemId: abItem.assemblyBuildItemId,
              oldLotQuantity: {
                quantity: abItem.committedQuantity,
                inventoryLotQuantityId: abItem.inventoryLotQuantityId,
              },
              newLotQuantity: {
                quantity: abItem.committedQuantity,
                inventoryLotQuantityId: newLotQuantityId,
                lotId: newLotId,
              },
            },
            abItem.warehouseWorkOrderId,
          );
        });
      }
    });
    const updates = await WarehouseWorkService.updateWorkorderLots(updateStruct);
    if (onAction) onAction(lotFFItems.length ? 'update-fulfillment-items' : 'update-assembly-items', updates);
    return updates;
  };

  return (
    <Grid container p={4} pt={2} mb={4}>
      <Grid item xs={12} mb={2}>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {hasFFItem && <Tab label="Sales Orders" value={0} />}
          {hasABItem && <Tab label="Assembly Builds" value={1} />}
          {isLotNumbered && hasFFItem && <Tab label="Fulfillment Lots" value={2} />}
          {isLotNumbered && hasABItem && <Tab label="Build Lots" value={3} />}
        </Tabs>
      </Grid>
      {tabIndex === 0 && hasFFItem && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sales Order</TableCell>
                <TableCell>Line</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Committed</TableCell>
                {isLotNumbered && <TableCell>LotID:Qty </TableCell>}
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productWithFulfillableItems.orderItems?.map((item, itemIndex) => {
                return (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Link href={item.additionalData?.externalLink} target="_blank">
                        {item.additionalData?.label}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={item.additionalData.originalProductName} arrow placement="right">
                        <div>{item.additionalData?.lineIndex}</div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{item.additionalData?.customerDisplayName}</TableCell>
                    <TableCell>{fixNumber(item.quantity)}</TableCell>
                    <TableCell>{fixNumber(item.quantityCommitted)}</TableCell>
                    {isLotNumbered && (
                      <TableCell>
                        <Lots orderItems={[item]} onClick={handleLotClick} />
                      </TableCell>
                    )}
                    <TableCell>{renderItemStatus(item, item.product?.productId)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isAssembly && (
            <Grid container pt={4} justifyContent="end">
              <Grid md={4} lg={3} sm={12}>
                <Card style={{ backgroundColor: 'rgb(245,245,190)' }}>
                  <CardContent>
                    <Grid container>
                      <Info color="primary" />
                      <Typography variant="button" ml={2}>
                        To do
                      </Typography>
                    </Grid>
                    <Grid container p={2}>
                      <Typography variant="caption">You can create a Work Order record.</Typography>
                    </Grid>
                  </CardContent>
                  <CardActionArea>
                    <CardActions>
                      <Button variant="contained" color="primary" fullWidth onClick={handleCreateAssemblyClick}>
                        <Add />
                        Create Assembly Work Order
                      </Button>
                    </CardActions>
                  </CardActionArea>
                  {/* */}
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {tabIndex === 1 && hasABItem && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Assembly Build</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Status</TableCell>
                {isLotNumbered && <TableCell>LotID:Qty </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {assemblyBuildItemGroups.map((gItem, gItemIndex) => {
                return gItem.assemblyBuildItems.map((abItem, abIndex) => (
                  <TableRow key={`${gItemIndex}-${abIndex}`}>
                    <TableCell>
                      <Link href={abItem.additionalData?.externalLink} target="_blank">
                        {abItem.additionalData?.label ?? `#AB${abItem.assemblyBuildId}`}
                      </Link>
                    </TableCell>

                    <TableCell>{fixNumber(abItem.quantity)}</TableCell>

                    <TableCell></TableCell>
                    {isLotNumbered && (
                      <TableCell>
                        <Lots assemblyBuildItems={[abItem]} onClick={handleABLotClick} />
                      </TableCell>
                    )}
                  </TableRow>
                ));
              })}
            </TableBody>
          </Table>
        </Grid>
      )}
      {tabIndex === 2 && hasFFItem && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Lot
                  {lotFFItems.length > 1 && (
                    <Link
                      TypographyClasses="button"
                      style={{ cursor: 'pointer' }}
                      ml={2}
                      onClick={() => onClickChangeLot(lotFFItems)}
                    >
                      Set All
                    </Link>
                  )}
                </TableCell>
                <TableCell>Total Quantity</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lotFFItems.map((lot, lotIndex) => (
                <TableRow key={lotIndex}>
                  <TableCell>{lot.lotId}</TableCell>
                  <TableCell>{fixNumber(lot.totalQuantity)}</TableCell>
                  <TableCell>
                    <Link
                      TypographyClasses="button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onClickChangeLot(lotFFItems, lot);
                      }}
                    >
                      Change
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
      {tabIndex === 3 && hasABItem && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Lot
                  {lotABItems.length > 1 && (
                    <Link
                      TypographyClasses="button"
                      style={{ cursor: 'pointer' }}
                      ml={2}
                      onClick={() => onClickChangeLot(lotABItems)}
                    >
                      Set All
                    </Link>
                  )}
                </TableCell>
                <TableCell>Total Quantity</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lotABItems.map((lot, lotIndex) => (
                <TableRow key={lotIndex}>
                  <TableCell>{lot.lotId}</TableCell>
                  <TableCell>{fixNumber(lot.totalQuantity)}</TableCell>
                  <TableCell>
                    <Link
                      TypographyClasses="button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onClickChangeLot(lotABItems, lot);
                      }}
                    >
                      Change
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
      <ChangeLotDialog
        open={changeDialogState.open}
        onClose={() => handleChangeDialogClose()}
        onChange={handleChangeLot}
        minQuantity={changeDialogState.minQuantity}
        productId={changeDialogState.productId}
        warehouseId={changeDialogState.warehouseId}
        defaultLotQuantityId={changeDialogState.defaultLotQuantityId}
      />
    </Grid>
  );
}

function renderItemStatus(row: OrderItem, productId?: number) {
  const statusesInfo: any = {
    [OrderItemFulfillmentStatus.INSUFFICIENT_INVENTORY]: {
      color: 'error',
      variant: 'filled',
      caption: 'Insufficient Inventory',
    },
    [OrderItemFulfillmentStatus.MISSING_PRODUCT]: {
      color: 'error',
      variant: 'outlined',
      caption: 'Missing Product',
    },
    [OrderItemFulfillmentStatus.AUTOLOT_FAILED]: {
      color: 'error',
      variant: 'outlined',
      caption: 'Missing Lot',
    },
    [OrderItemFulfillmentStatus.READY_TO_FULFILL]: {
      color: 'info',
      variant: 'outlined',
      caption: 'New',
    },
    [OrderItemFulfillmentStatus.PENDING]: {
      color: 'success',
      variant: 'outlined',
      caption: 'Pending Fulfillment',
    },
  };
  if (
    row.fulfillmentStatus == OrderItemFulfillmentStatus.AUTOLOT_FAILED &&
    !row.fulfillmentItems?.find((ffItem) => ffItem.productId == productId)
  ) {
    return <></>;
  }
  return statusesInfo[row.fulfillmentStatus] ? (
    <Chip
      label={statusesInfo[row.fulfillmentStatus].caption}
      size="small"
      variant={statusesInfo[row.fulfillmentStatus].variant}
      color={statusesInfo[row.fulfillmentStatus].color}
    />
  ) : (
    <></>
  );
}
