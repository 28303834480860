import UserDataChart from "./UserDataChart";
import UserDataTable from "./UserDataTable";

export interface UserDataProps {
    data: any;
    type: string;
    captions?: any;
    debugMode?: boolean;
    // eslint-disable-next-line
    onDisplayAllClick?: (data: any) => void;
}
export default function UserData({ type, data, captions, debugMode, onDisplayAllClick }: UserDataProps) {
    const [visualiztionType, visualizationForm] = type.split('.');
    switch (visualiztionType) {
        case 'chart':
            return <UserDataChart data={data} captions={captions} type={visualizationForm} debugMode={debugMode} onDisplayAllClick={onDisplayAllClick} />
        case 'table':
        default:
            return <UserDataTable data={data} captions={captions} debugMode={debugMode} onDisplayAllClick={onDisplayAllClick} />
    }
}