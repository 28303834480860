import { Dispatch } from 'redux';
import tripsService from '../../services/trips.service';
import { Trip, CreateTripOfferRequest, TripRequest, Stop } from '../config/types/trips.types';
import {
  FETCHING_TRIPS,
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILED,
  FETCHING_ALIVE_TRIPS,
  FETCH_ALIVE_TRIPS_SUCCESS,
  FETCH_ALIVE_TRIPS_FAILED,
  FetchTripsTypes,
  FETCHING_TRIP_STOPS,
  FETCH_TRIP_STOPS_SUCCESS,
  FETCH_TRIP_STOPS_FAILED,
  FetchTripStopsTypes,
  CreateTripOfferTypes,
  CREATING_TRIP_OFFER,
  CREATE_TRIP_OFFER_SUCCESS,
  CREATE_TRIP_OFFER_FAILED,
  DeleteTripOfferTypes,
  DELETING_TRIP_OFFER,
  DELETE_TRIP_OFFER_SUCCESS,
  DELETE_TRIP_OFFER_FAILED,
  CreateTripTypes,
  CREATING_TRIP,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAILED,
} from '../config/ActionTypes';
import { CustomerLocationOrder, RootState } from '../config/types';
import { hasPermission } from '../../utils/permissions';

// #region Fetch Trips

export const fetchTrips = (from: Date | null, to: Date | null) => {
  return (dispatch: Dispatch<FetchTripsTypes>, getState: () => RootState) => {
    dispatch(fetchingTrips());
    const account = getState().auth?.account;
    return tripsService.fetchTrips(from, to).then(
      (response) => {
        dispatch(fetchTripsSuccess(response!));
        const tripsIds = response!.trips.map((trip) => trip.tripId ?? 0) ?? [];
        const courierIds = response!.trips.filter((trip) => trip.courierId).map((trip) => trip.courierId ?? 0) ?? [];
        tripsService.LocationsPathService.loadTripsLocationsDistances(tripsIds).then(() => {
          tripsService.LocationsPathService.loadTripsParkings(tripsIds).finally(() => {
            if (account && hasPermission(account, ['shipperAdmin.Trips.TrackedPoints'])) {
              tripsService.LocationsPathService.loadCouriersTrackedPath(courierIds, from ?? to ?? new Date()).finally(
                () => {
                  dispatch(fetchTripsSuccess(response!));
                },
              );
            } else {
              dispatch(fetchTripsSuccess(response!));
            }
          });
        });
      },
      (error) => {
        dispatch(fetchTripsFailed(error));
      },
    );
  };
};

export const fetchingTrips = (): FetchTripsTypes => ({
  type: FETCHING_TRIPS,
});

export const fetchTripsSuccess = (data: {
  trips: Trip[];
  unAssignedOrders: CustomerLocationOrder[];
}): FetchTripsTypes => ({
  type: FETCH_TRIPS_SUCCESS,
  data,
});

export const fetchTripsFailed = (error: string): FetchTripsTypes => ({
  type: FETCH_TRIPS_FAILED,
  error,
});

export const fetchAliveTrips = (tripsIds: number[]) => {
  return (dispatch: Dispatch<FetchTripsTypes>, getState: () => RootState) => {
    dispatch(fetchingAliveTrips());
    const state = getState();
    const account = state.auth?.account;
    const courierIds: number[] = [];
    state.trips?.trips?.filter((trip) => trip.courierId).forEach((trip) => courierIds.push(trip.courierId ?? 0));
    return tripsService.fetchAliveTrips(tripsIds).then(
      (response) => {
        if (account && hasPermission(account, ['shipperAdmin.Trips.TrackedPoints'])) {
          tripsService.LocationsPathService.loadCouriersTrackedPath(courierIds, new Date()).finally(() => {
            dispatch(fetchAliveTripsSuccess(response!));
          });
        } else {
          dispatch(fetchAliveTripsSuccess(response!));
        }
      },
      (error) => {
        dispatch(fetchAliveTripsFailed(error));
      },
    );
  };
};
export const fetchingAliveTrips = (): FetchTripsTypes => ({
  type: FETCHING_ALIVE_TRIPS,
});

export const fetchAliveTripsSuccess = (data: {
  trips: Trip[];
  unAssignedOrders: CustomerLocationOrder[];
}): FetchTripsTypes => ({
  type: FETCH_ALIVE_TRIPS_SUCCESS,
  data,
});

export const fetchAliveTripsFailed = (error: string): FetchTripsTypes => ({
  type: FETCH_ALIVE_TRIPS_FAILED,
  error,
});
// #endregion Fetch Trips

// #region Fetch Trip Stops

export const fetchTripStops = (tripId: number) => {
  return (dispatch: Dispatch<FetchTripStopsTypes>) => {
    dispatch(fetchingTripStops());

    tripsService.fetchTripStops(tripId).then(
      (response) => {
        dispatch(fetchTripStopsSuccess(response!));
      },
      (error) => {
        dispatch(fetchTripStopsFailed(error));
      },
    );
  };
};

export const fetchingTripStops = (): FetchTripStopsTypes => ({
  type: FETCHING_TRIP_STOPS,
});

export const fetchTripStopsSuccess = (stops: Stop[]): FetchTripStopsTypes => ({
  type: FETCH_TRIP_STOPS_SUCCESS,
  stops,
});

export const fetchTripStopsFailed = (error: string): FetchTripStopsTypes => ({
  type: FETCH_TRIP_STOPS_FAILED,
  error,
});

// #endregion Fetch trip

// #region Create Trip

export const createTrip = (tripRequest: TripRequest) => {
  return (dispatch: Dispatch<CreateTripTypes>) => {
    dispatch(creatingTrip());

    return tripsService.createTrip(tripRequest).then(
      (response) => {
        dispatch(createTripSuccess(response!));
      },
      (error) => {
        dispatch(createTripFailed(error));
      },
    );
  };
};

export const creatingTrip = (): CreateTripTypes => ({
  type: CREATING_TRIP,
});

export const createTripSuccess = (trips: Trip[]): CreateTripTypes => ({
  type: CREATE_TRIP_SUCCESS,
  trips,
});

export const createTripFailed = (error: string): CreateTripTypes => ({
  type: CREATE_TRIP_FAILED,
  error,
});

// #endregion Create Trip

//#region Create trip offer

export const createTripOffer = (tripId: number, createTripOfferRequest: CreateTripOfferRequest) => {
  return (dispatch: Dispatch<CreateTripOfferTypes>) => {
    dispatch(creatingTripOffer());

    return tripsService.createTripOffer(tripId, createTripOfferRequest).then(
      (response: Trip | undefined) => {
        dispatch(createTripOfferSuccess(response!));
      },
      (error) => {
        dispatch(createTripOfferFailed(error.response.data.message));
      },
    );
  };
};

export const creatingTripOffer = (): CreateTripOfferTypes => ({
  type: CREATING_TRIP_OFFER,
});

export const createTripOfferSuccess = (updatedTrip: Trip): CreateTripOfferTypes => ({
  type: CREATE_TRIP_OFFER_SUCCESS,
  updatedTrip,
});

export const createTripOfferFailed = (error: string): CreateTripOfferTypes => ({
  type: CREATE_TRIP_OFFER_FAILED,
  error,
});

//#endregion Create trip offer

//#region Delete trip offer

export const deleteTripOffer = (tripOfferId: number) => {
  return (dispatch: Dispatch<DeleteTripOfferTypes>) => {
    dispatch(deletingTripOffer());

    return tripsService.deleteTripOffer(tripOfferId).then(
      () => {
        dispatch(deleteTripOfferSuccess(tripOfferId));
      },
      (error) => {
        dispatch(deleteTripOfferFailed(error));
      },
    );
  };
};

export const deletingTripOffer = (): DeleteTripOfferTypes => ({
  type: DELETING_TRIP_OFFER,
});

export const deleteTripOfferSuccess = (tripOfferId: number): DeleteTripOfferTypes => ({
  type: DELETE_TRIP_OFFER_SUCCESS,
  tripOfferId,
});

export const deleteTripOfferFailed = (error: string): DeleteTripOfferTypes => ({
  type: DELETE_TRIP_OFFER_FAILED,
  error,
});

//#endregion Delete trip offer
