import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { connect, ConnectedProps } from 'react-redux';
import AutocompleteField from '../../components/SpecialInput/AutocompleteField';
import * as actions from '../../store/actions/couriers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { createTripOffer } from '../../store/actions/trips.actions';
import { Courier, CreateTripOfferRequest, RootState, Trip } from '../../store/config/types';
import schemas from '../../utils/schemas';

interface OfferTripDialogProps {
  open: boolean;
  trip: Trip | null;
  handleClose(): void;
}

interface CourierOfferValues {
  courierId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      minWidth: 320,
    },
  }),
);

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    trips: state.trips,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(actions.fetchCouriers()),
  createTripOffer: (tripId: number, createTripOfferRequest: CreateTripOfferRequest) =>
    dispatch(createTripOffer(tripId, createTripOfferRequest)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = OfferTripDialogProps & PropsFromRedux;

function OfferTripDialog({
  open,
  trip,
  handleClose,
  couriers,
  trips,
  fetchCouriers,
  createTripOffer,
  setToast,
}: PropsType) {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers]);

  useEffect(() => {
    if (submitting) {
      if (trips.createTripOfferSuccess) {
        setSubmitting(false);
        setToast('Trip successfully offerred to couriers', 'success');
        handleClose();
      } else if (trips.createTripOfferErrorMessage) {
        setSubmitting(false);
        setToast(trips.createTripOfferErrorMessage, 'danger');
      }
    }
  }, [
    submitting,
    trips.createTripOfferSuccess,
    setSubmitting,
    setToast,
    handleClose,
    trips.createTripOfferErrorMessage,
  ]);

  const submitForm = (values: CourierOfferValues) => {
    if (trip) {
      const createTripOfferRequest: CreateTripOfferRequest = {
        courierIds: [values.courierId],
      };

      setSubmitting(true);
      createTripOffer(trip.tripId!, createTripOfferRequest);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik initialValues={{ courierId: '' }} onSubmit={submitForm} validationSchema={schemas.CourierOfferSchema}>
        <Form className={classes.form}>
          <DialogTitle>Offer Trip</DialogTitle>
          <DialogContent>
            {couriers.couriers && (
              <AutocompleteField
                placeholder="Offer to"
                name="courierId"
                values={couriers.couriers.map((courier: Courier) => ({
                  id: courier.courierId,
                  title: `${courier.firstName} ${courier.lastName}`,
                }))}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button color="primary" type="submit">
              Offer trip
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default connector(OfferTripDialog);
