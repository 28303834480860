import { Droppable } from 'react-beautiful-dnd';

interface SendToTodayProps {
  isDragStarts: boolean;
}
export default function SendToToday({ isDragStarts }: SendToTodayProps) {
  return (
    <Droppable droppableId={`sendToToday`} direction="horizontal">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={`dndt-recycle-row-icon${snapshot.isDraggingOver ? ' highlight' : ''}`}
          {...provided.droppableProps}
        >
          UNASSIGNED
          <div
            className={`dndt-recycle-send-to-today${isDragStarts ? ' expand' : ''}${
              snapshot.isDraggingOver ? ' highlight' : ''
            }`}
          >
            Send to Today
          </div>
        </div>
      )}
    </Droppable>
  );
}
