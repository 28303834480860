/* eslint-disable */
export enum CostStatus {
  OFFERED = 'OFFERED',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
}
export enum CostInputUoM {
  COUNT = 'COUNT',
  CALL = 'CALL',
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  FOOT = 'FOOT',
  MILE = 'MILE',
  LITER = 'LITER',
  GALLON = 'GALLON',
  CUSTOM = 'CUSTOM',
  ONETIME = 'ONETIME',
}
/* eslint-enable */
export interface DirectCost {
  costCategory: string;
  costCode: string;
  value: number;
  status: CostStatus;
}

export interface CostInput {
  costInputId: number;
  costSubject: string;
  costInputCategory: string;
  UoM: CostInputUoM;
  value: number;
}
