import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from './material-dashboard-react.jsx';

const tableStyle = () => ({
  warningTableHeader: {
    color: warningColor,
  },
  primaryTableHeader: {
    color: grayColor,
  },
  dangerTableHeader: {
    color: dangerColor,
  },
  successTableHeader: {
    color: successColor,
  },
  infoTableHeader: {
    color: infoColor,
  },
  roseTableHeader: {
    color: roseColor,
  },
  grayTableHeader: {
    color: grayColor,
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    padding: '12px 8px',
    verticalAlign: 'middle',
  },
  tableResponsive: {
    width: '100%',
    overflowX: 'auto',
  },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  paginationTableRow: {
    textAlign: 'center',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor,
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor,
    boxShadow: 'none',
  },
  profilePicContainer: {
    width: '64px',
    height: 'auto',
    padding: '10px',
  },
  profilePic: {
    borderRadius: '50%',
    width: '100%',
    height: 'auto',
  },
  profilePicContainerLarge: {
    width: '128px',
    height: '128px',
    marginRight: '30px',
  },
  profilePicLarge: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  imageFormContainer: {
    width: '285px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
  },
});

export default tableStyle;
