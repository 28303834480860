import { Checkbox, Collapse, Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { FilterableTableColumn } from './FilterableTable';
import { ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
interface FilterableTableRowProps {
  row: any;
  columns: FilterableTableColumn[];
  children?: any;
  selectable?: boolean;
  selected?: boolean;
  primaryKey?: string;
  // eslint-disable-next-line
  onSelectChange?: (id: any, selected: boolean) => void;
}
export default function FilterableTableRow({
  columns,
  row,
  children,
  selectable,
  selected,
  primaryKey,
  onSelectChange,
}: FilterableTableRowProps) {
  const [isOpen, setOpen] = useState(false);
  // useEffect(() => {
  //   setOpen(false);
  // }, [row]);
  const handleSelectClick = (e: any) => {
    e.stopPropagation();
    if (onSelectChange && primaryKey) onSelectChange(row[primaryKey], !selected);
  };
  const handleExpandClicked = () => {
    setOpen(!isOpen);
  };
  const extendable = Boolean(children);
  return (
    <>
      <TableRow
        className={`${extendable ? 'extendable-table-row' : ''}${isOpen ? ' extended' : ''}`}
        onClick={handleExpandClicked}
      >
        {selectable && (
          <TableCell width={32}>
            <Checkbox onClick={handleSelectClick} checked={selected === true} />
          </TableCell>
        )}
        {columns.map((column, colIndex) => (
          <TableCell width={column.fixWidth} key={colIndex}>
            {column.render !== undefined ? column.render(row) : row[column.field]}
          </TableCell>
        ))}
        {extendable && (
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton size="small" onClick={handleExpandClicked} className="row-extend-button">
              <ExpandMore className={isOpen ? 'is-open' : undefined} />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow className="extended-row">
        <TableCell colSpan={columns.length + 1}>
          <Collapse in={isOpen}>
            <Grid container p={2} pt={0}>
              {children}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
