import { Dialog } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { ReactComponent as CloseIcon } from '../../assets/images/close-svgrepo-com.svg';
import { Package } from '../../store/config/types';

interface StopPackages {
  packages: Package[];
  handleClosePackages(): void;
}

function TripStopPackages({ packages, handleClosePackages }: StopPackages) {
  return (
    <Dialog
      title="Trip Stop Details"
      open={!!packages?.length}
      onClose={() => handleClosePackages()}
      fullWidth={packages.length > 1}
      maxWidth="sm"
    >
      <div className="package-modal">
        <button className="close-button" onClick={() => handleClosePackages()}>
          <CloseIcon />
        </button>
        <div className={packages.length > 1 ? 'package-wrapper package-grid' : 'package-wrapper'}>
          {packages?.map((stopPackage: Package) => (
            <div key={stopPackage?.packageId} className="package">
              <div>
                <h2>Id: {stopPackage?.packageId}</h2>
                <span>Size: {stopPackage?.size}</span>
              </div>
              <QRCode value={stopPackage?.qrCode} className="qr" />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
}

export default TripStopPackages;
