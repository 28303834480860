import {
  Card,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import DatePicker from '../DatePicker/DatePicker';
import moment from 'moment-timezone';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

export interface SelectableFiltersStruct {
  [key: string]: [string, string | null][];
}
export interface DateFiltersStruct {
  [key: string]: string;
}
export interface FilterOptions {
  query?: string;
  selectableFilters?: SelectableFiltersStruct;
  dateFilters?: DateFiltersStruct;
}
export interface SearchBoxProps extends FilterOptions {
  captions?: { [key: string]: string };
  timezone?: string;
  disabled?: boolean;
  /* eslint-disable */
  onQueryChange?: (query: string) => void;
  onFilterApplying?: (filters: any) => void;
  /* eslint-enable */
}
export function SearchBox({
  dateFilters,
  selectableFilters,
  query,
  captions,
  disabled,
  onQueryChange,
  onFilterApplying,
  timezone,
}: SearchBoxProps) {
  if (timezone) moment.tz.setDefault(timezone);
  const [searchText, setSearchText] = useState<string>(query ?? '');
  const [dateValues, setDateValues] = useState<DateFiltersStruct>({});
  const [selectValues, setSelectValues] = useState<{ [key: string]: string[] }>({});
  const [dateCaptions, setDateCaptions] = useState<string[]>([]);
  const [selectCaptions, setSelectCaptions] = useState<string[]>([]);
  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);

  const handleQueryChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (onQueryChange && !disabled) onQueryChange(e.target.value);
  };

  const handleApplyClick = () => {
    setFilterChanged(false);
    const filtersVal: any = {};
    Object.keys(dateValues).forEach((field) => {
      filtersVal[field] = dateValues[field];
    });
    Object.keys(selectValues).forEach((field) => {
      filtersVal[field] = selectValues[field][0];
    });
    if (onFilterApplying) onFilterApplying(filtersVal);
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    setDateValues({ ...dateValues, [fieldName]: event.target.value });
    setFilterChanged(true);
  };

  const handleDayChange = (dateName: string, delta: number) => {
    const newDate = moment(dateValues[dateName], 'YYYY-MM-DD').add(delta, 'day').format('YYYY-MM-DD');
    setDateValues({ ...dateValues, [dateName]: newDate });
    setFilterChanged(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const fieldName = event.target.name;
    const values = event.target.value;
    setSelectValues({ ...selectValues, [fieldName]: typeof values === 'string' ? [values] : values });
    setFilterChanged(true);
  };

  useEffect(() => {
    const captions = [...Object.keys(selectableFilters ?? {})].filter(
      (caption) => selectableFilters && selectableFilters[caption] && selectableFilters[caption].length,
    );
    const selectVals: any = { ...selectValues };
    captions.forEach((caption) => {
      if (!selectVals[caption]) selectVals[caption] = selectableFilters ? [selectableFilters[caption][0][1]] : '';
    });

    setSelectCaptions(captions);
    setSelectValues(selectVals);
    setApplyButtonVisible(dateCaptions.length + captions.length > 1);
  }, [selectableFilters]);

  useEffect(() => {
    const captions = [...Object.keys(dateFilters ?? {})];
    const dateVals: any = {};
    captions.forEach((caption) => {
      dateVals[caption] = dateFilters ? dateFilters[caption] : '';
    });
    setDateCaptions(captions);
    setDateValues(dateVals);
    setApplyButtonVisible(selectCaptions.length + captions.length > 1);
  }, [dateFilters]);

  useEffect(() => {
    if (filterChanged && !applyButtonVisible) handleApplyClick();
  }, [filterChanged]);

  useEffect(() => {
    setSearchText(query ?? '');
  }, [query]);

  const onlyOneDatefilter =
    Object.keys(dateFilters ?? {}).length === 1 && Object.keys(selectableFilters ?? {}).length === 0;

  return (
    <Grid container m={0} p={1}>
      <Card style={{ width: '100%', background: '#eee' }}>
        <Grid container p={2}>
          <Grid item flexGrow={1} mr={1} style={{ backgroundColor: '#fff' }}>
            <InputBase
              fullWidth
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              value={searchText}
              disabled={disabled}
              onChange={handleQueryChanged}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flexGrow={1}>
            <Grid container p={2} pt={0} alignItems="center">
              {dateCaptions.length ? (
                dateCaptions.map((dateName, idx) => {
                  return (
                    <>
                      {onlyOneDatefilter && (
                        <Grid item>
                          <IconButton
                            size="small"
                            disabled={disabled}
                            onClick={() => {
                              handleDayChange(dateName, -1);
                            }}
                          >
                            <ChevronLeft />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item xs={12} mr={onlyOneDatefilter ? 0 : 1} md={3} xl={2} key={`date-${idx}`}>
                        <DatePicker
                          label={(captions ? captions[dateName] : undefined) ?? dateName}
                          name={dateName}
                          value={dateValues[dateName]}
                          onChange={handleDateChange}
                          disabled={disabled}
                        />
                      </Grid>
                      {onlyOneDatefilter && (
                        <Grid item>
                          <IconButton
                            disabled={disabled}
                            size="small"
                            onClick={() => {
                              handleDayChange(dateName, 1);
                            }}
                          >
                            <ChevronRight />
                          </IconButton>
                        </Grid>
                      )}
                    </>
                  );
                })
              ) : (
                <></>
              )}

              {selectCaptions.length ? (
                selectCaptions.map((filterName, filterIdx) => {
                  if (selectableFilters && !selectableFilters[filterName]) return <></>;
                  return (
                    <Grid item key={`filter-${filterIdx}`} xs={12} md={3} xl={2} mr={1}>
                      <FormControl fullWidth size="small" style={{ paddingTop: 4 }}>
                        <InputLabel id={filterName}>
                          {(captions ? captions[filterName] : undefined) ?? filterName}
                        </InputLabel>
                        <Select
                          disabled={disabled}
                          value={selectValues[filterName][0]}
                          label={(captions ? captions[filterName] : undefined) ?? filterName}
                          name={filterName}
                          onChange={handleSelectChange}
                          fullWidth
                        >
                          {selectableFilters &&
                            selectableFilters[filterName].map((option: [string, string | null], optIdx: number) => {
                              return (
                                <MenuItem value={option[1] ?? ''} key={`option-${filterIdx}-${optIdx}`}>
                                  {option[0] ?? option[1]}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                })
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {applyButtonVisible ? (
            <Grid item p={1} mr={2}>
              <Button
                variant="contained"
                color="success"
                disabled={disabled || !filterChanged}
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}
