import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, FormControl, InputLabel, Input, Tooltip } from '@material-ui/core';
import { Clear, Check } from '@material-ui/icons';
import customInputStyle from '../../assets/jss/customInputStyle';

function CustomInput({ classes, formControlProps, labelText, id, labelProps, inputProps, error, helperText, success }) {
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });

  const marginTop = classNames({ [classes.marginTop]: labelText === undefined });

  const onChange = (event) => {
    if (inputProps.type === 'number') {
      const value = event.target.value;
      if (value[0] === '0') {
        event.target.value = value.substring(1, value.length);
      }
    }
    inputProps.onChange(event);
  };

  return (
    <FormControl {...formControlProps} className={formControlProps.className + ' ' + classes.formControl}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{ root: marginTop, disabled: classes.disabled, underline: underlineClasses }}
        id={id}
        {...inputProps}
        onChange={onChange}
      />
      {error ? (
        <Tooltip title={helperText} placement="right">
          <Clear className={classes.feedback + ' ' + classes.labelRootError} />
        </Tooltip>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomInput);
