import { DeliveredIcon, PickupIcon, TransitIcon, BoxIcon, WarningIcon } from '../assets/images';

export interface TitleSection {
  image: string;
  amount: number;
  title: string;
  color: string;
  status: string;
}
//Order submitted,Scheduled,Delivered,Needs attention
export const dataTitle: TitleSection[] = [
  {
    image: BoxIcon,
    amount: 0,
    title: 'Deliveries',
    color: 'title-color-one',
    status: 'ALL',
  },
  {
    image: BoxIcon,
    amount: 0,
    title: 'New',
    color: 'title-color-new',
    status: 'New',
  },
  {
    image: PickupIcon,
    amount: 0,
    title: 'Scheduled',
    color: 'title-color-two',
    status: 'Scheduled',
  },
  {
    image: TransitIcon,
    amount: 0,
    title: 'In transit',
    color: 'title-color-three',
    status: 'In transit',
  },
  {
    image: DeliveredIcon,
    amount: 0,
    title: 'Delivered',
    color: 'title-color-four',
    status: 'Delivered',
  },
  {
    image: WarningIcon,
    amount: 0,
    title: 'Attentions',
    color: 'title-color-five',
    status: 'Needs attention',
  },
];
