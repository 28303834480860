import classNames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';

const style = {
  grid: {
    padding: '0 15px !important',
  },
};

function GridItem({ classes, className, children, ...props }) {
  return (
    <Grid item {...props} className={classNames(classes.grid, className)}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
