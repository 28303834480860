import { useEffect, useState } from 'react';
import { AssemblyBuildItem, OrderItem, ProductWithFulfillableItemsStruct } from '../../store/config/types';
import FilterableTable from '../../components/FilterableTable/FilterableTable';

// import ItemPendingFulfillmentDetails from './ItemPendingFulfillmentDetails';
import { isKitItem } from '../../utils/fulfillment.helpers';
import { strOrAlt } from '../../utils/string.helpers';
import ItemPendingFulfillmentDetails from '../Fulfillment/ItemPendingFulfillmentDetails';

interface ItemPendingBuildTableProps {
  assemblyBuildItems?: AssemblyBuildItem[];
  orderItems?: OrderItem[];
  // eslint-disable-next-line
  onAction?: (action: string, params: any) => void;
}

export default function ItemPendingBuildTable({
  assemblyBuildItems,
  orderItems,
  onAction,
}: ItemPendingBuildTableProps) {
  const [itemPendingFulfillment, setItemsPendingFulfillment] = useState<ProductWithFulfillableItemsStruct[]>([]);

  useEffect(() => {
    const itemPFF: ProductWithFulfillableItemsStruct[] = [];
    // const statuses={'INSUFFICIENT_INVENTORY':0, 'READY_TO_FULFILL':0, 'PENDING':0}
    // plain Items
    const plainItems: OrderItem[] = [];
    orderItems?.forEach((item) => {
      item.additionalData = {
        ...item.additionalData,
        originalProductName: strOrAlt(item.product?.description, item.product?.name, ''),
      };
      if (isKitItem(item)) {
        item.product?.subProducts.forEach((product) => {
          const quantity = product.product_relation?.quantity ?? 0;
          const subOrder: OrderItem = {
            ...item,
            product,
            productId: product.productId,
            type: product.type,
            quantity: item.quantity * quantity,
            quantityOrdered: item.quantityOrdered * quantity,
            quantityCommitted: item.quantityOrdered * quantity,
          };
          plainItems.push(subOrder);
        });
      } else {
        plainItems.push(item);
      }
    });
    plainItems.forEach((item) => {
      const itemIndex = itemPFF.findIndex((itm) => itm.productId == item.product?.productId);
      if (itemIndex < 0) {
        itemPFF.push({
          ...item.product,
          orderItems: [item],
          statusSummary: getItemsStatusSummary([item]),
        } as any);
      } else {
        itemPFF[itemIndex].orderItems.push(item);
        itemPFF[itemIndex].statusSummary = getItemsStatusSummary(itemPFF[itemIndex].orderItems);
      }
    });
    assemblyBuildItems?.forEach((item) => {
      const itemIndex = itemPFF.findIndex((itm) => itm.productId == item.product?.productId);
      if (itemIndex < 0) {
        itemPFF.push({
          ...item.product,
          assemblyBuildItems: [item],
          statusSummary: '',
        } as any);
      } else {
        itemPFF[itemIndex].assemblyBuildItems.push(item);
        // itemPFF[itemIndex].statusSummary = getItemsStatusSummary(itemPFF[itemIndex].orderItems);
      }
    });
    itemPFF.sort((a, b) => a.productId - b.productId);
    setItemsPendingFulfillment([...itemPFF]);
  }, [orderItems, assemblyBuildItems]);

  return (
    <FilterableTable
      data={itemPendingFulfillment.filter((itm) => itm.assemblyBuildItems?.length)}
      columns={[
        { field: 'externalId', caption: 'ID', filterType: 'string', sortable: true },
        {
          field: 'name',
          caption: 'Product',
          filterType: 'string',
          render: (row) => (row.description ? row.description : row.name),
          sortable: true,
        },
        { field: 'type', caption: 'type', filterType: 'values', sortable: true },
        // { field: 'statusSummary', caption: 'Status', filterType: 'values', render: renderItemStatus, sortable: true },
      ]}
      defaultSortBy="externalId"
      extendable
      renderExtended={(row) => {
        return <ItemPendingFulfillmentDetails productWithFulfillableItems={row} onAction={onAction} />;
      }}
    />
  );
}

// function renderItemStatus(row: ProductWithItemPendingFulfillmentStruct) {
//   const colors: any = {
//     'Needs Attention': { color: 'error', variant: 'filled' },
//     'Pending Fulfillment': { color: 'info', variant: 'outlined' },
//     'Ready to Fulfillment': { color: 'success', variant: 'outlined' },
//   };
//   return (
//     <Chip
//       label={row.statusSummary}
//       size="small"
//       variant={colors[row.statusSummary].variant}
//       color={colors[row.statusSummary].color}
//     />
//   );
// }
function getItemsStatusSummary(items: OrderItem[]): string {
  if (items.some((item) => item.fulfillmentStatus == 'INSUFFICIENT_INVENTORY')) return 'Needs Attention';
  if (items.every((item) => item.fulfillmentStatus == 'PENDING')) return 'Pending Fulfillment';
  // if(items.every((item) => item.fulfillmentStatus == 'READY_TO_FULFILL'))
  return 'Ready to Fulfillment';
}
