import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, FormControl, InputLabel, Select, Tooltip } from '@material-ui/core';
import { Clear, Check } from '@material-ui/icons';
import customInputStyle from '../../../assets/jss/customInputStyle';

function CustomInput({
  classes,
  formControlProps,
  labelText,
  id,
  labelProps,
  error,
  helperText,
  success,
  value,
  ...props
}) {
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });

  const marginTop = classNames({ [classes.marginTop]: labelText === undefined });

  return (
    <FormControl {...formControlProps} className={classes.formControl}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
          selectMenu: {
            width: '100%',
          },
        }}
        style={{ textAlign: props.inputProps.leftAligned ? 'left' : 'center' }}
        underlineStyle={{ borderColor: '#ff0450' }}
        iconStyle={{ fill: '#ff0000' }}
        labelStyle={{ color: '#ff0000' }}
        value={value}
        // onChange={props.onChange}
        id={id}
        autoWidth={true}
        {...props}
      >
        {props.inputProps.values}
      </Select>
      {error ? (
        <Tooltip title={helperText} placement="right">
          <Clear className={classes.feedback + ' ' + classes.labelRootError + ' ' + classes.selectError} />
        </Tooltip>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  //formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomInput);
