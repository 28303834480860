/* eslint-disable */
export enum TripStatusFilter {
  ALL = 0,
  NEW,
  PENDING,
  IN_TRANSIT,
  FINISHED,
  CANCELLED,
}

export enum TripStopTimeWindowStatuses {
  TIME_WINDOW_UNDEFINED,
  TIME_WINDOW_IN_RANGE,
  TIME_WINDOW_WARNING,
  TIME_WINDOW_MISSED,
}
