import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';

export interface InfoCardProps {
  icon?: ReactElement;
  caption: string;
  number: number;
  loading?: boolean;
}

export default function InfoCard({ icon, caption, number, loading }: InfoCardProps) {
  return (
    <Card>
      <CardContent>
        <div style={{ position: 'relative' }}>
          <Grid container justifyContent="center">
            <Grid item height={24}>
              {icon}
            </Grid>
            <Grid item ml={1}>
              <Typography variant="caption">{caption}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Typography align="center" variant="h3">
              {loading ? <CircularProgress /> : `${number ?? 0}`}
            </Typography>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}
