/* eslint-disable */
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/config/types';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Add, Delete, Edit, MoreVert, Remove } from '@material-ui/icons';
import CardBody from '../../components/Card/CardBody';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import WarehouseWorkService from '../../services/warehouseWork.service';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AppActions } from '../../store/config/ActionTypes';
import { setToast } from '../../store/actions/toast.actions';
import { MenuItem } from '@material-ui/core';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
const WmsUsers = ({ auth, setToast }: PropsFromRedux) => {
  const [wmsUsers, setWmsUsers] = useState<any[]>([]);
  const [processing, setProcessing] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const mnuElref = useRef<any>();
  const [mnuUserId, setMnuUserId] = useState<number>();
  const [email, setEmail] = useState<string>();
  const [warehouseUserId, setWarehouseUserId] = useState<number>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [password, setPassword] = useState<string>();
  useEffect(() => {
    if (auth.account && needsRefresh) {
      setNeedsRefresh(false);
      // get users
      WarehouseWorkService.getWmsUsers()
        .then((users) => {
          if (users?.length) {
            setWmsUsers(users);
          }
        })
        .catch((err) => {
          setToast(err.message, 'danger');
        });
    }
  }, [auth, needsRefresh]);

  function setUserInfo(info?: any) {
    if (!info) {
      setWarehouseUserId(undefined);
      setEmail('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setPassword('');
    } else {
      if (info.warehouseUserId != undefined) setWarehouseUserId(info.warehouseUserId);
      if (info.email != undefined) setEmail(info.email);
      if (info.firstName != undefined) setFirstName(info.firstName);
      if (info.lastName != undefined) setLastName(info.lastName);
      if (info.phoneNumber != undefined) setPhoneNumber(info.phoneNumber);
      if (info.password != undefined) setPassword(info.password);
    }
  }

  const handleAddClick = () => {
    setUserInfo();
    setNewUserDialogOpen(true);
  };
  const hanleCloseDialog = () => {
    setNewUserDialogOpen(false);
  };

  const getType = (user: any) => {
    const types: any = { PICKER: 'Picker', PACKER: 'Packer' };
    if (user) return types[user.type] ?? '';
    return types;
  };

  const handleMenuClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, warehouseUserId: any): void => {
    mnuElref.current = e.target;
    setMnuUserId(warehouseUserId);
  };
  const handleMenuClose = () => {
    mnuElref.current = undefined;
    setMnuUserId(undefined);
  };
  function mnuRemoveOnClick(event: any): void {
    const user = wmsUsers.find((u) => u.warehouseUserId === mnuUserId);
    handleMenuClose();
    if (user && confirm(`"${user.firstName ?? ''} ${user.lastName ?? ''}" will be deleted.`)) {
      setProcessing(true);
      WarehouseWorkService.removeWmsUser({ warehouseUserId: user.warehouseUserId })
        .then(() => {
          setToast('Removed', 'success');
          hanleCloseDialog();
          setNeedsRefresh(true);
        })
        .catch((err) => {
          setToast(err.message, 'danger');
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }

  function mnuEditOnClick(event: any): void {
    const user = wmsUsers.find((u) => u.warehouseUserId === mnuUserId);
    setUserInfo({ ...user });
    handleMenuClose();
    setMnuUserId(undefined);
    setNewUserDialogOpen(true);
  }

  const canSave = () =>
    Boolean(email) && (Boolean(firstName) || Boolean(lastName)) && (warehouseUserId || Boolean(password));
  function handleSave() {
    if (canSave()) {
      setProcessing(true);
      WarehouseWorkService.saveWmsUser({ warehouseUserId, email, firstName, lastName, phoneNumber, password })
        .then(() => {
          setToast('Saved', 'success');
          hanleCloseDialog();
          setNeedsRefresh(true);
        })
        .catch((err) => {
          setToast(err.message, 'danger');
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }

  function handleFieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string): void {
    setUserInfo({ [field]: e.target.value });
  }

  return (
    <Card profile>
      <CardHeader color="primary" className="card-header">
        <Grid container alignItems="center">
          <Grid item flexGrow={1} textAlign="left">
            WMS Users
          </Grid>
          <Grid>
            <IconButton onClick={handleAddClick}>
              <Add style={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </CardHeader>
      <CardBody>
        <Table>
          <TableHead>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Type</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {wmsUsers.map((user, index) => {
              const userType = getType(user) ?? '';
              return (
                <TableRow key={index}>
                  <TableCell>{user.email ?? ''}</TableCell>
                  <TableCell>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</TableCell>
                  <TableCell>{user.phoneNumber ?? ''}</TableCell>
                  <TableCell>{userType}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={(e) => handleMenuClick(e, user.warehouseUserId)}>
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Menu open={Boolean(mnuElref.current)} anchorEl={mnuElref.current} onClose={handleMenuClose}>
          <MenuItem onClick={mnuEditOnClick}>
            <ListItemIcon>
              <Edit color="action" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={mnuRemoveOnClick}>
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        </Menu>
      </CardBody>
      <Dialog open={newUserDialogOpen} onClose={processing ? () => {} : hanleCloseDialog}>
        <DialogTitle>{warehouseUserId ? 'Edit' : 'Create'}</DialogTitle>
        <DialogContent>
          <Grid container pb={2}>
            <TextField
              variant="standard"
              value={email}
              label="Email"
              disabled={Boolean(warehouseUserId)}
              onChange={(e) => handleFieldChange(e, 'email')}
            />
          </Grid>
          {!Boolean(warehouseUserId) && (
            <Grid container pb={2}>
              <TextField
                variant="standard"
                value={password}
                label="Password"
                onChange={(e) => handleFieldChange(e, 'password')}
              />
            </Grid>
          )}
          <Grid container pb={2}>
            <TextField
              variant="standard"
              label="First Name"
              value={firstName}
              onChange={(e) => handleFieldChange(e, 'firstName')}
            />
          </Grid>
          <Grid container pb={2}>
            <TextField
              variant="standard"
              label="Last Name"
              value={lastName}
              onChange={(e) => handleFieldChange(e, 'lastName')}
            />
          </Grid>
          <Grid container pb={2}>
            <TextField
              variant="standard"
              label="Phone"
              value={phoneNumber}
              onChange={(e) => handleFieldChange(e, 'phoneNumber')}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={hanleCloseDialog} disabled={processing}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleSave();
            }}
            disabled={processing || !canSave()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default connector(WmsUsers);
