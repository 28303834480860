/* eslint-disable */
import { ChangeEvent, useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { useFilePicker } from 'use-file-picker';
import { FileContent } from 'use-file-picker/dist/interfaces';
import { Dialog, DialogContent, MenuItem, TableBody, TableRow } from '@material-ui/core';
import axios from '../../services/axios';

import { setToast } from '../../store/actions/toast.actions';
// import schemas from '../../utils/schemas';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SelectField from '../../components/SpecialInput/SelectField';
import tripsService from '../../services/trips.service';
import {
  Alert,
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  LinearProgress,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableHead,
} from '@mui/material';
import { Delete, ImportContacts } from '@material-ui/icons';

import CSVMappingForm from './CSVMappingForm';

interface CSVMappingHeader {
  name: string;
  template?: string;
  required?: boolean;
  description?: string;
}

interface CSVDialogProps {
  open: boolean;
  subject: string;
  title: string;
  // eslint-disable-next-line no-unused-vars
  setOpen(value: boolean): void;
}

function CSVDialog({ subject, title, open, setOpen }: CSVDialogProps) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [importOptions, setImportOptions] = useState<any>(null);
  const [allRequiredFields, setAllRequiredFields] = useState(false);
  const [templateValues, setTemplateValues] = useState<CSVMappingHeader[]>([]);
  const [step, setStep] = useState('fileSelect');
  const [error, setError] = useState<string>();
  const [openFileSelector, { loading, filesContent, plainFiles, clear }] = useFilePicker({
    accept: '.csv',
    multiple: false,
  });
  const { readString } = usePapaParse();
  const [mappingValues, setMappingValues] = useState<any>({});

  const submitForm = () => {
    setStep('submitting');
    const formData: FormData = new FormData();

    formData.append('csv', plainFiles[0]);
    Object.keys(mappingValues).forEach((key) => {
      formData.append(key, mappingValues[key]);
    });

    localStorage.setItem(`CSV-map-template-${subject}`, JSON.stringify(mappingValues));
    setSubmitting(true);

    axios
      .post(`/csv_import/${subject}`, formData)
      .then((result) => {
        console.log(result);
        handleClose();
      })
      .catch((err) => {
        setError(err.message);
        setStep('error');
      })
      .finally(() => {
        setSubmitting(false);
      });
    // CSVServices.importCSV(subject,formData).then((res) => {
    //   console.log(res);
    // });
  };

  useEffect(() => {
    if (loading) {
      setLoaded(false);
    }
  }, [loading, setLoaded]);

  useEffect(() => {
    if (filesContent && filesContent.length !== 0 && !loaded) {
      setStep('options');
      readString(filesContent[0].content, {
        header: true,
        worker: true,
        complete: (results) => {
          setHeaders(results.meta.fields!);
          const mapSavedTemplateWithHeaders = (fieldName: string) => {
            return results.meta.fields!.includes(fieldName) ? fieldName : '';
          };
          // load options
          axios
            .get(`/csv_import/options/${subject}`)
            .then((csvOptions) => {
              // console.log(csvOptions.data.data);
              setImportOptions(csvOptions.data.data);
            })
            .catch((err) => {
              setError(err.message);
              setStep('error');
            });
          const template = localStorage.getItem(`CSV-map-template-${subject}`);
          if (template) {
            const templateVals = JSON.parse(template);
            setMappingValues(templateVals);
            setAllRequiredFields(templateVals);
          }
        },
      });

      setLoaded(true);
    }
  }, [filesContent, loaded, setLoaded, readString]);

  const handleClose = () => {
    setOpen(false);
    clear();
    setStep('fileSelect');
  };

  const checkAllRequiredFields = (values: any) => {
    setAllRequiredFields(
      importOptions.mappingHeaders?.findIndex((mapping: any) =>
        mapping.fields?.some((field: any) => field.required && !values[field.field]),
      ) < 0,
    );
  };
  const fieldChangeHandler = (field: string, value: string) => {
    const values = mappingValues;
    values[field] = value ? value : undefined;
    setMappingValues({ ...values });
    checkAllRequiredFields(values);
  };
  const fileSelected = filesContent.length > 0;
  return (
    <Dialog open={open} onClose={handleClose} disableEnforceFocus>
      <DialogTitle>{title}</DialogTitle>
      {step === 'fileSelect' && (
        <>
          <DialogContent>
            <div className="import">
              {fileSelected ? (
                <>
                  <h4>Import from:</h4>
                  <h3>
                    <IconButton
                      onClick={() => {
                        clear();
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                    {filesContent && filesContent[0] ? filesContent[0].name : ''}
                  </h3>
                </>
              ) : (
                <>
                  <Button onClick={openFileSelector} variant="outlined">
                    Select a files
                  </Button>
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!fileSelected}
              color="success"
              variant="outlined"
              onClick={() => {
                setStep('options');
              }}
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'options' && (
        <>
          <DialogContent>
            <div>{filesContent && filesContent[0] ? filesContent[0].name : ''}</div>
            {importOptions ? (
              <>
                <Alert severity="info">
                  We need to locate the following information in your CSV. What´s the name of the column that has each
                  of these data?
                </Alert>
                <CSVMappingForm
                  columns={headers}
                  mappingHeaders={importOptions?.mappingHeaders}
                  mappingValues={mappingValues}
                  setValue={fieldChangeHandler}
                />
              </>
            ) : (
              <LinearProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setStep('fileSelect');
              }}
            >
              Back
            </Button>
            <Button disabled={!allRequiredFields} color="success" variant="outlined" onClick={submitForm}>
              Import
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'submitting' && (
        <>
          <DialogContent>
            <div>
              Importing <strong>{filesContent && filesContent[0] ? filesContent[0].name : ''}</strong> ...
            </div>
            <LinearProgress />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={true}
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'error' && (
        <>
          <DialogContent>
            <div>
              Importing <strong>{filesContent && filesContent[0] ? filesContent[0].name : ''}</strong> failed.
            </div>
            <Alert severity="error">{error}</Alert>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default CSVDialog;
