import { ShipperUser } from '../store/config/types/shipperUser.types';
import { Shipper, ShipperRequest } from '../store/config/types/shippers.types';
import axios from './axios';

const fetchShippers = async (): Promise<Shipper[] | undefined> => {
  const res = await axios.get('/shippers');
  return res.data.data;
};
const fetchShipperUsers = async (): Promise<ShipperUser[] | undefined> => {
  const res = await axios.get('/shipper_users');
  return res.data.data;
};
const createShipperUser = async (createShipperUserRequest: ShipperUser): Promise<ShipperUser | undefined> => {
  const res = await axios.post('/shipper_users/register', createShipperUserRequest);
  return res.data.data;
};
const getShipperUser = async (shipperUserId: number): Promise<ShipperUser | undefined> => {
  const res = await axios.get(`/shipper_users/${shipperUserId}`);
  return res.data.data;
};
const deleteShipperUser = async (shipperUserId: number): Promise<ShipperUser | undefined> => {
  const res = await axios.delete(`/shipper_users/${shipperUserId}`);
  return res.data.data;
};
const updateShipperUser = async (shipperUserRequest: ShipperUser): Promise<ShipperUser | undefined> => {
  const res = await axios.patch(`/shipper_users/${shipperUserRequest.shipperUserId}`, shipperUserRequest);
  return res.data.data;
};
const updateShipper = async (updateShipperRequest: ShipperRequest): Promise<Shipper | undefined> => {
  const res = await axios.patch(`/shippers/${updateShipperRequest.shipperId}`, updateShipperRequest);
  return res.data.data;
};

// const deleteShipper = async (shipperId: number): Promise<any> => {
//   const res = await axios.delete(`/shippers/${shipperId}`);
//   return res.data.data;
// };

export const shippersService = {
  fetchShippers,
  fetchShipperUsers,
  updateShipper,
  createShipperUser,
  getShipperUser,
  deleteShipperUser,
  updateShipperUser,
};
