export interface ColorSpectrum {
  main: string;
  light20: string;
  dark40: string;
}
export const ColorPalette: ColorSpectrum[] = [
  { main: '#aacbb5', light20: '#e9f2ec', dark40: '#40694e' },
  { main: '#dec59d', light20: '#f8f2e9', dark40: '#83622c' },
  { main: '#7bacec', light20: '#d4e4f9', dark40: '#144687' },
  { main: '#e3a7dd', light20: '#fcf4fb', dark40: '#902e86' },
  { main: '#4a9c8e', light20: '#cbe7e2', dark40: '#29574f' },
  { main: '#ff70a6', light20: '#ffd6e5', dark40: '#a3003e' },
  { main: '#fbbc85', light20: '#fef2e8', dark40: '#ae5406' },
  { main: '#83f8ff', light20: '#e9feff', dark40: '#00acb6' },
  { main: '#f70945', light20: '#fdcfda', dark40: '#32020e' },
  { main: '#887dd3', light20: '#cec9ed', dark40: '#2a2163' },
];
