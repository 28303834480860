import { Badge, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { CumulativeOperation } from './FilterableTable';
import { useRef, useState } from 'react';
import { MoreVert } from '@material-ui/icons';

interface CumulativeOperationMenuProps {
  nativeOperations?: CumulativeOperation[];
  externalOperation?: CumulativeOperation[];
  disabled?: boolean;
  selectedRows?: any[];
  // eslint-disable-next-line
  onAction?: (action: string) => void;
}

export default function CumulativeOperationMenu({
  nativeOperations,
  externalOperation,
  disabled,
  selectedRows,
  onAction,
}: CumulativeOperationMenuProps) {
  const [mnuOpen, setMnuOpen] = useState(false);
  const btnRef = useRef<HTMLElement>();
  const handleClick = (e: any) => {
    e.stopPropagation();
    btnRef.current = e.target;
    setMnuOpen(true);
  };
  const handleMnuClose = () => {
    // e.stopPropagation();
    setMnuOpen(false);
  };
  const handleMnuClick = (e: any, action: string) => {
    // e.stopPropagation();
    setMnuOpen(false);
    if (onAction) onAction(action);
  };
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  return (
    <>
      <Badge overlap="circular" badgeContent={selectedRows?.length ?? 0} color="success">
        <IconButton size="small" onClick={handleClick} disabled={disabled}>
          <MoreVert />
        </IconButton>
      </Badge>

      <Menu open={mnuOpen} onClose={handleMnuClose} anchorEl={btnRef.current} onClick={stopPropagation}>
        {nativeOperations?.map((opr) => (
          <MenuItem key={opr.operation} onClick={(e) => handleMnuClick(e, opr.operation)}>
            <ListItemIcon>{opr.icon ?? ''}</ListItemIcon>
            {opr.caption ?? opr.operation}
          </MenuItem>
        ))}
        {nativeOperations?.length! > 0 && <Divider />}
        {externalOperation?.map((opr) => (
          <MenuItem
            key={opr.operation}
            onClick={(e) => handleMnuClick(e, opr.operation)}
            disabled={opr.isDisabled ? opr.isDisabled(selectedRows ?? []) : selectedRows?.length! === 0}
          >
            {opr.renderMenu ? (
              opr.renderMenu(selectedRows ?? [])
            ) : (
              <>
                <ListItemIcon>{opr.icon ?? ''}</ListItemIcon>
                <span>{opr.caption ?? opr.operation}</span>
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
