import { Subject } from '@material-ui/icons';
import { Grid, ListSubheader, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export interface CSVMappingFormProps {
  mappingValues: any;
  mappingHeaders: any[];
  columns: string[];
  // eslint-disable-next-line
  setValue: (field: string, value: string) => void;
}

export default function CSVMappingForm({ mappingValues, columns, mappingHeaders, setValue }: CSVMappingFormProps) {
  const Field = (attrs: { fieldKey: string; mapping: any; inFieldset?: boolean }) => {
    const field = attrs.mapping.field;
    const columnName = mappingValues[field];
    const inputType = attrs.mapping.type;
    const putColumns = inputType !== 'Select';
    const inFieldset: boolean = attrs.inFieldset ?? false;
    return (
      <Select
        size="small"
        fullWidth
        label={inFieldset ? 'test' : ''}
        name={field}
        onChange={(e) => {
          // console.log(`${e.target.name}->${e.target.value}`);
          setValue(e.target.name, e.target.value);
        }}
        value={columnName}
        title="test"
      >
        <MenuItem key={`mnu-${attrs.fieldKey}-none`} value={''}>
          None
        </MenuItem>
        {putColumns && <ListSubheader>Csv headers</ListSubheader>}
        {putColumns &&
          columns.map((column, colIndex) => (
            <MenuItem key={`mnu-${attrs.fieldKey}-${colIndex}`} value={`column:${column}`}>
              {column}
            </MenuItem>
          ))}
        {inputType == 'ColumnOrDefault' && <ListSubheader>Other values</ListSubheader>}

        {attrs.mapping.options?.map((item: any, itemIndex: number) => (
          <MenuItem key={`mnu-default-${attrs.fieldKey}-${itemIndex}`} value={`default:${item.value}`}>
            <Subject color="disabled" />
            {item.caption}
          </MenuItem>
        ))}
      </Select>
    );
  };
  return (
    <Table size="small">
      <TableHead>
        <TableCell>Field</TableCell>
        <TableCell>Column</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Required</TableCell>
      </TableHead>
      <TableBody>
        {mappingHeaders &&
          mappingHeaders?.map((mapping: any, fieldSetIndex: number) => {
            const isSingleField = mapping.fields?.length === 1;
            if (isSingleField) {
              mapping = mapping.fields[0];
            }
            const fieldSet = isSingleField ? (
              <TableRow key={`field-${fieldSetIndex}`}>
                <TableCell>{mapping.caption}</TableCell>
                <TableCell>
                  <Field fieldKey={`${fieldSetIndex}`} mapping={mapping} key={`field-${fieldSetIndex}`} />
                </TableCell>
                <TableCell>{mapping.description}</TableCell>
                <TableCell>{mapping.required ? 'x' : ''}</TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow key={`fieldset-${fieldSetIndex}`}>
                  <TableCell style={{ border: 'none' }}>{mapping.fieldsetName}</TableCell>
                  <TableCell style={{ border: 'none' }}></TableCell>
                  <TableCell style={{ border: 'none' }}>{mapping.description}</TableCell>
                  <TableCell style={{ border: 'none' }}></TableCell>
                </TableRow>
                <TableRow key={`fieldset-${fieldSetIndex}-fields`}>
                  <TableCell></TableCell>
                  <TableCell colSpan={3}>
                    <Grid container>
                      {mapping.fields?.map((field: any, fieldIndex: number) => {
                        return (
                          <Grid
                            item
                            pr={1}
                            pt={1}
                            key={`field-${fieldSetIndex}-${fieldIndex}`}
                            sm={12}
                            md={field.size ?? 12}
                          >
                            <span style={{ fontSize: '80%' }}>
                              {field.caption} {field.required ? ' *' : ''}
                            </span>
                            <Field fieldKey={`${fieldSetIndex}-${fieldIndex}`} mapping={field} inFieldset />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </TableCell>
                </TableRow>
              </>
            );
            return fieldSet;
          })}
      </TableBody>
    </Table>
  );
}
