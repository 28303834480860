import { CreditCard } from '@material-ui/icons';
import { Grid } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';

interface PaymentMethodSelectProps {
  paymentMethod?: PaymentMethod;
}

export default function PaymentMethodWithIcon({ paymentMethod }: PaymentMethodSelectProps) {
  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item>Payment method</Grid>
      <Grid item>{paymentMethod?.card?.brand} </Grid>
      <Grid item>
        <CreditCard />
      </Grid>
      <Grid item>****</Grid>
      <Grid item>{paymentMethod?.card?.last4}</Grid>
    </Grid>
  );
}
