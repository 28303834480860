import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import { Customer, TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';
import {
  DSPDeliveryPoint,
  DSPQuote,
  DSPTripStatus,
  DeliveryServiceProvider,
} from '../../store/config/types/deliveryServiceProvider.types';
import { DspType } from '../../store/config/enums/deliveryServiceProvider.enum';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CourierWithAvatar from '../TripsDnd/CourierWithAvatar';
import { DSPServices } from '../../services/DeliveryServiceProvider.service';
import { CreditCard, Warning } from '@material-ui/icons';
import moment from 'moment-timezone';
import { PaymentMethod } from '@stripe/stripe-js';
import { PaymentService } from '../../services/payment.service';
import PaymentMethodSelect from '../Payment/PaymentMethodsSelect';
import PaymentMethodWithIcon from '../Payment/PaymentMethodWithIcon';
import './thirdPartyInfoStyle.scss';
import { ThirdPartyInfoCapture, infoToValues } from './ThirdPartyInfoCapture';

interface ThirdPartyDeliveryDialogProps {
  trip?: TripWithStopsDetails | null;
  DSP?: DeliveryServiceProvider | null;
  trips?: TripWithStopsDetails[];
  open: boolean;
  customers?: Customer[];
  timezone?: string;
  paymentMethod?: PaymentMethod;
  /* eslint-disable */
  onAccept?: (DSP: DeliveryServiceProvider, quote: DSPQuote, paymentMethod: PaymentMethod) => void;
  onCancel?: (DSP: DeliveryServiceProvider, quote: DSPQuote) => void;
  onDeliveryCreationSuccess?: (trip: TripWithStopsDetails, paymentMethod?: PaymentMethod) => void;
  onDeliveryCreationFailed?: (trip: TripWithStopsDetails) => void;
  onDeliveryCreationComplete?: (submittedTrips: TripWithStopsDetails[]) => void;
  onClose?: () => void;
  /* eslint-enable */
}
const getCustomerNames = (customers: Customer[], trip: TripWithStopsDetails | null, stops: TripArrangeStop[]) => {
  if (!trip) return '';
  return stops.map((tripStop, tripStopIdx) => {
    const customer = customers?.find((cstmr) => {
      if (tripStop.placeInfo && tripStop.placeInfo.placeType === 'CUSTOMER') {
        return cstmr.customerId === tripStop.placeInfo.placeId;
      }
      return (
        cstmr.location.latitude === tripStop?.location.latitude &&
        cstmr.location.longitude === tripStop?.location.longitude
      );
    });
    const name = customer?.companyName ?? `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`;
    return <div key={`dsp-trip-customer-${tripStopIdx}`}>{name}</div>;
  });
};
export function ThirdPartyDeliveryQuoteDialog({
  trip,
  DSP,
  open,
  customers,
  timezone,
  onClose,
  onAccept,
  onCancel,
}: ThirdPartyDeliveryDialogProps) {
  const [step, setStep] = useState<'payment' | 'agreement' | 'required' | 'summary' | 'unknown'>('payment');
  const [processing, setProcessing] = useState(false);
  const [terms, setTerms] = useState('');
  const [requiredInfo, setRequiredInfo] = useState<any>();
  const [capturedInfo, setCapturedInfo] = useState<any>({});
  const [dspQuote, setDspQuote] = useState<DSPQuote | null>(null);
  const [btnNextCaption, setBtnNextCaption] = useState('Continiue');
  const [btnPrevCaption, setBtnPrevCaption] = useState('Cancel');
  const [agreeCheck, setAgreeChecheck] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);

  useEffect(() => {
    retryFetchPaymentMethods();
    if (paymentMethods) {
      setStep('agreement');
    }
  }, [open]);

  timezone && moment.tz.setDefault(timezone);
  useEffect(() => {
    if (DSP) {
      setProcessing(true);
      DSPServices.getTerms(DSP.deliveryServiceProviderId)
        .then((terms) => {
          setTerms(terms ?? ' ');
        })
        .catch(() => {
          // error handling
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [DSP, DSP?.deliveryServiceProviderId]);

  useEffect(() => {
    if (dspQuote) {
      setTotalPrice(
        dspQuote.trips.reduce(
          (total, trip) => total + ((trip.confirmed ?? false) && trip.status === 'QUOTED' ? trip.price ?? 0 : 0),
          0,
        ),
      );
      const skipItemCount = dspQuote.trips.filter((trip) => trip.status !== 'QUOTED' || !trip.confirmed).length;
      if (step === 'summary')
        setBtnNextCaption(
          skipItemCount > 0 && dspQuote.trips.length === skipItemCount
            ? 'Exit'
            : `Skip ${skipItemCount} and Create Selected`,
        );
    }
  }, [dspQuote]);

  const retryFetchPaymentMethods = () => {
    if ((!paymentMethods && !loadingPaymentMethods) || paymentMethods?.length === 0) {
      setLoadingPaymentMethods(true);
      setProcessing(true);
      setBtnNextCaption('Retry');
      PaymentService.getPaymentMethods()
        .then((methods) => {
          if (methods.length > 0) {
            setPaymentMethods(methods);
            setBtnNextCaption('Continiue');
            setStep('agreement');
            setSelectedPaymentMethod(methods?.find((method) => method.metadata.default));
          }
        })
        .finally(() => {
          setLoadingPaymentMethods(false);
          setProcessing(false);
        });
    }
  };

  const handleNextClick = () => {
    switch (step) {
      case 'payment':
        if (paymentMethods?.length) {
          setBtnNextCaption('Continue');
          setBtnPrevCaption('Cancel');
          setStep('agreement');
        } else {
          retryFetchPaymentMethods();
        }
        break;
      case 'agreement':
        if (DSP && trip) {
          setProcessing(true);
          DSPServices.getRequiredInfo(DSP, trip)
            .then((required) => {
              setRequiredInfo(required);
              setCapturedInfo(infoToValues(required));
              setBtnNextCaption('Get a Qoute');
              setBtnPrevCaption('Cancel');
              setStep('required');
            })
            .catch(() => {})
            .finally(() => {
              setProcessing(false);
            });
        }
        break;
      case 'required':
        if (DSP && trip) {
          setProcessing(true);
          DSPServices.getAvailability(DSP, trip, capturedInfo)
            .then((quote) => {
              setDspQuote({
                ...quote,
                trips: quote.trips.map((trip) => ({
                  ...trip,
                  confirmed: trip.status === 'QUOTED',
                  requiredInfo: capturedInfo,
                })),
              });
              setBtnNextCaption('Insert Selected');
              setBtnPrevCaption('Cancel');
              setStep('summary');
            })
            .catch(() => {})
            .finally(() => {
              setProcessing(false);
            });
        }
        break;
      case 'summary':
        if (DSP && dspQuote && selectedPaymentMethod && onAccept) {
          handleClose();
          onAccept(
            DSP,
            {
              ...dspQuote,
              trips: dspQuote.trips.filter((trip) => (trip.confirmed ?? false) && trip.status === 'QUOTED'),
            },
            selectedPaymentMethod,
          );
        }
        break;
      default:
        setProcessing(true);
        setStep('unknown');
    }
  };
  const handlePrevClick = () => {
    handleClose();
  };
  const handleClose = () => {
    onClose && onClose();
    DSP && dspQuote && onCancel && onCancel(DSP, dspQuote);
    setStep('payment');
    setProcessing(false);
    setBtnNextCaption('Next');
    setBtnPrevCaption('Cancel');
    setAgreeChecheck(false);
  };
  const handleTripConfirmCheckClick = (tripIndex: number) => {
    if (dspQuote && dspQuote.trips.length > tripIndex) {
      setDspQuote({
        ...dspQuote,
        trips: dspQuote.trips.map((trip, index) => {
          if (index === tripIndex) return { ...trip, confirmed: !trip.confirmed };
          else return trip;
        }),
      });
    }
  };
  const breakTrip = DSP?.dspType === DspType.P2P && (trip?.tripStops?.length ?? 0) > 2;
  timezone && moment.tz.setDefault(timezone);
  const dspStops2TripStops = (dspStops: DSPDeliveryPoint[]): TripArrangeStop[] => {
    if (!trip) return [];
    const tripStops: TripArrangeStop[] = [];
    dspStops.map((stop) => {
      const tripStop = trip.tripStops.find(
        (tripStopItem, index) =>
          index > 0 &&
          (tripStopItem.stopPackages ? tripStopItem.stopPackages[0].packageId : -1) === stop.firstPackageIdOfStop,
      );
      if (tripStop) tripStops.push(tripStop);
    });
    return tripStops;
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <CourierWithAvatar name={`${DSP?.displayName} Delivery Request`} avatarUrl={DSP?.logo} />
      </DialogTitle>
      {step === 'payment' && (
        <DialogContent>
          {!paymentMethods || paymentMethods.length === 0 ? (
            <Alert severity="error">
              Please setup at least one Payment Method in your <Link href="/payment">Payment Settings</Link>
            </Alert>
          ) : (
            <div>
              <PaymentMethodSelect paymentMethods={paymentMethods} />
            </div>
          )}
        </DialogContent>
      )}
      {step === 'agreement' && (
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: terms }}></div>
          {breakTrip && (
            <Alert severity="warning">
              {DSP?.displayName} may break this trip into {(trip?.tripStops?.length ?? 0) - 1} trips
            </Alert>
          )}
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setAgreeChecheck(e.currentTarget.checked);
                }}
              />
            }
            label={'I Agree'}
          />
        </DialogContent>
      )}
      {step === 'required' && (
        <ThirdPartyInfoCapture
          requiredInfo={requiredInfo}
          onChange={(info) => {
            setCapturedInfo(info);
          }}
        />
      )}
      {step === 'summary' && (
        <DialogContent>
          <h3>{DSP?.displayName} Quote</h3>
          <Table>
            <TableHead>
              <TableCell></TableCell>
              <TableCell>{'Customer(s)'}</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Comments</TableCell>
            </TableHead>
            <TableBody>
              {dspQuote?.trips.map((qouteTrip, index) => {
                const hasError: boolean = qouteTrip.status !== 'QUOTED';
                return (
                  <TableRow key={`dsp-trip-row-${index}`}>
                    <TableCell>
                      <Checkbox
                        checked={qouteTrip.confirmed ?? false}
                        disabled={qouteTrip.status !== 'QUOTED'}
                        onClick={() => {
                          handleTripConfirmCheckClick(index);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {getCustomerNames(customers ?? [], trip ?? null, dspStops2TripStops(qouteTrip.stops ?? []))}
                    </TableCell>
                    <TableCell>{!hasError ? qouteTrip.price : <Warning style={{ color: 'red' }} />}</TableCell>
                    {hasError && (
                      <TableCell>
                        {qouteTrip.errors?.map((error, erIndex) => (
                          <div key={`dsp-trip-error-${erIndex}`}>{error}</div>
                        ))}
                      </TableCell>
                    )}
                    {!hasError && (
                      <TableCell>
                        Pickup:
                        {moment(qouteTrip.warehouse?.arrivalTime).format('hh:mm a')}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }}></TableCell>
                <TableCell align="right" style={{ borderBottom: 'none', fontWeight: 'bold' }}>
                  Total
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>${totalPrice}</TableCell>
                <TableCell style={{ borderBottom: 'none' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }} colSpan={4}>
                  <Alert severity="info">
                    <Grid container wrap="nowrap">
                      <Grid item flexGrow={1}>
                        <PaymentMethodWithIcon paymentMethod={selectedPaymentMethod} />
                      </Grid>
                      {(paymentMethods?.length ?? 0) > 1 && (
                        <Grid item>
                          <Button>Change</Button>
                        </Grid>
                      )}
                    </Grid>
                  </Alert>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handlePrevClick}>{btnPrevCaption}</Button>
        <Button
          disabled={processing || (step === 'agreement' && !agreeCheck)}
          type="submit"
          variant="outlined"
          color="primary"
          onClick={handleNextClick}
        >
          {processing ? <CircularProgress size={24} /> : btnNextCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ThirdPartyDeliveryConfirm({
  trips,
  open,
  timezone,
  customers,
  onDeliveryCreationSuccess,
  onDeliveryCreationFailed,
  onDeliveryCreationComplete,
  onClose,
}: ThirdPartyDeliveryDialogProps) {
  const [processing, setProcessing] = useState(true);
  const [statuses, setStatuses] = useState<{ tripReference: string; status: DSPTripStatus }[]>([]);
  const [startupSubmission, setStartupSubmission] = useState(false);
  const [paymentError, setPaymentError] = useState<string>();

  timezone && moment.tz.setDefault(timezone);

  const getStatusOfTrip = (trip: TripWithStopsDetails) => {
    const tripStatus = statuses.find((statusItem) => statusItem.tripReference === trip.dspRefrenceId)?.status;
    switch (tripStatus) {
      case 'QUOTED':
        return <Alert severity="info">Quoted</Alert>;
      case 'EXPIRED':
        return <Alert severity="error">Quote Expired</Alert>;
      case 'NOTAVAILABLE':
        return <Alert severity="error">Not Available</Alert>;
      case 'SUBMITTING':
        return <Alert severity="warning">Submitting</Alert>;
      case 'SUBMITTED':
        return <Alert severity="success">Successfully Submitted</Alert>;
      case 'PAYING':
        return <Alert severity="warning">Paying</Alert>;
      case 'PAYMENT_FAILED':
        return <Alert severity="error">Payment failed</Alert>;
      case 'UNKNOWN':
        return <Alert severity="error">Unknown Status</Alert>;
    }
    return '';
  };
  const refreshStatuses = () => {
    setStatuses(
      trips?.map((trip) => ({ status: trip.dspStatus ?? 'UNKNOWN', tripReference: trip.dspRefrenceId ?? '' })) ?? [],
    );
  };
  const doPayment = async (index: number) => {
    setPaymentError(undefined);
    if (trips && trips.length) {
      trips[index].dspStatus = 'PAYING';
      refreshStatuses();
      const paymentDescription = `${trips[index].DSP?.displayName} Delivery`;
      const paymentResult = await PaymentService.chargeAndHold(
        trips[index].dspTotalPrice,
        trips[index].paymentMethod,
        paymentDescription,
      );
      if (paymentResult.result) {
        setPaymentError(undefined);
        trips[index].dspStatus = 'SUBMITTED';
        trips[index].paymentUuid = paymentResult.data;
        onDeliveryCreationSuccess && onDeliveryCreationSuccess(trips[index]);
      } else {
        console.log(paymentResult);
        trips[index].dspStatus = 'PAYMENT_FAILED';
        setPaymentError(paymentResult.data);
      }
    }
  };
  const acceptQuote = async (index: number) => {
    if (trips && trips.length > 0) {
      trips[index].dspStatus = 'SUBMITTING';
      refreshStatuses();
      const trip = trips[index];
      const result = await DSPServices.submitTrip(trip);
      trips[index].dspStatus = result.status;
      if (result.status === 'SUBMITTED') {
        // charge and hold customer
        await doPayment(index);
      }
      if (trips[index].dspStatus !== 'SUBMITTED') {
        onDeliveryCreationFailed && onDeliveryCreationFailed(trips[index]);
      }
    }
  };
  const handleAcceptQuoets = async () => {
    if (open) {
      if (trips && trips.length > 0) {
        for (let i = 0; i < trips.length; i++) {
          await acceptQuote(i);
          refreshStatuses();
        }
      }
      if (!trips || trips.every((trip) => trip.dspStatus === 'SUBMITTED')) handleOkClick();
    }
  };

  const handleRetryPament = async (index: number) => {
    await doPayment(index);
    refreshStatuses();
  };

  const handleOkClick = () => {
    onDeliveryCreationComplete && onDeliveryCreationComplete(trips ?? []);
    handleClose();
  };
  useEffect(() => {
    if (startupSubmission) {
      setStartupSubmission(false);
      console.log('startupSubmission fired');
      handleAcceptQuoets();
    }
  }, [startupSubmission]);
  useEffect(() => {
    setProcessing(statuses.some((statusItem) => statusItem.status === 'QUOTED'));
  }, [statuses]);

  useEffect(() => {
    if (open) {
      refreshStatuses();
      setStartupSubmission(true);
    }
  }, [open]);

  const handleClose = () => {
    setStatuses([]);
    setProcessing(true);
    onClose && onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Finalize Third-party Deliveries</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableCell>Provider</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Status</TableCell>
          </TableHead>
          <TableBody>
            {trips?.map((trip, tripIndex) => {
              return (
                <TableRow key={`dsp-trip-${tripIndex}`}>
                  <TableCell>
                    <CourierWithAvatar name={trip.DSP?.displayName ?? ''} avatarUrl={trip.DSP?.logo} />
                  </TableCell>
                  <TableCell>{trip.tripStops[0].name}</TableCell>
                  <TableCell>
                    {getCustomerNames(
                      customers ?? [],
                      trip,
                      trip.tripStops.filter((stop, index) => index > 0),
                    )}
                  </TableCell>
                  <TableCell>{trip.dspTotalPrice ?? 0}</TableCell>
                  <TableCell>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item>{getStatusOfTrip(trip)}</Grid>
                      {trip.dspStatus === 'PAYMENT_FAILED' && (
                        <Grid item>
                          <Button
                            onClick={() => {
                              handleRetryPament(tripIndex);
                            }}
                          >
                            Retry
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {paymentError && (
          <Alert severity="error" icon={<CreditCard />}>
            {paymentError}
          </Alert>
        )}
        <Alert severity="warning">Unsuccessful trips will be ignored</Alert>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={processing}
          variant="outlined"
          color="primary"
          onClick={() => {
            handleOkClick();
          }}
        >
          {processing ? <CircularProgress size={24} /> : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ThirdPartyDeliveryCancelDialog({
  trip,
  open,
  // timezone,
  // customers,
  // onCancel,
  onClose,
}: ThirdPartyDeliveryDialogProps) {
  const [processing, setProcessing] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState<string>();

  const handleCanceling = () => {
    if (!trip) {
      onClose && onClose();
    } else {
      setProcessing(true);
      DSPServices.cancelTrip(trip)
        .then((res) => {
          if (res.status === 'CANCELED') {
            onClose && onClose();
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };
  useEffect(() => {
    if (open) {
      setProcessing(true);
      setCancellationPolicy(undefined);
      DSPServices.getCancellationPolicy(trip?.DSP?.deliveryServiceProviderId, trip?.dspRefrenceId ?? '').then(
        (policy) => {
          setCancellationPolicy(policy);
          setProcessing(false);
        },
      );
    }
  }, [open]);
  return (
    <Dialog open={open}>
      <DialogTitle>Canceling Third-party Delivery</DialogTitle>
      <DialogContent>
        {cancellationPolicy && (
          <>
            <h4>Cancellation Policy</h4>
            <DialogContentText variant="body1">{cancellationPolicy}</DialogContentText>
          </>
        )}
        <Alert severity="warning">Do you want cancel {trip?.DSP?.displayName} delivery ?</Alert>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={processing}
          variant="text"
          onClick={() => {
            onClose && onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={processing}
          variant="outlined"
          color="primary"
          onClick={() => {
            handleCanceling();
          }}
        >
          {processing ? <CircularProgress size={24} /> : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ThirdPartySupportDialog({ open, trip, onClose }: ThirdPartyDeliveryDialogProps) {
  const [processing, setProcessing] = useState(true);
  const [content, setContent] = useState<string>();
  const dspRefrenceId = trip?.dspRefrenceId;

  const handleClose = () => {
    setProcessing(true);
    onClose && onClose();
  };

  useEffect(() => {
    if (open && dspRefrenceId) {
      DSPServices.getSupportInfo(dspRefrenceId).then((info) => {
        setContent(info);
        setProcessing(false);
      });
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{trip?.DSP?.displayName} Support Info</DialogTitle>
      <DialogContent>
        {processing ? (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div className="third-party-info" dangerouslySetInnerHTML={{ __html: content ?? '' }}></div>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
