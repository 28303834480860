import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ConfirmationDialogProps {
  message?: string;
  onConfirm?: () => void;
  onCancel: () => void;
  open: boolean;
  okBtnCaption?: string;
  cancelBtnCaption?: string;
  processing?: boolean;
}

export default function ConfirmationDialog({
  message = 'Are you sure?',
  open,
  okBtnCaption = 'Yes',
  cancelBtnCaption = 'NO',
  processing,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) {
  const handleCancel = () => {
    if (onCancel) onCancel();
  };
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={handleCancel} disabled={processing}>
          {cancelBtnCaption}
        </Button>
        <Button
          variant={processing ? 'text' : 'contained'}
          color={'success'}
          onClick={handleConfirm}
          disabled={processing}
        >
          {processing ? <CircularProgress size={24} /> : okBtnCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
