import { CircularProgress, Grid, Typography } from '@mui/material';
import TypingGif from '../../assets/images/Typing.gif';

export interface AIWaitingMessageProps {
  debugMode?: boolean;
  processDescription?: string;
  streamMessage?: string;
  aiPartialResponse?: string;
}
export default function AIWaitingMessage({
  debugMode,
  aiPartialResponse,
  streamMessage,
  processDescription,
}: AIWaitingMessageProps) {
  const iconSize = debugMode ? 48 : 24;
  return (
    <Grid
      container
      flexDirection="row"
      className="prospero-message-container"
      mb={8}
      pr={2}
      flexWrap="nowrap"
      fontSize={debugMode ? '1em' : '1.3em'}
    >
      <Grid item className="prospero-message-avatar" justifyItems="flex-start" pr={2}>
        <CircularProgress size={iconSize} />
      </Grid>
      <Grid item flexGrow={1} className="prospero-message-part" position="relative">
        <Grid container flexDirection="column">
          <Grid item>
            <Typography variant="subtitle1">Processing</Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="subtitle2">
                {processDescription} - <span style={{ fontSize: '80%' }}>{streamMessage}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item p={2}>
            <Grid container>
              <Typography whiteSpace="pre-wrap" mb={2}>
                {aiPartialResponse}
                <img alt="..." src={TypingGif} style={{ width: '16px', marginLeft: 4 }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
