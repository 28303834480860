import { MonetizationOn, Home as RoundTripIcon } from '@material-ui/icons';
import { Trip, TripWithStopsDetails } from '../../store/config/types';
import moment from 'moment-timezone';

interface TripViewTripMetricsProps {
  trip?: Trip | TripWithStopsDetails;
  totalTime?: string;
  showActions?: boolean;
  displayComputed?: boolean;
  // eslint-disable-next-line
  onAction?: (actionName: string, params?: boolean) => void;
}

export default function TripViewTripMetrics({
  trip,
  totalTime,
  showActions = false,
  displayComputed = false,
  onAction,
}: TripViewTripMetricsProps) {
  const totalMiles: string = displayComputed
    ? trip?.isRoundTrip
      ? `${Math.round((trip?.computedMiles ?? 0) + (trip?.computedReturnMiles ?? 0))}`
      : `${Math.round(trip?.computedMiles ?? 0)}`
    : `${Math.round(trip?.totalMiles ?? 0)}`;
  let dspTotalPrice = (trip as TripWithStopsDetails)?.dspTotalPrice;
  if (trip && (trip as Trip).dspJob) {
    dspTotalPrice = (trip as Trip).dspJob?.courier.directCosts?.reduce((total, cost) => total + cost.value, 0);
  }
  const handleRoundTripAction = (isRound: boolean) => onAction && onAction('isRoundTrip', isRound);
  const zoneCaption = (trip as TripWithStopsDetails)?.zoneCaption;
  return (
    <div className="metrics-area">
      {totalTime && (
        <div className="totalTime">
          {totalTime}{' '}
          {trip?.isRoundTrip && (
            <>
              <RoundTripIcon className="icon" />
              {trip.returnAt ? moment(trip?.returnAt).format('H:mm') : ''}
            </>
          )}
          {showActions &&
            (trip?.isRoundTrip ? (
              <span className="round-trip-action" onClick={() => handleRoundTripAction(false)}>
                [X]
              </span>
            ) : (
              <span className="round-trip-action" onClick={() => handleRoundTripAction(true)}>
                {' '}
                IsRound
              </span>
            ))}
        </div>
      )}
      {dspTotalPrice ? (
        <div className="total-price">
          <MonetizationOn className="icon" />
          {dspTotalPrice}
        </div>
      ) : (
        <>
          <div className="totalMiles inline">{+totalMiles ? `${totalMiles} Miles` : 'TotalMiles is missing'}</div>
          {!!zoneCaption && <div className="zone-caption inline">{zoneCaption}</div>}
        </>
      )}
    </div>
  );
}
