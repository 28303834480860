import { Polyline } from '@react-google-maps/api';
import { LatLng } from '../../store/config/types';
import { ColorSpectrum } from '../../store/config/types/ColorSpectrum';

interface RouteMarkerProps {
  points: LatLng[];
  color?: ColorSpectrum;
  highlight?: boolean;
  isReturn?: boolean;
}
export default function RoutePath({ points, color, highlight, isReturn }: RouteMarkerProps) {
  const lineSymbol = {
    path: 'M 0,0 0,0',
    strokeOpacity: 1,
    scale: 5,
    strokeColor: color?.dark40,
  };
  return isReturn ? (
    <>
      <Polyline
        path={points}
        options={{
          icons: [
            {
              icon: lineSymbol,
              offset: '0',
              repeat: '10px',
            },
          ],
          strokeColor: highlight ? color?.dark40 : color?.main,
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
      />
    </>
  ) : (
    <>
      <Polyline
        path={points}
        options={{
          strokeColor: color?.dark40,
          strokeOpacity: 1,
          strokeWeight: 8,
          zIndex: highlight ? 99999 : undefined,
        }}
      />
      <Polyline
        path={points}
        options={{
          strokeColor: highlight ? color?.dark40 : color?.main,
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
      />
    </>
  );
}
