import { Dispatch } from 'redux';
import { couriersService } from '../../services/couriers.service';
import { Courier, Vehicle } from '../config/types';
import {
  FETCHING_COURIERS,
  FETCH_COURIERS_SUCCESS,
  FETCH_COURIERS_FAILED,
  FetchCouriersTypes,
  CREATING_COURIER,
  CREATE_COURIER_SUCCESS,
  CREATE_COURIER_FAILED,
  UPDATING_COURIER,
  UPDATE_COURIER_SUCCESS,
  UPDATE_COURIER_FAILED,
  DELETING_COURIER,
  DELETE_COURIER_SUCCESS,
  DELETE_COURIER_FAILED,
  CreateCourierTypes,
  UpdateCourierTypes,
  DeleteCourierTypes,
  AddVehiclesTypes,
  ADDING_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILED,
  SET_COURIER,
  SetCourierType,
  UNSET_COURIER,
  UpdateVehiclesTypes,
  UPDATING_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILED,
  DeleteVehiclesTypes,
  DELETING_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILED,
  ClearCouriersStateType,
  CLEAR_COURIERS_STATE,
} from '../config/ActionTypes';

// #region Fetch Couriers

export const fetchCouriers = () => {
  return (dispatch: Dispatch<FetchCouriersTypes>) => {
    dispatch(fetchingCouriers());

    return couriersService.fetchCouriers().then(
      (response) => {
        dispatch(fetchCouriersSuccess(response!));
      },
      (error) => {
        dispatch(fetchCouriersFailed(error));
      },
    );
  };
};

export const fetchingCouriers = (): FetchCouriersTypes => ({
  type: FETCHING_COURIERS,
});

export const fetchCouriersSuccess = (couriers: Courier[]): FetchCouriersTypes => ({
  type: FETCH_COURIERS_SUCCESS,
  couriers,
});

export const fetchCouriersFailed = (error: string): FetchCouriersTypes => ({
  type: FETCH_COURIERS_FAILED,
  error,
});

// #endregion Fetch Couriers

// #region Set Courier

export const setCourier = (courierId?: string) => {
  return (dispatch: Dispatch<SetCourierType>) => {
    if (courierId) {
      dispatch(setCourierInState(courierId));
    } else {
      dispatch(unsetCourierInState());
    }
  };
};

export const unsetCourierInState = (): SetCourierType => ({
  type: UNSET_COURIER,
});

export const setCourierInState = (courierId: string): SetCourierType => ({
  type: SET_COURIER,
  courierId,
});

// #endregion Set Courier

// #region Create Courier

export const createCourier = (createCourierRequest: Courier, courierPhoto?: File) => {
  return (dispatch: Dispatch<CreateCourierTypes>) => {
    dispatch(creatingCourier());

    return couriersService.createCourier(createCourierRequest, courierPhoto).then(
      (response) => {
        dispatch(createCourierSuccess(response!));
      },
      (error) => {
        dispatch(createCourierFailed(error));
      },
    );
  };
};

export const creatingCourier = (): CreateCourierTypes => ({
  type: CREATING_COURIER,
});

export const createCourierSuccess = (courier: Courier): CreateCourierTypes => ({
  type: CREATE_COURIER_SUCCESS,
  courier,
});

export const createCourierFailed = (error: string): CreateCourierTypes => ({
  type: CREATE_COURIER_FAILED,
  error,
});

// #endregion Create Courier

// #region Update Courier

export const updateCourier = (courierId: number, updateCourierRequest: Courier, courierPhoto?: File) => {
  return (dispatch: Dispatch<UpdateCourierTypes>) => {
    dispatch(updatingCourier());

    return couriersService.updateCourier(courierId, updateCourierRequest, courierPhoto).then(
      (response) => {
        dispatch(updateCourierSuccess(response!));
      },
      (error) => {
        dispatch(updateCourierFailed(error));
      },
    );
  };
};

export const updatingCourier = (): UpdateCourierTypes => ({
  type: UPDATING_COURIER,
});

export const updateCourierSuccess = (courier: Courier): UpdateCourierTypes => ({
  type: UPDATE_COURIER_SUCCESS,
  courier,
});

export const updateCourierFailed = (error: string): UpdateCourierTypes => ({
  type: UPDATE_COURIER_FAILED,
  error,
});

// #endregion Update Courier

// #region Delete Courier

export const deleteCourier = (courierId: number) => {
  return (dispatch: Dispatch<DeleteCourierTypes>) => {
    dispatch(deletingCourier());

    return couriersService.deleteCourier(courierId).then(
      () => {
        dispatch(deleteCourierSuccess(courierId));
      },
      (error) => {
        dispatch(deleteCourierFailed(error));
      },
    );
  };
};

export const deletingCourier = (): DeleteCourierTypes => ({
  type: DELETING_COURIER,
});

export const deleteCourierSuccess = (courierId: number): DeleteCourierTypes => ({
  type: DELETE_COURIER_SUCCESS,
  courierId,
});

export const deleteCourierFailed = (error: string): DeleteCourierTypes => ({
  type: DELETE_COURIER_FAILED,
  error,
});

// #endregion Delete Courier

// #region Vehicles

export const addVehicle = (addVehicleRequest: Vehicle) => {
  return (dispatch: Dispatch<AddVehiclesTypes>) => {
    dispatch(addingVehicle());

    return couriersService.addVehicleToCourier(addVehicleRequest).then(
      (response) => {
        dispatch(addVehicleSuccess(response!));
      },
      (error) => {
        dispatch(addVehicleFailure(error));
      },
    );
  };
};

export const addingVehicle = (): AddVehiclesTypes => ({
  type: ADDING_VEHICLE,
});

export const addVehicleSuccess = (vehicle: Vehicle): AddVehiclesTypes => ({
  type: ADD_VEHICLE_SUCCESS,
  vehicle,
});

export const addVehicleFailure = (error: string): AddVehiclesTypes => ({
  type: ADD_VEHICLE_FAILED,
  error,
});

export const updateVehicle = (updateVehicleRequest: Vehicle) => {
  return (dispatch: Dispatch<UpdateVehiclesTypes>) => {
    dispatch(updatingVehicle());

    return couriersService.updateCourierVehicle(updateVehicleRequest).then(
      () => {
        dispatch(updateVehicleSuccess(updateVehicleRequest));
      },
      (error) => {
        dispatch(updateVehicleFailure(error));
      },
    );
  };
};

export const updatingVehicle = (): UpdateVehiclesTypes => ({
  type: UPDATING_VEHICLE,
});

export const updateVehicleSuccess = (vehicle: Vehicle): UpdateVehiclesTypes => ({
  type: UPDATE_VEHICLE_SUCCESS,
  vehicle,
});

export const updateVehicleFailure = (error: string): UpdateVehiclesTypes => ({
  type: UPDATE_VEHICLE_FAILED,
  error,
});

export const deleteVehicle = (courierId: string, vehicleId: string) => {
  return (dispatch: Dispatch<DeleteVehiclesTypes>) => {
    dispatch(deletingVehicle());

    return couriersService.deleteCourierVehicle(courierId, vehicleId).then(
      () => {
        dispatch(deleteVehicleSuccess(vehicleId));
      },
      (error) => {
        dispatch(deleteVehicleFailure(error));
      },
    );
  };
};

export const deletingVehicle = (): DeleteVehiclesTypes => ({
  type: DELETING_VEHICLE,
});

export const deleteVehicleSuccess = (vehicleId: string): DeleteVehiclesTypes => ({
  type: DELETE_VEHICLE_SUCCESS,
  vehicleId,
});

export const deleteVehicleFailure = (error: string): DeleteVehiclesTypes => ({
  type: DELETE_VEHICLE_FAILED,
  error,
});

export const clearCouriersState = (): ClearCouriersStateType => ({
  type: CLEAR_COURIERS_STATE,
});

// #endregion Vehicles
