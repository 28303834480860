import { Dispatch } from 'redux';
import { customersService } from '../../services/customers.service';
import { Customer } from '../config/types';
import {
  CustomersActionTypes,
  FETCHING_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILED,
  CREATING_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILED,
  UPDATING_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  SET_CUSTOMER,
  UNSET_CUSTOMER,
  SetCustomerType,
  CreateCustomerTypes,
  UpdateCustomerTypes,
  ClearCustomersStateType,
  CLEAR_CUSTOMERS_STATE,
} from '../config/ActionTypes';

// #region Fetch Customers

export const fetchCustomers = () => {
  return (dispatch: Dispatch<CustomersActionTypes>) => {
    dispatch(fetchingCustomers());

    return customersService.fetchCustomers().then(
      (response) => {
        dispatch(fetchCustomersSuccess(response!));
      },
      (error) => {
        dispatch(fetchCustomersFailed(error));
      },
    );
  };
};

export const fetchingCustomers = (): CustomersActionTypes => ({
  type: FETCHING_CUSTOMERS,
});

export const fetchCustomersSuccess = (customers: Customer[]): CustomersActionTypes => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  customers,
});

export const fetchCustomersFailed = (error: string): CustomersActionTypes => ({
  type: FETCH_CUSTOMERS_FAILED,
  error,
});

// #endregion Fetch Customers

// #region Set Customer

export const setCustomer = (customerId?: string) => {
  return (dispatch: Dispatch<SetCustomerType>) => {
    if (customerId) {
      dispatch(setCustomerInState(customerId));
    } else {
      dispatch(unsetCustomerInState());
    }
  };
};

export const unsetCustomerInState = (): SetCustomerType => ({
  type: UNSET_CUSTOMER,
});

export const setCustomerInState = (customerId: string): SetCustomerType => ({
  type: SET_CUSTOMER,
  customerId,
});

// #endregion Set Customer

// #region Create Customer
export const createCustomer = (createCustomerRequest: Customer) => {
  return (dispatch: Dispatch<CreateCustomerTypes>) => {
    dispatch(creatingCustomer());

    return customersService.createCustomer(createCustomerRequest).then(
      (response) => {
        dispatch(createCustomerSuccess(response!));
      },
      (error) => {
        dispatch(createCustomerFailed(error));
      },
    );
  };
};

export const creatingCustomer = (): CreateCustomerTypes => ({
  type: CREATING_CUSTOMER,
});

export const createCustomerSuccess = (customer: Customer): CreateCustomerTypes => ({
  type: CREATE_CUSTOMER_SUCCESS,
  customer,
});

export const createCustomerFailed = (error: string): CreateCustomerTypes => ({
  type: CREATE_CUSTOMER_FAILED,
  error,
});

// #endregion Create Customer

// #region Update Customer

export const updateCustomer = (customerId: number, updateCustomerRequest: Customer) => {
  return (dispatch: Dispatch<UpdateCustomerTypes>) => {
    dispatch(updatingCustomer());

    return customersService.updateCustomer(customerId, updateCustomerRequest).then(
      (response) => {
        dispatch(updateCustomerSuccess(response!));
      },
      (error) => {
        dispatch(updateCustomerFailed(error));
      },
    );
  };
};

export const updatingCustomer = (): UpdateCustomerTypes => ({
  type: UPDATING_CUSTOMER,
});

export const updateCustomerSuccess = (customer: Customer): UpdateCustomerTypes => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  customer,
});

export const updateCustomerFailed = (error: string): UpdateCustomerTypes => ({
  type: UPDATE_CUSTOMER_FAILED,
  error,
});

// #endregion Update Customer

// #region Clear Customers State

export const clearCustomersState = (): ClearCustomersStateType => ({
  type: CLEAR_CUSTOMERS_STATE,
});

// #endregion Clear Customers State
