import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, Vehicle } from '../../store/config/types';
import { setToast } from '../../store/actions/toast.actions';
import AddButton from '../../components/CustomButtons/AddButton';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';
import { VehiclesService } from '../../services/vehicles.service';
import { couriersService } from '../../services/couriers.service';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Vehicles({ setToast, loggedIn }: PropsFromRedux) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [vehicleToDelete, setVehicleToDelete] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setloading] = useState(false);
  const [vehicles, setvehicles] = useState<Vehicle[]>();

  useEffect(() => {
    if (!vehicles) {
      setloading(true);
      couriersService
        .fetchCouriers()
        .then((couriers) => {
          VehiclesService.fetchVehicles().then((result) => {
            setvehicles(
              result?.map((vehicle) => ({
                ...vehicle,
                courier: couriers?.find((courier) => courier.courierId === vehicle.courierId),
              })),
            );
          });
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [loggedIn]);

  useEffect(() => {}, [submitted, setloading, setvehicles, setToast]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateVehicle = () => history.push('/vehicles/create');

  const handleUpdateVehicle = (vehicleId: number) => history.push(`/vehicles/${vehicleId}/update`);

  const handleDeleteVehicle = (vehicleId: number) => setVehicleToDelete(vehicleId);

  const deleteVehicle = () => {
    if (vehicleToDelete) {
      setSubmitted(true);
      VehiclesService.deleteVehicle(vehicleToDelete)
        .then((res) => {
          if (res) {
            setvehicles(vehicles?.filter((vehicle) => vehicle.vehicleId !== vehicleToDelete));
            setToast('Vehicle deleted', 'success');
          }
        })
        .catch((err) => {
          setToast(err.response?.data?.message ?? err.message, 'danger');
        })
        .finally(() => {
          setSubmitted(false);
          setVehicleToDelete(null);
        });
    }
  };

  const handleCloseDialog = () => setVehicleToDelete(null);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
    </GridContainer>
  );

  let vehiclesContent = null;
  if (vehicles) {
    let vehicleToShow = vehicles || [];

    if (searchText !== '') {
      vehicleToShow = vehicleToShow?.filter((vehicle) => {
        return ['vehicleType', 'licensePlate', 'model', 'color'].some((key) => {
          return (vehicle as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = vehicleToShow.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT).map((vehicle) => {
      return [
        vehicle.vehicleId,
        vehicle.vehicleType,
        vehicle.model,
        vehicle.licensePlate,
        vehicle.color,
        vehicle.capacity,
        `${vehicle.courier?.firstName ?? ''} ${vehicle.courier?.lastName ?? ''}`,
      ];
    });

    vehiclesContent = (
      <TableList
        title="Vehicles"
        head={['Id', 'Type', 'Model', 'License Plate', 'Color', 'Capacity', 'Courier', '']}
        onEdit={handleUpdateVehicle}
        onDelete={handleDeleteVehicle}
        edit
        delete
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={vehicleToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (loading) {
    vehiclesContent = <Spinner />;
  } else {
    vehiclesContent = <div>No data to show</div>;
  }

  if (!vehicles) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {vehiclesContent}
      <AddButton onClick={handleCreateVehicle} />
      <CustomDialog
        title={'Confirm'}
        description={'¿Are you sure you want to delete this Vehicle?'}
        open={vehicleToDelete != null}
        onConfirmation={deleteVehicle}
        handleClose={handleCloseDialog}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
}

export default connector(Vehicles);
