import { Marker, InfoBox } from '@react-google-maps/api';
import { Stop, Trip, TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';
import TripDndItemToolTip from '../TripsDnd/TripDndItemToolTip';
import { stopToDndItem } from '../TripsDnd/helper';
import './style.scss';
import { useState } from 'react';
import { ColorSpectrum } from '../../store/config/types/ColorSpectrum';
interface StopMarkerProps {
  trip: Trip | TripWithStopsDetails;
  stop: Stop | TripArrangeStop;
  stopIndex: number;
  tripIndex?: number;
  color?: ColorSpectrum;
  highlight?: boolean;
  label?: string;
  displayComputed?: boolean;
  /* eslint-disable */
  onHover?: (stopId: number, isOver: boolean) => void;
  onClick?: (stopId: number) => void;
  /* eslint-enable */
}
export default function StopMarker({
  trip,
  stop,
  tripIndex,
  stopIndex,
  color,
  highlight,
  label,
  displayComputed,
  onClick,
}: StopMarkerProps) {
  const stopCompleted = stop.status == 'COMPLETED';
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Marker
        position={{ lat: stop.location.latitude, lng: stop.location.longitude }}
        // onMouseOver={() => {
        //   onHover && onHover(stop.tripStopId ?? 0, true);
        // }}
        // onMouseOut={() => {
        //   onHover && onHover(stop.tripStopId ?? 0, false);
        // }}
        icon={{
          path: 'M -8,0 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0',
          strokeColor: color?.dark40,
          strokeWeight: 16,
          fillOpacity: 0.5,
        }}
      />
      <Marker
        position={{ lat: stop.location.latitude, lng: stop.location.longitude }}
        // onMouseOver={() => {
        //   onHover && onHover(stop.tripStopId ?? 0, true);
        // }}
        // onMouseOut={() => {
        //   onHover && onHover(stop.tripStopId ?? 0, false);
        // }}
        zIndex={highlight ? 99999 : undefined}
        icon={
          highlight
            ? {
                path: 'M -8,0 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0',
                fillColor: color?.dark40,
                strokeColor: color?.dark40,
                strokeWeight: 30,
                fillOpacity: 0.5,
              }
            : {
                path: 'M -12,0 a 12,12 0 1,0 24,0 a 12,12 0 1,0 -24,0',
                fillColor: stopCompleted ? color?.main ?? 'red' : color?.light20,
                strokeColor: color?.main ?? 'red',
                strokeWeight: 4,
                fillOpacity: 1,
              }
        }
        label={{
          text: label ?? (stopIndex > 0 ? `${stopIndex}` : 'W'),
          fontSize: '16pt',
          fontWeight: 'bold',
          color: highlight ? color?.light20 : color?.dark40,
        }}
        onClick={() => {
          setClicked(!clicked);
          onClick && onClick(stop.tripStopId);
        }}
      >
        {highlight && (
          <InfoBox
            onCloseClick={() => {
              alert('clicked');
            }}
          >
            <div className="map-stop-info">
              <TripDndItemToolTip
                item={stopToDndItem(trip, stop, tripIndex ?? 0, stopIndex, highlight, undefined, displayComputed)}
                showTimes={true}
              />
            </div>
          </InfoBox>
        )}
      </Marker>
    </>
  );
}
