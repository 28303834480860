import {
  PackageGroupFulfillmentReadableStatus,
  PackageGroupFulfillmentStatus,
} from '../store/config/enums/warehouseWork.enum';
import { FulfillmentSalesOrderStruct, OrderItem, PackageGroup } from '../store/config/types';

export function getfulfillmentSummaryOfPackageGroup(pg: PackageGroup) {
  // const summary = {
  //   commited: 0,
  //   ordered: 0,
  //   fulfilled: 0,
  //   status: PackageGroupFulfillmentStatus.PENDING,
  //   someFulfilled: false,
  //   somePending: false,
  //   somePartialFulfilled: false,
  //   someProcessing: false,
  //   lotQty: [] as { lotId: string | null; quantity: number; inventoryLotQuantityId?: number }[],
  // };

  // pg.fulfillment?.warehouseWorkOrders?.forEach((wo) => {
  //   if (wo.workType == WarehouseWorkType.PICK) {
  //     wo.fulfillmentItems?.forEach((ffi) => {
  //       summary.ordered += ffi.quantity;
  //       if (ffi.lotId) summary.commited += ffi.quantity;
  //       summary.fulfilled += ffi.fulfilledQuantity;
  //       summary.somePending = summary.somePending || ffi.fulfilledQuantity === 0;
  //       summary.someFulfilled = summary.someFulfilled || ffi.fulfilledQuantity >= ffi.quantity;
  //       summary.somePartialFulfilled =
  //         summary.somePartialFulfilled || (ffi.fulfilledQuantity > 0 && ffi.fulfilledQuantity < ffi.quantity);
  //       const lotIdx = summary.lotQty.findIndex((lq) => lq.lotId === ffi.lotId);
  //       if (lotIdx < 0) {
  //         summary.lotQty.push({
  //           lotId: ffi.lotId,
  //           quantity: ffi.quantity,
  //           inventoryLotQuantityId: ffi.inventoryLotQuantityId,
  //         });
  //       } else {
  //         summary.lotQty[lotIdx].quantity += ffi.quantity;
  //       }
  //     });
  //   }
  // });
  // if (summary.commited < summary.ordered) {
  //   summary.status = PackageGroupFulfillmentStatus.INSUFFICIENT_INVENTORY;
  // } else if (summary.somePending) {
  //   if (summary.someFulfilled || summary.somePartialFulfilled) {
  //     summary.status = PackageGroupFulfillmentStatus.PROCESSING;
  //   } else {
  //     summary.status = PackageGroupFulfillmentStatus.PENDING;
  //   }
  // } else {
  //   if (summary.somePartialFulfilled) {
  //     summary.status = PackageGroupFulfillmentStatus.PARTIAL_FULFILLED;
  //   } else {
  //     summary.status = PackageGroupFulfillmentStatus.FULFILLED;
  //   }
  // }

  return {
    commited: 0,
    ordered: 0,
    fulfilled: 0,
    lotQty: [],
    status: pg.fulfillment?.status ?? PackageGroupFulfillmentStatus.NEEDS_ATTENTION,
  };
}

export function getfulfillmentStatusOfPackageGroups(packageGroups: PackageGroup[]) {
  let somePending = false;
  let someProcessing = false;
  let someFulfilled = false;
  let somePartialFulfilled = false;
  let status = PackageGroupFulfillmentStatus.PROCESSING;

  packageGroups.forEach((pg) => {
    switch (pg.fulfillment?.status) {
      case PackageGroupFulfillmentStatus.PENDING:
        somePending = true;
        break;
      case PackageGroupFulfillmentStatus.PROCESSING:
        someProcessing = true;
        break;
      // case PackageGroupFulfillmentStatus.PARTIAL_FULFILLED:
      //   somePartialFulfilled = true;
      // break;
      case PackageGroupFulfillmentStatus.FULFILLED:
        someFulfilled = true;
        break;
    }
  });

  if (somePending) {
    if (someFulfilled || somePartialFulfilled || someProcessing) {
      status = PackageGroupFulfillmentStatus.PROCESSING;
    } else {
      status = PackageGroupFulfillmentStatus.PENDING;
    }
  } else {
    if (someProcessing) {
      status = PackageGroupFulfillmentStatus.PROCESSING;
      // } else if (somePartialFulfilled) {
      //   status = PackageGroupFulfillmentStatus.PARTIAL_FULFILLED;
    } else {
      status = PackageGroupFulfillmentStatus.FULFILLED;
    }
  }
  return status;
}

export function packageGroupToFulfillmentSalesOrder(pg: PackageGroup) {
  let customerDisplayName = pg.customer?.companyName;
  if (!customerDisplayName) {
    customerDisplayName = `${pg.customer?.firstName ?? ''} ${pg.customer?.lastName ?? ''}`;
  }
  const isPartial: boolean =
    pg.fulfillment?.status !== PackageGroupFulfillmentStatus.EXTERNALLY_FULFILLED &&
    (pg.orderItems?.findIndex(
      (oi) => oi.quantityCommitted < oi.quantity || (oi.quantityCommitted == 0 && oi.quantity == 0),
    ) ?? -1) >= 0;

  const wUsersDisplayName: string[] = [];
  pg.fulfillment?.warehouseWorkOrders.forEach((workorder) => {
    if (workorder.warehouseUser)
      wUsersDisplayName.push(workorder.warehouseUser.firstName + ' ' + workorder.warehouseUser.lastName);
  });
  const itemCount = pg.orderItems?.length ?? 0;
  const status = pg.fulfillment?.status;

  return {
    packageGroupId: pg.packageGroupId,
    warehouse: pg.warehouse,
    customerDisplayName,
    label: pg.additionalData.label ? pg.additionalData.label : pg.externalId,
    items: pg.orderItems,
    pickerDisplayName: wUsersDisplayName.join(', '),
    shippingDate: pg.shippingDate,
    shipVia: pg.shipVia === 'DEFAULT' ? 'Curbhub' : pg.shipVia,
    status,
    statusReadable: pg.fulfillment?.status ? PackageGroupFulfillmentReadableStatus[pg.fulfillment.status] : '',
    externalLink: pg.additionalData.externalLink,
    itemCount,
    isPartial,
  } as FulfillmentSalesOrderStruct;
}

export function getReadableItemStatus(status: string) {
  switch (status) {
    case 'INSUFFICIENT_INVENTORY':
      return 'Insufficient Inventory';
    case 'PENDING':
      return 'Pending Fulfillment';
    case 'READY_TO_FULFILL':
      return 'New';
    case 'AUTOLOT_FAILED':
      return 'Missing Lot';
    default:
      return '';
  }
}

export function isKitItem(item: OrderItem) {
  return item.type.toLowerCase().includes('kit');
}
