import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { FulfillmentSalesOrderStruct } from '../../store/config/types';
import { HdrStrong, HighlightOff, Input, MoreVert, Sync } from '@material-ui/icons';
import { useRef, useState } from 'react';
import { OrderItemFulfillmentStatus, PackageGroupFulfillmentStatus } from '../../store/config/enums/warehouseWork.enum';

interface SalesOrderActionProps {
  salesOrder: FulfillmentSalesOrderStruct;
  // eslint-disable-next-line
  onAction?: (action: string, params: any) => void;
}
export default function SalesOrderAction({ salesOrder, onAction }: SalesOrderActionProps) {
  const [mnuOpen, setMnuOpen] = useState(false);
  const btnRef = useRef<HTMLElement>();
  const handleClick = (e: any) => {
    e.stopPropagation();
    btnRef.current = e.target;
    setMnuOpen(true);
  };
  const handleMnuClose = () => {
    // e.stopPropagation();
    setMnuOpen(false);
  };
  const handleMnuClick = (e: any, action: string, params: any) => {
    // e.stopPropagation();
    setMnuOpen(false);
    if (onAction) onAction(action, params);
  };
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  const skippable = [
    PackageGroupFulfillmentStatus.COMMITTED,
    PackageGroupFulfillmentStatus.NEEDS_ATTENTION,
    PackageGroupFulfillmentStatus.NEW,
    PackageGroupFulfillmentStatus.PENDING,
  ].includes(salesOrder.status);
  const canConvertToPending = [PackageGroupFulfillmentStatus.COMMITTED, PackageGroupFulfillmentStatus.NEW].includes(
    salesOrder.status,
  );
  const canSync = [PackageGroupFulfillmentStatus.FULFILLED].includes(salesOrder.status);
  const canPartialCommit =
    salesOrder.status === PackageGroupFulfillmentStatus.NEEDS_ATTENTION &&
    salesOrder.items.findIndex((item) => item.fulfillmentStatus === OrderItemFulfillmentStatus.MISSING_PRODUCT) < 0 &&
    !salesOrder.items.every((item) => item.quantityCommitted === 0);

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={mnuOpen} onClose={handleMnuClose} anchorEl={btnRef.current} onClick={stopPropagation}>
        <MenuItem disabled={true}>PG#{salesOrder.packageGroupId}</MenuItem>
        {skippable && (
          <MenuItem onClick={(e) => handleMnuClick(e, 'skip', { packageGroupId: salesOrder.packageGroupId })}>
            <ListItemIcon>
              <HighlightOff color="error" />
            </ListItemIcon>
            <ListItemText>Skip Fulfillment</ListItemText>
          </MenuItem>
        )}
        {canConvertToPending && (
          <MenuItem
            onClick={(e) => handleMnuClick(e, 'convert-to-pending', { packageGroupId: salesOrder.packageGroupId })}
          >
            <ListItemIcon>
              <Input color="primary" />
            </ListItemIcon>
            <ListItemText>Add to Fulfillment Queue</ListItemText>
          </MenuItem>
        )}
        {canPartialCommit && (
          <MenuItem onClick={(e) => handleMnuClick(e, 'partial-commit', { packageGroupId: salesOrder.packageGroupId })}>
            <ListItemIcon>
              <HdrStrong color="primary" />
            </ListItemIcon>
            <ListItemText>Partial Commit</ListItemText>
          </MenuItem>
        )}
        {canSync && (
          <MenuItem onClick={(e) => handleMnuClick(e, 'sync-with-esm', { packageGroupId: salesOrder.packageGroupId })}>
            <ListItemIcon>
              <Sync color="primary" />
            </ListItemIcon>
            <ListItemText>Sync</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
