import axios from './axios';
import { Vehicle } from '../store/config/types';

async function fetchVehicles(): Promise<Vehicle[] | undefined> {
  const res = await axios.get('/vehicles');
  return res.data.data;
}

async function fetchVehicle(vehicleId: number): Promise<Vehicle | undefined> {
  const res = await axios.get('/vehicles/' + vehicleId);
  return res.data.data;
}

async function createVehicle(vehicle: Vehicle): Promise<Vehicle | undefined> {
  const res = await axios.post('/vehicles', vehicle);
  return res.data.data;
}

async function updateVehicle(vehicleId: number, vehicle: Vehicle): Promise<Vehicle | undefined> {
  const res = await axios.patch('/vehicles/' + vehicleId, vehicle);
  return res.data.data;
}

async function deleteVehicle(vehicleId: number): Promise<boolean | undefined> {
  const res = await axios.delete('/vehicles/' + vehicleId);
  return res.data.data;
}

export const VehiclesService = {
  fetchVehicles,
  fetchVehicle,
  createVehicle,
  updateVehicle,
  deleteVehicle,
};
