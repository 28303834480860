import { Chip } from '@mui/material';
import { FilterType } from './FilterableTable';
import { formatDate } from '../../utils/string.helpers';

// eslint-disable-next-line
type FilterChangedFunc = (newValue: any) => void;
interface FilterInfoProps {
  type: FilterType;
  value: any;
  onFilterChanged: FilterChangedFunc;
}

export default function FilterInfo({ type, value, onFilterChanged }: FilterInfoProps) {
  const getChipText = () => {
    switch (type) {
      case 'string':
        return value;
      case 'boolean':
        return value ? 'Yes' : '';
      case 'date':
        return formatDate(value);
      case 'values':
        if (Array.isArray(value) && value.length > 1) return `${value.length} selected`;
        return value[0];
    }
  };
  const emptyValue = type === 'values' ? [] : undefined;
  if (type === 'values' && !value.length) return <></>;
  return (
    <Chip
      label={getChipText()}
      clickable
      onDelete={() => {
        onFilterChanged(emptyValue);
      }}
      size="small"
      color="info"
      variant="outlined"
    />
  );
}
