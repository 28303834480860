import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Product } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { AssemblyBuildService } from '../../services/warehouseWork.service';

export interface ChangeLotOfAssemblyItemDialogProps {
  lotIdToChange?: string | null;
  assemblyBuildItemIds?: number[];
  product?: Product;
  title?: string;
  onClose: () => void;
  // eslint-disable-next-line
  onNotify?: (message: string, type: string) => void;
}
export default function ChangeLotOfAssemblyItemDialog({
  lotIdToChange,
  onClose,
  onNotify,
  title,
  product,
  assemblyBuildItemIds,
}: ChangeLotOfAssemblyItemDialogProps) {
  const [newValue, setNewValue] = useState(lotIdToChange);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setNewValue(lotIdToChange);
  }, [lotIdToChange]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleChangeLot = () => {
    setProcessing(true);
    if (assemblyBuildItemIds) {
      AssemblyBuildService.changeLotOfAssemblyItem(assemblyBuildItemIds, newValue ?? '')
        .then(() => {
          onClose();
          if (onNotify) onNotify('LotId successfuly changed', 'success');
        })
        .catch((reason: any) => {
          if (onNotify) onNotify(reason.message, 'danger');
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <Dialog open={lotIdToChange !== undefined && !!assemblyBuildItemIds} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">{title ?? `Change Lot`} </Typography>
        <Typography variant="h5">{product?.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container pt={2}>
          <TextField
            fullWidth
            variant="outlined"
            label="Default Lot"
            name="lot"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            disabled={processing}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="success" disabled={processing || !newValue} onClick={handleChangeLot}>
              Change
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
