import {
  CREATE_SHIPPER_FAILED,
  CREATE_SHIPPER_SUCCESS,
  CREATING_SHIPPER,
  FETCHING_SHIPPERS,
  FETCH_SHIPPERS_FAILED,
  FETCH_SHIPPERS_SUCCESS,
  SET_SHIPPER,
  ShippersActionTypes,
  UNSET_SHIPPER,
  UPDATE_SHIPPER_FAILED,
  UPDATE_SHIPPER_SUCCESS,
  UPDATING_SHIPPER,
  DELETING_SHIPPER,
  DELETE_SHIPPER_SUCCESS,
  DELETE_SHIPPER_FAILED,
} from '../config/ActionTypes';
import { Shipper, ShippersState } from '../config/types/shippers.types';

export const initialState: ShippersState = {
  shipper: null,
  shippers: null,
  loadingShippers: false,
  shippersFetched: false,
  shippersErrorMessage: null,
  creatingShipper: false,
  createShipperSuccess: false,
  createShipperErrorMessage: null,
  updatingShipper: false,
  updateShipperSuccess: false,
  updateShipperErrorMessage: null,
  deletingShipper: false,
  deleteShipperSuccess: false,
  deleteShipperErrorMessage: null,
};

export function shippersReducer(state = initialState, action: ShippersActionTypes): ShippersState {
  switch (action.type) {
    case FETCHING_SHIPPERS:
      return {
        ...state,
        loadingShippers: true,
        shippersFetched: false,
        shippersErrorMessage: null,
      };

    case FETCH_SHIPPERS_SUCCESS:
      return {
        ...state,
        loadingShippers: false,
        shippersFetched: true,
        shippers: action.shippers,
        shippersErrorMessage: null,
      };

    case FETCH_SHIPPERS_FAILED:
      return {
        ...state,
        loadingShippers: false,
        shippersFetched: false,
        shippersErrorMessage: action.error,
      };

    case SET_SHIPPER:
      const newShipper = state.shippers?.find((s: Shipper) => s.shipperId === Number(action.shipperId)) || null;

      return {
        ...state,
        shipper: newShipper,
      };

    case UNSET_SHIPPER:
      return {
        ...state,
        shipper: null,
      };

    case CREATING_SHIPPER:
      return {
        ...state,
        creatingShipper: true,
        createShipperSuccess: false,
        createShipperErrorMessage: null,
      };

    case CREATE_SHIPPER_SUCCESS:
      const newShippers = state.shippers ? [...state.shippers] : [];
      newShippers.push(action.shipper);

      return {
        ...state,
        shippers: newShippers,
        creatingShipper: false,
        createShipperSuccess: true,
        createShipperErrorMessage: null,
      };

    case CREATE_SHIPPER_FAILED:
      return {
        ...state,
        creatingShipper: false,
        createShipperSuccess: false,
        createShipperErrorMessage: action.error,
      };

    case UPDATING_SHIPPER:
      return {
        ...state,
        updatingShipper: true,
        updateShipperSuccess: false,
        updateShipperErrorMessage: null,
      };

    case UPDATE_SHIPPER_SUCCESS:
      let updatedShippers =
        state.shippers &&
        state.shippers.map((item: Shipper) => {
          if (item.shipperId !== action.shipper.shipperId) {
            return item;
          }

          return {
            ...action.shipper,
          };
        });

      return {
        ...state,
        updatingShipper: false,
        updateShipperSuccess: true,
        shippers: updatedShippers,
        updateShipperErrorMessage: null,
      };

    case UPDATE_SHIPPER_FAILED:
      return {
        ...state,
        updatingShipper: false,
        updateShipperSuccess: false,
        updateShipperErrorMessage: action.error,
      };

    case DELETING_SHIPPER:
      return {
        ...state,
        deletingShipper: true,
        deleteShipperSuccess: false,
        deleteShipperErrorMessage: null,
      };

    case DELETE_SHIPPER_SUCCESS:
      let nonDeletedShippers =
        state.shippers &&
        state.shippers.filter((item: Shipper) => {
          if (item.shipperId !== action.shipperId) {
            return item;
          }
          return null;
        });

      return {
        ...state,
        deletingShipper: false,
        deleteShipperSuccess: true,
        shippers: nonDeletedShippers,
        deleteShipperErrorMessage: null,
      };

    case DELETE_SHIPPER_FAILED:
      return {
        ...state,
        deletingShipper: false,
        deleteShipperSuccess: false,
        deleteShipperErrorMessage: action.error,
      };

    default:
      return state;
  }
}
