interface CardHomeProps {
  image: string;
  amount: number;
  title: string;
  color: string;
  status: string;
  isSelected?: boolean;
  // eslint-disable-next-line no-unused-vars
  onCardClick?: (status: string) => void;
}

function CardHome({ image, amount, title, color, status, isSelected, onCardClick }: CardHomeProps) {
  return (
    <div
      className={`home-card${isSelected ? ' selected' : ''}`}
      onClick={() => {
        if (onCardClick) onCardClick(status);
      }}
    >
      <div className={`card-icon ${color}`}>
        <img className="icon" src={image} alt="home" />
      </div>
      <div className="card-info">
        <p className="number-info">{amount}</p>
        <p className="text-info">{title}</p>
      </div>
    </div>
  );
}

export default CardHome;
