import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { RootState } from '../../store/config/types';
import * as actions from '../../store/actions/couriers.actions';
import { setToast } from '../../store/actions/toast.actions';
import { googleMapsLink, maskPhone } from '../../utils/string.helpers';
import AddButton from '../../components/CustomButtons/AddButton';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Pagination from '../../components/Pagination/Pagination';
import SpecialInput from '../../components/SpecialInput/Input';
import Spinner from '../../components/Spinner/Spinner';
import TableList from '../../components/TableList/TableList';

const PAGE_LIMIT: number = 12;

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(actions.fetchCouriers()),
  onDeleteCourier: (courierId: number) => dispatch(actions.deleteCourier(courierId)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Couriers({ fetchCouriers, onDeleteCourier, couriers, setToast, loggedIn }: PropsFromRedux) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [courierToDelete, setCourierToDelete] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers, loggedIn]);

  useEffect(() => {
    if (submitted && couriers.deleteCourierSuccess) {
      setSubmitted(false);
      setToast('Courier successfully deleted', 'success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriers.deleteCourierSuccess]);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleCreateCourier = () => history.push('/couriers/create');

  const handleUpdateCourier = (courierId: number) => history.push(`/couriers/${courierId}/update`);

  const handleDeleteCourier = (courierId: number) => setCourierToDelete(courierId);

  const deleteCourier = () => {
    setSubmitted(true);
    setCourierToDelete(null);
    onDeleteCourier(courierToDelete!);
  };

  const handleCloseDialog = () => setCourierToDelete(null);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
    </GridContainer>
  );

  let couriersContent = null;
  if (couriers.couriers) {
    let couriersToShow = couriers.couriers || [];

    if (searchText !== '') {
      couriersToShow = couriersToShow?.filter((courier) => {
        return ['firstName', 'lastName', 'phoneNumber'].some((key) => {
          return (courier as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = couriersToShow.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT).map((courier) => {
      const vehicle = courier.vehicle && !!courier.vehicle.length ? courier.vehicle[0] : null;
      const locationHistory = courier.locationHistory;
      const locationUnavailable = !locationHistory || locationHistory.length === 0;
      const lastLocationWhen = locationUnavailable
        ? 'N/D'
        : moment(locationHistory[0].locationAt).format('MM-DD-YYYY, hh:mm');
      const lastLocationWhere = locationUnavailable ? 'N/D' : googleMapsLink(locationHistory[0]);
      const courierImage = courier.photoUrl
        ? courier.photoUrl
        : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

      return [
        courier.courierId,
        courierImage,
        `${courier.firstName} ${courier.lastName}`,
        maskPhone(courier.phoneNumber),
        vehicle ? `${vehicle.vehicleType} ${vehicle.model} ${vehicle.licensePlate}` : '',
        lastLocationWhere,
        lastLocationWhen,
      ];
    });

    couriersContent = (
      <TableList
        title="Couriers"
        head={['Id', 'Photo', 'Name', 'Phone number', 'Vehicle', 'Last Location', 'Last location: When', '']}
        onEdit={handleUpdateCourier}
        onDelete={handleDeleteCourier}
        edit
        delete
        filters={filters}
        data={options}
        pagination={
          <Pagination
            totalRecords={couriersToShow?.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={handlePageClick}
          />
        }
      />
    );
  } else if (couriers.loadingCouriers) {
    couriersContent = <Spinner />;
  } else {
    couriersContent = <div>No data to show</div>;
  }

  if (!couriers.couriersFetched) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {couriersContent}
      <AddButton onClick={handleCreateCourier} />
      <CustomDialog
        title={'Confirm'}
        description={'¿Are you sure you want to delete this courier?'}
        open={courierToDelete != null}
        onConfirmation={deleteCourier}
        handleClose={handleCloseDialog}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
}

export default connector(Couriers);
