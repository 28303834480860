import { Field } from 'formik';
import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import moment from 'moment-timezone';

interface TimezoneInputProps {
  name: string;
  defaultValue?: string;
  // eslint-disable-next-line no-unused-vars
  setfieldvalue: (name: string, value: string | number) => void;
}
const getTimezoneOffset = (zone: string): string => {
  // const now = moment.utc().unix();
  // const offset = moment.tz.zone(zone)?.offset(now) ?? 0;
  return moment.tz(zone).format('Z');
};
function TimezoneInput({ name, defaultValue, setfieldvalue }: TimezoneInputProps) {
  const timezoneOptions = moment.tz
    .names()
    .map((zone) => ({ label: `${zone}`, value: zone, offset: getTimezoneOffset(zone) }))
    .sort((a, b) => {
      const nA = +a.offset.split(':')[0] * 60 + +a.offset.split(':')[1];
      const nB = +b.offset.split(':')[0] * 60 + +b.offset.split(':')[1];
      return nA - nB;
    });
  return (
    <div style={{ marginTop: 27 }}>
      <Field name={name}>
        {({ field }: any) => (
          <FormControl fullWidth>
            <Autocomplete
              id="country-select-demo"
              fullWidth
              options={timezoneOptions}
              autoHighlight
              getOptionLabel={(option) => option.label}
              onChange={(event, value) => {
                if (value) setfieldvalue(field.name, value?.value);
              }}
              //value={defaultValue ? { label: defaultValue, value: defaultValue } : null}
              defaultValue={timezoneOptions.find((zone) => zone.value.toLowerCase() === defaultValue?.toLowerCase())}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {`${option.offset} ${option.label}`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Field>
    </div>
  );
}

export default TimezoneInput;
