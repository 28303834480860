import { NotificationSettingsState } from '../config/types/notificationSystem.types';
import {
  FETCHING_NOTIFICATION_SETTINGS,
  FETCHING_NOTIFICATION_SETTINGS_FAILED,
  FETCHING_NOTIFICATION_SETTINGS_SUCCESS,
  FETCHING_NOTIFICATION_TEMPLATES,
  FETCHING_NOTIFICATION_TEMPLATES_FAILED,
  FETCHING_NOTIFICATION_TEMPLATES_SUCCESS,
  NotificationSystemActionTypes,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_FAILED,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
} from '../config/ActionTypes';

export const initialState: NotificationSettingsState = {
  isFetching: false,
  fetchErrorMessage: null,
  fetchSuccess: false,
  isUpdating: false,
  updateErrorMessage: null,
  updateSuccess: false,
  isFetchingTemplates: false,
  fetchTemplatesErrorMessage: null,

  notifyOnShipping: false,
  shippingNotificationTemplate: null,
  notifyOnDelivery: false,
  deliveryNotificationTemplate: null,
  // twilioAccountSid: null,
  // twilioAccountAuthToken: null,
  // twilioAccountPhoneNumber: null,

  templates: { shipping: [], delivery: [] },
};

export function NotificationSettingsReducer(
  state = initialState,
  action: NotificationSystemActionTypes,
): NotificationSettingsState {
  switch (action.type) {
    case FETCHING_NOTIFICATION_SETTINGS:
      return {
        ...state,
        isFetching: true,
        fetchSuccess: false,
        fetchErrorMessage: null,
      };

    case FETCHING_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.settings,
        isFetching: false,
        fetchSuccess: true,
        fetchErrorMessage: null,
      };

    case FETCHING_NOTIFICATION_SETTINGS_FAILED:
      return {
        ...state,
        isFetching: false,
        fetchSuccess: false,
        fetchErrorMessage: action.error,
      };

    case UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: false,
        updateErrorMessage: null,
      };

    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.settings,
        isUpdating: false,
        updateSuccess: true,
        updateErrorMessage: null,
      };
    case UPDATE_NOTIFICATION_SETTINGS_FAILED:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: false,
        updateErrorMessage: action.error,
      };
    case FETCHING_NOTIFICATION_TEMPLATES:
      return {
        ...state,
        isFetching: true,
        fetchSuccess: false,
        fetchErrorMessage: null,
      };
    case FETCHING_NOTIFICATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        isFetching: false,
        fetchSuccess: true,
        fetchErrorMessage: null,
      };

    case FETCHING_NOTIFICATION_TEMPLATES_FAILED:
      return {
        ...state,
        isFetching: false,
        fetchSuccess: false,
        fetchErrorMessage: action.error,
      };
    default:
      return state;
  }
}
