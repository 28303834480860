import { CSSProperties, ChangeEvent } from 'react';
import { TextField, FormControl } from '@material-ui/core';

interface DatePickerProps {
  label?: string;
  value: string;
  style?: CSSProperties;
  hiddenLabel?: boolean;
  name?: string;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function DatePicker({ label, value, name, disabled, onChange, style, hiddenLabel }: DatePickerProps) {
  return (
    <FormControl fullWidth>
      <TextField
        type="date"
        label={label}
        name={name ?? label}
        value={value}
        onChange={onChange}
        variant={'outlined'}
        margin="dense"
        disabled={disabled}
        InputLabelProps={{ shrink: true, style }}
        hiddenLabel={hiddenLabel}
      />
    </FormControl>
  );
}

export default DatePicker;
