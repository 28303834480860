import {
  DeliveryOrderIcon,
  HomeIcon,
  SettingsIcon,
  WarehousesIcon,
  TripsIcon,
  // NotificationSettingsIcon,
  CourierIcon,
  CustomersIcon,
  // UserManagerIcon,
  // PaymentIcon,
  VehiclesIcon,
  AnalysisIcon,
  ProsperoIcon,
  FulfillmentIcon,
  WarehouseInventoryIcon,
} from '../assets/images';

import Couriers from '../screens/Couriers/Couriers';
import CouriersForm from '../screens/Couriers/CouriersForm';
import Customers from '../screens/Customers/Customers';
import CustomersForm from '../screens/Customers/CustomersForm';
import Home from '../screens/Home/Home';
import DeliveryOrders from '../screens/DeliveryOrders/DeliveryOrders';
import DeliveryOrderForm from '../screens/DeliveryOrders/DeliveryOrderForm';
import Profile from '../screens/Profile/Profile';
// import VehiclesForm from '../screens/Couriers/VehiclesForm';
import Warehouses from '../screens/Warehouses/Warehouses';
import WarehouseForm from '../screens/Warehouses/WarehouseForm';
import Trips from '../screens/Trips/Trips';
//import TripsForm from '../screens/Trips/TripsForm';
import NotificationSettings from '../screens/NotificationSettings/NotificationSettings';
import CustomerDeliveryOrderDetails from '../screens/DeliveryOrders/CustomerDeliveryOrderDetails';
import TripCreationWizard from '../screens/Trips/TripCreationWizard';
import EditNotificationTemplates from '../screens/NotificationSettings/EditNotificationTemplates';
import UserControl from '../screens/UserControl/UserControl';
import UserControlForm from '../screens/UserControl/UserControlForm';
import TripEdit from '../screens/Trips/TripEdit';
import Payment from '../screens/Payment/Payment';
import Vehicles from '../screens/Vehicles/Vehicles';
import VehiclesForm from '../screens/Vehicles/VehiclesForm';
import Analysis from '../screens/Analysis/Analysis';
import Prospero from '../screens/Prospero/Prospero';
import Fulfillment from '../screens/Fulfillment/Fulfillment';
import InventoryManagement from '../screens/InventoryManagement/InventoryManagement';
import WmsUsers from '../screens/WmsUsers/WmsUsers';

const routes = [
  {
    path: '/home',
    sidebarName: 'Home',
    navbarName: 'Home',
    icon: HomeIcon,
    component: Home,
    hasPermission: true,
  },
  {
    path: '/delivery-orders',
    sidebarName: 'Deliveries',
    navbarName: 'Deliveries',
    icon: DeliveryOrderIcon,
    component: DeliveryOrders,
    permissions: ['shipperAdmin.deliveryOrders.*'],
    hasPermission: true,
  },
  {
    path: '/delivery-orders/create',
    component: DeliveryOrderForm,
    permissions: ['shipperAdmin.deliveryOrders.edit'],
    hasPermission: true,
  },
  {
    path: '/delivery-orders/package-group/:packageGroupId',
    component: DeliveryOrderForm,
    permissions: ['shipperAdmin.deliveryOrders.edit'],
    hasPermission: true,
  },
  {
    path: '/delivery-orders/:deliveryOrderId/detail',
    component: DeliveryOrderForm,
    permissions: ['shipperAdmin.deliveryOrders.view'],
    hasPermission: true,
  },
  {
    path: '/delivery-orders/details/:warehouseId/:customerId/:deliveryExpectedAt',
    component: CustomerDeliveryOrderDetails,
    permissions: ['shipperAdmin.deliveryOrders.view'],
    hasPermission: true,
  },
  {
    path: '/fulfillment',
    sidebarName: 'Fulfillment',
    navbarName: 'Fulfillment',
    icon: FulfillmentIcon,
    component: Fulfillment,
    permissions: ['warehouse.fulfillment'],
    hasPermission: true,
  },
  {
    path: '/fulfillment/:pageMode',
    component: Fulfillment,
    permissions: ['warehouse.fulfillment'],
    hasPermission: true,
  },
  {
    path: '/inventory-management',
    sidebarName: 'Inventory Management',
    navbarName: 'Inventory Management',
    icon: WarehouseInventoryIcon,
    component: InventoryManagement,
    permissions: ['warehouse.inventoryManagement'],
    hasPermission: true,
  },
  {
    path: '/customers',
    sidebarName: 'Customers',
    navbarName: 'Customers',
    icon: CustomersIcon,
    component: Customers,
    permissions: ['shipperAdmin.Customer.*'],
    hasPermission: true,
  },
  {
    path: '/customers/create',
    component: CustomersForm,
    permissions: ['shipperAdmin.Customer.edit'],
    hasPermission: true,
  },
  {
    path: '/customers/:customerId/update',
    component: CustomersForm,
    permissions: ['shipperAdmin.Customer.edit'],
    hasPermission: true,
  },
  {
    path: '/couriers',
    component: Couriers,
    sidebarName: 'Couriers',
    navbarName: 'Couriers',
    icon: CourierIcon,
    permissions: ['shipperAdmin.Courier.*'],
    hasPermission: true,
  },

  {
    path: '/settings/wms-users',
    component: WmsUsers,
    permissions: ['warehouse.userManagement'],
    hasPermission: true,
  },

  {
    path: '/couriers/create',
    component: CouriersForm,
    permissions: ['shipperAdmin.Courier.edit'],
    hasPermission: true,
  },
  {
    path: '/couriers/:courierId/update',
    component: CouriersForm,
    permissions: ['shipperAdmin.Courier.edit'],
    hasPermission: true,
  },
  {
    path: '/vehicles',
    component: Vehicles,
    sidebarName: 'Vehicles',
    navbarName: 'Vehicles',
    icon: VehiclesIcon,
    permissions: ['shipperAdmin.Vehicle.*'],
    hasPermission: true,
  },
  {
    path: '/vehicles/create',
    component: VehiclesForm,
    permissions: ['shipperAdmin.Vehicle.edit'],
    hasPermission: true,
  },
  {
    path: '/vehicles/:vehicleId/update',
    component: VehiclesForm,
    permissions: ['shipperAdmin.Vehicle.edit'],
    hasPermission: true,
  },

  {
    path: '/trips',
    sidebarName: 'Trips',
    navbarName: 'Trips',
    icon: TripsIcon,
    component: Trips,
    permissions: ['shipperAdmin.Trips.*'],
    hasPermission: true,
  },
  {
    path: '/trips/create',
    component: TripCreationWizard,
    permissions: ['shipperAdmin.Trips.edit'],
    hasPermission: true,
  },
  {
    path: '/trips/:date/edit',
    component: TripEdit,
    permissions: ['shipperAdmin.Trips.edit'],
    hasPermission: true,
  },
  {
    path: '/warehouses',
    sidebarName: 'Warehouses',
    navbarName: 'Warehouses',
    icon: WarehousesIcon,
    component: Warehouses,
    permissions: ['shipperAdmin.Warehouse.*'],
    hasPermission: true,
  },
  {
    path: '/warehouses/create',
    component: WarehouseForm,
    permissions: ['shipperAdmin.Warehouse.edit'],
    hasPermission: true,
  },
  {
    path: '/warehouses/:warehouseId/update',
    component: WarehouseForm,
    permissions: ['shipperAdmin.Warehouse.edit'],
    hasPermission: true,
  },
  {
    path: '/notification-settings',
    // sidebarName: 'Notification',
    // navbarName: 'Notification',
    // icon: NotificationSettingsIcon,
    component: NotificationSettings,
    permissions: ['shipperAdmin.Settings.notifications'],
    hasPermission: true,
  },
  {
    path: '/notification-settings/edit-templates',
    component: EditNotificationTemplates,
    permissions: ['shipperAdmin.Settings.notifications'],
    hasPermission: true,
  },
  {
    path: '/user-control',
    // sidebarName: 'User Management',
    // navbarName: 'User Management',
    // icon: UserManagerIcon,
    component: UserControl,
    permissions: ['shipperAdmin.Settings.userManagement'],
    hasPermission: true,
  },
  {
    path: '/user-control/create',
    component: UserControlForm,
    permissions: ['shipperAdmin.userManagement'],
    hasPermission: true,
  },
  {
    path: '/user-control/:shipperUserId/update',
    component: UserControlForm,
    permissions: ['shipperAdmin.userManagement'],
    hasPermission: true,
  },
  {
    path: '/analysis',
    sidebarName: 'Analysis',
    navbarName: 'Analysis',
    icon: AnalysisIcon,
    component: Analysis,
    permissions: ['analysis'],
    hasPermission: true,
  },
  {
    path: '/settings',
    sidebarName: 'Settings',
    navbarName: 'Settings',
    icon: SettingsIcon,
    component: Profile,
    permissions: ['shipperAdmin.Settings'],
    hasPermission: true,
  },
  {
    path: '/payment',
    // sidebarName: 'Payment',
    // navbarName: 'Payment',
    // icon: PaymentIcon,
    component: Payment,
    permissions: ['shipperAdmin.Settings'],
    hasPermission: true,
  },
  {
    path: '/payment/:test',
    component: Payment,
    permissions: ['shipperAdmin.userManagement'],
    hasPermission: true,
  },
  {
    path: '/prospero',
    sidebarName: 'Prospero AI',
    navbarName: 'Prospero',
    icon: ProsperoIcon,
    component: Prospero,
    permissions: ['ai.prospero'],
    hasPermission: true,
  },
];

export default routes;
