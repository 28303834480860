import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

interface TripDndChangeTimeDialogProbs {
  currentTime?: Date;
  tripIndex: number;
  // eslint-disable-next-line
  onSetTime?: (tripIndex: number, time: string) => void;
  onClose?: () => void;
}
export function TripDndChangeTimeDialog({ tripIndex, currentTime, onSetTime, onClose }: TripDndChangeTimeDialogProbs) {
  const [newTime, setNewTime] = useState(moment(currentTime).format('HH:mm'));
  useEffect(() => {
    setNewTime(moment(currentTime).format('HH:mm'));
  }, [tripIndex, currentTime]);
  return (
    <Dialog
      open={tripIndex >= 0}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          Change Trip Start Time
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          type="time"
          variant="standard"
          value={newTime}
          title="New Time"
          onChange={(e) => {
            setNewTime(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="end">
          <Button
            onClick={() => {
              if (moment(currentTime).format('HH:mm') === newTime) {
                onClose && onClose();
              } else {
                onSetTime && onSetTime(tripIndex, newTime);
              }
            }}
          >
            Ok
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
