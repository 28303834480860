import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close-svgrepo-com.svg';
import Spinner from '../Spinner/Spinner';
import { CustomerLocationOrder, PackageGroupPODinfo } from '../../store/config/types';
import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import PODImage from './PODImage';

interface DeliveryOrderPODProps {
  deliveryOrder: CustomerLocationOrder | null;
  open: boolean;
  handleClose: () => void;
}
export default function DeliveryOrderPODView({ deliveryOrder, open, handleClose }: DeliveryOrderPODProps) {
  const [loading, setLoading] = useState(true);
  const [podInfo, setPodInfo] = useState<PackageGroupPODinfo[]>([]);
  useEffect(() => {
    setLoading(true);
    if (deliveryOrder) {
      deliveryOrdersService
        .fetchPackageGroupPODinfo(deliveryOrder.packageGroups[0].packageGroupId ?? 0)
        .then((info) => {
          setLoading(false);
          setPodInfo(info);
        });
    }
  }, [deliveryOrder]);

  const pod = (
    <Grid container key={`pods`} sx={{ p: 2 }}>
      {podInfo.map((info, index) => {
        const interaction = info.interactionType.toLowerCase().replaceAll('_', ' ');
        const title = interaction.charAt(0).toLocaleUpperCase() + interaction.slice(1);
        return (
          <PODImage key={`pod-${index}`} type={info.type} url={info.url} title={`${info.type} Taken (${title})`} />
        );
      })}
    </Grid>
  );
  return (
    <Dialog open={open} title="Proof of Delivery" onClose={() => handleClose()} fullWidth maxWidth="md">
      <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1, alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <h3>Proof of Delivery</h3>
        </Box>
        <Box>
          <IconButton size="small" style={{ width: 32, height: 32 }} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid></Grid>
      {loading ? (
        <div style={{ minHeight: 120 }}>
          <Spinner />
        </div>
      ) : (
        pod
      )}
    </Dialog>
  );
}
