
import {
  DeliveryOrdersActionTypes,
  FETCHING_CUSTOMERS_DELIVERY_ORDERS,
  FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS,
  FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED,
} from '../config/ActionTypes';
import {
//   CustomerOrders,
  CustomersDeliveryOrdersState,
} from '../config/types/deliveryOrders.types';

export const initialState: CustomersDeliveryOrdersState = {
  loadingCustomersDeliveryOrders: false,
  customersDeliveryOrdersFetched: false,
  customersDeliveryOrders: null,
  customerDeliveryOrdersErrorMessage: null,
//   creatingDeliveryOrder: false,
//   createDeliveryOrderSuccess: false,
//   createDeliveryOrderErrorMessage: null,
//   parsingCSV: false,
//   parsedCSVLines: null,
//   parseCSVSuccess: false,
//   parseCSVErrorMessage: null,
};

export function customersDeliveryOrdersReducer(state = initialState, action: DeliveryOrdersActionTypes): CustomersDeliveryOrdersState {
  switch (action.type) {
    case FETCHING_CUSTOMERS_DELIVERY_ORDERS:
      return {
        ...state,
        loadingCustomersDeliveryOrders: true,
        customersDeliveryOrders: null,
        customerDeliveryOrdersErrorMessage: null,
      };

    case FETCH_CUSTOMERS_DELIVERY_ORDERS_SUCCESS:
      return {
        ...state,
        loadingCustomersDeliveryOrders: false,
        customersDeliveryOrdersFetched: true,
        customersDeliveryOrders: action.customerDeliveryOrders,
        customerDeliveryOrdersErrorMessage: null,
      };

    case FETCH_CUSTOMERS_DELIVERY_ORDERS_FAILED:
      return {
        ...state,
        loadingCustomersDeliveryOrders: false,
        customersDeliveryOrdersFetched: true,
        customersDeliveryOrders: null,
        customerDeliveryOrdersErrorMessage: action.error,
      };

    
    default:
      return state;
  }
}
