import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';
import { CreditCard } from '@material-ui/icons';

export default function PaymentForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [inProgress, setInProgress] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    setInProgress(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    setInProgress(false);

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <PaymentElement />
      </Grid>
      {errorMessage && (
        <Grid item mt={4} xs={12}>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      )}
      <Grid xs={12} item mt={4} textAlign="right">
        <Button
          onClick={handleSubmit}
          disabled={inProgress}
          variant="contained"
          color="success"
          startIcon={inProgress ? <CircularProgress size={16} /> : <CreditCard />}
        >
          Add
        </Button>
      </Grid>
    </form>
  );
}
