import { ToastState } from '../config/types/toast.types';
import { SET_TOAST, REMOVE_TOAST, ToastActionTypes } from '../config/ActionTypes';

export const initialState: ToastState = {
  show: false,
  message: '',
  messageType: 'success',
};

export function toastReducer(state = initialState, action: ToastActionTypes): ToastState {
  switch (action.type) {
    case SET_TOAST:
      return {
        ...state,
        show: true,
        message: action.message,
        messageType: action.messageType,
      };

    case REMOVE_TOAST:
      return {
        ...initialState,
        show: false,
      };

    default:
      return state;
  }
}
