import { Grid, withStyles } from '@material-ui/core';

const style = (theme) => ({
  grid: {
    margin: '16px 0 !important',
    width: 'unset',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0 !important',
    },
  },
  noMarginTop: {
    marginTop: '0 !important',
  },
});

function GridContainer({ classes, children, noMarginTop, ...props }) {
  return (
    <Grid container {...props} className={`${classes.grid} ${noMarginTop && classes.noMarginTop}`}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
