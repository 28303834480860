import { Alert, Button, Grid, IconButton, Link, TextField } from '@mui/material';
import { AIChunkStruct, AIKnowledgeStruct, AIMessageStruct } from './prospero.types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useState } from 'react';
import { Add, DataUsage, Delete } from '@material-ui/icons';
import ProsperoService from '../../services/prospero.service';

export interface AIRAGKnowledgeManagerStruct {
  message?: AIMessageStruct;
  question?: string;
  knowledgeType?: string;
}

export function AIRAGKnowledgeManager({
  message,
  question = '',
  knowledgeType = 'UNKNOWN',
}: AIRAGKnowledgeManagerStruct) {
  const hasKnowledge = (message?.ragChunks?.length ?? 0) > 0;

  let content: string =
    hasKnowledge && message?.ragChunks ? message?.ragChunks[0].aiKnowledge?.content : message?.originalResponse ?? '';

  try {
    content = JSON.stringify(JSON.parse(content), undefined, 2);
  } catch {
    // console.log('content is not structured');
  }
  // content = `Question: ${question}\nSolution:` + content;

  const chunks = message?.ragChunks ?? [];
  const metaData = { aiChatMessageId: message?.aiChatMessageId };
  const [newChunk, setNewChunk] = useState(question);
  const [processing, setProcessing] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [textContent, setTextContent] = useState<string>(content);

  const aiKnowledgeId = chunks?.length ? chunks[0].aiKnowledge?.aiKnowledgeId ?? undefined : undefined;

  const updateKnowledge = () => {
    if (chunks?.length && chunks[0].aiKnowledge.aiKnowledgeId) {
      setProcessing(true);
      const aiKnowledgeId = chunks[0].aiKnowledge.aiKnowledgeId;
      ProsperoService.ragUpdateKnowledge(aiKnowledgeId, textContent)
        .then((knowledge: AIKnowledgeStruct) => {
          setTextContent(knowledge.content);
          chunks?.forEach((chunk) => (chunk.aiKnowledge = knowledge));
          setEditContent(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      setEditContent(false);
    }
  };

  const addChunktoKnowledge = async (chunk: AIChunkStruct) => {
    setProcessing(true);
    chunk.aiKnowledge.content = textContent;
    ProsperoService.ragAddChunkToKnowledge(chunk)
      .then((chunk) => {
        chunks?.unshift(chunk);
        setNewChunk('');
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const removeChunkFromKnowledge = async (chunk: AIChunkStruct) => {
    if (!chunks || chunks?.length <= 1) return false;
    setProcessing(true);
    ProsperoService.ragRemoveChunkFromKnowledge(chunk)
      .then((result) => {
        if (result) {
          const chunkIndex = chunks?.findIndex((ch) => ch.aiChunkId === chunk.aiChunkId);
          if (chunkIndex >= 0) {
            chunks?.splice(chunkIndex, 1);
          }
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const handleAddChunk = () => {
    if (newChunk) {
      addChunktoKnowledge({
        chunk: newChunk,
        metaData,
        aiKnowledge: {
          aiKnowledgeId,
          content: JSON.stringify(content),
          type: knowledgeType,
        },
      });
    }
  };
  const contentChangeHandler = (value: string) => {
    setTextContent(value);
  };

  const handleKnowledgeEdit = () => {
    if (editContent) {
      updateKnowledge();
    } else {
      setEditContent(true);
    }
  };

  return (
    <Grid container flexDirection="row" spacing={2} flexWrap="nowrap">
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item>
            <Grid container flexDirection="row">
              <Grid item>Knowledge: </Grid>
              <Grid item flexGrow={1} ml={2}>
                <Link style={{ cursor: 'pointer' }} onClick={handleKnowledgeEdit}>
                  {editContent ? 'Save' : 'Edit'}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ maxWidth: '50vw', position: 'relative' }}>
            <textarea
              onChange={(e) => contentChangeHandler(e.target.value)}
              disabled={processing}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: editContent ? 'block' : 'none',
              }}
            >
              {textContent}
            </textarea>

            <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers customStyle={{ maxHeight: '80vh' }}>
              {textContent}
            </SyntaxHighlighter>
          </Grid>
        </Grid>
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container flexDirection="column">
          <Grid item>Chunks:</Grid>
          <Grid item flexGrow={1}>
            <Grid container flexDirection="column">
              <Grid item key="chunk-new">
                <TextField
                  autoComplete="false"
                  multiline
                  fullWidth
                  style={{ width: '100%' }}
                  rows={2}
                  value={newChunk}
                  onChange={(e) => setNewChunk(e.target.value)}
                  disabled={processing || editContent}
                />
              </Grid>
              <Grid item key="chunk-new-btn">
                <Button style={{ width: '100%' }} disabled={processing || editContent} onClick={handleAddChunk}>
                  <Add />
                </Button>
              </Grid>
              {chunks?.map((chunk) => (
                <Grid item key={`chunk-${chunk.aiChunkId}`}>
                  <Alert
                    icon={<DataUsage />}
                    severity="info"
                    action={
                      <IconButton
                        color="error"
                        disabled={processing || editContent || chunks?.length <= 1}
                        onClick={() => removeChunkFromKnowledge(chunk)}
                      >
                        <Delete />
                      </IconButton>
                    }
                  >
                    {chunk.chunk}
                  </Alert>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
