import { PaymentMethod } from '@stripe/stripe-js';
import axios from './axios';

async function getUnpaid() {
  const res = await axios.get(`/payment/get-unpaid`);
  return res.data.data;
}

async function getStripePublishableKey(): Promise<string> {
  const res = await axios.get(`/payment/get-publishable-key`);
  return res.data.data;
}

async function getSetupClientSecret(): Promise<{ clientSecret: string; id: string }> {
  const res = await axios.get(`/payment/get-setup-client-secret?returnUrl=` + decodeURI(window.location.href));
  return res.data.data;
}
async function cofirmSetupIntent(id: string): Promise<{ status: string; id: string }> {
  const res = await axios.get(`/payment/confirm-setup-intent?id=${id}`);
  return res.data.data;
}
async function getPaymentMethods(): Promise<PaymentMethod[]> {
  const res = await axios.get(`/payment/get-payment-methods`);
  return res.data.data;
}

async function getCustomerPortalLink(): Promise<string> {
  const res = await axios.get(`/payment/get-customer-portal-link?returnUrl=` + decodeURI(window.location.href));
  return res.data.data;
}

async function deletePaymentMethod(id: string) {
  const res = await axios.delete(`/payment/payment-method`, { data: { id } });
  return res;
}

async function getSetupSession() {
  const res = await axios.get(`/payment/get-setup-session?url=` + decodeURI(window.location.href));
  return res.data.data;
}

async function chargeAndHold(amount?: number, paymentMethod?: PaymentMethod, description?: string) {
  if (!amount) return { result: true };
  if (paymentMethod) {
    try {
      const res = await axios.post(`/payment/charge-and-hold`, {
        amount,
        paymentMethodId: paymentMethod.id,
        description,
      });
      return { result: res.data.result, data: res.data.data };
    } catch (e: any) {
      console.log(e.message);
      return { result: false };
    }
  } else {
    return { result: false };
  }
}
export const PaymentService = {
  getUnpaid,
  getStripePublishableKey,
  getSetupClientSecret,
  cofirmSetupIntent,
  getPaymentMethods,
  deletePaymentMethod,
  getCustomerPortalLink,
  getSetupSession,
  chargeAndHold,
};
