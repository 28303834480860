import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function CustomDialog({ open, handleClose, title, description, cancelButtonText, onConfirmation, okButtonText }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirmation} color="primary" autoFocus>
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
