import { useEffect, useState } from 'react';
import { PaymentService } from '../../services/payment.service';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CircularProgress, Grid } from '@mui/material';
import PaymentForm from './PaymentForm';

// const mapStateToProps = (state: RootState) => ({
//   auth: state.auth,
// });
// const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
//   setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
// });

// const connector = connect(mapStateToProps, mapDispatchToProps);

// type PropsFromRedux = ConnectedProps<typeof connector>;

export default function PaymentCreate() {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    if (!stripe) {
      try {
        PaymentService.getStripePublishableKey().then((key) => {
          loadStripe(key).then((loadedStripe) => {
            setStripe(loadedStripe);
          });
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (!clientSecret) {
      PaymentService.getSetupClientSecret().then((secret) => {
        setClientSecret(secret.clientSecret);
      });
    }
  }, []);

  return (
    <div className="payment-page">
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {!stripe || !clientSecret ? (
          <Grid item justifyContent="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container>
            <Elements stripe={stripe} options={{ clientSecret }}>
              <PaymentForm />
            </Elements>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
