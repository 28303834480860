import { FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import { LoggedAccount } from '../../store/config/types';
import { hasPermission } from '../../utils/permissions';
import { AIChatSession } from './prospero.types';
import { Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';

export interface ChatHeaderProps {
  user: LoggedAccount | null;
  conversationHeader?: AIChatSession;
  debugMode?: boolean;
  // eslint-disable-next-line
  changeDebugMode?: (checked: boolean) => void;
  onSettingClick?: () => void;
}

export function ChatHeader({ user, debugMode, changeDebugMode, conversationHeader, onSettingClick }: ChatHeaderProps) {
  const adminMode = hasPermission(user, ['AI.adminMode']);

  return (
    <Grid container alignItems="center">
      <Grid item flexGrow={1}>
        {adminMode ? (
          conversationHeader && <Typography variant="body1">Session#{conversationHeader.aiChatSessionId}</Typography>
        ) : (
          <Typography variant="h5">{conversationHeader?.title}</Typography>
        )}
      </Grid>
      {adminMode && (
        <>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={debugMode}
                  onChange={(e, checked) => {
                    if (changeDebugMode) changeDebugMode(checked);
                  }}
                />
              }
              label="Debug Mode"
            />
          </Grid>
          <Grid>
            <IconButton color="primary" onClick={onSettingClick}>
              <Settings />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
