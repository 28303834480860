import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { FormControl, MenuItem } from '@material-ui/core';
import { RootState } from '../../store/config/types';
import { Courier, Vehicle } from '../../store/config/types/couriers.types';
import {
  createCourier,
  updateCourier,
  fetchCouriers,
  setCourier,
  clearCouriersState,
} from '../../store/actions/couriers.actions';
import * as actions from '../../store/actions/toast.actions';
// import { AccountType } from '../../store/config/enums/bank.enum';
import { VehicleEnum } from '../../store/config/enums/vehicle.enum';
import schemas from '../../utils/schemas';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';
import SelectField from '../../components/SpecialInput/SelectField';
import CostsForm from '../../components/Cost/CostsForm';
import { hasPermission } from '../../utils/permissions';
import { VehiclesService } from '../../services/vehicles.service';
import { Link } from '@mui/material';

interface CourierParams {
  courierId?: string;
}

interface CourierValues {
  courierId?: number;
  email?: string;
  password?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  homeAddress?: string;
  drivingLicenseNumber?: number;
  photoUrl?: string;
  qrCode?: number;
  preferredServiceAreaLatitude?: string;
  preferredServiceAreaLongitude?: string;

  vehicleId?: number;
  vehicleType?: VehicleEnum;
  licensePlate: string;
  model: string;
  color: string;
  capacity?: number;
}

const mapStateToProps = (state: RootState) => ({
  couriers: state.couriers,
  courier: state.couriers.courier,
  user: state.auth?.account,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateCourier: (courier: Courier, courierPhoto: File) => dispatch(createCourier(courier, courierPhoto)),
  onUpdateCourier: (courierId: number, courier: Courier, courierPhoto: File) =>
    dispatch(updateCourier(courierId, courier, courierPhoto)),
  fetchCouriers: () => dispatch(fetchCouriers()),
  setCourier: (courierId: string) => dispatch(setCourier(courierId)),
  unsetCourier: () => dispatch(setCourier()),
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
  clearCouriersState: () => dispatch(clearCouriersState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CourierForm = ({
  onCreateCourier,
  onUpdateCourier,
  unsetCourier,
  setToast,
  couriers,
  clearCouriersState,
  user,
  fetchCouriers,
}: PropsFromRedux) => {
  const { courierId } = useParams<CourierParams>();
  const editMode: boolean = Boolean(courierId);
  const history = useHistory();
  const [courierPhoto, setCourierPhoto] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState<Courier | null>(null);
  const [address, setAddress] = useState<string>(selectedCourier ? selectedCourier.homeAddress! : '');
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [newVehicle, setNewVehicle] = useState(true);
  // const [defaultVehicle, setDefaultVehicle] = useState(
  //   selectedCourier && selectedCourier.vehicle.length ? selectedCourier.vehicle[0].vehicleId : null,
  // );
  useEffect(() => {
    VehiclesService.fetchVehicles().then((fetchedVehicles) => {
      if (fetchedVehicles && fetchedVehicles.length) {
        setVehicles(fetchedVehicles);
        setNewVehicle(false);
      }
    });
  }, []);
  useEffect(() => {
    if (editMode && selectedCourier === null && couriers.couriers && courierId) {
      const editedCourier = couriers.couriers.filter((c: Courier) => c.courierId === Number(courierId))[0];
      setSelectedCourier(editedCourier);
      setAddress(editedCourier.homeAddress!);
    }
  }, [editMode, selectedCourier, couriers.couriers, courierId, setSelectedCourier]);

  useEffect(() => {
    if (submitting) {
      if (couriers.createCourierSuccess || couriers.updateCourierSuccess) {
        setSubmitting(false);
        unsetCourier();
        setToast(`Courier successfully ${couriers.createCourierSuccess ? 'created' : 'updated'}`, 'success');
        clearCouriersState();
        fetchCouriers();
        history.push('/couriers');
      } else if (couriers.createCourierErrorMessage || couriers.updateCourierErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${couriers.createCourierErrorMessage ? 'creating' : 'updating'} courier`,
          'danger',
        );
        clearCouriersState();
      }
    }
  }, [
    submitting,
    couriers.createCourierSuccess,
    couriers.updateCourierSuccess,
    couriers.createCourierErrorMessage,
    couriers.updateCourierErrorMessage,
    history,
    setSubmitting,
    setToast,
    unsetCourier,
    clearCouriersState,
  ]);

  // const bankInputChangedHandler = (setFieldValue: any, e: any) => setFieldValue('accountType', e.target.value);

  const valueChangedHandler = (setFieldValue: any, e: any) => setFieldValue('vehicleType', e.target.value);

  const onFileChange = (event: any) => setCourierPhoto(event.target.files[0]);

  const handleGoBack = () => history.push('/couriers');

  const submitForm = async (values: CourierValues) => {
    const courierRequest: Courier = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      homeAddress: values.homeAddress,
      password: values.password,
      vehicle: newVehicle
        ? [
            [VehicleEnum.MOTORCYCLE, VehicleEnum.CAR, VehicleEnum.TRUCK].includes(values.vehicleType!)
              ? {
                  vehicleType: values.vehicleType!,
                  licensePlate: values.licensePlate,
                  model: values.model,
                  color: values.color,
                  capacity: values.capacity!,
                }
              : {
                  vehicleType: values.vehicleType!,
                },
          ]
        : [{ vehicleId: values.vehicleId }],
      bankAccountInfo: [],
      online: false,
    };

    setSubmitting(true);

    if (editMode) {
      await onUpdateCourier(Number(courierId), courierRequest, courierPhoto!);
    } else {
      await onCreateCourier(courierRequest, courierPhoto!);
    }
  };

  if (editMode && !selectedCourier) {
    return <Spinner />;
  }

  return (
    <div className="courier-form">
      <Card profile>
        <CardHeader color="primary" className="card-header">
          {editMode && selectedCourier ? (
            <h4>{`${selectedCourier.firstName} ${selectedCourier.lastName}`}</h4>
          ) : (
            <h4>New courier</h4>
          )}
        </CardHeader>
        <CardBody profile>
          <Formik
            initialValues={{
              firstName: selectedCourier ? selectedCourier.firstName! : '',
              lastName: selectedCourier ? selectedCourier.lastName! : '',
              phoneNumber: selectedCourier ? selectedCourier.phoneNumber! : '',
              password: selectedCourier ? selectedCourier.password! : '',
              email: selectedCourier ? selectedCourier.email! : '',
              homeAddress: selectedCourier ? selectedCourier.homeAddress! : '',
              vehicleId: selectedCourier && selectedCourier.vehicle.length ? selectedCourier.vehicle[0].vehicleId : 0,
              vehicleType:
                selectedCourier && !!selectedCourier.vehicle?.length
                  ? selectedCourier.vehicle[0]?.vehicleType!
                  : undefined,
              licensePlate:
                selectedCourier && !!selectedCourier.vehicle?.length ? selectedCourier.vehicle[0]?.licensePlate! : '',
              model: selectedCourier && !!selectedCourier.vehicle?.length ? selectedCourier.vehicle[0]?.model! : '',
              color: selectedCourier && !!selectedCourier.vehicle?.length ? selectedCourier.vehicle[0]?.color! : '',
              capacity:
                selectedCourier && !!selectedCourier.vehicle?.length
                  ? selectedCourier.vehicle[0]?.capacity!
                  : undefined,
              bank:
                selectedCourier && !!selectedCourier.bankAccountInfo?.length
                  ? selectedCourier.bankAccountInfo[0]?.bank!
                  : '',
              accountNumber:
                selectedCourier && !!selectedCourier.bankAccountInfo?.length
                  ? selectedCourier.bankAccountInfo[0]?.accountNumber!
                  : undefined,
              accountType:
                selectedCourier && !!selectedCourier.bankAccountInfo?.length
                  ? selectedCourier.bankAccountInfo[0]?.accountType!
                  : undefined,
              accountHoldersName:
                selectedCourier && !!selectedCourier.bankAccountInfo?.length
                  ? selectedCourier.bankAccountInfo[0]?.accountHoldersName!
                  : '',
              accountRoutingNumber:
                selectedCourier && !!selectedCourier.bankAccountInfo?.length
                  ? selectedCourier.bankAccountInfo[0]?.accountRoutingNumber!
                  : '',
            }}
            validationSchema={editMode ? schemas.CourierUpdateSchema : schemas.CourierCreateSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12}>
                    <div className="image-form-container">
                      <div className="profile-pic-container-large">
                        <img
                          className="profile-pic-large"
                          src={
                            courierPhoto
                              ? URL.createObjectURL(courierPhoto)
                              : selectedCourier?.photoUrl
                              ? selectedCourier.photoUrl
                              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                          }
                        />
                        {courierPhoto ? <span>Unsaved changes</span> : null}
                      </div>
                      <Button variant="contained" component="label">
                        Upload File
                        <input accept="image/*" type="file" hidden onChange={onFileChange} />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
                <h3 className="section-title">Personal info</h3>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="First name" name="firstName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Last name" name="lastName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput placeholder="Phone number" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Email" name="email" type="email" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={false}
                    inputName={'homeAddress'}
                    placeholderName={'Home address'}
                    inputSize={6}
                  />
                  <GridItem xs={12} sm={6}>
                    {!editMode && <InputField placeholder="Password" name="password" type="text" />}
                  </GridItem>
                </GridContainer>
                {newVehicle && (
                  <>
                    <h3 className="section-title">Default vehicle</h3>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <FormControl variant="standard" fullWidth className="vehicle-type-select">
                          <SelectField
                            placeholder="Vehicle type"
                            name="vehicleType"
                            values={values.vehicleType}
                            defaultValue=""
                            onChange={(e: React.ChangeEvent): void => valueChangedHandler(setFieldValue, e)}
                          >
                            <MenuItem value={VehicleEnum.CAR}>{VehicleEnum[VehicleEnum.CAR]}</MenuItem>
                            <MenuItem value={VehicleEnum.TRUCK}>{VehicleEnum[VehicleEnum.TRUCK]}</MenuItem>
                            <MenuItem value={VehicleEnum.MOTORCYCLE}>{VehicleEnum[VehicleEnum.MOTORCYCLE]}</MenuItem>
                            <MenuItem value={VehicleEnum.BIKE}>{VehicleEnum[VehicleEnum.BIKE]}</MenuItem>
                            <MenuItem value={VehicleEnum.WALKER}>{VehicleEnum[VehicleEnum.WALKER]}</MenuItem>
                          </SelectField>
                        </FormControl>
                      </GridItem>

                      {[VehicleEnum.MOTORCYCLE, VehicleEnum.CAR, VehicleEnum.TRUCK].includes(values.vehicleType!) && (
                        <>
                          <GridItem xs={12} sm={6}>
                            <InputField placeholder="License plate" name="licensePlate" />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <InputField placeholder="Vehicle model" name="model" type="text" />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <InputField placeholder="Vehicle color" name="color" type="text" />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <InputField placeholder="Vehicle capacity" name="capacity" type="text" />
                          </GridItem>
                        </>
                      )}
                      <GridItem xs={12} style={{ textAlign: 'left', marginTop: 12 }}>
                        <Link
                          onClick={() => {
                            setNewVehicle(false);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Cancel vehicle creation
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
                {!newVehicle && (
                  <>
                    <h3 className="section-title">Default vehicle</h3>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <FormControl variant="standard" fullWidth className="vehicle-type-select">
                          <SelectField
                            placeholder="Default Vehicle"
                            name="vehicleId"
                            value={values.vehicleId}
                            defaultValue=""
                          >
                            <MenuItem value={0}> - </MenuItem>
                            {vehicles.map((vehicle) => (
                              <MenuItem value={vehicle.vehicleId} key={`vehicle-${vehicle.vehicleId}`}>
                                {`${vehicle.vehicleType ? VehicleEnum[vehicle.vehicleType] : ''} ${
                                  vehicle.model ?? ''
                                } ${vehicle.color ?? ''} ${vehicle.licensePlate}`}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} style={{ textAlign: 'left', marginTop: 12 }}>
                        <Link
                          onClick={() => {
                            setNewVehicle(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Create a new vehicle
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {`${editMode ? 'Update' : 'Create'} courier`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
          {editMode && hasPermission(user, ['analyze.cost']) && (
            <GridContainer>
              <CostsForm subject="courier" subjectId={`${selectedCourier?.courierId ?? 0}`} />
            </GridContainer>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default connector(CourierForm);
