import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AssemblyBuild, ProductWithItemPendingFulfillmentStruct } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { AssemblyBuildService } from '../../services/warehouseWork.service';
import { strOrAlt } from '../../utils/string.helpers';
import { AssemblyBuildStatus } from '../../store/config/enums/warehouseWork.enum';
import moment from 'moment-timezone';

export interface CreateAssemblyWorkorderDialogProps {
  productWithItemPendingFulfillment?: ProductWithItemPendingFulfillmentStruct;
  defaultCount: number;
  onClose: () => void;
  // eslint-disable-next-line
  onNotify?: (message: string, type: string) => void;
}
interface AssemblyInfo {
  warehouseId: number;
  warehouseName: String;
  quantity: number;
}
export default function CreateAssemblyWorkorderDialog({
  onClose,
  onNotify,
  productWithItemPendingFulfillment,
}: CreateAssemblyWorkorderDialogProps) {
  const [assemblyCount, setAssemblyCount] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [warehouseNeededQuantity, setWarehouseNeededQuantity] = useState<AssemblyInfo[]>([]);
  const [warehouseId, setWarehouseId] = useState<number>();
  const [lotName, setLotName] = useState<string>();
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [totalRequired, setTotalRequired] = useState<number>();
  const [totalPending, setTotalPending] = useState<number>();

  useEffect(() => {
    const warehouseQuantities: AssemblyInfo[] = [];
    if (productWithItemPendingFulfillment?.productId) {
      AssemblyBuildService.getAssemblyTotalQuantities(productWithItemPendingFulfillment?.productId).then(
        (quantities) => {
          const { totalPendingBuildQuantity, totalRequiredQuantity } = quantities;
          setTotalPending(totalPendingBuildQuantity);
          setTotalRequired(totalRequiredQuantity);
          // const qty = Math.max((totalRequiredQuantity ?? 0) - (totalPendingBuildQuantity ?? 0), assemblyCount, 1);
          // setAssemblyCount(qty);
        },
      );
    }
    productWithItemPendingFulfillment?.orderItems.forEach((item) => {
      if (item.additionalData.warehouse) {
        var wIndex = warehouseQuantities.findIndex((w) => w.warehouseId === item.additionalData.warehouse.warehouseId);
        if (wIndex < 0) {
          warehouseQuantities.push({
            warehouseId: item.additionalData.warehouse.warehouseId,
            warehouseName: item.additionalData.warehouse.name,
            quantity: item.quantity,
          });
        } else {
          warehouseQuantities[wIndex].quantity += item.quantity;
        }
      }
    });
    setWarehouseNeededQuantity(warehouseQuantities);
    if (warehouseQuantities.length) {
      setWarehouseId(warehouseQuantities[0].warehouseId);
      // setAssemblyCount(warehouseQuantities[0].quantity);
    }
    setLotName('');
    if (productWithItemPendingFulfillment) {
      const count = productWithItemPendingFulfillment.orderItems.reduce(
        (sum, oItem) => sum + (oItem.quantity - oItem.quantityCommitted),
        0,
      );

      setAssemblyCount(count);
    } else {
      setAssemblyCount(1);
    }
  }, [productWithItemPendingFulfillment]);

  const handleClose = () => {
    if (!processing) onClose();
  };

  const handleSubmit = () => {
    setProcessing(true);
    AssemblyBuildService.createAssemblyWorkorder(
      warehouseId ?? 0,
      productWithItemPendingFulfillment?.productId ?? 0,
      assemblyCount,
      lotName ?? '',
      date,
    )
      .then((result) => {
        console.log(result);

        const assembly: AssemblyBuild = result.assemblyBuild;
        const errors: string[] = result.errors;
        if (errors.length) {
          console.error(errors);
          if (onNotify)
            onNotify(errors.join('\n'), assembly.status === AssemblyBuildStatus.PENDING_API ? 'warning' : 'success');
        } else {
          const message = `Assembly workorder successfuly created${
            assembly.status === AssemblyBuildStatus.PENDING_API ? ' and added to API Call Queue.' : '.'
          }`;
          if (onNotify) onNotify(message, assembly.status === AssemblyBuildStatus.PENDING_API ? 'warning' : 'success');
          onClose();
        }
      })
      .catch((err: any) => {
        if (onNotify) onNotify(err.message, 'danger');
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const handleWarehouseChange = (e: any) => {
    const wId: number = +e.target.value;
    setWarehouseId(wId);
    // const q = warehouseNeededQuantity.find((w) => w.warehouseId == wId)?.quantity ?? 1;
    // setAssemblyCount(q);
  };

  return (
    <Dialog open={!!productWithItemPendingFulfillment} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">Create Assembly Workorder for</Typography>
        <Typography variant="h5">
          {strOrAlt(productWithItemPendingFulfillment?.description, productWithItemPendingFulfillment?.name)}
        </Typography>
        <Grid>
          <Table size="small" style={{ display: 'inline' }}>
            <TableRow>
              <TableCell>Total Required Quantity</TableCell>
              <TableCell>{totalRequired ?? '...'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Pending Build</TableCell>
              <TableCell>{totalPending ?? '...'}</TableCell>
            </TableRow>
          </Table>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container pt={2} spacing={1}>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel id="assembly-Warehouse">Warehouse</InputLabel>
              <Select
                labelId="assembly-quantity"
                value={warehouseId}
                label="Warehouse"
                onChange={handleWarehouseChange}
              >
                {warehouseNeededQuantity.map((w, index) => (
                  <MenuItem value={w.warehouseId} key={index}>
                    {w.warehouseName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              label="Count"
              name="assemblyCount"
              value={assemblyCount}
              onChange={(e) => setAssemblyCount(+e.target.value)}
              disabled={processing}
            />
          </Grid>
        </Grid>
        <Grid container pt={2} spacing={1}>
          <Grid item pt={2} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Lot ID"
              name="lotName"
              value={lotName}
              onChange={(e) => setLotName(e.target.value)}
              disabled={processing}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              label="Date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              disabled={processing}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} pl={2}>
            {processing && <CircularProgress size={32} />}
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={processing || !assemblyCount || !lotName}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
