import { withStyles } from '@material-ui/core/styles';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import SortableTable from '../Table/SortableTable';
import Table from '../Table/Table';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const TableList = ({ classes, ...props }) => {
  return (
    <GridContainer>
      {props.filters ? <GridItem xs={12}>{props.filters}</GridItem> : null}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          {props.title ? (
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{props.title}</h4>
            </CardHeader>
          ) : null}
          <CardBody>
            {props.sortable ? (
              <SortableTable
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onSaveSort={props.onSaveSort}
              />
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            )}
            {props.pagination}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(styles)(TableList);
