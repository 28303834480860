import { TripWithStopsDetails } from '../store/config/types';
import {
  DSPQuote,
  DSPAvailabilityRequest,
  DeliveryServiceProvider,
  DSPAcceptQuoteRequest,
  DSPTrip,
} from '../store/config/types/deliveryServiceProvider.types';
import axios from './axios';

async function getTerms(deliveryOrdersServiceId: number): Promise<string> {
  const res = await axios.get(`deliveryServiceProvider/${deliveryOrdersServiceId}/terms`);
  return res.data.data.terms ?? '';
}
async function getRequiredInfo(DSP: DeliveryServiceProvider, trip: TripWithStopsDetails) {
  const tripRequest: DSPAvailabilityRequest = {
    warehouseLocationId: trip.tripStops[0].location.locationId,
    deliveryServiceProviderId: DSP.deliveryServiceProviderId,
    tripStartsAt: trip.startsAt,
    requiredInfo: {},
    stopsInfo: trip.tripStops.map((stop) => ({
      firstPackage: stop.stopPackages ? stop.stopPackages[0].packageId : 0,
      arrive: stop.routerArrivalAt,
    })),
  };
  const res = await axios.post('deliveryServiceProvider/get-required-info', tripRequest);
  return res.data.data;
}

async function getSupportInfo(dspReferenceId: string): Promise<string> {
  const res = await axios.get(`deliveryServiceProvider/get-support-info/${dspReferenceId}`);
  return res.data.data ?? '';
}

async function getAvailability(DSP: DeliveryServiceProvider, trip: TripWithStopsDetails, requiredInfo: any) {
  const tripRequest: DSPAvailabilityRequest = {
    warehouseLocationId: trip.tripStops[0].location.locationId,
    deliveryServiceProviderId: DSP.deliveryServiceProviderId,
    tripStartsAt: trip.startsAt,
    requiredInfo: { ...requiredInfo },
    stopsInfo: trip.tripStops.map((stop) => ({
      firstPackage: stop.stopPackages ? stop.stopPackages[0].packageId : 0,
      arrive: stop.routerArrivalAt,
    })),
  };
  console.log(tripRequest);
  const res = await axios.post('deliveryServiceProvider/availability', tripRequest);
  return res.data.data as DSPQuote;
}

async function submitTrip(trip: TripWithStopsDetails): Promise<DSPTrip> {
  if (trip.DSP && trip.dspRefrenceId) {
    const acceptRequest: DSPAcceptQuoteRequest = {
      deliveryServiceProviderId: trip.DSP.deliveryServiceProviderId,
      refrenceId: trip.dspRefrenceId,
      warehouseLocationId: trip.tripStops[0].location.locationId,
      stopsInfo: trip.tripStops.map((stop) => ({
        firstPackage: stop.stopPackages ? stop.stopPackages[0].packageId : 0,
        arrive: stop.routerArrivalAt,
      })),
      tripStartsAt: trip.startsAt,
      requiredInfo: trip.dspRequiredInfo,
    };
    const res = await axios.post('deliveryServiceProvider/acceptquote', acceptRequest);
    return res.data.data;
  }
  return { status: 'UNKNOWN', refrenceId: trip.dspRefrenceId };
}

async function cancelTrip(trip: TripWithStopsDetails): Promise<DSPTrip> {
  if (trip.DSP && trip.dspRefrenceId) {
    const request: DSPAcceptQuoteRequest = {
      deliveryServiceProviderId: trip.DSP.deliveryServiceProviderId,
      refrenceId: trip.dspRefrenceId,
    };
    const res = await axios.post('deliveryServiceProvider/cancelquote', request);
    if (res.status === 200) return res.data.data;
  }
  return { status: 'UNKNOWN', refrenceId: trip.dspRefrenceId };
}

async function getCancellationPolicy(
  deliveryServiceProviderId: number | undefined,
  dspReferenceId: string,
): Promise<string> {
  if (!deliveryServiceProviderId) return '';
  const res = await axios.get(
    `deliveryServiceProvider/get-cancellation-policy/${deliveryServiceProviderId}/${dspReferenceId}`,
  );
  return res.data.data ?? '';
}

export const DSPServices = {
  getTerms,
  getRequiredInfo,
  getSupportInfo,
  getAvailability,
  submitTrip,
  cancelTrip,
  getCancellationPolicy,
};
