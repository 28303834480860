import { JSONGrid } from '@redheadphone/react-json-grid';
function JsonGrid({ data }) {
  try {
    return (
      <div>
        <JSONGrid data={data} />
      </div>
    );
  } catch (err) {
    return <JSONGrid data={[{ error: `${err.message}` }]} />;
  }
}
// JsonGrid.propTypes = {
//   data: PropTypes.any,
// };

export default JsonGrid;
