import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { fetchTripStops } from '../../store/actions/trips.actions';
import { ReactComponent as CloseIcon } from '../../assets/images/close-svgrepo-com.svg';
import { RootState, Package, Stop } from '../../store/config/types';
import Spinner from '../../components/Spinner/Spinner';
import TripStopDetails from './TripStopDetails';
import { stopSortBySchedulerTimes } from '../../utils/trips.helper';

interface TripDetailProps {
  selectedTripId: number;
  handleClose(): void;
  // eslint-disable-next-line no-unused-vars
  setStopPackages(packages: Package[]): void;
}

const mapStateToProps = (state: RootState) => ({
  stops: state.trips.stops,
  loading: state.trips.loadingStops,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onFetchTripStops: (tripId: number) => dispatch(fetchTripStops(tripId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & TripDetailProps;

function TripDetails({ selectedTripId, handleClose, loading, onFetchTripStops, stops, setStopPackages }: PropsType) {
  useEffect(() => {
    onFetchTripStops(selectedTripId);
  }, [onFetchTripStops, selectedTripId]);

  return (
    <Dialog title="Trip Details" open={selectedTripId != null} onClose={() => handleClose()} fullWidth maxWidth="sm">
      <div className="detail-modal">
        <button className="close-button" onClick={() => handleClose()}>
          <CloseIcon />
        </button>
        {loading ? (
          <Spinner />
        ) : (
          stops
            ?.sort(
              stopSortBySchedulerTimes,
              // (stop1: Stop, stop2: Stop) =>
              //   moment(stop1.routerArrivalAt).valueOf() - moment(stop2.routerArrivalAt).valueOf(),
            )
            .map((stop: Stop) => (
              <TripStopDetails key={stop.tripStopId} stop={stop} setStopPackages={setStopPackages} />
            ))
        )}
      </div>
    </Dialog>
  );
}

export default connector(TripDetails);
