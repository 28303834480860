import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table } from '@mui/material';
import { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { deliveryOrdersService } from '../../services/deliveryOrders.service';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { formatDate } from '../../utils/string.helpers';

interface TripStopPackageGroupsDetailsProps {
  tripStopId?: number;
  onClose?: () => void;
}

export default function TripStopPackageGroupsDetails({ tripStopId, onClose }: TripStopPackageGroupsDetailsProps) {
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getDetails = () => {
    if (!details) return [];
    const map: any = {
      ship_via: 'Ship Via',
      order_date: 'Order Date',
      shipping_date: 'Shipping Date',
      customer_name: 'Customer',
      cases_ordered: 'Case Ordered',
      cases_shipped: 'Case Shipped',
      delivery_status: 'Status',
      driver_name: 'Driver',
      street_address: 'Address',
      zip_code: 'Zip',
      delivery_cost: 'Delivery Cost',
      invoice_total: 'Invoice Total',
      gross_profit: 'Gross Profit',
      net_profit: 'Net Profiit',
      sales_rep: 'Sales Rep',
      delivery_dwell_time_minutes: 'Dwell Time',
      on_time_delivery: 'Is Ontime',
    };
    const infos: any[] = [];
    [
      'customer_name',
      'street_address',
      'order_date',
      'shipping_date',
      'cases_ordered',
      'cases_shipped',
      'delivery_status',
      'driver_name',
      'sales_rep',
      'on_time_delivery',
      'delivery_dwell_time_minutes',
      'delivery_cost',
      'invoice_total',
      'gross_profit',
      'net_profit',
    ].forEach((fieldName: string) => {
      let value = details[fieldName];
      if (fieldName.includes('date')) {
        value = formatDate(value);
      }
      if (value && value !== '0') infos.push({ caption: map[fieldName], value });
    });

    return infos;
  };

  const handleClose = () => {
    setDetails(undefined);
    setLoading(false);
    if (onClose) onClose();
  };
  useEffect(() => {
    if (tripStopId) {
      setLoading(true);
    }
  }, [tripStopId]);
  useEffect(() => {
    if (loading) {
      deliveryOrdersService
        .getTripStopPackageGroupsDetails(tripStopId ?? 0)
        .then((data) => {
          if (data && data.length) setDetails(data[0]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);
  const infos = getDetails();
  return (
    <Dialog open={tripStopId !== undefined} onClose={handleClose}>
      <DialogTitle>Details</DialogTitle>
      <DialogContent style={{ minWidth: 200, minHeight: 130 }}>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {!infos.length ? (
              <>No Data</>
            ) : (
              <Table>
                <TableBody>
                  {infos.map((row) => (
                    <TableRow key={row.caption}>
                      <TableCell>{row.caption}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
