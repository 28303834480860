import { Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import ColumnFilter from './ColumnFilter';
import FilterInfo from './FilterInfo';
import moment from 'moment-timezone';
import FilterableTableRow from './FilterableTableRow';
import './FilterableTable.scss';
import { IconButton } from '@material-ui/core';
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  SwapVert,
} from '@material-ui/icons';
import CumulativeOperationMenu from './CumulativeOperationMenu';

export type FilterType = 'string' | 'values' | 'date' | 'boolean';
export interface FilterableTableColumn {
  field: string;
  caption?: string;
  filterType?: FilterType;
  sortable?: boolean;
  fixWidth?: string | number;
  // eslint-disable-next-line
  render?: (row: any) => any;
}
export interface CumulativeOperation {
  operation: string;
  caption?: string;
  icon?: any;
  /* eslint-disable */
  isDisabled?: (rows: any[]) => boolean;
  renderMenu?: (rows: any[]) => any;
  /* eslint-enable */
}
interface FilterableTableProps {
  data: any[];
  columns: FilterableTableColumn[];
  extendable?: boolean;
  itemPerPage?: number;
  externalFilterValues?: any;
  selectable?: boolean;
  primaryKey?: string;
  cumulativeOperations?: CumulativeOperation[];
  defaultSortBy?: string;
  /* eslint-disable */
  renderExtended?: (row: any) => any;
  onFilterChanged?: (filters: any) => void;
  onCumulativeOperation?: (operation: string, rows: any[]) => void;
  /* eslint-enable */
}
export default function FilterableTable({
  data,
  columns,
  extendable,
  itemPerPage,
  externalFilterValues,
  selectable,
  primaryKey,
  cumulativeOperations,
  defaultSortBy,
  renderExtended,
  onFilterChanged,
  onCumulativeOperation,
}: FilterableTableProps) {
  const [filters, setFilters] = useState<any>({});
  const [sortColumn, setSortColumn] = useState<string | undefined>(defaultSortBy);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [nativeCumulativeOperations, setNativeCumulativeOperations] = useState<CumulativeOperation[]>([]);

  const [selectionList, setSelectionList] = useState<any[]>([]);

  useEffect(() => {
    if (externalFilterValues) {
      const newValue: any = { ...filters };
      Object.keys(externalFilterValues).forEach((filter) => {
        newValue[filter] = externalFilterValues[filter];
      });
      setFilters(newValue);
    }
  }, [externalFilterValues]);

  useEffect(() => {
    const dataToShow = data.filter((item) => filterMatch(item));
    if (sortColumn) {
      const filterType = columns.find((c) => c.field === sortColumn)?.filterType;
      dataToShow.sort(sortByColumn(sortColumn, sortDirection, filterType ?? 'string'));
    }
    setFilteredData([...dataToShow]);
    if (itemPerPage) {
      const pageCount = Math.ceil(dataToShow.length / itemPerPage);
      if (currentPage > pageCount) setCurrentPage(Math.max(pageCount, 1));
    } else {
      setCurrentPage(1);
    }
  }, [filters, sortColumn, sortDirection, data]);
  useEffect(() => {
    const cOprList: CumulativeOperation[] = [];
    if (selectable) {
      const selectedFilteredCount = filteredData.reduce((sum, row) => {
        if (selectionList.includes(row[primaryKey ?? ''])) return sum + 1;
        else return sum;
      }, 0);
      const caption = selectedFilteredCount ? 'Select None' : 'Select All';
      cOprList.push({
        operation: selectedFilteredCount ? 'select-none' : 'select-all',
        caption,
        icon: selectedFilteredCount ? <CheckBoxOutlineBlank /> : <CheckBox />,
      });
    }
    setNativeCumulativeOperations(cOprList);
  }, [selectionList, selectable]);
  useEffect(() => {
    setSelectionList(getSelectedRows().map((row) => row[primaryKey ?? '']));
  }, [data]);

  const handleChangeFilterValue = (fieldName: string, value: any) => {
    const newValue: any = { ...filters };

    newValue[fieldName] = value;
    setFilters(newValue);
    if (onFilterChanged) onFilterChanged(newValue);
  };
  const handleSortClick = (fieldName?: string) => {
    if (fieldName) {
      if (!sortColumn) {
        setSortColumn(fieldName);
      } else {
        if (sortColumn != fieldName) {
          setSortColumn(fieldName);
        } else {
          setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        }
      }
    } else {
      setSortColumn(undefined);
      setSortDirection('ASC');
    }
  };
  const handleRowSelectChange = (key: any, value: boolean) => {
    const list = [...selectionList];
    const idx = list.findIndex((l) => l == key);
    if (value) {
      if (idx < 0) list.push(key);
    }
    if (!value) {
      list.splice(idx, 1);
    }
    setSelectionList(list);
  };
  // const handleSelectAllChanged = (value: boolean) => {
  //   setSelectionList(value ? filteredData.map((d) => d[primaryKey ?? '']) : []);
  // };
  const filterMatch = (item: any) => {
    let visible = true;
    columns.forEach((col) => {
      if (col.filterType && filters[col.field]) {
        switch (col.filterType) {
          case 'string':
            const itemStr = item[col.field] ?? '';
            if (!itemStr.toLowerCase().includes(filters[col.field].toLowerCase().trim())) {
              visible = false;
            }
            break;
          case 'values':
            if (filters[col.field].length && !filters[col.field].includes(item[col.field])) {
              visible = false;
            }
            break;
          case 'date':
            if (!moment(item[col.field]).isSame(filters[col.field], 'day')) {
              visible = false;
            }
            break;
          case 'boolean':
            visible = Boolean(item[col.field]);
            break;
        }
      }
    });
    return visible;
  };

  if (!itemPerPage) itemPerPage = filteredData.length;
  const pageCount = Math.ceil(filteredData.length / itemPerPage);

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };
  const getSelectedRows = () => data.filter((d) => selectionList.includes(d[primaryKey ?? '']));

  const handleActions = (action: string) => {
    switch (action) {
      case 'select-all':
        setSelectionList([...selectionList, ...filteredData.map((d) => d[primaryKey ?? ''])]);
        break;
      case 'select-none':
        setSelectionList(selectionList.filter((id) => filteredData.findIndex((d) => d[primaryKey ?? ''] == id) < 0));
        break;
      default:
        if (onCumulativeOperation) {
          onCumulativeOperation(action, getSelectedRows());
        }
    }
  };
  const pageData = filteredData.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage);
  return (
    <>
      <Table className="filterable-table">
        <TableHead>
          <TableRow>
            {(nativeCumulativeOperations.length > 0 || cumulativeOperations?.length!) && (
              <TableCell width={32}>
                <CumulativeOperationMenu
                  nativeOperations={nativeCumulativeOperations}
                  externalOperation={cumulativeOperations}
                  disabled={data.length == 0}
                  selectedRows={getSelectedRows()}
                  onAction={handleActions}
                />
              </TableCell>
            )}
            {columns.map((column, colIndex) => {
              const values: string[] = [];
              if (column.filterType === 'values') {
                data.forEach((row) => {
                  if (!values.includes(row[column.field])) values.push(row[column.field]);
                });
              }
              const colCaption = column.caption ? column.caption : column.field;
              const isSort = column.field === sortColumn;
              const colHeader = (
                <>
                  {column.sortable ? (
                    <IconButton
                      size="small"
                      className={`sortable-col${isSort ? ' is-sort' : ''}`}
                      onClick={() => handleSortClick(column.field)}
                    >
                      {isSort ? (
                        sortDirection === 'ASC' ? (
                          <ArrowUpwardOutlined />
                        ) : (
                          <ArrowDownwardOutlined />
                        )
                      ) : (
                        <SwapVert />
                      )}
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {colCaption}
                </>
              );
              return (
                <TableCell key={colIndex} style={{ verticalAlign: 'top' }} width={column.fixWidth}>
                  <Grid container direction={'column'} alignSelf={'stretch'} alignItems={'stretch'}>
                    <Grid item>
                      {colHeader}
                      {column.filterType && (
                        <ColumnFilter
                          type={column.filterType}
                          value={filters[column.field]}
                          values={values}
                          onFilterChanged={(newFilterValue: any) => {
                            handleChangeFilterValue(column.field, newFilterValue);
                          }}
                        />
                      )}
                    </Grid>
                    {column.filterType && filters[column.field] && (
                      <Grid item p={0}>
                        <FilterInfo
                          type={column.filterType}
                          value={filters[column.field]}
                          onFilterChanged={(newFilterValue: any) => {
                            handleChangeFilterValue(column.field, newFilterValue);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              );
            })}
            {extendable && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {pageData?.map((row, index) => (
            <FilterableTableRow
              row={row}
              columns={columns}
              key={index}
              selectable={selectable}
              primaryKey={primaryKey}
              selected={selectionList.includes(row[primaryKey ?? ''])}
              onSelectChange={handleRowSelectChange}
            >
              {extendable && renderExtended ? renderExtended(row) : ''}
            </FilterableTableRow>
          ))}
        </TableBody>
      </Table>
      {pageCount > 1 && (
        <Grid container pt={2} justifyContent="center">
          <Grid item>
            <Pagination count={pageCount} shape="rounded" page={currentPage} onChange={handlePageChange} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

function sortByColumn(colName: string, direction: 'ASC' | 'DESC', colType: FilterType) {
  switch (colType) {
    case 'string':
    case 'values':
      return (a: any, b: any) => {
        let res = 0;
        if (a[colName] > b[colName]) res = 1;
        if (a[colName] < b[colName]) res = -1;
        if (direction === 'DESC') res = -res;
        return res;
      };
    case 'boolean':
      return (a: any, b: any) => {
        let res = 0;
        if (a[colName] && !b[colName]) res = 1;
        if (!a[colName] && b[colName]) res = -1;
        if (direction === 'DESC') res = -res;
        return res;
      };
    case 'date':
      return (a: any, b: any) => {
        let res = 0;
        if (moment(a[colName]).isAfter(b[colName])) res = 1;
        if (moment(a[colName]).isBefore(b[colName])) res = -1;
        if (direction === 'DESC') res = -res;
        return res;
      };
  }
}
