import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { RootState } from '../../store/config/types';
import { Customer } from '../../store/config/types/customers.types';
import { Coordinates } from '../../store/config/types/location.types';
import {
  createCustomer,
  updateCustomer,
  fetchCustomers,
  clearCustomersState,
} from '../../store/actions/customers.actions';
import * as actions from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';

interface CustomerParams {
  customerId?: string;
}

interface CustomerValues {
  firstName: string;
  lastName: string;
  companyName: string;
  streetAddress: string;
  unit: string;
  contactPhoneNumber: string;
  email: string;
  specialInstructions: string;
  timeWindowFrom: string;
  timeWindowTo: string;
}

interface CustomersFormProps {
  fromModal?: boolean;
  closeModal?(): void;
  // eslint-disable-next-line no-unused-vars
  submitModal?(customer: Customer): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    customer: state?.customers.customer,
    customers: state?.customers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onCreateCustomer: (customer: Customer) => dispatch(createCustomer(customer)),
  onUpdateCustomer: (customerId: number, customer: Customer) => dispatch(updateCustomer(customerId, customer)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
  clearCustomersState: () => dispatch(clearCustomersState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & CustomersFormProps;

const CustomersForm = ({
  onUpdateCustomer,
  onCreateCustomer,
  fetchCustomers,
  clearCustomersState,
  customer,
  customers,
  setToast,
  fromModal,
  closeModal,
  submitModal,
}: PropsType) => {
  const history: History = useHistory();
  const { customerId } = useParams<CustomerParams>();
  const editMode: boolean = Boolean(customerId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [address, setAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<Coordinates>({ lat: null, lng: null });
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  useEffect(() => {
    if (editMode && selectedCustomer === null && customers.customers && customerId) {
      const editedCustomer = customers.customers.filter((c: Customer) => c.customerId === Number(customerId))[0];
      setSelectedCustomer(editedCustomer);
      setAddress(editedCustomer?.location?.streetAddress);
    }
  }, [editMode, selectedCustomer, customers.customers, customerId, setSelectedCustomer]);

  useEffect(() => {
    if (!customers.customers) fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers.customers, editMode, customer]);

  useEffect(() => {
    if (submitting) {
      if (customers.createCustomerSuccess || customers.updateCustomerSuccess) {
        setSubmitting(false);
        setToast(`Customer successfully ${customers.createCustomerSuccess ? 'created' : 'updated'}`, 'success');
        clearCustomersState();

        if (fromModal) {
          customers.createCustomerSuccess && submitModal && submitModal(customers.createdCustomer!);
        } else {
          history.push('/customers');
        }
      } else if (customers.createCustomerErrorMessage || customers.updateCustomerErrorMessage) {
        setSubmitting(false);
        setToast(
          `An error has ocurred while ${customers.createCustomerErrorMessage ? 'creating' : 'updating'} customer`,
          'danger',
        );
        clearCustomersState();
      }
    }
  }, [
    submitting,
    customers.createCustomerSuccess,
    customers.createdCustomer,
    customers.updateCustomerSuccess,
    customers.createCustomerErrorMessage,
    customers.updateCustomerErrorMessage,
    history,
    fromModal,
    submitModal,
    setSubmitting,
    setToast,
    clearCustomersState,
  ]);

  const handleGoBack = () => (fromModal ? closeModal && closeModal() : history.push('/customers'));

  const submitForm = async (values: CustomerValues) => {
    setSubmitting(true);
    let customerRequest: Customer = {
      unit: values.unit,
      customerId: Number(customerId),
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      contactPhoneNumber: values.contactPhoneNumber,
      email: values.email,
      specialInstructions: values.specialInstructions,
      timeWindowFrom: values.timeWindowFrom,
      timeWindowTo: values.timeWindowTo,
      location: {
        latitude: coordinates.lat || 0,
        longitude: coordinates.lng || 0,
        streetAddress: address,
        city: city,
        state: state,
        zipCode: zipCode,
        apartmentNumber: values.unit,
      },
    };
    if (editMode) {
      await onUpdateCustomer(Number(customerId), customerRequest);
    } else {
      await onCreateCustomer(customerRequest);
    }
  };

  if (editMode && !selectedCustomer) {
    return <Spinner />;
  }

  return (
    <div className={`courier-form ${fromModal && 'modal-form'}`}>
      <Card profile>
        <CardHeader color="primary" className="card-header">
          <h4>{editMode ? 'Update customer' : 'New customer'}</h4>
        </CardHeader>
        <CardBody profile>
          <h3 className="section-title">Customer info</h3>
          <Formik
            initialValues={{
              unit: selectedCustomer ? selectedCustomer.location.apartmentNumber! : '',
              firstName: selectedCustomer ? selectedCustomer.firstName! : '',
              lastName: selectedCustomer ? selectedCustomer.lastName! : '',
              companyName: selectedCustomer ? selectedCustomer.companyName! : '',
              contactPhoneNumber: selectedCustomer ? selectedCustomer.contactPhoneNumber! : '',
              email: selectedCustomer ? selectedCustomer.email! : '',
              specialInstructions: selectedCustomer ? selectedCustomer.specialInstructions! : '',
              timeWindowFrom: selectedCustomer ? selectedCustomer.timeWindowFrom! : '',
              timeWindowTo: selectedCustomer ? selectedCustomer.timeWindowTo! : '',
              streetAddress: selectedCustomer ? selectedCustomer.location.streetAddress! : '',
            }}
            validationSchema={schemas.CustomerSchema}
            onSubmit={submitForm}
          >
            {({ setFieldValue }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <InputField placeholder="First Name" name="firstName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <InputField placeholder="Last Name" name="lastName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <InputField placeholder="Company Name" name="companyName" type="text" />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                    setState={setState}
                    setZipCode={setZipCode}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={true}
                    inputSize={6}
                    inputName={'streetAddress'}
                    placeholderName={'Address'}
                  />
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Unit" name="unit" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <MaskedPhoneInput
                      placeholder="Phone Number"
                      name="contactPhoneNumber"
                      setfieldvalue={setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Email" name="email" type="text" />
                  </GridItem>
                  <GridItem xs={12}>
                    <InputField placeholder="Instructions" name="specialInstructions" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer justifyContent="flex-start" alignItems="flex-end">
                  <GridItem xs={12} justifyContent="flex-start" alignItems="flex-end">
                    <h3 style={{ textAlign: 'left', marginBottom: -16 }}>Default Time Window</h3>
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <InputField placeholder={'From'} name="timeWindowFrom" type="time" />
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <InputField mt={0} placeholder={'To'} name="timeWindowTo" type="time" />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {`${editMode ? 'Update' : 'Create'} customer`}
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default connector(CustomersForm);
