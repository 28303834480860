import { Dispatch } from 'redux';
import axios from '../../services/axios';
import { authService } from '../../services/auth.service';
import { LoggedAccount, LoginRequest } from '../config/types/auth.types';
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LoginActionTypes,
  GetAccountActionTypes,
  GETTING_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  LogoutActionTypes,
  LOGGING_OUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  UPDATING_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  UpdateAccountActionTypes,
  CLEAR_LOGIN_ERROR,
} from '../config/ActionTypes';

// #region Login

export const login = (loginRequest: LoginRequest) => {
  return (dispatch: Dispatch<LoginActionTypes>) => {
    dispatch(loggingIn());

    return authService.login(loginRequest).then(
      (response) => {
        const token: string = response?.data.token;
        localStorage.setItem('token', token);
        localStorage.setItem('shipperUserId', response?.data.user.shipperUserId);
        localStorage.setItem('shipperId', response?.data.user.shipper.shipperId);
        dispatch(loginSuccess(token, response.data.user));
      },
      (error) => {
        dispatch(loginError(error));
      },
    );
  };
};

export const persistLogin = (token: string) => {
  return (dispatch: Dispatch<LoginActionTypes>) => {
    // TODO if getAccount becomes available, fetch user data here
    dispatch(loginSuccess(token));
  };
};

const loggingIn = (): LoginActionTypes => ({
  type: LOGGING_IN,
});

const loginSuccess = (token: string, account?: LoggedAccount): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  token,
  account,
});

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
});

export const clearLoginError = (): LoginActionTypes => ({
  type: CLEAR_LOGIN_ERROR,
});

// #endregion Login

// #region Logout

export const logout = () => {
  return (dispatch: Dispatch<LogoutActionTypes>) => {
    dispatch(loggingOut());

    return authService.logout().then(
      () => {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        dispatch(logoutSuccess());
      },
      (error) => {
        dispatch(logoutFailed(error));
      },
    );
  };
};

const loggingOut = (): LogoutActionTypes => ({
  type: LOGGING_OUT,
});

const logoutSuccess = (): LogoutActionTypes => ({
  type: LOGOUT_SUCCESS,
});

const logoutFailed = (error: string): LogoutActionTypes => ({
  type: LOGOUT_FAILED,
  error,
});

// #endregion Logout

// #region Get Account

export const getAccount = (shipperUserId: number) => (dispatch: Dispatch<GetAccountActionTypes>) => {
  dispatch(gettingAccount());

  return authService.getAccount(shipperUserId).then(
    (response) => {
      dispatch(getAccountSuccess(response!.data));
    },
    (error) => {
      dispatch(getAccountError(error));
    },
  );
};

const gettingAccount = (): GetAccountActionTypes => ({
  type: GETTING_ACCOUNT,
});

const getAccountSuccess = (account: LoggedAccount): GetAccountActionTypes => ({
  type: GET_ACCOUNT_SUCCESS,
  account,
});

const getAccountError = (error: string): GetAccountActionTypes => ({
  type: GET_ACCOUNT_ERROR,
  error,
});

// #endregion Get Account

// #region Update Account

export const updateAccount =
  (shipperUserId: number, shipperData: LoggedAccount) => (dispatch: Dispatch<UpdateAccountActionTypes>) => {
    dispatch(updatingAccount());

    return authService.updateAccount(shipperUserId, shipperData).then(
      () => {
        dispatch(updateAccountSuccess());
      },
      (error) => {
        dispatch(updateAccountError(error));
      },
    );
  };

const updatingAccount = (): UpdateAccountActionTypes => ({
  type: UPDATING_ACCOUNT,
});

const updateAccountSuccess = (): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_SUCCESS,
});

const updateAccountError = (error: string): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_ERROR,
  error,
});

// #endregion Update Account
