import {
  NotificationSettingsState,
  NotificationTemplate,
  NotificationTemplates,
  // NotificationTemplate,
  UpdateNotificationSettingsRequest,
  UpdateNotificationTemplateRequest,
} from '../store/config/types';
import axios from './axios';

async function fetchNotificationSettings(shipperID: number | string): Promise<NotificationSettingsState> {
  const res = await axios.get(`/notificationSettings/${shipperID}`);
  return res.data;
}

async function fetchNotificationTemplates(
  shipperID: number | string | null,
  onlyCustomTemplates = false,
): Promise<NotificationTemplates> {
  const res = await axios.get(
    `/notificationSettings/templates/${shipperID}` + (onlyCustomTemplates ? '?onlyCustomTemplates' : ''),
  );
  let templates: NotificationTemplates = { shipping: [], delivery: [] };
  if (Array.isArray(res.data)) {
    res.data.forEach((template) => {
      if (template.type === 'ON_SHIPPING') {
        templates.shipping.push({ id: template.id, name: template.name, body: template.body, type: template.type });
      } else {
        templates.delivery.push({ id: template.id, name: template.name, body: template.body, type: template.type });
      }
    });
  }
  return templates;
}

async function updateNotificationSettings(
  updateNotificationSettingsRequest: UpdateNotificationSettingsRequest,
): Promise<NotificationSettingsState> {
  const res = await axios.patch(`/notificationSettings`, updateNotificationSettingsRequest);
  return res.data;
}

async function updateNotificationTemplate(
  updateNotificationTemplateRequest: UpdateNotificationTemplateRequest,
): Promise<NotificationTemplate> {
  const res = await axios.patch(`/notificationSettings/template/edit`, updateNotificationTemplateRequest);
  return res.data;
}

async function createNotificationTemplate(
  newTemplate: UpdateNotificationTemplateRequest,
): Promise<NotificationTemplate> {
  const res = await axios.patch(`/notificationSettings/template/create`, newTemplate);
  return res.data;
}

export const NotificationService = {
  fetchNotificationSettings,
  fetchNotificationTemplates,
  updateNotificationSettings,
  updateNotificationTemplate,
  createNotificationTemplate,
};
