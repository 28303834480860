import moment from 'moment-timezone';
import { Stop, Trip, TripArrangeStop, TripStopLabelStruct, TripWithStopsDetails } from '../../store/config/types';
import { getTimeWindowStatus } from '../../utils/trips.helper';

export function stopToDndItem(
  trip: Trip | TripWithStopsDetails,
  stop: Stop | TripArrangeStop,
  tripIndex: number,
  stopIndex: number,
  isHighLight?: boolean,
  timezone?: string,
  displayComputed?: boolean,
  labels?: TripStopLabelStruct[],
) {
  {
    const statuses = stop.stopPackages?.map((stopPackage) => stopPackage.changeHandStatus) ?? [];

    let packagesStatus: string | null = null;
    let failedStatuses: (string | undefined)[] = statuses.filter((status) => status === 'FAIL');
    if (failedStatuses.length > 0)
      packagesStatus =
        failedStatuses.length > 0
          ? `${failedStatuses.length} Package${failedStatuses.length > 1 ? 's' : ''} failed`
          : null;

    failedStatuses = statuses.filter((status) => status === 'FAILED_BEFORE');
    if (failedStatuses.length > 0)
      packagesStatus =
        failedStatuses.length > 0
          ? `${failedStatuses.length} Package${failedStatuses.length > 1 ? 's' : ''} failed before`
          : null;
    // if (packagesStatus === '') packagesStatus = null;
    const delayedMinutes = Math.max(
      ['NEW', 'PENDING'].includes(trip.status) && trip.startsAt ? moment().diff(trip.startsAt, 'minute') : 0,
      0,
    );
    const start = moment(
      (displayComputed ? stop.computedArrivalAt : undefined) ?? stop.schedulerArrivalAt ?? stop.routerArrivalAt,
    );
    const end = moment(
      (displayComputed ? stop.computedDepartureAt : undefined) ?? stop.schedulerDepartureAt ?? stop.routerDepartureAt,
    );

    return {
      start,
      end,
      label: labels
        ? labels.find((label) => label.tripId === trip.tripId && stop.tripStopId === label.tripStopId)?.label ??
          `${stopIndex}`
        : `${stopIndex}`,
      // colorClass: displayComputed && !stop.computedArrivalAt ? 'computed-missed' : `color-unknown`,
      colorIndex: tripIndex,
      additionalInfo: {
        tripStopId: stop.tripStopId,
        tripIndex: tripIndex,
        name: stop.location.customer
          ? `${stop.location.customer.firstName} ${stop.location.customer.lastName}`
          : undefined,
        companyName: stop.location.customer ? `${stop.location.customer.companyName ?? ''}` : undefined,
        warehouseName: stop.location.warehouse ? `${stop.location.warehouse.name ?? ''}` : undefined,
        arrive: stop.schedulerArrivalAt ?? stop.routerArrivalAt,
        departure: stop.schedulerDepartureAt ?? stop.routerDepartureAt,
        status: stop.status,
        timeWindowFrom: stopIndex > 0 ? stop.timeWindowFrom : null,
        timeWindowTo: stopIndex > 0 ? stop.timeWindowTo : null,
        timeWindowStatus:
          stopIndex > 0 ? getTimeWindowStatus(stop, trip.status, trip.startsAt, timezone, displayComputed) : 0,
        timeWindowAlertReason: delayedMinutes > 0 ? ' - Due to late start' : '',
        packagesStatus,
        shipToAddressee: stop.shipToAddressee,
      },
      highlight: isHighLight,
      hidden: displayComputed && !stop.computedArrivalAt,
    };
  }
}

export function getLabelsOfTripStops(trips: Trip[]) {
  const labels: TripStopLabelStruct[] = [];
  trips.forEach((trip) => {
    trip.tripStops?.forEach((stop, stopIndex) => {
      labels.push({
        tripId: trip.tripId ?? 0,
        tripStopId: stop.tripStopId,
        label: stopIndex > 0 ? `${stopIndex}` : 'W',
      });
    });
  });
  return labels;
}

export function getStopSequence(tripStops: Stop[] | TripArrangeStop[], sortByComptedTimes?: boolean) {
  const stopsSequence = tripStops
    .map((stop) => ({
      tripStopId: stop.tripStopId ? stop.tripStopId : stop.locationId,
      arrivalDate: sortByComptedTimes ? stop.computedArrivalAt : stop.routerArrivalAt,
    }))
    .filter((stopInfo) => stopInfo.arrivalDate);
  stopsSequence.sort((a: any, b: any) => moment(a.arrivalDate).unix() - moment(b.arrivalDate).unix());
  return stopsSequence;
}
