import { Grid, Typography } from '@mui/material';
import { AIChatSession } from './prospero.types';
import moment from 'moment-timezone';
export interface ProsperoChatSessionHeaderProps {
  chatSession: AIChatSession;
  isCurrent?: boolean;
  debugMode?: boolean;
  onClick: () => void;
}
export default function ChatSessionHeaderItem({
  chatSession,
  isCurrent,
  debugMode,
  onClick,
}: ProsperoChatSessionHeaderProps) {
  const totalTokens = chatSession.conversation?.reduce((total, message) => {
    return total + (message.usageInfo?.completionTokens ?? message.usageInfo?.promptTokens ?? 0);
  }, 0);
  return (
    <Grid
      item
      onClick={() => onClick()}
      className={`prospero-chat-session-header-item${isCurrent ? ' current' : ''}`}
      p={1}
    >
      <div className="prospero-chat-session-header-item-title">
        {debugMode && (
          <Typography variant="caption" color="brown" fontSize={10}>
            {chatSession.user}
          </Typography>
        )}
        <Typography variant="body1">{chatSession.title}</Typography>
      </div>
      <div className="prospero-chat-session-header-item-date">
        <Typography variant="caption" fontSize={10}>
          {moment(chatSession.date).calendar()}
          {debugMode && <span style={{ marginLeft: 24, color: '#888' }}>Usage: {totalTokens}</span>}
        </Typography>
      </div>
    </Grid>
  );
}
