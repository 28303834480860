import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { store } from './store/config/configureStore';
import Main from './screens/Main';
import { theme } from './styles/theme';
import './styles/app.scss';
import WarehouseWorkService from './services/warehouseWork.service';
import { createContext, useEffect, useState } from 'react';

export const ApiQueueInfoContext = createContext<any>(null);

function App() {
  const [apiQueueInfo, setApiQueueInfo] = useState<any>(null);
  const inqueryApiCallQueue = () => {
    WarehouseWorkService.getApiQueueInfo()
      .then((info) => {
        setApiQueueInfo(info);
        if (info !== null) {
          setTimeout(() => {
            inqueryApiCallQueue();
          }, 10000);
        }
      })
      .catch(() => {
        setTimeout(() => {
          inqueryApiCallQueue();
        }, 30000);
      });
  };
  useEffect(() => {
    inqueryApiCallQueue();
  }, []);
  return (
    <ApiQueueInfoContext.Provider value={apiQueueInfo}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Main />
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    </ApiQueueInfoContext.Provider>
  );
}

export default App;
