import moment from 'moment-timezone';
import { TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';

/* eslint-disable */
export enum TripsChangeType {
  ADD_NEW_TRIP = 'ADD_NEW_TRIP',
  REMOVE_TRIP = 'REMOVE_TRIP',
  STOPS_ORDER_CHANGE = 'STOPS_ORDER_CHANGE',
  STOP_REMOVE = 'STOP_REMOVE',
  STOP_INSERT = 'STOP_INSERT',
  STOP_DRAG_AND_DROP = 'STOP_DRAG_AND_DROP',
  TRIP_CUSTOM_CHANGE = 'TRIP_CUSTOM_CHANGE',
}
/* eslint-enable */
export interface TripStopPointer {
  tripIdx?: number;
  stopIdx?: number;
  aPackageId?: number;
}

export interface TripStopTimes {}
export interface TripsChangeStructure {
  changeType: TripsChangeType;
  sourceTripIdx?: number;
  sourceTripIdxs?: number[];
  destTripIdx?: number;
  destTripIdxs?: number[];
  sourceStopPointer?: TripStopPointer;
  sourceStopPointers?: TripStopPointer[];
  destStopPointer?: TripStopPointer;
  destStopPointers?: TripStopPointer[];
  sourceTripId?: number;
  /* eslint-disable */
  customFunction?: (trips: TripWithStopsDetails[], unAssignStops?: TripArrangeStop[]) => TripsChangeResult;
  /* eslint-enable */
}

export interface TripsChangeResult {
  ok: boolean;
  trips?: TripWithStopsDetails[];
  unAssignStops?: TripArrangeStop[];
}
const addStopToTripStops = (tripStops: TripArrangeStop[], stop: TripArrangeStop, warehouseStop?: TripArrangeStop) => {
  if (tripStops.length === 0 && warehouseStop) {
    tripStops.push({ ...warehouseStop, colorClass: 'color0' });
  }
  tripStops.push(stop);
};
const fixTripStopsTimes = (tripStops: TripArrangeStop[], startTime?: Date) => {
  const time = moment(startTime ?? (tripStops.length > 0 ? tripStops[0].routerArrivalAt : new Date()));
  tripStops.forEach((stop) => {
    stop.routerArrivalAt = time.toDate();
    time.add(5, 'minutes');
    stop.routerDepartureAt = time.toDate();
    time.add(5, 'minutes');
  });
};
const findStop = (
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  stopPointer: TripStopPointer,
): TripArrangeStop | null => {
  let stop: TripArrangeStop | null = null;
  if (stopPointer.aPackageId !== undefined) {
    trips.forEach((trip, tripIndex) => {
      const stopIndex = trip.tripStops.findIndex(
        (stopItem, itemIndex) =>
          itemIndex > 0 &&
          stopItem.stopPackages?.some((stopPackage) => stopPackage.packageId === stopPointer.aPackageId),
      );
      if (stopIndex >= 0) stop = trips[tripIndex].tripStops.splice(stopIndex, 1)[0];
    });
    if (stop === null) {
      const stopIndex = unAssignStops.findIndex((stopItem) =>
        stopItem.stopPackages?.some((stopPackage) => stopPackage.packageId === stopPointer.aPackageId),
      );
      if (stopIndex >= 0) stop = unAssignStops.splice(stopIndex, 1)[0];
    }
  } else if (stopPointer.stopIdx !== undefined && stopPointer.tripIdx != undefined) {
    stop =
      stopPointer.tripIdx >= 0
        ? trips[stopPointer.tripIdx].tripStops.splice(stopPointer.stopIdx, 1)[0]
        : unAssignStops.splice(stopPointer.stopIdx, 1)[0];
  }
  return stop;
};
export function handleDragAndDrop(
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  changeStruct: TripsChangeStructure,
  /* eslint-disable */
  getWarehouseOfStop: (stop: TripArrangeStop) => TripArrangeStop | undefined,
  /* eslint-enable */
): TripsChangeResult {
  const sourceRowIndex = changeStruct.sourceTripIdx;
  const sourceItemIndex = changeStruct.sourceStopPointer?.stopIdx;
  const destRowIndex = changeStruct.destTripIdx;
  const destItemIndex = changeStruct.destStopPointer?.stopIdx;

  if (sourceRowIndex === destRowIndex && sourceItemIndex === destItemIndex) return { ok: false };
  if (
    sourceRowIndex !== undefined &&
    sourceItemIndex !== undefined &&
    destRowIndex !== undefined &&
    destItemIndex !== undefined
  ) {
    if (destRowIndex >= 0 && destItemIndex === 0) return { ok: false };

    const sourceWarehouse = getWarehouseOfStop(
      sourceRowIndex !== -1 ? trips[sourceRowIndex].tripStops[sourceItemIndex] : unAssignStops[sourceItemIndex],
    );
    const destWarehouse = destRowIndex !== -1 ? getWarehouseOfStop(trips[destRowIndex].tripStops[1]) : sourceWarehouse;

    if (sourceRowIndex === destRowIndex) {
      //inner order
      if (sourceRowIndex === -1) {
        // unAssign inner order skip
        return { ok: false };
      } else {
        // reorder
        const stop = trips[sourceRowIndex].tripStops.splice(sourceItemIndex, 1)[0];
        trips[sourceRowIndex].tripStops.splice(destItemIndex, 0, stop);
        // mark need optimize
        trips[sourceRowIndex].needsOptimize = true;
        // turn on lock
        trips[sourceRowIndex].doNotOptimize = true;
      }
    } else {
      const isLast = sourceRowIndex >= 0 && sourceItemIndex === trips[sourceRowIndex].tripStops.length - 1;
      if (!sourceWarehouse || !destWarehouse) return { ok: false };
      if (sourceWarehouse.location.locationId !== destWarehouse.location.locationId) return { ok: false };
      if (destRowIndex === -1) {
        // delete source , add to unAssign
        const stop = trips[sourceRowIndex].tripStops.splice(sourceItemIndex, 1)[0];
        unAssignStops.push(stop);
        // mark need optimize
        if (!isLast) trips[sourceRowIndex].needsOptimize = true;
      } else if (sourceRowIndex === -1) {
        // remove from unAssign and add to row
        const stop = unAssignStops.splice(sourceItemIndex, 1)[0];
        trips[destRowIndex].tripStops.splice(destItemIndex, 0, stop);
        // mark need optimize
        trips[destRowIndex].needsOptimize = true;
      } else {
        // reorder
        const stop = trips[sourceRowIndex].tripStops.splice(sourceItemIndex, 1)[0];
        trips[destRowIndex].tripStops.splice(destItemIndex, 0, stop);
        // mark both as need optimize if source index not last
        trips[destRowIndex].needsOptimize = true;
        if (!isLast) trips[sourceRowIndex].needsOptimize = true;
      }
    }
    if (destRowIndex >= 0) fixTripStopsTimes(trips[destRowIndex].tripStops, trips[destRowIndex].startsAt);
    return { trips: trips.filter((trip) => trip.tripStops.length > 1), unAssignStops, ok: true };
  }

  return { ok: false };
}

export function handleDropToNewLine(
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  changeStruct: TripsChangeStructure,
  /* eslint-disable */
  getWarehouseOfStop: (stop: TripArrangeStop) => TripArrangeStop | undefined,
  /* eslint-enable */
): TripsChangeResult {
  if (changeStruct.changeType === TripsChangeType.ADD_NEW_TRIP) {
    if (changeStruct.sourceStopPointers && changeStruct.sourceStopPointers.length > 0) {
      const tripStops: TripArrangeStop[] = [];
      const firstStopPointer = changeStruct.sourceStopPointers[0];
      const firstStop = findStop(trips, unAssignStops, firstStopPointer);
      if (!firstStop) return { ok: false };
      const warehouse = getWarehouseOfStop(firstStop);

      if (!warehouse) return { ok: false };
      changeStruct.sourceStopPointers?.forEach((stopPointer, index) => {
        const stop = index > 0 ? findStop(trips, unAssignStops, stopPointer) : firstStop;
        if (stop) addStopToTripStops(tripStops, stop, warehouse);
      });
      fixTripStopsTimes(tripStops);
      const newTrip: TripWithStopsDetails = {
        tripId: 0, //trips.length,
        startsAt: tripStops[0].routerArrivalAt,
        courierId: null,
        firstLeg: true,
        status: 'NEW',
        needsOptimize: true,
        doNotOptimize: false,
        totalMiles: 0,
        tripOffers: [],
        tripStops: tripStops,
        curbhubTrip: {
          courier_id: 0,
          first_leg: true,
          stops: [],
          trip_id: `${trips.length}`,
        },
        editable: true,
      };
      trips.push(newTrip);

      return { trips: trips.filter((trip) => trip.tripStops.length > 1), unAssignStops, ok: true };
    }
  }
  return { ok: false };
}

export function handleRemoveLine(
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  changeStruct: TripsChangeStructure,
): TripsChangeResult {
  if (changeStruct.changeType === TripsChangeType.REMOVE_TRIP) {
    const sourceRowIndex =
      changeStruct.sourceTripIdx ?? trips.findIndex((trip) => trip.tripId === changeStruct.sourceTripId);

    if (sourceRowIndex === undefined || sourceRowIndex < 0) return { ok: false };

    // delete stops and add to unAssign
    const stopCount = trips[sourceRowIndex].tripStops.length - 1;
    if (stopCount > 0) {
      const trip = trips.splice(sourceRowIndex, 1)[0];

      trip.tripStops.forEach((stop, index) => {
        if (index > 0) unAssignStops.push(stop);
      });
    }
    return { trips: trips.filter((trip) => trip.tripStops.length > 1), unAssignStops, ok: true };
  }
  return { ok: false };
}
export function handleCustomChange(
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  changeStruct: TripsChangeStructure,
): TripsChangeResult {
  if (changeStruct.changeType === TripsChangeType.TRIP_CUSTOM_CHANGE && changeStruct.customFunction !== undefined) {
    return changeStruct.customFunction(trips, unAssignStops);
  }
  return { ok: false };
}

export const handleChangeChain = (
  trips: TripWithStopsDetails[],
  unAssignStops: TripArrangeStop[],
  changeStructs: TripsChangeStructure[],
  /* eslint-disable */
  getWarehouseOfStop: (stop: TripArrangeStop) => TripArrangeStop | undefined,
  /* eslint-enable */
): TripsChangeResult => {
  let changeResult: TripsChangeResult = {
    ok: true,
    trips,
    unAssignStops,
  };

  let ok = true;
  changeStructs.forEach((changeStruct) => {
    // changeResult.trips = changeResult.trips?.filter((trip) => trip.tripStops.length > 1);
    switch (changeStruct.changeType) {
      case TripsChangeType.STOP_DRAG_AND_DROP:
        changeResult = handleDragAndDrop(
          changeResult.trips ?? [],
          changeResult.unAssignStops ?? [],
          changeStruct,
          getWarehouseOfStop,
        );
        break;
      case TripsChangeType.ADD_NEW_TRIP:
        changeResult = handleDropToNewLine(
          changeResult.trips ?? [],
          changeResult.unAssignStops ?? [],
          changeStruct,
          getWarehouseOfStop,
        );
        break;
      case TripsChangeType.REMOVE_TRIP:
        changeResult = handleRemoveLine(changeResult.trips ?? [], changeResult.unAssignStops ?? [], changeStruct);
        break;
      case TripsChangeType.TRIP_CUSTOM_CHANGE:
        changeResult = handleCustomChange(changeResult.trips ?? [], changeResult.unAssignStops ?? [], changeStruct);
        break;
    }
    ok &&= changeResult.ok;
  });
  return ok ? { ...changeResult, ok } : { ok };
};
