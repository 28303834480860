import { Grid, Link, Typography } from '@mui/material';

import { CheckCircle, Person, ThumbDownOutlined, Error, DataUsage, Forum } from '@material-ui/icons';
import moment from 'moment-timezone';
import { ProsperoIcon } from '../../assets/images';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { AIMessageParticipantType, AIMessageStruct, AIMessageType, AISolutionStatus } from './prospero.types';
import UserData from './UserData';

interface ProsperoMessageProps {
  message: AIMessageStruct;
  mIndex: number;
  debugMode?: boolean;
  /* eslint-disable */
  onTableViewerOpen?: (data: any) => void;
  onJsonViewerOpen?: (data: any) => void;
  onRagManageClick?: (message: AIMessageStruct, mIndex: number) => void;
  /* eslint-enable */
}

export default function ProsperoMessage({
  message,
  mIndex,
  debugMode,
  onTableViewerOpen,
  onJsonViewerOpen,
  onRagManageClick,
}: ProsperoMessageProps) {
  if (
    !debugMode &&
    ![
      AIMessageType.USER_OUTPUT,
      AIMessageType.MESSAGE,
      AIMessageType.RESULT,
      AIMessageType.CLARIFICATION_REQUEST,
      AIMessageType.ERROR,
      AIMessageType.CONFIRMATION_REQUEST,
      AIMessageType.CONFIRMATION_RESPONSE_REJECTED,
      AIMessageType.CLARIFICATION_RESPONSE,
      AIMessageType.WELCOME,
    ].includes(message.type)
  )
    return <></>;
  const getAvatar = () => {
    const iconSize = debugMode ? 48 : 24;
    if (message.type === AIMessageType.ERROR)
      return <Error color="error" style={{ height: iconSize, width: iconSize }} />;

    switch (message.sender) {
      case AIMessageParticipantType.USER:
        return <Person color="secondary" style={{ height: 48, width: 48 }} />;

      //case AIMessageType.ANSWER:
      default:
        return <img src={ProsperoIcon} alt="Prospero" style={{ width: 48, height: 48 }} />;
    }
  };
  const getStepsOfOriginalResponse = (response?: string) => {
    if (!response) return [];
    try {
      let resObj = JSON.parse(response);
      if (resObj.response) resObj = JSON.parse(resObj.response);
      return resObj.solver_instructions ?? [];
    } catch (err) {
      console.error(err, response);
      return [];
    }
  };
  const getUserName = () => {
    switch (message.sender) {
      case AIMessageParticipantType.AI:
        return 'AI';
      case AIMessageParticipantType.SOLVER:
        return debugMode ? 'Solver' : 'AI';
      case AIMessageParticipantType.USER:
        return 'You';
    }
  };
  // if ([ProsperoMessageTypes.FUNCTION_CALL, ProsperoMessageTypes.FUNCTION_RESULT].includes(message.type)) return <></>;
  let parseJson = false;
  if ([AIMessageType.ERROR, AIMessageType.USER_OUTPUT, AIMessageType].includes(message.type)) {
    try {
      JSON.parse(message.message);
      if (message.message) parseJson = true;
    } catch {
      parseJson = false;
    }
  }

  const messageBody = parseJson ? JSON.parse(message.message) : message.message;
  const steps = getStepsOfOriginalResponse(message.originalResponse);
  const hasSolution: boolean = !!message.solution?.length;
  const solutionConfirmed: boolean = message.solutionStatus == AISolutionStatus.CONFIRMED;
  const solutionRejected: boolean = message.solutionStatus == AISolutionStatus.REJECTED;
  const canAddToRAG: boolean = solutionConfirmed || message.solutionStatus == AISolutionStatus.UNKNOWN;
  if (message.type === AIMessageType.ERROR && !debugMode && mIndex > 0) return <></>
  return (
    <Grid
      container
      flexDirection="row"
      className="prospero-message-container"
      key={`message-${mIndex}`}
      mb={8}
      pr={2}
      flexWrap="nowrap"
      fontSize={debugMode ? '1em' : '1.3em'}
    >
      <Grid item className="prospero-message-avatar" justifyItems="flex-start" pr={2}>
        {getAvatar()}
      </Grid>
      <Grid item flexGrow={1} className="prospero-message-part" position="relative">
        {message.type == AIMessageType.ERROR ? (
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="caption">
                {debugMode ? (
                  <div className="AIdbId">
                    Error
                    <code>#{message.aiChatMessageId}</code>
                    <span>{message.type}</span>
                  </div>
                ) : (
                  'Something wrong!'
                )}
              </Typography>
            </Grid>
            {debugMode && (
              <Grid item p={2}>
                {messageBody.solver_message?.content?.error}
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="caption">
                {getUserName()}
                {debugMode && (
                  <div className="AIdbId">
                    <code>#{message.aiChatMessageId}</code>
                    <span>{message.type}</span>
                  </div>
                )}
              </Typography>
            </Grid>

            <Grid item p={2}>
              <Grid container>
                {parseJson && message.sender != AIMessageParticipantType.USER ? (
                  <UserData type={messageBody.type ?? 'table'} data={messageBody.data ?? messageBody} captions={messageBody.captions} onDisplayAllClick={onTableViewerOpen} debugMode={debugMode} />
                ) : (
                  <div style={{ whiteSpace: 'pre-line' }}>{messageBody}</div>
                )}
              </Grid>
            </Grid>

            {hasSolution && (
              <Grid item p={2} pt={0}>
                <Grid container flexDirection="row">
                  {!debugMode && (
                    <Grid container flexDirection="row" mb={2} pl={2}>
                      {message.solution?.map((step, stepIndex) => (
                        <Grid container mt={0} p={0} key={`step-${stepIndex}`}>
                          <div style={{ whiteSpace: 'pre-line' }}>{`${step.stepNumber}> ${step.description}`}</div>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {solutionConfirmed && (
                    <Grid item>
                      <Grid container alignItems="center">
                        <CheckCircle style={{ marginRight: 4, color: 'green' }} />
                        <Typography variant="caption" color="green">
                          Solution accepted
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {debugMode && canAddToRAG && (
                    <Grid item ml={2}>
                      <Link
                        onClick={() => {
                          onRagManageClick && onRagManageClick(message, message.aiChatMessageId ?? -1);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Grid container alignItems="center">
                          <DataUsage style={{ marginRight: 4, color: 'blue' }} />
                          <Typography variant="caption" color="blue">
                            {message.ragChunks?.length ? 'RAG Management' : 'Add to RAG'}
                          </Typography>
                        </Grid>
                      </Link>
                    </Grid>
                  )}
                </Grid>
                {solutionRejected && (
                  <Grid container alignItems="center">
                    <ThumbDownOutlined style={{ marginRight: 4, color: 'red' }} />
                    <Typography variant="caption" color="red">
                      Solution recejted
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {debugMode && steps && steps.length > 0 && (
              <Grid item p={2}>
                <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers wrapLongLines>
                  {JSON.stringify(steps, null, '   ')}
                </SyntaxHighlighter>
              </Grid>
            )}
            <Grid item>
              <Typography variant="caption" fontSize={10}>
                {moment(message.date).calendar()}
                {debugMode && (message.usageInfo?.completionTokens || message.usageInfo?.promptTokens) && (
                  <span style={{ marginLeft: 24, color: '#888' }}>
                    Usage: {message.usageInfo?.completionTokens ?? message.usageInfo?.promptTokens}
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {debugMode && message.originalPrompt && (
          <Grid item pl={2}>
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onJsonViewerOpen && onJsonViewerOpen(message.originalPrompt);
              }}
            >
              <Grid container alignItems="center">
                <Forum style={{ marginRight: 4 }} />
                <Typography variant="caption">Prompt</Typography>
              </Grid>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
