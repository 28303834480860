import moment from 'moment-timezone';
import { Dispatch } from 'redux';
import { SET_TIME, TimeActionTypes } from '../config/ActionTypes';
import { dateFormat } from '../config/types';

export const setTime = () => (dispatch: Dispatch<TimeActionTypes>) => {
  dispatch(settingTime(moment().format(dateFormat)));
};

const settingTime = (time: string): TimeActionTypes => ({
  type: SET_TIME,
  time,
});
