import { CustomersState } from '../config/types';
import {
  CustomersActionTypes,
  FETCHING_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILED,
  CREATING_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILED,
  UPDATING_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  SET_CUSTOMER,
  UNSET_CUSTOMER,
  CLEAR_CUSTOMERS_STATE,
} from '../config/ActionTypes';

export const initialState: CustomersState = {
  loadingCustomers: false,
  customersFetched: false,
  customers: null,
  customer: null,
  customersErrorMessage: null,
  creatingCustomer: false,
  createdCustomer: null,
  createCustomerSuccess: false,
  createCustomerErrorMessage: null,
  updatingCustomer: false,
  updateCustomerSuccess: false,
  updateCustomerErrorMessage: null,
};

export function customersReducer(state = initialState, action: CustomersActionTypes): CustomersState {
  switch (action.type) {
    case FETCHING_CUSTOMERS:
      return {
        ...state,
        loadingCustomers: true,
        customers: null,
        customersErrorMessage: null,
      };

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingCustomers: false,
        customersFetched: true,
        customers: action.customers,
        customersErrorMessage: null,
        updateCustomerSuccess: false,
      };

    case FETCH_CUSTOMERS_FAILED:
      return {
        ...state,
        loadingCustomers: false,
        customersFetched: true,
        customers: null,
        customersErrorMessage: action.error,
      };

    case SET_CUSTOMER:
      const newCustomer = state.customers?.find((c) => c.customerId === Number(action.customerId)) || null;
      return {
        ...state,
        customer: newCustomer,
      };

    case UNSET_CUSTOMER:
      return {
        ...state,
        customer: null,
      };

    case CREATING_CUSTOMER:
      return {
        ...state,
        creatingCustomer: true,
        createCustomerSuccess: false,
        createCustomerErrorMessage: null,
      };

    case CREATE_CUSTOMER_SUCCESS:
      const newCustomers = state.customers ? [...state.customers] : [];
      newCustomers.push(action.customer);

      return {
        ...state,
        customers: newCustomers,
        creatingCustomer: false,
        createdCustomer: action.customer,
        createCustomerSuccess: true,
        createCustomerErrorMessage: null,
      };

    case CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        creatingCustomer: false,
        createCustomerSuccess: false,
        createCustomerErrorMessage: action.error,
      };

    case UPDATING_CUSTOMER:
      return {
        ...state,
        updatingCustomer: true,
        updateCustomerSuccess: false,
        updateCustomerErrorMessage: null,
      };

    case UPDATE_CUSTOMER_SUCCESS:
      const updatedCustomers =
        state.customers &&
        state.customers.map((item) => {
          if (item.customerId !== action.customer.customerId) {
            return item;
          }
          return {
            ...action.customer,
          };
        });

      return {
        ...state,
        updatingCustomer: false,
        updateCustomerSuccess: true,
        customers: updatedCustomers,
        updateCustomerErrorMessage: null,
      };

    case UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        updatingCustomer: false,
        updateCustomerSuccess: false,
        updateCustomerErrorMessage: action.error,
      };

    case CLEAR_CUSTOMERS_STATE:
      return {
        ...state,
        customersErrorMessage: null,
        createCustomerSuccess: false,
        createCustomerErrorMessage: null,
        updateCustomerSuccess: false,
        updateCustomerErrorMessage: null,
      };

    default:
      return state;
  }
}
