import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { Card, Button } from '@material-ui/core';
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import { CurbhubLogoLarge } from '../../assets/images';
import { login, clearLoginError } from '../../store/actions/auth.actions';
import { setToast, removeToast } from '../../store/actions/toast.actions';
import { LoginRequest, RootState } from '../../store/config/types';
import schemas from '../../utils/schemas';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Snackbar from '../../components/Snackbar/Snackbar';
import InputField from '../../components/SpecialInput/InputField';
import Spinner from '../../components/Spinner/Spinner';

interface LoginValues {
  email: string;
  password: string;
}

const SNACKBAR_ICON: { [key: string]: any } = {
  success: CheckCircle,
  danger: ErrorIcon,
};

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    toast: state.toast,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onLogin: (loginRequest: LoginRequest) => dispatch(login(loginRequest)),
  clearLoginError: () => dispatch(clearLoginError()),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  onCloseToast: () => dispatch(removeToast()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Login({ auth, toast, onLogin, clearLoginError, setToast, onCloseToast }: PropsFromRedux) {
  const history: History = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (auth.loggedIn) {
      history.push('/home');
    }
  });

  useEffect(() => {
    if (submitting && auth.logInErrorMessage) {
      setSubmitting(false);
      setToast('Wrong login credentials', 'danger');
      clearLoginError();
    }
  }, [submitting, auth.logInErrorMessage, setSubmitting, setToast, clearLoginError]);

  const submitForm = (values: LoginValues) => {
    setSubmitting(true);

    const loginRequest: LoginRequest = {
      email: values.email,
      password: values.password,
      loginType: 'SHIPPER',
      userType: 'SHIPPER_USER',
    };

    onLogin(loginRequest);
  };

  return (
    <div className="Auth">
      <div className="FormContainer">
        <div className="FormSubContainer">
          <img className="Logo" src={CurbhubLogoLarge} alt="CurbhubLogoLarge" />
          <Card className="Form">
            {auth.loggingIn ? (
              <Spinner />
            ) : (
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={schemas.LoginSchema}
                onSubmit={submitForm}
              >
                <Form>
                  <GridContainer>
                    <GridItem xs={12}>
                      <InputField placeholder="Email" name="email" type="text" />
                    </GridItem>
                    <GridItem xs={12}>
                      <InputField placeholder="Password" name="password" type="password" />
                    </GridItem>
                  </GridContainer>
                  <Button className="SubmitButton" type="submit" variant="contained" color="primary">
                    LOGIN
                  </Button>
                </Form>
              </Formik>
            )}
          </Card>
        </div>
      </div>
      <Snackbar
        place="bl"
        color={toast.messageType}
        icon={SNACKBAR_ICON[toast.messageType]}
        message={toast.message}
        open={toast.show}
        closeNotification={onCloseToast}
        close
      />
    </div>
  );
}

export default connector(Login);
