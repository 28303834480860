import {
  WarehousesActionTypes,
  FETCHING_WAREHOUSES,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSES_FAILED,
  DELETING_WAREHOUSE,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_FAILED,
  CREATING_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILED,
  UPDATING_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_FAILED,
  CLEAR_WAREHOUSES_STATE,
} from '../config/ActionTypes';
import { Warehouse, WarehousesState } from '../config/types/warehouses.types';

export const initialState: WarehousesState = {
  loadingWarehouses: false,
  warehousesFetched: false,
  warehouses: null,
  warehouse: null,
  warehousesErrorMessage: null,
  creatingWarehouse: false,
  createdWarehouse: null,
  createWarehouseSuccess: false,
  createWarehouseErrorMessage: null,
  updatingWarehouse: false,
  updateWarehouseSuccess: false,
  updateWarehouseErrorMessage: null,
  deletingWarehouse: false,
  deleteWarehouseSuccess: false,
  deleteWarehouseErrorMessage: null,
};

export function warehousesReducer(state = initialState, action: WarehousesActionTypes): WarehousesState {
  switch (action.type) {
    case FETCHING_WAREHOUSES:
      return {
        ...state,
        loadingWarehouses: true,
        warehouses: null,
        warehousesErrorMessage: null,
      };

    case FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        loadingWarehouses: false,
        warehousesFetched: true,
        warehouses: action.warehouses,
        warehousesErrorMessage: null,
      };

    case FETCH_WAREHOUSES_FAILED:
      return {
        ...state,
        loadingWarehouses: false,
        warehousesFetched: true,
        warehouses: null,
        warehousesErrorMessage: action.error,
      };

    case CREATING_WAREHOUSE:
      return {
        ...state,
        creatingWarehouse: true,
        createWarehouseSuccess: false,
        createWarehouseErrorMessage: null,
      };

    case CREATE_WAREHOUSE_SUCCESS:
      const newWarehouses = state.warehouses ? [...state.warehouses] : [];
      newWarehouses.push(action.warehouse);

      return {
        ...state,
        warehouses: newWarehouses,
        creatingWarehouse: false,
        createdWarehouse: action.warehouse,
        createWarehouseSuccess: true,
        createWarehouseErrorMessage: null,
      };

    case CREATE_WAREHOUSE_FAILED:
      return {
        ...state,
        creatingWarehouse: false,
        createWarehouseSuccess: false,
        createWarehouseErrorMessage: action.error,
      };

    case UPDATING_WAREHOUSE:
      return {
        ...state,
        updatingWarehouse: true,
        updateWarehouseSuccess: false,
        updateWarehouseErrorMessage: null,
      };

    case UPDATE_WAREHOUSE_SUCCESS:
      const updatedWarehouses =
        state.warehouses &&
        state.warehouses.map((item) => {
          if (item.warehouseId !== action.warehouse.warehouseId) {
            return item;
          }

          return {
            ...action.warehouse,
          };
        });

      return {
        ...state,
        updatingWarehouse: false,
        updateWarehouseSuccess: true,
        warehouses: updatedWarehouses,
        updateWarehouseErrorMessage: null,
      };

    case UPDATE_WAREHOUSE_FAILED:
      return {
        ...state,
        updatingWarehouse: false,
        updateWarehouseSuccess: false,
        updateWarehouseErrorMessage: action.error,
      };

    case DELETING_WAREHOUSE:
      return {
        ...state,
        deletingWarehouse: true,
        deleteWarehouseSuccess: false,
        deleteWarehouseErrorMessage: null,
      };

    case DELETE_WAREHOUSE_SUCCESS:
      const nonDeletedWarehouses =
        state.warehouses && state.warehouses?.filter((d: Warehouse) => d.warehouseId !== action.warehouseId);

      return {
        ...state,
        deletingWarehouse: false,
        deleteWarehouseSuccess: true,
        warehouses: nonDeletedWarehouses,
        deleteWarehouseErrorMessage: null,
      };

    case DELETE_WAREHOUSE_FAILED:
      return {
        ...state,
        deletingWarehouse: false,
        deleteWarehouseSuccess: false,
        deleteWarehouseErrorMessage: action.error,
      };

    case CLEAR_WAREHOUSES_STATE:
      return {
        ...state,
        warehousesErrorMessage: null,
        createWarehouseSuccess: false,
        createWarehouseErrorMessage: null,
        updateWarehouseSuccess: false,
        updateWarehouseErrorMessage: null,
        deleteWarehouseErrorMessage: null,
      };

    default:
      return state;
  }
}
