import { useEffect, useState } from 'react';
import { PaymentService } from '../../services/payment.service';

import { Grid } from '@mui/material';
import Spinner from '../../components/Spinner/Spinner';

import { PaymentMethod } from '@stripe/stripe-js';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import { CheckCircle } from '@material-ui/icons';
import PaymentCreate from './PaymentCreate';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { setToast } from '../../store/actions/toast.actions';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Payment({ setToast }: PropsFromRedux) {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  // const [customerPortalLink, setCustomerPortalLink] = useState<string>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!paymentMethods) loadPaymentMethods();
    // if (!customerPortalLink) {
    //   PaymentService.getCustomerPortalLink().then((link) => {
    //     setCustomerPortalLink(link);
    //   });
    // }
  }, []);

  useEffect(() => {
    // "?setup_intent=&setup_intent_client_secret=&redirect_status=succeeded"
    const sarchParams = new URLSearchParams(location.search);
    const setupIntentStatus = sarchParams.get('redirect_status');
    if (setupIntentStatus === 'succeeded') {
      const setupIntentId = sarchParams.get('setup_intent');
      PaymentService.cofirmSetupIntent(setupIntentId ?? '').then(() => {
        history.replace('/payment');
      });
    }
  }, [location]);

  const handleDeleteMethod = async (id: string) => {
    try {
      const res = await PaymentService.deletePaymentMethod(id);
      if (res.data.data) setToast(res.statusText, 'success');
      else setToast('Error', 'danger');
      loadPaymentMethods();
    } catch (e: any) {
      setToast(e.message, 'danger');
    }
  };
  const loadPaymentMethods = () => {
    setPaymentMethods(undefined);
    PaymentService.getPaymentMethods()
      .then((methods) => {
        setPaymentMethods(methods);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleCreateMethod = () => {
    setOpenCreateDialog(true);
  };

  const cardList = (paymentMethods ?? []).map((method, methodIndex) => {
    const isDefault = method.metadata.default ? true : false;
    return (
      <Grid item key={`card-${methodIndex}`} position={'relative'}>
        <Cards
          expiry={`${('00' + method.card?.exp_month).slice(-2)}/${method.card?.exp_year}`}
          name={`${method.billing_details.name ?? ''}`}
          number={`************${method.card?.last4}`}
          cvc={'*'}
          preview={true}
          issuer={method.card?.brand}
        />
        <div style={{ top: 24, left: 24, position: 'absolute' }}>
          {isDefault && (
            <CheckCircle
              style={{
                color: 'green',
                width: 32,
                height: 32,
                borderRadius: '50%',
                backgroundColor: 'white',
              }}
            />
          )}
        </div>
        <div style={{ marginTop: 8 }}>
          <Link onClick={() => handleDeleteMethod(method.id)} style={{ marginLeft: 8, cursor: 'pointer' }}>
            DELETE
          </Link>
        </div>
      </Grid>
    );
  });
  return (
    <div className="payment-page">
      <Card>
        <CardHeader color="primary" className="card-header">
          Payment Methods
        </CardHeader>
        <CardBody>
          <Grid container>
            {paymentMethods ? (
              <>
                <Grid container spacing={4}>
                  {cardList}
                </Grid>
                {paymentMethods.length === 0 && (
                  <Grid item key={`card-new`}>
                    <Link onClick={handleCreateMethod} style={{ cursor: 'pointer' }}>
                      <span style={{ opacity: 0.3 }}>
                        <Cards expiry={``} name={``} number={``} cvc={''} />
                      </span>
                      Create new method
                    </Link>
                  </Grid>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </Grid>
        </CardBody>
      </Card>
      <Dialog
        open={openCreateDialog}
        title="Create new method"
        onClose={() => {
          setOpenCreateDialog(false);
        }}
      >
        <DialogTitle>Create new payment method</DialogTitle>
        <DialogContent>
          <PaymentCreate />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connector(Payment);
