import { useEffect, useState, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store/config/types';
import { maskPhone } from '../../utils/string.helpers';
import TableList from '../../components/TableList/TableList';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import SpecialInput from '../../components/SpecialInput/Input';
import AddButton from '../../components/CustomButtons/AddButton';
import Spinner from '../../components/Spinner/Spinner';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { ShipperUser, ShipperUserGroups } from '../../store/config/types/shipperUser.types';
import { shippersService } from '../../services/shippers.service';
import { ThunkDispatch } from 'redux-thunk';
import * as actions from '../../store/actions/toast.actions';

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function UserControl({ auth, setToast }: PropsFromRedux) {
  const history = useHistory();
  const [shipperUserToDelete, setShipperUserToDelete] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [shipperUsers, setShipperUsers] = useState<ShipperUser[] | undefined>(undefined);
  const [refreshList, setRefreshList] = useState(true);

  useEffect(() => {
    if (refreshList)
      shippersService
        .fetchShipperUsers()
        .then((shipperUsers) => {
          setShipperUsers(
            shipperUsers,
            //   ?.filter((shipperUser) => shipperUser.shipperUserId !== auth.account?.shipperUserId),
          );
          setRefreshList(false);
        })
        .catch((error) => {
          setToast(error, 'danger');
        });
  }, [auth, refreshList]);

  const handleCreateShipperUser = () => history.push('/user-control/create');

  const handleUpdateShipperUser = (shipperUserId: number) => history.push(`/user-control/${shipperUserId}/update`);

  const handleDeleteShipperUser = (shipperUserId: number) => setShipperUserToDelete(shipperUserId);

  const deleteShipperUser = () => {
    // onDeleteShipper(shipperToDelete!);
    shipperUserToDelete &&
      shippersService
        .deleteShipperUser(shipperUserToDelete)
        .then(() => {
          setRefreshList(true);
        })
        .catch(() => {
          setToast('Can not delete user', 'danger');
        });
    setShipperUserToDelete(null);
  };

  const handleCloseDialog = () => setShipperUserToDelete(null);

  const searchTextHandler = (text: string) => setSearchText(text);

  const filters = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <SpecialInput
          element={{
            elementType: 'input',
            elementConfig: { type: 'text', placeholder: 'Search..' },
            value: searchText,
            validation: {},
          }}
          onChange={(e) => searchTextHandler(e)}
        />
      </GridItem>
    </GridContainer>
  );

  let shipperUsersContent = null;
  if (shipperUsers) {
    let shipperUsersToShow = shipperUsers || [];

    if (searchText !== '') {
      shipperUsersToShow = shipperUsersToShow?.filter((shipperUser) => {
        return ['firstName', 'lastName', 'email'].some((key) => {
          return (shipperUser as any)[key]?.toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    const options = shipperUsersToShow.map((shipperUser) => {
      return [
        shipperUser.shipperUserId,
        shipperUser.firstName,
        shipperUser.lastName,
        shipperUser.homeAddress,
        maskPhone(shipperUser.phoneNumber),
        shipperUser.email,
        ShipperUserGroups.find((group) => shipperUser.groups && group.GroupId === shipperUser.groups[0].GroupId)
          ?.name ?? '-',
      ];
    });

    shipperUsersContent = (
      <TableList
        title="Users"
        head={['Id', 'First Name', 'Last Name', 'Home address', 'Phone number', 'Email', 'Type', '']}
        onEdit={handleUpdateShipperUser}
        onDelete={handleDeleteShipperUser}
        edit // TODO try to remove this once services are wired up again
        delete
        filters={filters}
        data={options}
      />
    );
  } else {
    shipperUsersContent = <Spinner />;
  }

  return (
    <Fragment>
      {shipperUsersContent}
      <AddButton onClick={handleCreateShipperUser} />
      <CustomDialog
        title={'Confirm'}
        description={'¿Are you sure you want to delete this user?'}
        open={shipperUserToDelete !== null}
        onConfirmation={deleteShipperUser}
        handleClose={handleCloseDialog}
        okButtonText={'Yes'}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
}

export default connector(UserControl);
