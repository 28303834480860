import { ArrowRight, MoreVert } from '@material-ui/icons';
import { CustomerLocationOrder } from '../../store/config/types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { NestedMenuItem } from 'mui-nested-menu';
import { getPackageGroupLabel } from '../../utils/deliveryOrders.helpers';

interface DeliveryOrderActionsProps {
  deliveryOrder: CustomerLocationOrder;
  // eslint-disable-next-line no-unused-vars
  onMenuSelect?: (deliveryOrder: CustomerLocationOrder, menuAction: string, id?: number) => void;
}

export default function DeliveryOrderActions({ deliveryOrder, onMenuSelect }: DeliveryOrderActionsProps) {
  const [moreMenuElement, setMoreMenuElement] = useState<HTMLElement | null>(null);

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setMoreMenuElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMoreMenuElement(null);
  };
  const handleMenuSelect = (action: string, id?: number) => {
    onMenuSelect && onMenuSelect(deliveryOrder, action, id);
    handleMenuClose();
  };

  const hasPOD = deliveryOrder.packageGroups.some((packageGroup) => packageGroup.hasPOD);
  const deletable = deliveryOrder.deletable;

  return (
    <>
      <IconButton size="small" style={{ width: 24, height: 24 }} onClick={handleMoreMenuClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="delivery-order-menu"
        className="delivery-order-popup-menu"
        anchorEl={moreMenuElement}
        open={moreMenuElement ? true : false}
        onClose={handleMenuClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span className="menu-title">
          PG# {deliveryOrder.packageGroups.map((packageGroup) => `${packageGroup.packageGroupId}`).join(', ')}
        </span>
        <MenuItem
          disabled={!hasPOD}
          key={`delivery-order-pod`}
          onClick={(event) => {
            event.stopPropagation();
            handleMenuSelect('pod');
          }}
        >
          <span>Proof Of Delivery</span>
        </MenuItem>
        {deliveryOrder.packageGroups.length > 1 && deletable ? (
          <NestedMenuItem
            rightIcon={<ArrowRight />}
            label="Edit"
            parentMenuOpen={moreMenuElement ? true : false}
            disabled={!deletable}
          >
            {deliveryOrder.packageGroups.map((packageGroup) => {
              return (
                <MenuItem
                  key={`delivery-order-edit-${packageGroup.packageGroupId}`}
                  onClick={() => {
                    handleMenuSelect('edit', packageGroup.packageGroupId);
                  }}
                >
                  {getPackageGroupLabel(packageGroup)}
                </MenuItem>
              );
            })}
          </NestedMenuItem>
        ) : (
          <MenuItem
            key={`delivery-order-edit`}
            onClick={(event) => {
              event.stopPropagation();
              handleMenuSelect('edit', deliveryOrder.packageGroups[0].packageGroupId);
            }}
            disabled={!deletable}
          >
            <span>Edit</span>
          </MenuItem>
        )}
        <MenuItem
          disabled={!deletable}
          key={`delivery-order-delete`}
          color="secondary"
          onClick={(event) => {
            event.stopPropagation();
            handleMenuSelect('delete');
          }}
        >
          <span style={{ color: 'red' }}>Delete</span>
        </MenuItem>
      </Menu>
    </>
  );
}
