import { Dispatch } from 'redux';
import { SET_TOAST, REMOVE_TOAST, ToastActionTypes } from '../config/ActionTypes';

export const setToast = (message: string, messageType: string) => (dispatch: Dispatch<ToastActionTypes>) => {
  dispatch(settingToast(message, messageType));
  setTimeout(() => {
    dispatch(removeToast());
  }, 5000);
};

const settingToast = (message: string, messageType: string): ToastActionTypes => ({
  type: SET_TOAST,
  message,
  messageType,
});

export const removeToast = (): ToastActionTypes => {
  return {
    type: REMOVE_TOAST,
  };
};
