import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { RootState } from '../../store/config/types';
import * as actions from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';
import { ShipperUser, ShipperUserGroups } from '../../store/config/types/shipperUser.types';
import SelectField from '../../components/SpecialInput/SelectField';
import { MenuItem } from '@mui/material';
import { shippersService } from '../../services/shippers.service';

interface ShipperUserParams {
  shipperUserId?: string;
}

interface ShipperUserValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  homeAddress: string;
  groupId: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(actions.setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
const ShipperUserForm = ({ setToast }: PropsFromRedux) => {
  const history = useHistory();
  const { shipperUserId } = useParams<ShipperUserParams>();
  const editMode: boolean = Boolean(shipperUserId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedShipperUser, setSelectedShipperUser] = useState<ShipperUser | null>(null);
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    if (editMode && !selectedShipperUser && shipperUserId) {
      shippersService.getShipperUser(+shipperUserId).then((response) => {
        if (response) {
          setSelectedShipperUser(response);
          setAddress(response.homeAddress ?? '');
        }
      });
    }
  }, [editMode, selectedShipperUser, shipperUserId]);

  useEffect(() => {
    // if (submitting) {
    //   setToast(`User successfully ${editMode ? 'updated' : 'created'}`, 'success');
    //   history.push('/user-control');
    //   setSubmitting(false);
    //   setToast(`An error has ocurred while ${editMode ? 'updating' : 'creating'} User`, 'danger');
    // }
  }, [submitting, history, setSubmitting, setToast]);

  const handleGoBack = () => history.push('/user-control');

  const submitForm = (values: ShipperUserValues) => {
    const shipperUserRequest: ShipperUser = {
      ...values,
    };

    setSubmitting(true);

    if (editMode) {
      shipperUserRequest.shipperUserId = selectedShipperUser?.shipperUserId;
      shippersService
        .updateShipperUser(shipperUserRequest)
        .then((response) => {
          if (response) {
            setToast(`User successfully updated`, 'success');
            history.push('/user-control');
          } else {
            setToast(`An error has ocurred while updating User`, 'danger');
          }
        })
        .catch(() => {
          setToast(`An error has ocurred while updating User`, 'danger');
        })
        .finally(() => {
          setSubmitting(false);
        });
      // onUpdateShipper(shipperRequest);
    } else {
      shippersService
        .createShipperUser(shipperUserRequest)
        .then((response) => {
          if (response) {
            setToast(`User successfully created`, 'success');
            history.push('/user-control');
          } else {
            setToast(`An error has ocurred while creating User`, 'danger');
          }
        })
        .catch(() => {
          setToast(`An error has ocurred while creating User`, 'danger');
        })
        .finally(() => {
          setSubmitting(false);
        });
      // onCreateShipper(shipperRequest);
    }
  };

  if (editMode && !selectedShipperUser) {
    return <Spinner />;
  }

  return (
    <div className="shipper-form">
      <Formik
        initialValues={{
          firstName: selectedShipperUser ? selectedShipperUser.firstName! : '',
          lastName: selectedShipperUser ? selectedShipperUser.lastName! : '',
          email: selectedShipperUser ? selectedShipperUser.email! : '',
          password: selectedShipperUser ? selectedShipperUser.password! : '',
          phoneNumber: selectedShipperUser ? selectedShipperUser.phoneNumber! : '',
          homeAddress: selectedShipperUser ? selectedShipperUser.homeAddress! : '',
          shipperId: selectedShipperUser ? selectedShipperUser.shipperUserId : shipperUserId,
          groupId: (selectedShipperUser?.groups ?? [{ GroupId: 2 }])[0].GroupId,
        }}
        validationSchema={schemas.ShipperUserSchema}
        onSubmit={submitForm}
      >
        {({ setFieldValue }) => (
          <Form>
            <Card profile>
              <CardHeader color="primary" className="card-header">
                {editMode && selectedShipperUser ? (
                  <h4>{`${selectedShipperUser.firstName} ${selectedShipperUser.lastName}`}</h4>
                ) : (
                  <h4>New User</h4>
                )}
              </CardHeader>
              <CardBody profile>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="First name" name="firstName" type="text" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Last name" name="lastName" type="text" />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={editMode ? 6 : 4}>
                    <InputField placeholder="Email" name="email" type="text" disabled={editMode} />
                  </GridItem>
                  {!editMode && (
                    <GridItem xs={12} sm={4}>
                      <InputField placeholder="Password" name="password" type="password" />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={editMode ? 6 : 4}>
                    <SelectField
                      placeholder="Type"
                      name="groupId"
                      leftAligned
                      disabled={editMode}
                      values={ShipperUserGroups.map((group) => (
                        <MenuItem key={`shipper-user-group-${group.GroupId}`} value={group.GroupId}>
                          {group.name}
                        </MenuItem>
                      ))}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8}>
                    <GoogleAutocomplete
                      address={address}
                      setAddress={setAddress}
                      setFieldValue={setFieldValue}
                      usesAllGoogleProps={true}
                      inputName="homeAddress"
                      placeholderName={'Home address'}
                      inputSize={12}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <MaskedPhoneInput placeholder="Phone number" name="phoneNumber" setfieldvalue={setFieldValue} />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <div className="btn-group">
                      <Button type="submit" className="submit-button" color="primary">
                        {editMode ? 'UPDATE' : 'CREATE'} USER
                      </Button>
                      <Button onClick={handleGoBack} className="cancel-button" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connector(ShipperUserForm);
