import { DndTimeLineChartItem } from './DndTimelineChart';
// import { DeleteOutline } from '@material-ui/icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DndTimelineItem from './DndTimelineItem';
import { ReactElement } from 'react';
// import { MINIMUM_ITEM_WIDTH } from './DndTimelineRow';

interface DndTimelineRowProps {
  items: DndTimeLineChartItem[];
  additionAreaWidth?: number;
  isDragging?: boolean;
  minutesToPixel: number;
  visible?: boolean;
  /* eslint-disable */
  itemRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  itemToolTipRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  additionAreaRender?: (isDragStarts: boolean) => ReactElement;
  onItemClick?: (itemIndex: number) => void;
  onItemDoubleClick?: (itemIndex: number) => void;
  /* eslint-enable */
}
export function DndTimelineRecycleRow({
  items,
  additionAreaWidth,
  isDragging,
  visible,
  itemRender,
  itemToolTipRender,
  additionAreaRender,
  onItemClick,
  onItemDoubleClick,
}: DndTimelineRowProps) {
  return (
    <div className="dndt-row" style={visible ? {} : { display: 'none' }}>
      <div className="dndt-recyccle-addition-area" style={{ width: additionAreaWidth ?? 24, textAlign: 'center' }}>
        {additionAreaRender ? (
          additionAreaRender(isDragging ?? false)
        ) : (
          <span className="dndt-recycle-row-icon">UNASSIGNED</span>
        )}
      </div>
      <Droppable droppableId={`unassigned`} direction="horizontal">
        {(provided, snapshot) => (
          <div
            // ${
            //   items.length === 0 ? ' empty' : ''
            // }
            className={`dndt-recycle-row${snapshot.isDraggingOver ? ' highlight' : ''}${
              isDragging ? ' drag-over' : ''
            } visible`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item, itemIndex) => {
              const draggableId = `r${-1}-i${itemIndex}`;

              // const itemWidth = Math.max(
              //   item.end.diff(item.start, 'minutes') * (minutesToPixel ?? 1),
              //   MINIMUM_ITEM_WIDTH,
              // );
              return (
                <>
                  <Draggable
                    key={draggableId}
                    draggableId={draggableId}
                    index={itemIndex}
                    isDragDisabled={!item.dragable}
                    disableInteractiveElementBlocking={true}
                  >
                    {(provided) => (
                      <DndTimelineItem
                        item={item}
                        rowIndex={-1}
                        itemIndex={itemIndex}
                        itemWidth={30}
                        itemRender={itemRender}
                        itemToolTipRender={itemToolTipRender}
                        provided={provided}
                        key={`item-${-1}-${itemIndex}`}
                        onItemClick={onItemClick}
                        onItemDoubleClick={onItemDoubleClick}
                      />
                    )}
                  </Draggable>
                  <div className="dndt-recycle-row-spacer">&nbsp;&nbsp;</div>
                </>
              );
            })}
          </div>
        )}
      </Droppable>
    </div>
  );
}
