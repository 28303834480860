import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Pie, PieChart, Rectangle, Tooltip, XAxis, YAxis } from 'recharts';

export interface UserDataChartProps {
  data: any[];
  debugMode?: boolean;
  captions?: any;
  type: string;
  // eslint-disable-next-line
  onDisplayAllClick?: (data: any) => void;
}
export default function UserDataChart({ data, type }: UserDataChartProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [chartWidth, setChartWidth] = useState(100);
  const colors = ['#1B96C6', '#EF767A', '#456990', '#49DCB1', '#EEB868'];

  useEffect(() => {
    setChartWidth(Math.min(containerRef?.current?.clientWidth ?? 100, 500));
  }, [containerRef]);
  // Prepare data
  if (!Array.isArray(data) || data.length == 0) {
    return (
      <Grid item p={1}>
        No data to display
      </Grid>
    );
  }

  let dataKey = 'name';
  const valueKeys: string[] = [];
  if (data.length == 1) {
    data = Object.keys(data).map((key) => ({
      name: key,
      value: data[0][key],
    }));
    valueKeys.push('value');
  } else {
    const keys = Object.keys(data[0]);
    if (keys.length == 1) {
      data = data.map(record => {
        const name = Object.keys(record)[0]
        const value = record[name]
        return { name, value }
      })
      dataKey = 'name';
      valueKeys.push('value')
    } else {
      dataKey = keys[0];
      valueKeys.push(...keys.filter((key) => (key != dataKey)));
    }
  }
  console.log(dataKey, valueKeys)
  return (
    <Grid item width="100%" height="100%" ref={containerRef}>
      {type == 'pie' && (
        <PieChart width={chartWidth} height={chartWidth} >
          <Legend />
          <Tooltip />
          <Pie
            nameKey={dataKey}
            dataKey={valueKeys[0]}
            data={data.map((record: any, colorIndex: number) => {
              return {
                ...record,
                fill: colors[colorIndex % 5],
              };
            })}
            label
          />
        </PieChart>
      )}
      {type == 'bar' && (
        <BarChart width={chartWidth} height={chartWidth * 0.75} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} angle={-20} textAnchor="end" />
          <YAxis />
          <Tooltip />

          {valueKeys.map((valueKey, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey={valueKey}
              fill={colors[index % colors.length]}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
        </BarChart>
      )}
      {type == 'line' && (
        <LineChart width={chartWidth} height={chartWidth * 0.75} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} angle={-20} textAnchor="end" />
          <YAxis />
          <Tooltip />

          {valueKeys.map((valueKey, index) => (
            <Line
              key={`line-${index}`}
              dataKey={valueKey}
              type="monotone"
              fill={colors[index % colors.length]}
              activeDot={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
        </LineChart>
      )}
      {type == 'area' && (
        <AreaChart width={chartWidth} height={chartWidth * 0.75} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} angle={-20} textAnchor="end" />
          <YAxis />
          <Tooltip />

          {valueKeys.map((valueKey, index) => (
            <Area
              key={`line-${index}`}
              dataKey={valueKey}
              type="monotone"
              fill={colors[index % colors.length]}
              activeDot={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
        </AreaChart>
      )}
      {type == 'stack' && (
        <BarChart width={chartWidth} height={chartWidth * 0.75} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} angle={-20} textAnchor="end" />
          <YAxis />
          <Tooltip />

          {valueKeys.map((valueKey, index) => (
            <Bar
              stackId='a'
              key={`stack-${index}`}
              dataKey={valueKey}
              fill={colors[index % colors.length]}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
        </BarChart>
      )}
    </Grid>
  );
}
