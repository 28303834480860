import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../store/config/types';

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Analysis({ auth }: PropsFromRedux) {
  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <iframe
        title="googleReport"
        src={auth?.account?.shipper?.additionalInfo?.analysisPage}
        style={{ border: 0, width: '100%', display: 'flex', flexGrow: 1 }}
        allowFullScreen
      />
    </div>
  );
}

export default connector(Analysis);
