export interface ShipperUser {
  shipperUserId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  homeAddress?: string;
  shipperId?: number;
  groupIds?: number[];
  groups?: Groups[];
}

export interface Groups {
  GroupId: number;
  name: string;
}

export const ShipperUserGroups: Groups[] = [
  { GroupId: 1, name: 'Admin' },
  { GroupId: 2, name: 'Restricted User' },
];
