import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';

interface ThirdPartyInfoCaptureProps {
  requiredInfo: any;
  /* eslint-disable */
  onChange?: (capturedInfo: any) => void;
  onCancel?: () => void;
  /* eslint-enable */
}
interface SelectItem {
  title: string;
  value: string;
}
interface InfoFieldProps {
  name: string;
  info: { type: string; title: string; value: string; items?: SelectItem[] };
  // eslint-disable-next-line
  onChange?: (name: string, value: string) => void;
}

export const infoToValues = (info: any) => {
  const keys = Object.keys(info);
  let values: any = {};
  keys.forEach((key) => {
    values[key] = info[key].value;
  });
  return values;
};

export function ThirdPartyInfoCapture({ requiredInfo, onChange }: ThirdPartyInfoCaptureProps) {
  const [capturedInfo, setCapturedInfo] = useState<any>(infoToValues(requiredInfo));
  const handleChange = (name: string, value: string) => {
    let info = { ...capturedInfo };
    info[name] = value;
    setCapturedInfo(info);
    onChange && onChange(info);
  };
  const keys = Object.keys(requiredInfo);
  const rows = keys.map((key) => {
    return <InfoField name={key} info={requiredInfo[key]} key={`info-${key}`} onChange={handleChange} />;
  });
  return (
    <Grid container p={2}>
      <h3>Options</h3>
      {rows}
    </Grid>
  );
}

const InfoField = ({ name, info, onChange }: InfoFieldProps) => {
  const [value, setValue] = useState<string>(info.value);
  const handleChange = (value: string) => {
    setValue(value);
    onChange && onChange(name, value);
  };
  let field = <div></div>;
  switch (info.type) {
    case 'text':
      field = (
        <TextField
          fullWidth
          id={`field-${name}-label`}
          label={info.title}
          variant="outlined"
          value={value}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      );
      break;
    case 'select':
      field = (
        <FormControl fullWidth>
          <InputLabel id={`field-${name}-label`}>{info.title}</InputLabel>
          <Select
            labelId={`field-${name}-label`}
            id={`field-${name}`}
            value={value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            label={info.title}
            variant="outlined"
          >
            {info.items?.map((item) => (
              <MenuItem key={`select-item-${item.value}`} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
  }
  return (
    <Grid container mt={2}>
      {field}
    </Grid>
  );
};
