import { Badge, Checkbox, Grid, IconButton, List, ListItemText, Popover, TextField } from '@mui/material';
import { FilterType } from './FilterableTable';
import { Check, FilterListOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { ListItem } from '@material-ui/core';
/* eslint-disable */
type FilterChangedFunc = (newValue: any) => void;
type MappingFunc = (value: string) => string;
/* eslint-enable */
interface ColumnFilterProps {
  type: FilterType;
  value: any;
  values?: string[];
  onFilterChanged: FilterChangedFunc;
  getValueCaption?: MappingFunc;
}

export default function ColumnFilter({ type, value, values, onFilterChanged, getValueCaption }: ColumnFilterProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [input, setInput] = useState<any>(value);
  useEffect(() => {
    setInput(value);
  }, [value]);
  useEffect(() => {}, [values]);
  const handleClose = () => {
    setAnchorEl(null);
    setInput(undefined);
  };
  const handleApply = () => {
    onFilterChanged(input);
    handleClose();
  };
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge color="primary" variant="dot" invisible={!value || !value.length}>
          <FilterListOutlined />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <Grid container direction={'column'} p={1}>
          {type === 'string' ? (
            FilterFormString(input, setInput, handleApply)
          ) : type === 'date' ? (
            FilterFormDate(input, setInput)
          ) : type === 'boolean' ? (
            FilterFormBoolean(input, setInput)
          ) : values ? (
            FilterFormValues(input, values, setInput, getValueCaption)
          ) : (
            <></>
          )}
          <Grid item textAlign={'right'}>
            <IconButton onClick={handleApply}>
              <Check />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

function FilterFormString(value: string, onChange: FilterChangedFunc, onEnterPressed?: () => void) {
  return (
    <Grid item flexGrow={1}>
      <TextField
        variant="outlined"
        size="small"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key == 'Enter' && onEnterPressed) onEnterPressed();
        }}
      />
    </Grid>
  );
}

function FilterFormDate(value: Date, onChange: FilterChangedFunc) {
  return (
    <Grid item flexGrow={1}>
      <TextField
        variant="outlined"
        size="small"
        value={value}
        type="date"
        onChange={(e) => {
          onChange(Boolean(e.target.value));
        }}
      />
    </Grid>
  );
}

function FilterFormBoolean(value: boolean, onChange: FilterChangedFunc) {
  return (
    <Grid item flexGrow={1}>
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
    </Grid>
  );
}

function FilterFormValues(value: string[], values: string[], onChange: FilterChangedFunc, getCaption?: MappingFunc) {
  const [selected, setSelected] = useState(value ?? []);
  useEffect(() => {
    setSelected([]);
  }, [values]);

  const toggleCheck = (item: string) => {
    if (selected.includes(item)) {
      const newValue = [...selected.filter((v) => v !== item)];
      setSelected(newValue);
      onChange(newValue);
    } else {
      const newValue = [...selected, item];
      setSelected(newValue);
      onChange(newValue);
    }
  };
  return (
    <Grid item flexGrow={1}>
      <List>
        {values.map((item, index) => {
          return (
            <ListItem
              key={index}
              dense
              onClick={() => {
                toggleCheck(item);
              }}
            >
              <Checkbox checked={value && value.includes(item)} tabIndex={-1} disableRipple />
              <ListItemText primary={getCaption ? getCaption(item) : item} />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}
