import { Marker } from '@react-google-maps/api';
import { StopLocation } from '../../store/config/types';
import { customerLabel } from '../../utils/trips.helper';
interface CustomerMarkerProps {
  location: StopLocation;
  color?: string;
  highlight?: boolean;
  customerIndex?: number;
  // eslint-disable-next-line
  onHover?: (customerId: number, isOver: boolean) => void;
}
export default function CustomerMarker({ location, color, highlight, customerIndex, onHover }: CustomerMarkerProps) {
  const label = location.label ?? customerLabel(location.customer, customerIndex ? customerIndex + 1 : undefined);
  return (
    <>
      <Marker
        position={{ lat: location.latitude, lng: location.longitude }}
        onMouseOver={() => {
          onHover && onHover(location.customer?.customerId ?? 0, true);
        }}
        onMouseOut={() => {
          onHover && onHover(location.customer?.customerId ?? 0, false);
        }}
        icon={{
          path: 'M -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
          fillColor: 'black',
          strokeColor: 'black',
          strokeWeight: 15,
          fillOpacity: 1,
        }}
      />
      <Marker
        position={{ lat: location.latitude, lng: location.longitude }}
        onMouseOver={() => {
          onHover && onHover(location.customer?.customerId ?? 0, true);
        }}
        onMouseOut={() => {
          onHover && onHover(location.customer?.customerId ?? 0, false);
        }}
        icon={
          highlight
            ? {
                path: 'M -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
                fillColor: 'black',
                strokeColor: 'black',
                strokeWeight: 20,
                fillOpacity: 1,
              }
            : {
                path: 'M -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
                fillColor: color ?? 'red',
                strokeColor: color ?? 'red',
                strokeWeight: 8,
                fillOpacity: 1,
              }
        }
        label={{
          text: label,
          fontSize: '12px',
          color: highlight ? 'white' : 'black',
        }}
      />
    </>
  );
}
