import { Divider, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Courier } from '../../store/config/types';
import './TripsDnd.styles.scss';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import CourierWithAvatar from './CourierWithAvatar';
import { NestedMenuItem } from 'mui-nested-menu';
import { ArrowRight } from '@material-ui/icons';

export default function CourierSelector(props: {
  index: number;
  couriers: Courier[];
  courierId?: number;
  availableCouriersIds?: number[];
  DeliveryServiceProviders?: DeliveryServiceProvider[];
  DeliveryServiceProviderId?: number;
  selectedCourierIds?: number[];
  editable?: boolean;
  /* eslint-disable */
  onChange?: (
    tripIndex: number,
    courierId: number | undefined,
    prevCourierId: number | undefined,
    DeliveryServiceProviderId?: number,
  ) => boolean;
  /* eslint-enable */
}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [selectedId, setSelectedId] = useState<number | undefined>(props.courierId);
  const [selectedDeliveryServiceProviderId, setSelectedDeliveryServiceProviderId] = useState<number | undefined>(
    props.DeliveryServiceProviderId,
  );
  const open: boolean = element ? true : false;
  useEffect(() => {
    setSelectedId(props.courierId);
    setSelectedDeliveryServiceProviderId(props.DeliveryServiceProviderId);
  }, [props.courierId, props.DeliveryServiceProviderId]);
  const handleElementClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setElement(null);
  };
  const handleSelectMenu = (courierId: number | undefined, DeliveryServiceProviderId?: number) => {
    const doChange: boolean = props.onChange
      ? props.onChange(props.index, courierId, selectedId, DeliveryServiceProviderId)
      : true;
    if (doChange) {
      setSelectedId(courierId);
      setSelectedDeliveryServiceProviderId(DeliveryServiceProviderId);
    }
    handleMenuClose();
  };

  const getCouriersNameByCourierId = (courierId: number | undefined) => {
    const courier = props.couriers.find((courier) => courier.courierId === courierId);
    const DeliveryServiceProvider = props.DeliveryServiceProviders?.find(
      (vCourier) => vCourier.deliveryServiceProviderId === selectedDeliveryServiceProviderId,
    );
    return courier
      ? `${courier.firstName} ${courier.lastName}`
      : DeliveryServiceProvider
      ? `(${DeliveryServiceProvider.displayName})`
      : `Select a courier`;
  };
  const availableCourier = props.couriers.filter((courier) =>
    props.availableCouriersIds ? props.availableCouriersIds.includes(courier.courierId ?? 0) : true,
  );
  const unavailableCouriers = props.couriers.filter((courier) =>
    props.availableCouriersIds ? !props.availableCouriersIds.includes(courier.courierId ?? 0) : false,
  );
  return (
    <div className="courier-selector" key={`courier-selector-${props.index}`}>
      <span
        className={`courier-selector-link${selectedId ? '' : ' empty'}`}
        onClick={props.editable ?? true ? handleElementClick : () => {}}
      >
        {getCouriersNameByCourierId(selectedId)}
      </span>
      <Menu id="couriers-menu" anchorEl={element} open={open} onClose={handleMenuClose}>
        <MenuItem
          key={`courier-menu-item-0`}
          onClick={() => {
            handleSelectMenu(undefined);
          }}
        >
          <span className="none-menu-item">[None]</span>
        </MenuItem>
        {availableCourier.map((courier) => {
          if (!(props.selectedCourierIds && props.selectedCourierIds.includes(courier.courierId ?? 0)))
            return (
              <MenuItem
                key={`courier-menu-item-${courier.courierId}`}
                onClick={() => {
                  handleSelectMenu(courier.courierId);
                }}
              >
                {getCouriersNameByCourierId(courier.courierId)}
              </MenuItem>
            );
        })}
        {unavailableCouriers.length > 0 && (
          <>
            <Divider />
            <NestedMenuItem rightIcon={<ArrowRight />} label="Unavailable Couriers" parentMenuOpen={open}>
              {unavailableCouriers.map((courier) => (
                <MenuItem
                  key={`navailable-courier-menu-item-${courier.courierId ?? 0}`}
                  onClick={() => {
                    handleSelectMenu(courier.courierId, undefined);
                  }}
                >
                  <CourierWithAvatar avatarUrl={courier.photoUrl} name={`${courier.firstName} ${courier.lastName}`} />
                </MenuItem>
              ))}
            </NestedMenuItem>
          </>
        )}
        {props.DeliveryServiceProviders && props.DeliveryServiceProviders.length > 0 && (
          <>
            <Divider />
            <NestedMenuItem rightIcon={<ArrowRight />} label="Third-parties" parentMenuOpen={open}>
              {props.DeliveryServiceProviders.map((vCourier) => (
                <MenuItem
                  key={`dsp-menu-item-${vCourier.deliveryServiceProviderId}`}
                  onClick={() => {
                    handleSelectMenu(undefined, vCourier.deliveryServiceProviderId);
                  }}
                >
                  <CourierWithAvatar avatarUrl={vCourier.logo} name={vCourier.displayName} />
                </MenuItem>
              ))}
            </NestedMenuItem>
          </>
        )}
      </Menu>
    </div>
  );
}
