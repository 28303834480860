/* eslint-disable */
import { useEffect, useState } from 'react';
import ChatBox from '../../components/ChatBox/ChatBox';
import { Button, DialogTitle, FormControlLabel, Grid, Link, Switch, Typography } from '@mui/material';

import ChatSessionHeaderItem from './ChatSessionHeaderItem';
import './prospero.style.scss';
import { ProsperoIcon } from '../../assets/images';
import ProsperoMessage from './ProsperoMessage';
import { RootState } from '../../store/config/types';
import { ConnectedProps, connect } from 'react-redux';
import ProsperoService from '../../services/prospero.service';
import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';
import { CircularProgress, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import {
  AIChatInputMode,
  AIChatSession,
  AIChatStatuses,
  AIMessageParticipantType,
  AIMessageStruct,
  AIMessageType,
  AISolutionStatus,
  InputStates,
  confirmationOptions,
} from './prospero.types';
import { hasPermission } from '../../utils/permissions';
import JSONGrid from '../../components/JSONGrid/JSONGrid';
import { ChatHeader } from './AIChatHeader';
import { AIRAGKnowledgeManager } from './AIRAGKnowledgeManager';
import { Close } from '@material-ui/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import TypingGif from '../../assets/images/Typing.gif';
import AIWaitingMessage from './AIWaitingMessage';

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
function Prospero({ auth, setToast }: PropsFromRedux) {
  const [chatSessions, setChatSessions] = useState<AIChatSession[]>([]);
  const [currentSessionIndex, setCurrentSessionIndex] = useState<number>();
  const [messages, setMessages] = useState<AIMessageStruct[]>([]);
  const [processing, setProcessing] = useState(false);
  const [processDescription, setProcessDescription] = useState<string>('Wait a moment ...');
  const [inputState, setInputState] = useState<AIChatInputMode>('MESSAGING');
  const [streamMessage, setStreamMessage] = useState<string>('Processing');
  const [sendDisabled, setSendDisabled] = useState(false);
  const [debugMode, setDebugMode] = useState(true);
  const [isAIAdmin, setIsAIAdmin] = useState(false);
  const [tableViewerData, setTableViewerData] = useState<any[]>([]);
  const [jsonViewerData, setJsonViewerData] = useState<any>();
  const [ragMngId, setRagMngId] = useState<number>(-1);
  const [aiPartialResponse, setAiPartialResponse] = useState<string>();

  useEffect(() => {
    if (auth.account?.shipperUserId)
      ProsperoService.loadSessions().then((sessions: AIChatSession[]) => {
        setChatSessions(sessions);
      });
    setIsAIAdmin(hasPermission(auth?.account, ['AI.adminMode']));
    setDebugMode(hasPermission(auth?.account, ['AI.adminMode']));
  }, [auth]);

  useEffect(() => {
    if (currentSessionIndex !== undefined) {
      setMessages(chatSessions[currentSessionIndex].conversation);
    }
  }, [currentSessionIndex]);

  const handleStatusChange = (chatStatus: string) => {
    let description = 'Processing';
    switch (chatStatus as AIChatStatuses) {
      case AIChatStatuses.INIT:
        description = 'Initializing';
        break;
      case AIChatStatuses.ERROR:
        description = 'Error handling';
        break;
      case AIChatStatuses.STREAM_START:
        description = 'Thinking';
        break;
      case AIChatStatuses.AI_SOLVER_CONVERSATION:
        description = 'Finding solution';
        break;
      case AIChatStatuses.RUNNING_SOLUTION:
        description = 'Testing solution';
        break;
      case AIChatStatuses.FINALIZE:
        description = 'Finalizing output';
        break;
    }
    setProcessDescription(description);
  };
  const handleStreamMessage = (streamStr: string) => {
    setStreamMessage(streamStr);
  };
  const handleResponseRecieve = (response: string) => {
    setAiPartialResponse(response.replaceAll('\\n', '\n'));
  };
  const updateInputStatus = () => {
    let inState: AIChatInputMode = 'MESSAGING';
    if (messages.length) {
      const lastMessage = messages[messages.length - 1];
      switch (lastMessage.type) {
        case AIMessageType.CLARIFICATION_REQUEST:
          inState = 'CLARIFICATION';
          break;
        case AIMessageType.CONFIRMATION_REQUEST:
          inState = 'CONFIRMATION';
          break;
        case AIMessageType.CONFIRMATION_RESPONSE_REJECTED:
          inState = 'CORRECTION';
          break;
      }
    }
    if (processing) inState = 'PROCESSING';
    setInputState(inState);
  };

  useEffect(() => {
    setSendDisabled(InputStates[inputState].sendDisabled);
  }, [inputState]);

  useEffect(() => {
    updateInputStatus();
  }, [messages, processing]);

  const updateMessage = (index: number, updates: any) => {
    try {
      setMessages(
        messages.map((message, msgIndex) => {
          if (msgIndex === index) return { ...message, ...updates };
          return message;
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentMessageType = (): AIMessageType => {
    switch (inputState) {
      case 'CLARIFICATION':
        return AIMessageType.CLARIFICATION_RESPONSE;
      case 'CORRECTION':
        return AIMessageType.CONFIRMATION_RESPONSE_REJECTED;
      case 'CONFIRMATION':
        return AIMessageType.CONFIRMATION_RESPONSE_CONFIRMED;
    }
    return AIMessageType.MESSAGE;
  };
  const handleConfirmation = (answer: string) => {
    const messageBody = confirmationOptions.find((option) => option.name == answer)?.caption ?? answer;
    if (answer == 'yes') {
      updateMessage(messages.length - 1, { solutionStatus: AISolutionStatus.CONFIRMED });
      handleSendClick(messageBody, currentSessionIndex);
    } else {
      setInputState('CORRECTION');
      // updateMessage(messages.length - 1, { solutionStatus: AISolutionStatus.REJECTED });
    }
  };

  const handleTableViewerOpen = (data: any[]) => {
    const fixedData: any[] = data.map((row) => {
      let fixedRow: any = {};
      Object.keys(row).forEach((field) => {
        fixedRow[field] = row[field] ?? ' ';
      });
      return fixedRow;
    });
    setTableViewerData(fixedData);
  };

  const handleJsonViewerOpen = (data: string) => {
    setJsonViewerData(data);
  };

  const handleJsonViewerClose = () => {
    setJsonViewerData(undefined);
  };

  const handleRagManagementClick = (messageId: number) => {
    setRagMngId(messageId);
  };
  const handleRagManagementClose = (messageId: number) => {
    setRagMngId(-1);
    const mIdx = messages.findIndex((msg) => msg.aiChatMessageId === messageId);
    if (mIdx >= 0) updateMessage(mIdx, {});
  };

  const handleTableViewerClose = () => {
    setTableViewerData([]);
  };
  const handleChangeSession = (sIndex: number) => {
    setCurrentSessionIndex(sIndex);
  };
  const newSession = async (title: string, message?: AIMessageStruct, onComplete?: () => void) => {
    ProsperoService.createChatSession(title)
      .then((session: AIChatSession) => {
        if (message) {
          ProsperoService.sendChatMessage(
            session.aiChatSessionId,
            message,
            handleStatusChange,
            handleStreamMessage,
            handleResponseRecieve,
          )
            .then((conversation) => {
              session.conversation = conversation;
              setChatSessions([...chatSessions, session]);
              if (onComplete) onComplete();
            })
            .catch((err) => {
              throw err;
            });
        } else {
          setChatSessions([...chatSessions, session]);
          if (onComplete) onComplete();
        }
      })
      .catch((err) => {
        setToast(err.message, 'danger');
        console.log(err);
      });
  };
  const handleNewSessionClick = () => {
    const lastSessionIndex = chatSessions.length - 1;
    if (lastSessionIndex < 0 || chatSessions[lastSessionIndex].conversation.length) {
      newSession('Type a question and click on Send', undefined, () => {
        setCurrentSessionIndex(lastSessionIndex + 1);
      });
    } else {
      setCurrentSessionIndex(lastSessionIndex);
    }
  };
  // const handleStatusChange = (status: string) => {
  //   setProcessDescription(status);
  // };
  // const handleStreamMessage = (message: string) => {
  //   setStreamMessage(message);
  // };
  const addMessageToCurrentSession = (message: AIMessageStruct, onComplete?: () => void) => {
    if (currentSessionIndex !== undefined) {
      const sessionId = chatSessions[currentSessionIndex].aiChatSessionId;
      if (chatSessions[currentSessionIndex].conversation.length === 0) {
        ProsperoService.editChatSessionTitle(sessionId, message.message)
          .then(() => {
            chatSessions[currentSessionIndex].title = message.message;
            ProsperoService.sendChatMessage(
              sessionId,
              message,
              handleStatusChange,
              handleStreamMessage,
              handleResponseRecieve,
            )
              .then((conversation) => {
                chatSessions[currentSessionIndex].conversation = conversation;
                setMessages([...chatSessions[currentSessionIndex].conversation]);
              })
              .finally(() => {
                if (onComplete) onComplete();
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            setToast(err.message, 'danger');
            console.log(err);
          });
      } else {
        ProsperoService.sendChatMessage(
          sessionId,
          message,
          handleStatusChange,
          handleStreamMessage,
          handleResponseRecieve,
        )
          .then((conversation) => {
            // console.log(responseMessages);
            chatSessions[currentSessionIndex].conversation = conversation;
            setMessages([...chatSessions[currentSessionIndex].conversation]);
          })
          .catch((err) => {
            setToast(err.message, 'danger');
            console.log(err);
          })
          .finally(() => {
            if (onComplete) onComplete();
          });
      }
    }
  };
  const handleSendClick = (messageBody: string, currentConversationIdx?: number) => {
    setProcessing(true);
    setProcessDescription('Sending ...');
    setAiPartialResponse('');
    setProcessDescription('');
    let chatSession: AIChatSession;
    const sessionsLen = chatSessions.length;
    if (currentConversationIdx !== undefined) {
      chatSession = chatSessions[currentConversationIdx];

      addMessageToCurrentSession(
        {
          displayName: auth.account?.firstName ?? 'User',
          message: messageBody,
          type: getCurrentMessageType(),
          date: new Date(),
          sender: AIMessageParticipantType.USER,
        },
        () => {
          setProcessing(false);
        },
      );
    } else {
      newSession(
        messageBody,
        {
          type: AIMessageType.MESSAGE,
          message: messageBody,
          date: new Date(),
          displayName: auth.account?.firstName,
          sender: AIMessageParticipantType.USER,
          reciever: AIMessageParticipantType.AI,
        },
        () => {
          setCurrentSessionIndex(sessionsLen);
          setProcessing(false);
        },
      );
    }
  };

  const renderWelcomeMessage = () => {
    return (
      <Grid container flexGrow={1} justifyContent="center" alignItems="center">
        <div style={{ textAlign: 'center', margin: '10%' }}>
          <img src={ProsperoIcon} alt="Prospero" />
          <Typography variant="h4">Prospero</Typography>
          <Typography variant="caption">Your Buisness AI</Typography>
          <Typography variant="body1" pt={2}>
            Select a previous session to continue the conversation or type a question to create a new session
          </Typography>
        </div>
      </Grid>
    );
  };

  const NewConversationMessage = (
    <ProsperoMessage
      message={{
        message: `Welcome back ${auth.account?.firstName}, to start type a question `,
        date: new Date(),
        type: AIMessageType.WELCOME,
        displayName: 'AI',
        sender: AIMessageParticipantType.AI,
      }}
      mIndex={0}
    />
  );

  return (
    <>
      <ChatBox
        conversationHeaderList={chatSessions}
        currentConversationIndex={currentSessionIndex}
        messages={messages as any[]}
        selectOptions={confirmationOptions}
        inputType={inputState && InputStates[inputState].inputType}
        inputPlaceholder={inputState && InputStates[inputState].inputPlaceholder}
        renderChatHeader={
          isAIAdmin
            ? (conversationHeader) => (
              <ChatHeader
                user={auth.account}
                debugMode={debugMode}
                changeDebugMode={(checked) => {
                  setDebugMode(checked);
                }}
                conversationHeader={conversationHeader as any}
              />
            )
            : undefined
        }
        renderMessage={(message: any, mIndex: number) => (
          <ProsperoMessage
            debugMode={debugMode}
            message={message}
            mIndex={mIndex}
            onTableViewerOpen={handleTableViewerOpen}
            onJsonViewerOpen={handleJsonViewerOpen}
            onRagManageClick={(message, mindex) => handleRagManagementClick(mindex)}
          />
        )}
        renderSideBarHeader={() => {
          return <Typography variant="caption">Recent Sessions</Typography>;
        }}
        renderSideBarFooter={() => {
          return (
            <Link style={{ cursor: 'pointer' }} onClick={handleNewSessionClick}>
              + New Session
            </Link>
          );
        }}
        renderConversationHeader={(chatSession, index, isCurrent) => (
          <ChatSessionHeaderItem
            chatSession={chatSession}
            isCurrent={isCurrent}
            debugMode={debugMode}
            onClick={() => {
              handleChangeSession(index);
            }}
          />
        )}
        renderWelcomeMessage={renderWelcomeMessage}
        renderWaitingMessage={() => (
          <AIWaitingMessage
            aiPartialResponse={aiPartialResponse}
            debugMode={debugMode}
            processDescription={processDescription}
            streamMessage={streamMessage}
          />
        )}
        renderNewConversationMessage={() => NewConversationMessage}
        onSendClick={handleSendClick}
        onSelectOption={handleConfirmation}
        onSearch={ProsperoService.searchName}
        sendDisabled={sendDisabled}
        waiting={processing}
      />
      <Dialog open={tableViewerData.length > 0} onClose={handleTableViewerClose}>
        <JSONGrid data={tableViewerData} />
      </Dialog>
      <Dialog open={ragMngId >= 0} onClose={handleRagManagementClose} fullScreen>
        <DialogTitle>
          <Grid container>
            <Grid item flexGrow={1}>
              RAG Management
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  handleRagManagementClose(ragMngId);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {currentSessionIndex && (
            <Grid container>
              <Typography variant="subtitle1">{chatSessions[currentSessionIndex].title}</Typography>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent>
          <AIRAGKnowledgeManager
            message={messages.find((msg) => msg.aiChatMessageId == ragMngId)}
            knowledgeType="EXAMPLE"
            question={currentSessionIndex ? chatSessions[currentSessionIndex].title : ''}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={jsonViewerData} onClose={handleJsonViewerClose}>
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers wrapLongLines>
            {jsonViewerData}
          </SyntaxHighlighter>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connector(Prospero);
