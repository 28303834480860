
import { Popper, List, ListItem, Paper, ListItemButton, ListItemText } from "@mui/material"
import { useEffect, useState } from "react"

export interface ChatboxNameSearchFormProps {
    query: string
    anchorEl: any
    /* eslint-disable */
    onSearch?: (query: string) => Promise<string[]>
    onChoose?: (choice: string) => void
    /* es-lint-enable */

}

export default function ChatboxNameSearchForm({ query, onSearch, onChoose, anchorEl }: ChatboxNameSearchFormProps) {
    const [choices, setChoices] = useState<string[]>([])
    const [searching, setSearching] = useState(false)
    const [lastQuery, setLastQuery] = useState<string>('')

    useEffect(() => {
        if (!!onSearch && !searching) {
            // const timeDiff=new Date().valueOf()-lastQueryTime
            if (lastQuery != query) {
                setLastQuery(query)
                setSearching(true);
                onSearch(query).then((newChoices: string[]) => {
                    setChoices(newChoices)
                }).finally(() => {
                    setSearching(false)
                })
            }
        }
    }, [searching, query])
    if (!query) return <></>
    return (
        <Popper open={!!query && choices.length > 0} anchorEl={anchorEl}
            placement="top-start"
        >
            <Paper>

                <List dense>
                    {choices.map((choice, choiceIdx) => (
                        <ListItem key={`choice-${choiceIdx}`} disablePadding>
                            <ListItemButton onClick={() => { if (!!onChoose) onChoose(choice) }}>
                                <ListItemText primary={choice} />
                            </ListItemButton>
                        </ListItem>))}
                </List>

            </Paper>
        </Popper>
    )

}