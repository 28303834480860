import LockIcon from '../../assets/images/lock.svg';
interface OptimizationLockProps {
  doNotOptimize: boolean;
  // eslint-disable-next-line
  onChange?: (value: boolean) => void;
}
export default function OptimizationLock({ doNotOptimize, onChange }: OptimizationLockProps) {
  return (
    <div className="lock-area">
      {
        <img
          onClick={() => {
            const value = doNotOptimize;
            if (onChange) onChange(!value);
          }}
          className={`lock${doNotOptimize ? ' locked' : ''}`}
          src={LockIcon}
          width={16}
        />
      }
    </div>
  );
}
