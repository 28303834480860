import { Customer } from '../store/config/types';
import axios from './axios';

async function fetchCustomers(): Promise<Customer[] | undefined> {
  const res = await axios.get('/customers');
  return res.data.data;
}

async function createCustomer(createCustomerRequest: Customer): Promise<Customer | undefined> {
  const res = await axios.post('/customers', createCustomerRequest);
  return res.data.data;
}

async function updateCustomer(customerId: number, updateCustomerRequest: Customer): Promise<Customer | undefined> {
  const res = await axios.patch(`/customers/${customerId}`, updateCustomerRequest);
  return res.data.data;
}

export const customersService = { fetchCustomers, createCustomer, updateCustomer };
