import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grayColor, lightGrayColor, whiteColor } from '../../assets/jss/material-dashboard-react';
import GridItem from '../Grid/GridItem';

interface CustomToggleProps {
  value: boolean;
  // eslint-disable-next-line no-unused-vars
  setValue(value: boolean): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleContainer: {
      margin: '4px 0',
      display: 'flex',
      width: '100%',
      border: 'none',
      backgroundColor: lightGrayColor,
      borderRadius: 32,
      padding: '4px',
      minHeight: 40,
    },
    selectedButton: {
      border: 'none',
      borderRadius: 32,
      backgroundColor: theme.palette.primary.main,
      color: whiteColor,
      cursor: 'pointer',
    },
    unselectedButton: {
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: 32,
      cursor: 'pointer',
      color: grayColor,
    },
  }),
);

function CustomToggle({ value, setValue }: CustomToggleProps) {
  const classes = useStyles();

  return (
    <GridItem xs={12} lg={3} className="left-align options">
      <div className={classes.toggleContainer}>
        <button
          type="button"
          className={value ? classes.selectedButton : classes.unselectedButton}
          onClick={() => setValue(true)}
        >
          Same day
        </button>
        <button
          type="button"
          className={value ? classes.unselectedButton : classes.selectedButton}
          onClick={() => setValue(false)}
        >
          Scheduled
        </button>
      </div>
    </GridItem>
  );
}

export default CustomToggle;
