import { ChangeEvent } from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';
import InputField from '../SpecialInput/InputField';

interface MaskedPhoneInputProps {
  name: string;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  setfieldvalue: (name: string, value: string | number) => void;
}

function MaskedPhoneInput({ name, placeholder, setfieldvalue }: MaskedPhoneInputProps) {
  return (
    <Field name={name}>
      {({ field }: any) => (
        <InputMask
          {...field}
          type="tel"
          maskChar={null}
          mask="(999) 999-9999"
          placeholder=""
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setfieldvalue(name, event.target.value.replace(/[^0-9A-Za-z]/g, ''))
          }
        >
          {() => <InputField name={name} placeholder={placeholder} />}
        </InputMask>
      )}
    </Field>
  );
}

export default MaskedPhoneInput;
