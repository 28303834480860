import { CloudDownload } from '@material-ui/icons';
import { Card, CardContent, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

interface PODImageProps {
  type: string;
  url: string;
  title?: string;
  description?: string;
}
export default function PODImage({ type, url, title, description }: PODImageProps) {
  return (
    <Grid sm={6} xs={12} p={2}>
      <Card>
        <Zoom>
          <CardMedia sx={{ paddingTop: '75%' }} image={`${url}`} title={type} />
        </Zoom>

        <CardContent style={{ position: 'relative' }}>
          <Typography variant="subtitle1" component="div">
            {title ?? type}
          </Typography>
          <Typography variant="caption" component="div">
            {description}
          </Typography>
          <Tooltip title="Download" arrow placement="left">
            <a href={`${url}`} style={{ position: 'absolute', top: 10, right: 10 }} title="Download">
              <IconButton
                style={{ backgroundColor: 'white' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <CloudDownload />
              </IconButton>
            </a>
          </Tooltip>
        </CardContent>
      </Card>
    </Grid>
  );
}
