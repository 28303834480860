/* eslint-disable */
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AssemblyBuild,
  AssemblyBuildItem,
  FulfillmentItem,
  ItemLotQuantity,
  LotQuantity,
  LotUpdateStruct,
  OrderItem,
  Product,
  WarehouseWorkorderUpdateResponse,
  WorkorderUpdateStruct,
} from '../../store/config/types';
import { useEffect, useRef, useState } from 'react';
import WarehouseWorkService, { FulfillmentService } from '../../services/warehouseWork.service';
import { fixNumber, strOrAlt } from '../../utils/string.helpers';
import { AddCircle } from '@material-ui/icons';
import { WarehouseWorkReferenceType } from '../../store/config/enums/warehouseWork.enum';

export interface LotsDialogProps {
  orderItems?: OrderItem[];
  assemblyBuildItems?: AssemblyBuildItem[];
  product?: Product;
  onClose: () => void;
  onSuccess?: (updates: WarehouseWorkorderUpdateResponse) => void;
}

export default function LotsDialog({ orderItems, assemblyBuildItems, onClose, onSuccess, product }: LotsDialogProps) {
  const [processing, setProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [readyTosave, setReadyToSave] = useState(false);
  const [availableLots, setAvailableLots] = useState<LotQuantity[]>([]);
  const [itemsLots, setItemsLots] = useState<ItemLotQuantity[]>([]);

  useEffect(() => {
    if (!product) return;

    setProcessing(true);
    setStatusMessage('Loading available lots');
    const warehouseIds: number[] = [];
    let minQuantity: number = Infinity;
    const lots: ItemLotQuantity[] = [];
    orderItems?.forEach((item) => {
      lots.push({
        itemId: item.itemId,
        info: `${item.additionalData.label}:${item.additionalData.lineIndex}`,
        lotQuantities:
          item.fulfillmentItems?.map((ffItem) => ({
            inventoryLotQuantityId: ffItem.inventoryLotQuantityId ?? 0,
            lotId: ffItem.lotId,
            quantity: ffItem.quantity,
            warehouseId: item.additionalData.warehouse.warehouseId,
            fulfillmentItemId: ffItem.fulfillmentItemId,
            canEdit: ffItem.fulfilledQuantity === null,
          })) ?? [],
        totalQuantities: item.fulfillmentItems?.reduce((sum, ffItem) => sum + ffItem.quantity, 0) ?? 0,
        parentProductName: item.additionalData.originalProductName,
        productId: product?.productId,
      });
      if (
        item.additionalData.warehouse?.warehouseId &&
        !warehouseIds.includes(item.additionalData.warehouse.warehouseId)
      ) {
        warehouseIds.push(item.additionalData.warehouse.warehouseId);
      }
      if (minQuantity > item.quantityCommitted) minQuantity = item.quantityCommitted;
    });
    assemblyBuildItems?.forEach((assembly) => {
      const lotIdx = lots.findIndex((l) => l.itemId == assembly.assemblyBuildId);
      if (lotIdx < 0) {
        lots.push({
          itemId: assembly.assemblyBuildId,
          info: `${assembly.additionalData.label}`,
          lotQuantities: [
            {
              inventoryLotQuantityId: assembly.inventoryLotQuantityId ?? 0,
              lotId: assembly.lotId,
              quantity: assembly.committedQuantity,
              warehouseId: assembly.inventoryLotQuantity?.warehouseId,
              assemblyBuildItemId: assembly.assemblyBuildItemId,
              canEdit: assembly.builtQuantity === null,
            },
          ],
          totalQuantities: assembly.committedQuantity ?? 0,
          parentProductName: assembly.additionalData.originalProductName,
          productId: product?.productId,
        });
      } else {
        lots[lotIdx].lotQuantities.push({
          inventoryLotQuantityId: assembly.inventoryLotQuantityId ?? 0,
          lotId: assembly.lotId,
          quantity: assembly.committedQuantity,
          warehouseId: assembly.inventoryLotQuantity?.warehouseId,
          assemblyBuildItemId: assembly.assemblyBuildItemId,
          canEdit: assembly.builtQuantity === null,
        });
        lots[lotIdx].totalQuantities += assembly.committedQuantity ?? 0;
      }
      if (
        assembly.inventoryLotQuantity?.warehouseId &&
        !warehouseIds.includes(assembly.inventoryLotQuantity?.warehouseId)
      ) {
        warehouseIds.push(assembly.inventoryLotQuantity?.warehouseId);
      }
      if (minQuantity > assembly.committedQuantity) minQuantity = assembly.committedQuantity;
    });
    setItemsLots(lots);
    if (warehouseIds.length)
      FulfillmentService.getAvailableLotsFor(product?.productId, warehouseIds, minQuantity)
        .then((availlots) => {
          setAvailableLots(availlots);
          setStatusMessage('');
        })
        .finally(() => {
          setProcessing(false);
        });
  }, [product, orderItems, assemblyBuildItems]);

  function handleSaveLots() {
    setProcessing(true);
    if (orderItems?.length || assemblyBuildItems?.length) {
      const updateStruct: WorkorderUpdateStruct[] = [];
      const addFFitemTOUpdateStruct = (item: LotUpdateStruct, warehouseWorkOrderId?: number) => {
        const woIndex = updateStruct.findIndex((us) => us.warehouseWorkOrderId === warehouseWorkOrderId);
        if (woIndex < 0) {
          updateStruct.push({
            items: [item],
            warehouseWorkOrderId,
          });
        } else {
          updateStruct[woIndex].items.push(item);
        }
      };
      orderItems?.forEach((oItem) => {
        const itemLQ = itemsLots.find((il) => il.itemId === oItem.itemId);
        if (itemLQ) {
          const LQs = itemLQ.lotQuantities;
          oItem.fulfillmentItems?.forEach((ffItem) => {
            const ffLQ = LQs.find((lq) => lq.fulfillmentItemId === ffItem.fulfillmentItemId);

            // !ffLQ = deleted FFitem
            if (!ffLQ) {
              addFFitemTOUpdateStruct(
                {
                  itemId: ffItem.fulfillmentItemId,
                  oldLotQuantity: { inventoryLotQuantityId: ffItem.inventoryLotQuantityId, quantity: ffItem.quantity },
                  newLotQuantity: undefined,
                },
                ffItem.warehouseWorkOrderId,
              );
            } else {
              // check changes
              if (ffItem.inventoryLotQuantityId !== ffLQ.inventoryLotQuantityId || ffItem.quantity !== ffLQ.quantity) {
                addFFitemTOUpdateStruct(
                  {
                    itemId: ffItem.fulfillmentItemId,
                    parentId: oItem.itemId,
                    oldLotQuantity: {
                      inventoryLotQuantityId: ffItem.inventoryLotQuantityId,
                      quantity: ffItem.quantity,
                    },
                    newLotQuantity: ffLQ,
                  },
                  ffItem.warehouseWorkOrderId,
                );
              } else {
                // not changed
                // updateStruct[woIndex].items.push({
                //   itemId: ffItem.fulfillmentItemId,
                // });
              }
            }
          });
          // new WOs and new FFItems
          // #region TODO: multi WO not implemented
          const warehouseWorkOrderId = updateStruct.length ? updateStruct[0].warehouseWorkOrderId : undefined;
          LQs.filter((lq) => !lq.fulfillmentItemId).forEach((lq) => {
            addFFitemTOUpdateStruct(
              {
                newLotQuantity: lq,
                productId: itemLQ.productId,
                parentId: oItem.itemId,
              },
              warehouseWorkOrderId,
            );
          });
        }
      });

      assemblyBuildItems?.forEach((aItem) => {
        const itemLQ = itemsLots.find((il) => il.itemId === aItem.assemblyBuildId);
        if (itemLQ) {
          const abLQidx = itemLQ.lotQuantities.findIndex((lq) => lq.assemblyBuildItemId === aItem.assemblyBuildItemId);
          const abLQ = abLQidx >= 0 ? itemLQ.lotQuantities[abLQidx] : null;

          // !ffLQ = deleted FFitem
          if (!abLQ) {
            addFFitemTOUpdateStruct(
              {
                itemId: aItem.assemblyBuildItemId,
                oldLotQuantity: {
                  inventoryLotQuantityId: aItem.inventoryLotQuantityId,
                  quantity: aItem.committedQuantity,
                },
                newLotQuantity: undefined,
              },
              aItem.warehouseWorkOrderId,
            );
          } else {
            // check changes

            if (aItem.inventoryLotQuantityId !== abLQ.inventoryLotQuantityId || aItem.quantity !== abLQ.quantity) {
              addFFitemTOUpdateStruct(
                {
                  itemId: aItem.assemblyBuildItemId,
                  parentId: aItem.assemblyBuildId,
                  oldLotQuantity: {
                    inventoryLotQuantityId: aItem.inventoryLotQuantityId,
                    quantity: aItem.committedQuantity,
                  },
                  newLotQuantity: abLQ,
                },
                aItem.warehouseWorkOrderId,
              );
            } else {
              // not changed
              // updateStruct[woIndex].items.push({
              //   itemId: ffItem.fulfillmentItemId,
              // });
            }
          }
        }
      });
      if (assemblyBuildItems?.length) {
        // new WOs and new FFItems
        // #region TODO: multi WO not implemented
        const warehouseWorkOrderId = updateStruct.length ? updateStruct[0].warehouseWorkOrderId : undefined;
        itemsLots
          .filter((assemblyLQ) => assemblyLQ.itemId === assemblyBuildItems[0].assemblyBuildId)
          .forEach((assemblyLQ) => {
            assemblyLQ.lotQuantities
              .filter((lq) => !lq.assemblyBuildItemId)
              .forEach((lq) => {
                addFFitemTOUpdateStruct(
                  {
                    newLotQuantity: lq,
                    productId: assemblyLQ.productId,
                    parentId: assemblyLQ.itemId,
                  },
                  warehouseWorkOrderId,
                );
              });
          });
      }

      WarehouseWorkService.updateWorkorderLots(updateStruct)
        .then((updates) => {
          if (onSuccess) onSuccess(updates);
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }
  function handleSetLots(itemId: number, lots: LotQuantity[]) {
    const newItemsLots = itemsLots.map((item) => {
      return { ...item, lotQuantities: item.itemId === itemId ? lots : item.lotQuantities };
    });
    setItemsLots(newItemsLots);
    const canSave = newItemsLots.every(
      (item) => item.lotQuantities.reduce((sum, lot) => sum + lot.quantity, 0) >= item.totalQuantities,
    );
    console.log(newItemsLots, canSave);

    setReadyToSave(canSave);
  }

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={(!!orderItems?.length || !!assemblyBuildItems?.length) && !!product} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="subtitle2">
          Edit Lot IDs of{' '}
          <strong>{strOrAlt(product?.description, product?.name, `Product #${product?.productId}`)}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{orderItems ? 'SO:Line' : 'WO:Line'}</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Lot:Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsLots.map((itemLots, itemIndex) => {
              return (
                <TableRow key={`item-${itemIndex}`}>
                  <TableCell>
                    <Tooltip title={itemLots.parentProductName}>
                      <span>{itemLots.info}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{fixNumber(itemLots.totalQuantities)}</TableCell>
                  <TableCell>
                    <ItemLotManagement
                      availableLots={availableLots}
                      lots={itemLots.lotQuantities}
                      totalQuantity={itemLots.totalQuantities}
                      setLots={(lots) => handleSetLots(itemLots.itemId, lots)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item flexGrow={1} p={1}>
            <Grid alignContent="center">
              {processing && <CircularProgress size={12} />} <Typography variant="caption">{statusMessage}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="text" color="error" onClick={handleClose} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="success" disabled={processing || !readyTosave} onClick={handleSaveLots}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

interface LotManagementProps {
  availableLots: LotQuantity[];
  lots: LotQuantity[];
  totalQuantity: number;
  /* eslint- disable */
  setLots: (newLots: LotQuantity[]) => void;
  /* eslint- enable */
}
function ItemLotManagement({ availableLots, lots, totalQuantity, setLots }: LotManagementProps) {
  const [reminded, setReminded] = useState(0);
  const [addValue, setAddValue] = useState(0);
  const [selectedLot, setSelectedLot] = useState<LotQuantity>();
  const [mnuOpen, setMnuOpen] = useState(false);
  const newLotRef = useRef<any>();

  useEffect(() => {
    setReminded(totalQuantity - lots.reduce((sum, lot) => sum + lot.quantity, 0));
  }, [lots]);
  useEffect(() => {
    setAddValue(reminded);
  }, [reminded]);

  const onDeleteLot = (lotIndex: number) => {
    const newLots = [...lots];
    newLots.splice(lotIndex, 1);
    setLots(newLots);
  };
  const handleChooseLot = (lot: LotQuantity) => {
    setSelectedLot(lot);
    setMnuOpen(false);
  };
  const onAddLot = () => {
    if (selectedLot) {
      const newLots = [...lots];
      newLots.push({ ...selectedLot, quantity: Math.min(reminded, addValue) });
      setLots(newLots);
      setSelectedLot(undefined);
    }
  };
  return (
    <Table size="small">
      {lots?.map((lot, lotIndex) => (
        <TableRow key={lotIndex}>
          <TableCell style={{ border: 'none' }}>
            <Chip
              label={lot.lotId}
              onDelete={
                lot.canEdit
                  ? () => {
                      onDeleteLot(lotIndex);
                    }
                  : undefined
              }
              onClick={() => {}}
              variant="outlined"
              style={{ border: 'none' }}
            />
          </TableCell>
          <TableCell style={{ border: 'none' }}>{fixNumber(lot.quantity)}</TableCell>
          <TableCell style={{ border: 'none' }}></TableCell>
        </TableRow>
      ))}
      {reminded > 0 && (
        <TableRow>
          <TableCell style={{ border: 'none' }}>
            <Button ref={newLotRef} onClick={() => setMnuOpen(true)}>
              {selectedLot ? selectedLot.lotId : 'Choose a lot'}
            </Button>
          </TableCell>
          <TableCell style={{ border: 'none' }}>
            <TextField
              type="number"
              placeholder="Quantity"
              size="small"
              variant="standard"
              style={{ width: 50 }}
              defaultValue={reminded}
              value={addValue}
              onChange={(e) => {
                setAddValue(+e.target.value);
              }}
            />
          </TableCell>
          <TableCell style={{ border: 'none' }}>
            <IconButton size="small" color="success" disabled={!selectedLot || !addValue} onClick={onAddLot}>
              <AddCircle />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      <Menu open={mnuOpen} onClose={() => setMnuOpen(false)} anchorEl={newLotRef.current}>
        {availableLots.map((lot) => (
          <MenuItem
            key={lot.inventoryLotQuantityId}
            value={lot.inventoryLotQuantityId}
            onClick={() => handleChooseLot(lot)}
          >
            {lot.lotId} : {fixNumber(lot.quantity)}
          </MenuItem>
        ))}
      </Menu>
    </Table>
  );
}
