import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { NotificationTemplate } from '../../store/config/types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface NotificationTemplateEditDialogProps {
  open: boolean;
  isNew: boolean;
  template?: NotificationTemplate | undefined;
  onCancelClick?: () => void;
  // eslint-disable-next-line no-unused-vars
  onSaveClick?: (template: NotificationTemplate, isNewTemplate: boolean) => void;
}
const menuItems = {
  my: [
    { title: 'First Name', value: '{my.firstName}' },
    { title: 'Last Name', value: '{my.lastName}' },
    { title: 'Full Name', value: '{my.fullName}' },
    { title: 'Phone Number', value: '{my.phoneNumber}' },
    { title: 'Company Name', value: '{my.companyName}' },
    { title: 'Email', value: '{my.email}' },
    { title: 'Address', value: '{my.address}' },
  ],
  customer: [
    { title: 'First Name: ', value: '{customer.firstName}' },
    { title: 'Last Name: ', value: '{customer.lastName}' },
    { title: 'Full Name: ', value: '{customer.fullName}' },
    { title: 'Contact Phone Number: ', value: '{customer.contactPhoneNumber}' },
    { title: 'Email: ', value: '{customer.email}' },
    { title: 'Time Window: ', value: '{customer.timeWindow}' },
    { title: 'Special Instructions: ', value: '{customer.specialInstructions}' },
  ],
};
interface InfoMenuProps {
  caption: string;
  data: { title: string; value: string }[];
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (text: string) => void;
}
function InfoMenu({ caption, data, onItemClick }: InfoMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = (text: string) => {
    if (onItemClick) onItemClick(text);
    handleClose();
  };

  return (
    <span>
      <Link
        underline="hover"
        style={{ cursor: 'pointer' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {caption}
        <ArrowDropDownIcon style={{ verticalAlign: 'middle', marginLeft: 0, marginRight: 5 }} />
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data.map((item) => {
          return (
            <MenuItem onClick={() => handleItemClick(item.value)} key={`item-${item.title}`}>
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </span>
  );
}

function NotificationTemplateEditDialog({
  open = false,
  isNew = false,
  template,
  onCancelClick,
  onSaveClick,
}: NotificationTemplateEditDialogProps) {
  const [templateBody, setTemplateBody] = useState<string>(template?.body ?? '');
  const [templateName, setTemplateName] = useState<string>(template?.name ?? 'Custom Template');
  const [templateType, setTemplateType] = useState<string>(template?.type ?? 'ON_SHIPPING');
  const handleInfoMenuClick = (text: string) => {
    setTemplateBody(templateBody + text);
  };
  const handleCancelClick = () => {
    setTemplateBody(template?.body ?? '');
    if (onCancelClick) onCancelClick();
  };
  const handleType = (newType: string) => {
    setTemplateType(newType);
  };
  const handleSaveClick = () => {
    const editedTemplate: NotificationTemplate = {
      id: template?.id ?? 0,
      body: templateBody,
      type: isNew ? templateType : template?.type ?? 'ON_SHIPPING',
      name: templateName,
    };
    if (onSaveClick) onSaveClick(editedTemplate, isNew);
  };
  useEffect(() => {
    setTemplateBody(template?.body ?? '');
    setTemplateName(isNew ? 'New Template' : template?.name ?? 'Custom Template');
  }, [open, isNew]);
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        {isNew && (
          <ToggleButtonGroup
            value={templateType}
            exclusive
            onChange={(e, t) => {
              handleType(t);
            }}
            aria-label="text alignment"
            color="primary"
          >
            <ToggleButton value="ON_SHIPPING" aria-label="On Shipping">
              On Shipping
            </ToggleButton>
            <ToggleButton value="ON_DELIVERY" aria-label="On Delivery">
              On Delivery
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          defaultValue={template?.name}
          value={templateName}
          onChange={(event) => setTemplateName(event.target.value)}
        />
      </DialogTitle>
      <DialogContent>
        <InfoMenu data={menuItems.my} caption="My Info" onItemClick={handleInfoMenuClick} />
        <InfoMenu data={menuItems.customer} caption="Customer Info" onItemClick={handleInfoMenuClick} />
        <TextField
          margin="dense"
          id="body"
          label="Body"
          fullWidth
          variant="outlined"
          defaultValue={templateBody}
          value={templateBody}
          onChange={(e) => {
            setTemplateBody(e.target.value);
          }}
          multiline
          rows={8}
        />
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={handleCancelClick}>Cancel</Button>
          <Button onClick={handleSaveClick}>Save</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationTemplateEditDialog;
