import { Button, Grid, Typography } from '@mui/material';
import { ChatBoxOptionStruct } from './chatbox.types';

export interface ChatboxOptionsInputProps {
  options: ChatBoxOptionStruct[];
  caption?: string;
  disabled?: boolean;
  // eslint-disable-next-line
  onSelectOption: (name: string) => void;
}

export default function ChatboxOptionsInput({ options, caption, disabled, onSelectOption }: ChatboxOptionsInputProps) {
  const handleOptionClick = (name: string) => {
    if (onSelectOption) onSelectOption(name);
  };
  return (
    <Grid container alignItems="center">
      {caption && (
        <Grid item mr={2}>
          <Typography variant="subtitle2" color="GrayText">
            {caption}
          </Typography>
        </Grid>
      )}
      {options.map((option, index) => (
        <Grid item key={`option-${index}`} mr={2}>
          <Button
            disabled={disabled}
            color={option.color ?? 'info'}
            variant="outlined"
            onClick={() => handleOptionClick(option.name)}
          >
            {option.caption ?? option.name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
