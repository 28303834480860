import { Chip, Divider, Grid } from '@mui/material';
import { AssemblyBuildItem, OrderItem } from '../../store/config/types';
import { useEffect, useState } from 'react';
import { fixNumber } from '../../utils/string.helpers';

interface LotsProps {
  orderItems?: OrderItem[];
  assemblyBuildItems?: AssemblyBuildItem[];
  // eslint-disable-next-line
  onClick?: (items: any[]) => void;
}
interface LotQtyStruct {
  lotId: string;
  inventoryLotQuantityId: number;
  quantity: number;
  unit: string;
  quantityFulfilled?: null | number;
}
export default function Lots({ orderItems, assemblyBuildItems, onClick }: LotsProps) {
  const [lotQty, setLotQty] = useState<LotQtyStruct[]>([]);
  useEffect(() => {
    const units: any = {};
    const lq: LotQtyStruct[] = [];
    orderItems?.forEach((orderItem) => {
      if (orderItem?.fulfillmentItems?.length) {
        if (orderItem.product) {
          units[orderItem.product.productId] = orderItem.product.unitOfMeasure;
          orderItem.product.subProducts?.forEach((p) => (units[p.productId] = p.unitOfMeasure));
        }
        orderItem.fulfillmentItems.forEach((ffItem) => {
          if (ffItem.inventoryLotQuantityId || ffItem.lotId)
            lq.push({
              inventoryLotQuantityId: ffItem.inventoryLotQuantityId ?? 0,
              lotId: ffItem.lotId ?? 'unknown',
              quantity: fixNumber(ffItem.quantity),
              unit: units[ffItem.productId ?? 0],
              quantityFulfilled: ffItem.fulfilledQuantity,
            });
        });
      }
    });
    assemblyBuildItems?.forEach((abItem) => {
      if (abItem.product) {
        units[abItem.product.productId] = abItem.product.unitOfMeasure;
      }

      if (abItem.inventoryLotQuantityId || abItem.lotId)
        lq.push({
          inventoryLotQuantityId: abItem.inventoryLotQuantityId ?? 0,
          lotId: abItem.lotId ?? 'unknown',
          quantity: fixNumber(abItem.quantity),
          unit: units[abItem.productId ?? 0],
          quantityFulfilled: abItem.builtQuantity,
        });
    });
    setLotQty(lq);
  }, [orderItems, assemblyBuildItems]);
  return (
    <Grid container>
      {lotQty.map((lq, lqIndex) => (
        <Chip
          key={lqIndex}
          variant="outlined"
          color={lq.quantityFulfilled === null ? 'info' : 'success'}
          onClick={
            lq.quantityFulfilled === null
              ? () => {
                  if (onClick && orderItems?.length) onClick(orderItems);
                  if (onClick && assemblyBuildItems?.length) onClick(assemblyBuildItems);
                }
              : undefined
          }
          style={{ borderRadius: 10, borderStyle: 'none' }}
          label={
            <Grid container>
              <Grid item pr={1}>
                {lq.lotId}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item pl={1}>
                {lq.quantity}
                {lq.unit ? ` ${lq.unit}` : ''}
              </Grid>
            </Grid>
          }
        />
      ))}
    </Grid>
  );
}
